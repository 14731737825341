import React, { useEffect, useState, useCallback } from 'react'
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import { withLocalizationConsumer } from '../../coraWebMComponents/utils/withLocalization';
import { useSelector, useDispatch } from 'react-redux';
import { adminModeSetEditedData, adminModeSetCurrEdited, adminModeUpdateOrAdd } from '../../actions/adminModeActions';
import { IFrmTxt } from '../../constants/adminConsts';
import ReactDOMServer from 'react-dom/server';
import Editor from '../../coraWebMComponents/inputs/Editor';
import { withStyles } from '@material-ui/core';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import { renderToString } from 'react-dom/server'


const styles = (theme) => ({
  ...getSharedStyles(theme),
  dialogDropdown: {
    overflowY: 'visible !important',
    wordWrap: 'break-word',
  }
});

const InfoDialog = ({ open, handleClose, locale, title, translateWithLocaleAsync, handleSetVisible, N_KLUC, classes, ...props }) => {
  const [text, setText] = useState('');
  const [textEdit, setTextEdit] = useState(false);
  const [openSaveDialog, setOpenSaveDialog] = useState(false);
  const { adminMode } = useSelector((state) => state);
  const dispatch = useDispatch();

  const ReactToHtml = (component) => {
    const HTML = ReactDOMServer.renderToStaticMarkup(component);
    const HTMLstring = HTML.toString();
    return HTMLstring;
  };

  const textInit = useCallback(async () => {
    translateWithLocaleAsync(locale, N_KLUC)
      .then((res) => {
        setText(res);
      });
  }, [locale, translateWithLocaleAsync, N_KLUC]);

  useEffect(() => {
    if (typeof handleSetVisible === 'function') {
      if (adminMode.isActive) {
        handleSetVisible(true)
      } else {
        const stringHtml = renderToString(text);
        const strippedHtml = stringHtml.replace(/<[^>]+>/g, '');
        if (strippedHtml.length === 0) {
          handleSetVisible(false)
        } else {
          handleSetVisible(true)
        }
      }
    }
  }
    , [text, adminMode, handleSetVisible])

  useEffect(() => {
    !openSaveDialog && textInit();
  }, [textInit, openSaveDialog]);

  const handleCurrEdited = (value) => {
    dispatch(adminModeSetCurrEdited(value));
    dispatch(adminModeSetEditedData(null));
    setTextEdit(true);
  };

  const handleEditData = (data) => {
    dispatch(
      adminModeSetEditedData([
        {
          TxtFrm: data,
          locale: locale,
          N_KLUC: N_KLUC,
        },
      ])
    );
  };

  const handleSaveSection = async () => {
    const { dataEdited } = adminMode;
    await dispatch(adminModeUpdateOrAdd(IFrmTxt, dataEdited));
    await dispatch(adminModeSetCurrEdited(null));
    setOpenSaveDialog(false);
    setTextEdit(false);
  };

  const onClose = () => {
    handleClose();
    setTextEdit(false);
  };

  const getDialogContent = () =>
    <Editor value={ReactToHtml(text)}
      onChange={(value) => handleEditData(value)}
    />

  const getDialogActions = () => {
    return adminMode.isActive && !textEdit ? [
      {
        label: 'Upraviť',
        color: 'secondary',
        onClick: () => { handleCurrEdited(props.currEdited) },
        keyboardFocused: true
      },
      {
        label: 'Zrušiť',
        color: 'primary',
        onClick: onClose,
        keyboardFocused: true
      }
    ] : textEdit ? [
      {
        label: 'Uložiť',
        color: 'secondary',
        onClick: () => { setOpenSaveDialog(true) },
        keyboardFocused: true
      },
      {
        label: 'Zrušiť',
        color: 'primary',
        onClick: onClose,
        keyboardFocused: true
      }
    ] :
      [
        {
          label: 'Zrušiť',
          color: 'primary',
          onClick: onClose,
          keyboardFocused: true
        }
      ]
  }


  return (
    <>
      <Dialog
        open={open}
        modal={true}
        onClose={onClose}
        contentText={!textEdit ? ReactDOMServer.renderToString(text) : null}
        content={textEdit ? getDialogContent() : null}
        contentClasses={{ root: classes.dialogDropdown }}
        title={title ? title : null}
        classes={{ dialogPaper: classes.dialogDropdown }}
        actions={getDialogActions()}

      />
      {adminMode.isActive && (
        <Dialog
          modal={true}
          open={openSaveDialog}
          onClose={null}
          contentText="Informácia: Potvrdením uložíte zmeny"
          title="Uloženie nastavení"
          actions={[
            {
              label: "Nie",
              color: "primary",
              onClick: () => setOpenSaveDialog(false),
              keyboardFocused: false,
            },
            {
              label: "Áno",
              color: "secondary",
              onClick: handleSaveSection,
              keyboardFocused: true,
            },
          ]}
        />
      )}
    </>
  )
}



export default withLocalizationConsumer(withStyles(styles)(InfoDialog));