import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import Button from '../../coraWebMComponents/inputs/Button';
import classNames from 'classnames';
import * as taxesActions from '../../actions/taxesActions';
import EuroCardIcon from '../../coraWebMComponents/dataDisplay/icons/EuroCard';
import MoreVertIcon from '../../coraWebMComponents/dataDisplay/icons/MoreVertIcon';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import MenuItem from '../../coraWebMComponents/dataDisplay/MenuItem';
import EventIcon from '../../coraWebMComponents/dataDisplay/icons/EventIcon';
import InfoIcon from '../../coraWebMComponents/dataDisplay/icons/InfoIcon';
import EuroSymbolIconComponent from '../../coraWebMComponents/dataDisplay/icons/EuroIcon';
import Menu from '../../coraWebMComponents/dataDisplay/Menu';
import InsertFile from '../../coraWebMComponents/dataDisplay/icons/InsertDriveFileOutlined';
import ProgressBar from '../../coraWebMComponents/dataDisplay/ProgressBar';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import { custStateCell, getColor, nbWithSpaces } from './Taxes';

const styles = theme => ({
  ...getSharedStyles(theme),
  pdfBtn: {
    border: '2px solid #ebebeb',
    display: 'flex',
    justifyContent: 'space-between',
    textTransform: 'none',
  },
  MuiLinearProgressBar529: {
    width: '14em',
  },
})

class TaxesCard extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      isLoading: false
    };
  }

  handleMenu = event => {
    event.stopPropagation();
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  handleGetPdfTaxProceeding = async (e, iFile = 0) => {
    e.stopPropagation();
    this.setState({ isLoading: true });
    await this.props.getPdfTaxProceeding(iFile);
    this.setState({ isLoading: false });
  }

  render() {
    const { classes, data, title } = this.props;
    const { anchorEl } = this.state;
    let value = data.PLATBA * 100 / data.SK;
    return (
      <div onClick={(event) => { Boolean(anchorEl) ? this.handleClose() : this.props.showDetail(event) }}>
        <Paper elevation={2} className={classNames(classes.ziadostCard, classes.mb3)} >
          <div className={classes.cardContent}>
            <CircularProgress loading={this.state.isLoading} />
            <div className={classNames(classes.row, classes.alignItemsCenter, classes.justifyContentStart, classes.mb2)}>
              <EuroCardIcon style={{ width: "45", height: "30" }}></EuroCardIcon>
              <div className={classNames(classes.ml2)}>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{title} - <span style={{ fontWeight: 'normal', fontSize: '16px' }}>{data.N_KON_TYP}</span></Typography>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <IconButton
                  onClick={event => { this.handleMenu(event) }}
                  color="inherit"
                  toolTip="Viac"
                  toolTipPlace="left"
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id={'menuCollectiveDecision'}
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={event => { this.props.showDetail(event) }}>
                    <InfoIcon style={{ marginRight: '7px' }} />
                    <Typography style={{ marginRight: '22px' }}>Detail</Typography>
                  </MenuItem>
                  <MenuItem onClick={event => { this.props.showInstallments(event) }}>
                    <EventIcon style={{ marginRight: '7px' }} />
                    <Typography style={{ marginRight: '22px' }}> Splátky</Typography>
                  </MenuItem>
                  <MenuItem onClick={event => { this.props.showPayments(event) }}>
                    <EuroSymbolIconComponent style={{ marginRight: '7px' }} />
                    <Typography>Úhrady</Typography>
                  </MenuItem>
                </Menu>
              </div>
            </div>
            <div className={classNames(classes.row, classes.alignItemsCenter, classes.mt2)} >
              <Typography variant="body1">Rok</Typography>
              <Typography variant="body2">{data.ROK}</Typography>
            </div>
            {data.DETAIL.length > 0 &&
              <div className={classNames(classes.row, classes.alignItemsCenter)} >
                <Typography variant="body1">Stav</Typography>
                <Typography variant="body2" style={{ color: getColor(data.STAV, data.DETAIL.every(i => i.L_UHR_PB === true) ? 1 : 0) }}>{custStateCell(data)}</Typography>
              </div>
            }
            <div className={classNames(classes.row, classes.alignItemsCenter)} >
              <Typography variant="body1">Dátum splatnosti</Typography>
              <Typography variant="body2" classes={{ root: classes.textRight }} >{data.D_SPLAT}</Typography>
            </div>
            {data.PLATBA < 0 &&
              <div className={classNames(classes.row, classes.alignItemsCenter)} >
                <Typography variant="body1">Preplatok</Typography>
                <Typography variant="body2">{nbWithSpaces(Math.abs(data.PLATBA).toFixed(2)) + ' '}{data.MENA === "EUR" ? "€" : data.MENA}</Typography>
              </div>
            }
            {data.PLATBA >= 0 && (data.DETAIL.every(i => i.L_UHR_PB === true) || data.STAV === 1) &&
              <React.Fragment>
                <div className={classNames(classes.row, classes.alignItemsCenter)} >
                  <Typography variant="body1">Celková suma</Typography>
                  <Typography variant="body2">{nbWithSpaces(Math.abs(data.SK).toFixed(2)) + ' '}{data.MENA === "EUR" ? "€" : data.MENA}</Typography>
                </div>
                <div className={classNames(classes.row, classes.alignItemsCenter)} >
                  <Typography variant="body1">Zostáva k úhrade</Typography>
                  <Typography variant="body2">0.00 {data.MENA === "EUR" ? "€" : data.MENA}</Typography>
                </div>
                <div className={classNames(classes.row, classes.alignItemsCenter)} >
                  <ProgressBar style={{ width: '7em', borderRadius: '5px' }} value={0} color="secondary" />
                </div>
              </React.Fragment>
            }
            {data.PLATBA > 0 && data.DETAIL.some(i => i.L_UHR_PB === false) &&
              <React.Fragment>
                <div className={classNames(classes.row, classes.alignItemsCenter)} >
                  <Typography variant="body1">Celková suma</Typography>
                  <Typography variant="body2">{nbWithSpaces(Math.abs(data.SK).toFixed(2)) + ' '}{data.MENA === "EUR" ? "€" : data.MENA}</Typography>
                </div>
                <div className={classNames(classes.row, classes.alignItemsCenter)} >
                  <Typography variant="body1">Zostáva k úhrade</Typography>
                  <Typography variant="body2">{nbWithSpaces(data.PLATBA.toFixed(2))}{' '}{data.MENA === "EUR" ? "€" : data.MENA}</Typography>
                </div>
                <div className={classNames(classes.row, classes.alignItemsCenter)} >
                  <ProgressBar style={{ width: '7em', borderRadius: '5px' }} value={value} color="secondary" />
                </div>
              </React.Fragment>
            }
            {data.I_FILE !== null &&
              <div className={classes.row} >
                <Button
                  variant="outlined"
                  color="inherit"
                  size="large"
                  type="button"
                  className={classNames(classes.w100, classes.mt2, classes.pdfBtn)}
                  onClick={(e) => this.handleGetPdfTaxProceeding(e, data.I_FILE)}
                >
                  Dokument PDF
                  <InsertFile />
                </Button>
              </div>
            }
          </div>
        </Paper>
      </div>
    );
  }
}

TaxesCard.propTypes = {
  getColor: PropTypes.func,
  handleMenu: PropTypes.func,
  handleClose: PropTypes.func,
  handleGetPdfTaxProceeding: PropTypes.func,
}

const mapStateToProps = (state) => ({
  isLoading: state.listTaxesDecision.isLoading,
  listTaxesDecision: state.listTaxesDecision.data,
})

const mapDispatchToProps = (dispatch) => ({
  getPdfTaxProceeding: (iFile) => dispatch(taxesActions.getPdfTaxProceeding(iFile)),
});


export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(TaxesCard)));
