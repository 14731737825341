import React from 'react';
import { withLocalizationConsumer } from "../../coraWebMComponents/utils/withLocalization";
import { connect } from "react-redux";
import { getParkPlaces } from "../../utils/parkingUtils";
import { withStyles } from "@material-ui/core/styles";
import CircularProgress from "../../coraWebMComponents/feedBack/progress/CircularProgress";
import Map from '../../coraWebMComponents/map/Map';
import MapPolygons from "./MapPolygons";
const styles = theme => ({
  root: {
  }
})


class MapPlaces extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parkPlaces: [],
      loading: true
    }
  }

  componentDidMount = async () => {


    getParkPlaces().then((result) => {
      const parkPlaces = result.Data.Items.map((place) => {
        return {
          coordinates: place.geometry.coordinates[0].map((coord) => {
            return [coord[1], coord[0]];
          }),
          key: place.properties.I_PARK_MIESTO_GEO,
          color: place.properties.PARK_MIESTO_STYLE,
          iMiestoTyp: place.properties.I_MIESTO_TYP,
          iStavMiesto: place.properties.I_STAV_MIESTO,
          nU: place.properties.N_U,
          cParkMiesto: place.properties.C_PARK_MIESTO,
        }
      })
      this.setState({ parkPlaces });

    }).finally(() => {
      this.setState({ loading: false });
    })
  }

  render() {
    return (
      <>
        <CircularProgress loading={this.state.loading} />
        <Map
          position={window.config.map.defaultPosition}
          zoom={window.config.map.placesMapDefaultZoom}
          maxZoom={window.config.map.maxZoomLevel}
          minZoom={window.config.map.minZoomLevel}
          scrollWheelZoom={true}
          height={window.innerHeight}
          markers={[]}
          zoomControlPosition="bottomleft"
          currentPositionMarker={false}
          maxBounds={[[window.config.map.defaultPosition[0] - window.config.map.maxBoundsOffsetX,
          window.config.map.defaultPosition[1] - window.config.map.maxBoundsOffsetY],
          [window.config.map.defaultPosition[0] + window.config.map.maxBoundsOffsetX,
          window.config.map.defaultPosition[1] + window.config.map.maxBoundsOffsetY]]}
        >
          <MapPolygons polygons={this.state.parkPlaces} dialogType="parkPlaces" />
        </Map>
      </>



    )
  }
}


export default withLocalizationConsumer(connect()(withStyles(styles)(MapPlaces)));
