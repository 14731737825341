import actionTypes from './actionTypes';
import * as ciselnikyUtils from '../utils/ciselnikyUtils';
import * as adminModeUtils from "../utils/adminModeUtils";
import { ISpDefOb } from "../constants/adminConsts";

const getListCountriesRequest = () => ({
  type: actionTypes.GET_LIST_COUNTRIES_REQUEST
});

const getListCountriesSuccess = (res) => ({
  type: actionTypes.GET_LIST_COUNTRIES_SUCCESS,
  res
});

const getListCountriesFailure = (error) => ({
  type: actionTypes.GET_LIST_COUNTRIES_FAILURE,
  error
});

export const getListCountries = (filter) => {
  return async (dispatch) => {
    try {
      dispatch(getListCountriesRequest());
      let res = await ciselnikyUtils.getListCountries(filter);
      dispatch(getListCountriesSuccess(res));
    } catch (error) {
      dispatch(getListCountriesFailure(error));
    }
  }
}

const getCountryRequest = () => ({
  type: actionTypes.GET_COUNTRY_REQUEST
});

const getCountrySuccess = (res) => ({
  type: actionTypes.GET_COUNTRY_SUCCESS,
  res
});

const getCountryFailure = (error) => ({
  type: actionTypes.GET_COUNTRY_FAILURE,
  error
});

export const getCountry = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getCountryRequest());
      let res = await ciselnikyUtils.getCountry(id);
      dispatch(getCountrySuccess(res));
    } catch (error) {
      dispatch(getCountryFailure(error));
    }
  }
}

const getCityOfSetupRequest = () => ({
  type: actionTypes.GET_CITY_OF_SETUP_REQUEST
});

const getCityOfSetupSuccess = (res) => ({
  type: actionTypes.GET_CITY_OF_SETUP_SUCCESS,
  res
});

const getCityOfSetupFailure = (error) => ({
  type: actionTypes.GET_CITY_OF_SETUP_FAILURE,
  error
});

export const getCityOfSetup = () => {
  return async (dispatch) => {
    try {
      dispatch(getCityOfSetupRequest());
      let res = await ciselnikyUtils.getPh(999998015);
      dispatch(getCityOfSetupSuccess(res));
    } catch (error) {
      dispatch(getCityOfSetupFailure(error));
    }
  }
}

const getIbanParkingRequest = () => ({
  type: actionTypes.GET_IBAN_PARKING_REQUEST
});

const getIbanParkingSuccess = (res) => ({
  type: actionTypes.GET_IBAN_PARKING_SUCCESS,
  res
});

const getIbanParkingFailure = (error) => ({
  type: actionTypes.GET_IBAN_PARKING_FAILURE,
  error
});

export const getIbanParking = () => {
  return async (dispatch) => {
    try {
      dispatch(getIbanParkingRequest());
      let res = await ciselnikyUtils.getPh(709007);
      dispatch(getIbanParkingSuccess(res));
    } catch (error) {
      dispatch(getIbanParkingFailure(error));
    }
  }
}

const getListDriveSuccess = (res) => ({
  type: actionTypes.GET_LIST_DRIVE_SUCCESS,
  res
});

const getListDriveRequest = () => ({
  type: actionTypes.GET_LIST_DRIVE_REQUEST
});

const getListDriveFailure = (error) => ({
  type: actionTypes.GET_LIST_DRIVE_FAILURE,
  error
});

export const getListDrive = (filter) => {
  return async (dispatch) => {
    try {
      dispatch(getListDriveRequest());
      let res = await ciselnikyUtils.getDrive(filter);
      dispatch(getListDriveSuccess(res));
    } catch (error) {
      dispatch(getListDriveFailure(error));
      console.error(error);
    }
  }
}

const getListZoneSuccess = (res) => ({
  type: actionTypes.GET_LIST_ZONE_SUCCESS,
  res
});

const getListZoneRequest = () => ({
  type: actionTypes.GET_LIST_ZONE_REQUEST
});

const getListZoneFailure = (error) => ({
  type: actionTypes.GET_LIST_ZONE_FAILURE,
  error
});

export const getListZone = () => {
  return async (dispatch) => {
    try {
      dispatch(getListZoneRequest());
      let res = await ciselnikyUtils.getListZone();
      dispatch(getListZoneSuccess(res));
    } catch (error) {
      dispatch(getListZoneFailure(error));
      console.error(error);
    }
  }
}

const getListZoneParkShortSuccess = (res) => ({
  type: actionTypes.GET_LIST_ZONE_PARK_SHORT_SUCCESS,
  res
});

const getListZoneParkShortRequest = () => ({
  type: actionTypes.GET_LIST_ZONE_PARK_SHORT_REQUEST
});

const getListZoneParkShortFailure = (error) => ({
  type: actionTypes.GET_LIST_ZONE_PARK_SHORT_FAILURE,
  error
});

export const getListZoneParkShort = (filter) => {
  return async (dispatch) => {
    try {
      dispatch(getListZoneParkShortRequest());
      let res = await ciselnikyUtils.getListZoneParkShort(filter);
      dispatch(getListZoneParkShortSuccess(res));
    } catch (error) {
      dispatch(getListZoneParkShortFailure(error));
      console.error(error);
    }
  }
}

const getListCardTypeSuccess = (res) => ({
  type: actionTypes.GET_LIST_CARD_TYPE_SUCCESS,
  res
});

const getListCardTypeRequest = () => ({
  type: actionTypes.GET_LIST_CARD_TYPE_REQUEST
});

const getListCardTypeFailure = (error) => ({
  type: actionTypes.GET_LIST_CARD_TYPE_FAILURE,
  error
});

export const getListCardType = (zony, iOtyp, iKartaKat) => {
  return async (dispatch) => {
    try {
      dispatch(getListCardTypeRequest());
      let res = await ciselnikyUtils.getListCardType(zony || '', iOtyp === 1 ? false : true, iKartaKat);
      dispatch(getListCardTypeSuccess(res));
    } catch (error) {
      dispatch(getListCardTypeFailure(error));
      console.error(error);
    }
  }
}


const getCardTypeRequest = () => ({
  type: actionTypes.GET_CARD_TYPE_REQUEST
});

const getCardTypeSuccess = (res) => ({
  type: actionTypes.GET_CARD_TYPE_SUCCESS,
  res
});

const getCardTypeFailure = (error) => ({
  type: actionTypes.GET_CARD_TYPE_FAILURE,
  error
});

export const getCardType = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getCardTypeRequest());
      let res = await ciselnikyUtils.getCardType(id);
      dispatch(getCardTypeSuccess(res));
    } catch (error) {
      dispatch(getCardTypeFailure(error));
    }
  }
}


const getListAdressRequest = () => ({
  type: actionTypes.GET_LIST_ADRESA_REQUEST
});

const getListAdressSuccess = (res) => ({
  type: actionTypes.GET_LIST_ADRESA_SUCCESS,
  res
});

const getListAdressFailure = (error) => ({
  type: actionTypes.GET_LIST_ADRESA_FAILURE,
  error
});

export const getListAdress = (filterType, filter, filter2) => {
  return async (dispatch) => {
    try {
      dispatch(getListAdressRequest());
      let res = await ciselnikyUtils.getListAdress(filterType, filter, filter2);
      dispatch(getListAdressSuccess(res));
    } catch (error) {
      dispatch(getListAdressFailure(error));
    }
  }
}

const getAdressRequest = () => ({
  type: actionTypes.GET_ADRESA_REQUEST
});

const getAdressSuccess = (res) => ({
  type: actionTypes.GET_ADRESA_SUCCESS,
  res
});

const getAdressFailure = (error) => ({
  type: actionTypes.GET_ADRESA_FAILURE,
  error
});

export const getAdress = (iAdr) => {
  return async (dispatch) => {
    try {
      dispatch(getAdressRequest());
      let res = await ciselnikyUtils.getAdress(iAdr);
      dispatch(getAdressSuccess(res));
    } catch (error) {
      dispatch(getAdressFailure(error));
    }
  }
}

const getListCardStateSuccess = (res) => ({
  type: actionTypes.GET_LIST_CARD_STATE_SUCCESS,
  res
});

const getListCardStateRequest = () => ({
  type: actionTypes.GET_LIST_CARD_STATE_REQUEST
});

const getListCardStateFailure = (error) => ({
  type: actionTypes.GET_LIST_CARD_STATE_FAILURE,
  error
});

export const getListCardState = (iO) => {
  return async (dispatch) => {
    try {
      dispatch(getListCardStateRequest());
      let res = await ciselnikyUtils.getCardState(iO);
      dispatch(getListCardStateSuccess(res));
    } catch (error) {
      dispatch(getListCardStateFailure(error));
      console.error(error);
    }
  }
}


const getZoneTimeMaxDateRequest = () => ({
  type: actionTypes.GET_ZONE_TIME_MD_REQUEST
});

const getZoneTimeMaxDateSuccess = (res) => ({
  type: actionTypes.GET_ZONE_TIME_MD_SUCCESS,
  res
});

const getZoneTimeMaxDateFailure = (error) => ({
  type: actionTypes.GET_ZONE_TIME_MD_FAILURE,
  error
});

export const getZoneTimeMaxDate = (iParkDruh) => {
  return async (dispatch) => {
    try {
      dispatch(getZoneTimeMaxDateRequest());
      let res = await ciselnikyUtils.getZoneTimeMaxDate(iParkDruh);
      dispatch(getZoneTimeMaxDateSuccess(res));
    } catch (error) {
      dispatch(getZoneTimeMaxDateFailure(error));
    }
  }
}

const getZoneTimeRequest = () => ({
  type: actionTypes.GET_ZONE_TIME_REQUEST
});

const getZoneTimeSuccess = (res) => ({
  type: actionTypes.GET_ZONE_TIME_SUCCESS,
  res
});

const getZoneTimeFailure = (error) => ({
  type: actionTypes.GET_ZONE_TIME_FAILURE,
  error
});

export const getZoneTime = (datum, iZona, iParkDruh) => {
  return async (dispatch) => {
    try {
      dispatch(getZoneTimeRequest());
      let res = await ciselnikyUtils.getZoneTime(datum, iZona, iParkDruh);
      dispatch(getZoneTimeSuccess(res));
    } catch (error) {
      dispatch(getZoneTimeFailure(error));
    }
  }
}

const getZoneTimeListRequest = () => ({
  type: actionTypes.GET_ZONE_TIME_LIST_REQUEST
});

const getZoneTimeListSuccess = (res) => ({
  type: actionTypes.GET_ZONE_TIME_LIST_SUCCESS,
  res
});

const getZoneTimeListFailure = (error) => ({
  type: actionTypes.GET_ZONE_TIME_LIST_FAILURE,
  error
});

export const getZoneTimeList = (iZona, iParkDruh) => {
  return async (dispatch) => {
    try {
      dispatch(getZoneTimeListRequest());
      let res = await ciselnikyUtils.getZoneTimeList(iZona, iParkDruh);
      dispatch(getZoneTimeListSuccess(res));
    } catch (error) {
      dispatch(getZoneTimeListFailure(error));
    }
  }
}

const getCardKatRequest = () => ({
  type: actionTypes.GET_CARD_KAT_REQUEST
});

const getCardKatSuccess = (res) => ({
  type: actionTypes.GET_CARD_KAT_SUCCESS,
  res
});

const getCardKatFailure = (error) => ({
  type: actionTypes.GET_CARD_KAT_FAILURE,
  error
});

export const getCardKat = (iD) => {
  return async (dispatch) => {
    try {
      dispatch(getCardKatRequest());
      let res = await ciselnikyUtils.getCardKat(iD);
      dispatch(getCardKatSuccess(res));
    } catch (error) {
      dispatch(getCardKatFailure(error));
    }
  }
}

const getListCardKatRequest = () => ({
  type: actionTypes.GET_LIST_CARD_KAT_REQUEST
});

const getListCardKatSuccess = (res) => ({
  type: actionTypes.GET_LIST_CARD_KAT_SUCCESS,
  res
});

const getListCardKatFailure = (error) => ({
  type: actionTypes.GET_LIST_CARD_KAT_FAILURE,
  error
});

export const getListCardKat = () => {
  return async (dispatch) => {
    try {
      dispatch(getListCardKatRequest());
      let res = await ciselnikyUtils.getListCardKat();
      dispatch(getListCardKatSuccess(res));
    } catch (error) {
      dispatch(getListCardKatFailure(error));
    }
  }
}


const getUrlGdprRequest = () => ({
  type: actionTypes.GET_URL_GDPR_REQUEST
});

const getUrlGdprSuccess = (res) => ({
  type: actionTypes.GET_URL_GDPR_SUCCESS,
  res
});

const getUrlGdprFailure = (error) => ({
  type: actionTypes.GET_URL_GDPR_FAILURE,
  error
});

export const getUrlGdpr = () => {
  return async (dispatch) => {
    try {
      dispatch(getUrlGdprRequest());
      let res = await ciselnikyUtils.getPh(4003001003);
      dispatch(getUrlGdprSuccess(res));
    } catch (error) {
      dispatch(getUrlGdprFailure(error));
    }
  }
}

const getUrlConditionsPCRequest = () => ({
  type: actionTypes.GET_URL_CONDITIONS_PC_REQUEST
});

const getUrlConditionsPCSuccess = (res) => ({
  type: actionTypes.GET_URL_CONDITIONS_PC_SUCCESS,
  res
});

const getUrlConditionsPCFailure = (error) => ({
  type: actionTypes.GET_URL_CONDITIONS_PC_FAILURE,
  error
});

export const getUrlConditionsPC = () => {
  return async (dispatch) => {
    try {
      dispatch(getUrlConditionsPCRequest());
      let res = await ciselnikyUtils.getPh(4003001006);
      dispatch(getUrlConditionsPCSuccess(res));
    } catch (error) {
      dispatch(getUrlConditionsPCFailure(error));
    }
  }
}



const getVisiblePlacesMapButtonRequest = () => ({
  type: actionTypes.GET_VISIBLE_PLACES_MAP_BUTTON_REQUEST
});

const getVisiblePlacesMapButtonSuccess = (res) => ({
  type: actionTypes.GET_VISIBLE_PLACES_MAP_BUTTON_SUCCESS,
  res
});

const getVisiblePlacesMapButtonFailure = (error) => ({
  type: actionTypes.GET_VISIBLE_PLACES_MAP_BUTTON_FAILURE,
  error
});

export const getVisiblePlacesMapButton = () => {
  return async (dispatch) => {
    try {
      dispatch(getVisiblePlacesMapButtonRequest());
      let res = await ciselnikyUtils.getPh(4003001012);
      dispatch(getVisiblePlacesMapButtonSuccess(res));
    } catch (error) {
      dispatch(getVisiblePlacesMapButtonFailure(error));
    }
  }
}


const getVisibleZtpRequest = () => ({
  type: actionTypes.GET_VISIBLE_ZTP_REQUEST
});

const getVisibleZtpSuccess = (res) => ({
  type: actionTypes.GET_VISIBLE_ZTP_SUCCESS,
  res
});

const getVisibleZtpFailure = (error) => ({
  type: actionTypes.GET_VISIBLE_ZTP_FAILURE,
  error
});

export const getVisibleZtp = () => {
  return async (dispatch) => {
    try {
      dispatch(getVisibleZtpRequest());
      let res = await adminModeUtils.getForm(ISpDefOb, 4003001002);
      dispatch(getVisibleZtpSuccess(res));
    } catch (error) {
      dispatch(getVisibleZtpFailure(error));
    }
  }
}

const getPayRecipientRequest = () => ({
  type: actionTypes.GET_PAY_RECIPIENT_REQUEST
});

const getPayRecipientSuccess = (res) => ({
  type: actionTypes.GET_PAY_RECIPIENT_SUCCESS,
  res
});

const getPayRecipientFailure = (error) => ({
  type: actionTypes.GET_PAY_RECIPIENT_FAILURE,
  error
});

export const getPayRecipient = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getPayRecipientRequest());
      let res = await ciselnikyUtils.getPh(id);
      dispatch(getPayRecipientSuccess(res));
    } catch (error) {
      dispatch(getPayRecipientFailure(error));
    }
  }
}

const getPayRecipientTaxesRequest = () => ({
  type: actionTypes.GET_PAY_RECIPIENT_TAXES_REQUEST
});

const getPayRecipientTaxesSuccess = (res) => ({
  type: actionTypes.GET_PAY_RECIPIENT_TAXES_SUCCESS,
  res
});

const getPayRecipientTaxesFailure = (error) => ({
  type: actionTypes.GET_PAY_RECIPIENT_TAXES_FAILURE,
  error
});

export const getPayRecipientTaxes = (id) => {
  return async (dispatch) => {
    try {
      dispatch(getPayRecipientTaxesRequest());
      let res = await ciselnikyUtils.getPh(id);
      dispatch(getPayRecipientTaxesSuccess(res));
    } catch (error) {
      dispatch(getPayRecipientTaxesFailure(error));
    }
  }
}

// Zobrazenie verejnej zóny
const getVisiblePublicZoneRequest = () => ({
  type: actionTypes.GET_VISIBLE_PUBLIC_ZONE_REQUEST
});

const getVisiblePublicZoneSuccess = (res) => ({
  type: actionTypes.GET_VISIBLE_PUBLIC_ZONE_SUCCESS,
  res
});

const getVisiblePublicZoneFailure = (error) => ({
  type: actionTypes.GET_VISIBLE_PUBLIC_ZONE_FAILURE,
  error
});

export const getVisiblePublicZone = () => {
  return async (dispatch) => {
    try {
      dispatch(getVisiblePublicZoneRequest());
      let res = await ciselnikyUtils.getPh(4003001005);
      dispatch(getVisiblePublicZoneSuccess(res));
    } catch (error) {
      dispatch(getVisiblePublicZoneFailure(error));
    }
  }
}

// Zobrazenie parkovacích lístkov
const getVisibleParkShortRequest = () => ({
  type: actionTypes.GET_VISIBLE_PARK_SHORT_REQUEST
});

const getVisibleParkShortSuccess = (res) => ({
  type: actionTypes.GET_VISIBLE_PARK_SHORT_SUCCESS,
  res
});

const getVisibleParkShortFailure = (error) => ({
  type: actionTypes.GET_VISIBLE_PARK_SHORT_FAILURE,
  error
});

export const getVisibleParkShort = () => {
  return async (dispatch) => {
    try {
      dispatch(getVisibleParkShortRequest());
      let res = await ciselnikyUtils.getPh(4003001007);
      dispatch(getVisibleParkShortSuccess(res));
    } catch (error) {
      dispatch(getVisibleParkShortFailure(error));
    }
  }
}

/** Zobrazenie parkovacích kariet bez registrácie - Request */
const getVisibleParkCardUnRegRequest = () => ({
  type: actionTypes.GET_VISIBLE_PARK_CARD_UNREG_REQUEST
});

/** Zobrazenie parkovacích kariet bez registrácie - Success */
const getVisibleParkCardUnRegSuccess = (res) => ({
  type: actionTypes.GET_VISIBLE_PARK_CARD_UNREG_SUCCESS,
  res
});

/** Zobrazenie parkovacích kariet bez registrácie - Failure */
const getVisibleParkCardUnRegFailure = (error) => ({
  type: actionTypes.GET_VISIBLE_PARK_CARD_UNREG_FAILURE,
  error
});

/** Zobrazenie parkovacích kariet bez registrácie - získanie PH 4003001008 (bool) */
export const getVisibleParkCardUnReg = () => {
  return async (dispatch) => {
    try {
      dispatch(getVisibleParkCardUnRegRequest());
      let res = await ciselnikyUtils.getPh(4003001008);
      dispatch(getVisibleParkCardUnRegSuccess(res));
    } catch (error) {
      dispatch(getVisibleParkCardUnRegFailure(error));
    }
  }
}

const getVisibleTempEcvRequest = () => ({
  type: actionTypes.GET_VISIBLE_TEMP_ECV_REQUEST
});

const getVisibleTempEcvSuccess = (res) => ({
  type: actionTypes.GET_VISIBLE_TEMP_ECV_SUCCESS,
  res
});

const getVisibleTempEcvFailure = (error) => ({
  type: actionTypes.GET_VISIBLE_TEMP_ECV_FAILURE,
  error
});

export const getVisibleTempEcv = () => {
  return async (dispatch) => {
    try {
      dispatch(getVisibleTempEcvRequest());
      let res = await ciselnikyUtils.getPh(4003001010);
      dispatch(getVisibleTempEcvSuccess(res));
    } catch (error) {
      dispatch(getVisibleTempEcvFailure(error));
    }
  }
}


const getPasswordPatternRequest = () => ({
  type: actionTypes.GET_PASSWORD_PATTERN_REQUEST
});

const getPasswordPatternSuccess = (res) => ({
  type: actionTypes.GET_PASSWORD_PATTERN_SUCCESS,
  res
});

const getPasswordPatternFailure = (error) => ({
  type: actionTypes.GET_PASSWORD_PATTERN_FAILURE,
  error
});

export const getPasswordPattern = () => {
  return async (dispatch) => {
    try {
      dispatch(getPasswordPatternRequest());
      let res = await ciselnikyUtils.getPh(-1);
      dispatch(getPasswordPatternSuccess(res));
    } catch (error) {
      dispatch(getPasswordPatternFailure(error));
    }
  }
}

const getPasswordErrMsgRequest = () => ({
  type: actionTypes.GET_PASSWORD_ERR_MSG_REQUEST
});

const getPasswordErrMsgSuccess = (res) => ({
  type: actionTypes.GET_PASSWORD_ERR_MSG_SUCCESS,
  res
});

const getPasswordErrMsgFailure = (error) => ({
  type: actionTypes.GET_PASSWORD_ERR_MSG_FAILURE,
  error
});

export const getPasswordErrMsg = () => {
  return async (dispatch) => {
    try {
      dispatch(getPasswordErrMsgRequest());
      let res = await ciselnikyUtils.getPh(-2);
      dispatch(getPasswordErrMsgSuccess(res));
    } catch (error) {
      dispatch(getPasswordErrMsgFailure(error));
    }
  }
}


const getEgovHandlerUrlRequest = () => ({
  type: actionTypes.GET_EGOV_HANDLER_URL_REQUEST
});

const getEgovHandlerUrlSuccess = (res) => ({
  type: actionTypes.GET_EGOV_HANDLER_URL_SUCCESS,
  res
});

const getEgovHandlerUrlFailure = (error) => ({
  type: actionTypes.GET_EGOV_HANDLER_URL_FAILURE,
  error
});

export const getEgovHandlerUrl = () => {
  return async (dispatch) => {
    try {
      dispatch(getEgovHandlerUrlRequest());
      let res = await ciselnikyUtils.getPh(-3);
      dispatch(getEgovHandlerUrlSuccess(res));
    } catch (error) {
      dispatch(getEgovHandlerUrlFailure(error));
    }
  }
}


const getVisibleD_ODRequest = () => ({
  type: actionTypes.GET_VISIBLE_D_OD_REQUEST
});

const getVisibleD_ODSuccess = (res) => ({
  type: actionTypes.GET_VISIBLE_D_OD_SUCCESS,
  res
});

const getVisibleD_ODFailure = (error) => ({
  type: actionTypes.GET_VISIBLE_D_OD_FAILURE,
  error
});

export const getVisibleD_OD = () => {
  return async (dispatch) => {
    try {
      dispatch(getVisibleD_ODRequest());
      let res = await adminModeUtils.getForm(ISpDefOb, 4003001011);
      dispatch(getVisibleD_ODSuccess(res));
    } catch (error) {
      dispatch(getVisibleD_ODFailure(error));
    }
  }
}

// Verejná zóna
const getPublicZoneButtonRequest = () => ({
  type: actionTypes.GET_PUBLIC_ZONE_BUTTON_REQUEST
});

const getPublicZoneButtonSuccess = (res) => ({
  type: actionTypes.GET_PUBLIC_ZONE_BUTTON_SUCCESS,
  res
});

const getPublicZoneButtonFailure = (error) => ({
  type: actionTypes.GET_PUBLIC_ZONE_BUTTON_FAILURE,
  error
});

export const getPublicZoneButton = () => {
  return async (dispatch) => {
    try {
      dispatch(getPublicZoneButtonRequest());
      let res = await adminModeUtils.getMenuNoAuth(4004019);
      dispatch(getPublicZoneButtonSuccess(res));
    } catch (error) {
      dispatch(getPublicZoneButtonFailure(error));
    }
  }
}

// Moja (Privátna) zóna
const getMyZoneButtonRequest = () => ({
  type: actionTypes.GET_MY_ZONE_BUTTON_REQUEST
});

const getMyZoneButtonSuccess = (res) => ({
  type: actionTypes.GET_MY_ZONE_BUTTON_SUCCESS,
  res
});

const getMyZoneButtonFailure = (error) => ({
  type: actionTypes.GET_MY_ZONE_BUTTON_FAILURE,
  error
});

export const getMyZoneButton = () => {
  return async (dispatch) => {
    try {
      dispatch(getMyZoneButtonRequest());
      let res = await adminModeUtils.getMenuNoAuth(4004017);
      dispatch(getMyZoneButtonSuccess(res));
    } catch (error) {
      dispatch(getMyZoneButtonFailure(error));
    }
  }
}

// Parkovanie bez prihlásenia do datamesta
const getParkingButtonRequest = () => ({
  type: actionTypes.GET_PARKING_BUTTON_REQUEST
});

const getParkingButtonSuccess = (res) => ({
  type: actionTypes.GET_PARKING_BUTTON_SUCCESS,
  res
});

const getParkingButtonFailure = (error) => ({
  type: actionTypes.GET_PARKING_BUTTON_FAILURE,
  error
});

export const getParkingButton = () => {
  return async (dispatch) => {
    try {
      dispatch(getParkingButtonRequest());
      let res = await adminModeUtils.getMenuNoAuth(4004018);
      dispatch(getParkingButtonSuccess(res));
    } catch (error) {
      dispatch(getParkingButtonFailure(error));
    }
  }
}

const getNotAvailableFreeParkingRequest = () => ({
  type: actionTypes.GET_NOT_AVAILABLE_FREE_PARKING_REQUEST
});

const getNotAvailableFreeParkingSuccess = (res) => ({
  type: actionTypes.GET_NOT_AVAILABLE_FREE_PARKING_SUCCESS,
  res
});

const getNotAvailableFreeParkingFailure = (error) => ({
  type: actionTypes.GET_NOT_AVAILABLE_FREE_PARKING_FAILURE,
  error
});

export const getNotAvailableFreeParking = () => {
  return async (dispatch) => {
    try {
      dispatch(getNotAvailableFreeParkingRequest());
      let res = await ciselnikyUtils.getPh(4003001013);
      dispatch(getNotAvailableFreeParkingSuccess(res));
    } catch (error) {
      dispatch(getNotAvailableFreeParkingFailure(error));
    }
  }
}

/** Konfiguračné nastavenia pre platobnú bránu Besteron (PH=-4) - Request */
const getBesteronPaymentGatewaySettingsRequest = () => ({
  type: actionTypes.GET_BESTERON_PAYMENT_GATEWAY_SETTINGS_REQUEST
});

/** Konfiguračné nastavenia pre platobnú bránu Besteron (PH=-4) - Success */
const getBesteronPaymentGatewaySettingsSuccess = (res) => ({
  type: actionTypes.GET_BESTERON_PAYMENT_GATEWAY_SETTINGS_SUCCESS,
  res
});

/** Konfiguračné nastavenia pre platobnú bránu Besteron (PH=-4) - Failure */
const getBesteronPaymentGatewaySettingsFailure = (error) => ({
  type: actionTypes.GET_BESTERON_PAYMENT_GATEWAY_SETTINGS_FAILURE,
  error
});

/** Konfiguračné nastavenia pre platobnú bránu Besteron (PH=-4) - Načítanie PH -4 */
export const getBesteronPaymentGatewaySettings = () => {
  return async (dispatch) => {
    try {
      dispatch(getBesteronPaymentGatewaySettingsRequest());
      let res = await ciselnikyUtils.getPh(-4);
      dispatch(getBesteronPaymentGatewaySettingsSuccess(res));
    } catch (error) {
      dispatch(getBesteronPaymentGatewaySettingsFailure(error));
    }
  }
}

const getVersionInfoRequest = () => ({
  type: actionTypes.GET_VERSION_INFO_REQUEST
});

const getVersionInfoSuccess = (res) => ({
  type: actionTypes.GET_VERSION_INFO_SUCCESS,
  res
});

const getVersionInfoFailure = (error) => ({
  type: actionTypes.GET_VERSION_INFO_FAILURE,
  error
});

export const getVersionInfo = () => {
  return async (dispatch) => {
    try {
      dispatch(getVersionInfoRequest());
      let res = await ciselnikyUtils.getVersionInfo();
      dispatch(getVersionInfoSuccess(res));
    } catch (error) {
      dispatch(getVersionInfoFailure(error));
    }
  }
}

const getCardExtendBeforeRequest = () => ({
  type: actionTypes.GET_CARD_EXTEND_BEFORE_REQUEST
})

const getCardExtendBeforeSuccess = (res) => ({
  type: actionTypes.GET_CARD_EXTEND_BEFORE_SUCCESS,
  res
})

const getCardExtendBeforeFailure = (error) => ({
  type: actionTypes.GET_CARD_EXTEND_BEFORE_FAILURE,
  error
})

export const getCardExtendBefore = () => {
  return async (dispatch) => {
    try {
      dispatch(getCardExtendBeforeRequest());
      let res = await ciselnikyUtils.getPh(4003001015)
      dispatch(getCardExtendBeforeSuccess(res));
    } catch (error) {
      dispatch(getCardExtendBeforeFailure(error));
    }
  }
}

const getCardExtendAfterRequest = () => ({
  type: actionTypes.GET_CARD_EXTEND_AFTER_REQUEST
})

const getCardExtendAfterSuccess = (res) => ({
  type: actionTypes.GET_CARD_EXTEND_AFTER_SUCCESS,
  res
})

const getCardExtendAfterFailure = (error) => ({
  type: actionTypes.GET_CARD_EXTEND_AFTER_FAILURE,
  error
})

export const getCardExtendAfter = () => {
  return async (dispatch) => {
    try {
      dispatch(getCardExtendAfterRequest());
      let res = await ciselnikyUtils.getPh(4003001016)
      dispatch(getCardExtendAfterSuccess(res));
    } catch (error) {
      dispatch(getCardExtendAfterFailure(error));
    }
  }
}

// Zobrazenie identifikačného čísla dotácie
const getVisibleIdNumberSubsidyRequest = () => ({
  type: actionTypes.GET_VISIBLE_ID_NUMBER_SUBSIDY_REQUEST
});

const getVisibleIdNumberSubsidySuccess = (res) => ({
  type: actionTypes.GET_VISIBLE_ID_NUMBER_SUBSIDY_SUCCESS,
  res
});

const getVisibleIdNumberSubsidyFailure = (error) => ({
  type: actionTypes.GET_VISIBLE_ID_NUMBER_SUBSIDY_FAILURE,
  error
});

export const getVisibleIdNumberSubsidy = () => {
  return async (dispatch) => {
    try {
      dispatch(getVisibleIdNumberSubsidyRequest());
      let res = await ciselnikyUtils.getPh(4006001001);
      dispatch(getVisibleIdNumberSubsidySuccess(res));
    } catch (error) {
      dispatch(getVisibleIdNumberSubsidyFailure(error));
    }
  }
}