import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import Button from '../../coraWebMComponents/inputs/Button';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import ParkingCardIcon from '../../coraWebMComponents/dataDisplay/icons/ParkingCard';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import DialogIcon from '../../coraWebMComponents/feedBack/DialogIcon';
import * as convert from '../../coraWebMComponents/utils/convert';
import * as parkingActions from '../../actions/parkingActions';
import * as userActions from '../../actions/userActions';
import * as ciselnikyActions from '../../actions/ciselnikyActions';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import getSharedStyles from '../../coraWebMComponents/sharedStyles'
import classNames from 'classnames';
import VerticalStepper from '../../coraWebMComponents/dataDisplay/VerticalStepper';
import routes from '../../routes';
import EuroIcon from '../../coraWebMComponents/dataDisplay/icons/EuroIcon';
import CreditCard from '../../coraWebMComponents/dataDisplay/icons/CreditCardIcon';
import MoreVertIcon from '../../coraWebMComponents/dataDisplay/icons/MoreVertIcon';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import MenuItem from '../../coraWebMComponents/dataDisplay/MenuItem';
import Menu from '../../coraWebMComponents/dataDisplay/Menu';
import EditIcon from '../../coraWebMComponents/dataDisplay/icons/EditIcon';
import HourGlassIcon from '../../coraWebMComponents/dataDisplay/icons/HourGlassIcon';
import InfoIcon from '../../coraWebMComponents/dataDisplay/icons/InfoIcon';
import TimerIcon from '../../coraWebMComponents/dataDisplay/icons/TimerIcon';
import RemoveBlackIcon from '../../coraWebMComponents/dataDisplay/icons/RemoveBlackIcon';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import SelectFilterField from '../../coraWebMComponents/inputs/SelectFilterField';
import AddBlackIcon from "../../coraWebMComponents/dataDisplay/icons/AddBlackIcon"
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import _ from 'lodash';
import * as ciselnikyUtils from '../../utils/ciselnikyUtils';
import AttachmentInput from '../../coraWebMComponents/inputs/AttachmentInput';
import Attachment from '../../coraWebMComponents/inputs/Attachment';
import AttachmentFile from '../../coraWebMComponents/inputs/AttachmentFile';
import FileInput from '../../coraWebMComponents/inputs/FileInput';
import CheckBox from '../../coraWebMComponents/inputs/CheckBox'
import withRouter from '../../coraWebMComponents/utils/withRouter';

const styles = theme => ({
  ...getSharedStyles(theme),
  expansionRoot: {
    boxShadow: 'none',
    borderTop: '1px solid #e1e1e1',
    //background: '#f6f6f6',
    '&:before': {
      display: 'none',
    },
  },
  parkingCardHeader: {
    //borderBottom: '1px solid #e1e1e1',
    paddingBottom: '5px',
  },
  expansionExpanded: {
    margin: 'auto',
  },
  summaryRoot: {
    height: '50px',
    minHeight: '50px',
    padding: '0 20px'
  },
  summaryExpanded: {
    minHeight: '50px!important',
  },
  summaryContent: {
    minHeight: 'auto!important'
  },
  detailRoot: {
    padding: '0px 20px 20px 20px'
  },
  ziadostCardContent: {
    padding: '20px',
    //backgroundColor: '#e9e9e9',
  },
})

let _iZiadost, _iKarta;
const _currencyCode = 'EUR';

class Parking extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoad: true,
      openAlertCancel: false,
      openAlertPay: false,
      openAlertExtend: false,
      I_KARTA_STAV: 0,
      N_KARTA_STAV: '',
      showDetail: false,
      ECV: '',
      ECV_TMP: '',
      I_ZIADOST: 0,
      C_KARTA: null,
      I_KARTA_STAV_DET: 0,
      N_KARTA_STAV_DET: '',
      D_DO: null,
      ADRESA: '',
      MIESTO: '',
      N_KARTA_KAT: '',
      N_KARTA_TYP: '',
      N_POHON: '',
      stavy: null,
      visibleTempEcv: false,
      zozCardState: [],
      kartaTyp: {},
      openAttachmentsDialog: false,
      ATTACHS_FILE: [],
      ATTACHS_TP: [],
      showZiadosti: false
    };
  }

  componentDidMount = async () => {
    await this.props.dispatch(ciselnikyActions.getCardExtendBefore());
    await this.props.dispatch(ciselnikyActions.getCardExtendAfter());
    if (!this.props.paymentGatewaySettings)
      await this.props.dispatch(ciselnikyActions.getBesteronPaymentGatewaySettings());

    if (this.props.person.I_O) {
      await this.getCardData(this.props.person.I_O, this.state.I_KARTA_STAV);
    }

    await this.props.dispatch(ciselnikyActions.getVisibleTempEcv());
    var bShwTE = false;
    if (this.props.visibleTempEcv) {
      if (this.props.visibleTempEcv.HODN) {
        if (this.props.visibleTempEcv.HODN.trim() === '1')
          bShwTE = true;
      }
    }

    this.setState({ isLoad: false, visibleTempEcv: bShwTE });
  }

  componentWillUnmount = () => {
    this.props.dispatch(parkingActions.resetParkingPermitsList())
  }

  handleOpenAlert = async (name, iZiadost, iKartaTyp, iKarta) => {
    _iKarta = iKarta;
    if (name === 'openAlertExtend') {
      const res = await ciselnikyUtils.getCardType(iKartaTyp)

      if (res.Data) {
        this.setState({ kartaTyp: res.Data })
      } else {
        this.props.dispatch(userActions.showErrorMsg("Neplatný typ karty, predĺženie karty nie je možné vykonať."));
        return
      }
    }
    this.setState({ [name]: true });
    _iZiadost = iZiadost;
  };

  handleCloseAlert = (name) => {
    this.setState({ [name]: false });
  };

  getStavColor = stav => {
    switch (stav) {
      case 2: case 5: return '#f57c00'; //orange
      case 3: case 4: case 8: case 11: case 12: return '#4caf50'; //green    
      case 7: case 13: case 14: case 15: case 18: case 19: return '#f44336'; //red
      default: return ''
    }
  }

  getCardData = async (iO, iKartaStav) => {
    await this.props.dispatch(parkingActions.getListParkingCard(iO, iKartaStav));
    //await this.props.dispatch(ciselnikyActions.getListCardState(iO));
    if (iKartaStav === 0 || !iKartaStav)
      this.GetZozCardState();

  }


  GetZozCardState = () => {
    let zoz = [], l;
    this.props.listParkingCard.forEach(card);

    function card(value) {
      if (value.I_KARTA_STAV) {
        let stav = value.stavy;
        l = stav.length - 1;

        if (l > -1)
          zoz.push({ I_KARTA_STAV: stav[l].I_KARTA_STAV, N_KARTA_STAV: stav[l].N_KARTA_STAV });

      }
    }

    const uniqueZoz = [...new Map(zoz.map(item => [item['I_KARTA_STAV'], item])).values()];
    const zozCardState = _.sortBy(uniqueZoz, 'I_KARTA_STAV');
    this.setState({ zozCardState });
  };

  handleChange = async (name, value) => {
    switch (name) {
      case 'I_KARTA_STAV':
        await this.props.dispatch(parkingActions.getListParkingCard(this.props.person.I_O, value));
        break;
      default:
    }
    this.setState({ [name]: value });
  };

  handleClickMenu = (route) => {
    this.props.navigate({ pathname: route });
  }

  cancelParkingPermit = async () => {
    this.handleCloseAlert('openAlertCancel');
    await this.props.dispatch(parkingActions.cancelParkingPermit(_iZiadost)).then(() => {
      this.props.dispatch(userActions.showMsg('Žiadosť bola úspešne zrušená.'))
    });
    if (this.state.showZiadosti) {
      await this.props.dispatch(parkingActions.getParkingPermitsList(this.props.person.I_O))
    } else {
      await this.getCardData(this.props.person.I_O, this.state.I_KARTA_STAV);
    }
  }

  handleParkingModify = async (idZiadost, iKarta) => {
    this.props.navigate({ pathname: routes.PARKING_MODIFY, search: `?iZadost=${idZiadost}&iKarta=${iKarta}` });
  }

  handleCredit = async (idZiadost, iKarta) => {
    this.props.navigate({ pathname: routes.PARKING_CREDIT, search: `?iZadost=${idZiadost}&iKarta=${iKarta}` });
  }

  handleTempEcv = async (idZiadost, iKarta) => {
    this.props.navigate({ pathname: routes.PARKING_TEMP_ECV, search: `?iZiadost=${idZiadost}&iKarta=${iKarta}` });
  }

  handleParkingExtend = async () => {
    this.handleCloseAlert('openAlertExtend');
    if (this.state.kartaTyp.TXT_PREDLZ?.length > 0) {
      this.setState({ openAttachmentsDialog: true })
      return
    }
    this.handleParkingExtendDispatch();
  }

  handleParkingExtendDispatch = async (attachments) => {
    if (!attachments) {
      attachments = { ATTACHS_FILE: [], ATTACHS_TP: [] }
    }

    this.setState({ isLoad: true });
    const res = await this.props.dispatch(parkingActions.addParkingExtend(_iKarta, attachments));
    if (res) {
      this.setState({ I_KARTA_STAV: 10 });
      await this.getCardData(this.props.person.I_O, 10);
    }

    this.setState({ isLoad: false });

    if (!res)
      this.props.dispatch(userActions.showErrorMsg("Žiadosť o predľženie platnosti parkovacej karty sa nepodarilo zaevidovať."));
    else if (res?.Data?.I_ZIADOST)
      this.props.dispatch(userActions.showMsg("Žiadosť o predľženie platnosti parkovacej karty bola zaevidovaná."));
    else
      this.props.dispatch(userActions.showErrorMsg(res.Data));
  }


  getAttachmentsDialogContent() {
    const { classes } = this.props;
    return (
      <>
        <div>
          <Typography className={classes.mb4}>
            {this.state.kartaTyp.TXT_PREDLZ}
          </Typography>
        </div>
        <div
          className={classNames(classes.row)}
        >
          <Typography style={{ color: "rgba(0, 0, 0, 0.54)", fontSize: "1.0714285714285714rem", lineHeight: "1" }}>Prílohy</Typography>
          <div className={classNames(classes.row)}>
            <FileInput
              onChange={this.handleChangeFile}
              mimetype={'application/pdf'}
              title={'súbory typu PDF'}
              iconType={2}
            />
            <AttachmentInput
              onChange={this.handleChangeImageTp}
              iconType={1}
            />
          </div>
        </div>
        <div className='attachments'>
          {this.state.ATTACHS_TP.map((image, index) =>
            <Attachment
              key={index}
              image={image}
              onRemove={() => this.handleRemoveImageTp(index)}
            />
          )}
        </div>
        <div className='attachments'>
          {this.state.ATTACHS_FILE.map((file, index) =>
            <AttachmentFile
              key={index}
              image={file}
              onRemove={() => this.handleRemoveFile(index)}
            />
          )}
        </div>
      </>
    );
  }



  handleParkingExtendWithAttachments = async () => {
    this.setState({ openAttachmentsDialog: false });
    let AttachsFileNew = this.state.ATTACHS_FILE;
    let AttachsTpNew = this.state.ATTACHS_TP;
    for (let i = 0; i < AttachsFileNew.length; i++) {
      AttachsFileNew[i].data = AttachsFileNew[i].data.replace(/^.+,/, '');
    }
    for (let i = 0; i < AttachsTpNew.length; i++) {
      AttachsTpNew[i].data = AttachsTpNew[i].data.replace(/^.+,/, '');
    }
    const attachments = { ATTACHS_FILE: AttachsFileNew, ATTACHS_TP: AttachsTpNew }
    this.handleParkingExtendDispatch(attachments);
  }

  handleChangeFile = (attach) => {
    const ATTACHS_FILE = this.state.ATTACHS_FILE;
    for (let i = 0; i < ATTACHS_FILE.length; i++) {
      if (ATTACHS_FILE[i].name === attach.name) {
        return;
      }
    }
    this.setState(prevState => ({ ATTACHS_FILE: [...prevState.ATTACHS_FILE, attach] }));
  };

  handleChangeImageTp = (attach) => {
    this.setState(prevState => ({ ATTACHS_TP: [...prevState.ATTACHS_TP, attach] }));
  }

  handleRemoveFile = (index) => {
    this.setState(prevState => ({
      ATTACHS_FILE: [
        ...prevState.ATTACHS_FILE.slice(0, index),
        ...prevState.ATTACHS_FILE.slice(index + 1)
      ]
    }));
  };

  handleRemoveImageTp = (index) => {
    this.setState(prevState => ({
      ATTACHS_TP: [
        ...prevState.ATTACHS_TP.slice(0, index),
        ...prevState.ATTACHS_TP.slice(index + 1)
      ]
    }));
  };

  handlePay = async (type) => {

    this.handleCloseAlert('openAlertPay');
    await this.props.dispatch(parkingActions.getParkingCard(_iKarta));

    if (type === 1) { // platobna brana

      // 0 = povodne riesenie / 1 = riesenie HD 70888
      const payMethod = Number(this.props.paymentGatewaySettings.HODN);

      // povodne riesenie
      let dataPay = { VS: this.props.parkingCard.VS, CENA: this.props.parkingCard.CENA, LANG: 'sk', EMAIL: this.props.user.data.n_uz };

      if (payMethod === 1) {  // riesenie HD 70888

        const { parkingCard } = this.props;

        const prjId = 4003;
        const payType = 1;
        const iPk = parkingCard.I_KARTA;
        const vs = dataPay.VS;
        const language = 'SK';
        const email = dataPay.EMAIL;

        // sprievodka
        const waybill = { payMethod, prjId, payType, iPk, vs, language, email, 'transactionId': null };

        // udaje pre platbu
        const payData = { 'items': [], 'totalAmount': 0, 'totalAmountDec': 0, currencyCode: _currencyCode };

        let name = `Parkovacia karta`;
        if (parkingCard.C_KARTA) name += ` č. ${parkingCard.C_KARTA}`;
        if (parkingCard.ECV) name += ` pre EČV ${parkingCard.ECV}`;

        const amountDec = Number(dataPay.CENA);
        payData.totalAmountDec = Number(payData.totalAmountDec) + amountDec;

        const amount = amountDec * 100;
        payData.totalAmount = Number(payData.totalAmount) + amount;

        payData.items.push({ name, amount, amountDec });

        dataPay = { ...dataPay, waybill, payData };
      }
      this.props.navigate(routes.PAYMENT_GATEWAY, { state: dataPay });
    } else {
      this.props.navigate(routes.PAYMENT_PAY, { state: { payType: 1 } });
    }
  }

  handleShowDetail = (rec) => {
    this.state.showZiadosti ?
      this.setState({
        showDetail: true,
        ECV: rec.ECV,
        ECV_TMP: null,
        I_ZIADOST: rec.I_ZIADOST,
        C_KARTA: null,
        I_KARTA_STAV_DET: rec.stavy[rec.stavy?.length - 1].I_KARTA_STAV,
        N_KARTA_STAV_DET: rec.stavy[rec.stavy?.length - 1].N_KARTA_STAV,
        D_DO: rec.D_DO,
        ADRESA: null,
        MIESTO: null,
        N_KARTA_KAT: rec.kategoria.N_KARTA_KAT,
        N_KARTA_TYP: rec.typ.N_KARTA_TYP,
        N_POHON: rec.pohon.N_POHON,
        stavy: rec.stavy
      })
      :
      this.setState({
        showDetail: true,
        ECV: rec.ECV,
        ECV_TMP: rec.ECV_TMP,
        I_ZIADOST: rec.I_ZIADOST,
        C_KARTA: rec.C_KARTA,
        I_KARTA_STAV_DET: rec.I_KARTA_STAV,
        N_KARTA_STAV_DET: rec.N_KARTA_STAV,
        D_DO: rec.D_DO,
        ADRESA: rec.ADRESA,
        MIESTO: rec.MIESTO,
        N_KARTA_KAT: rec.N_KARTA_KAT,
        N_KARTA_TYP: rec.N_KARTA_TYP,
        N_POHON: rec.N_POHON,
        stavy: rec.stavy
      });
  };

  handleCloseDetail = () => {
    this.setState({ showDetail: false });
  };

  handleShowZiadosti = () => {
    this.setState({ showZiadosti: !this.state.showZiadosti }, async () => {
      if ((this.props.parkingPermitsList?.length === 0 || !this.props.parkingPermitsList) && this.state.showZiadosti) {
        this.setState({ isLoad: true })
        await this.props.dispatch(parkingActions.getParkingPermitsList(this.props.person.I_O))
        this.setState({ isLoad: false })

        if (!this.props.parkingPermitsList) {
          this.setState({ showZiadosti: false })
          this.props.dispatch(userActions.showErrorMsg("Evidované alebo zrušené žiadosti neexistujú."))
        }
      }
    })
  }

  isMobile(width) {
    if (width === "xs" || width === "sm") {
      return true;
    }
    return false;
  }

  render() {
    const menuA = this.props.menuTree.children;
    const menuPrivate = menuA.filter(x => x.MenuID === 4002001);
    const menuCards = menuPrivate[0]?.children.filter(x => x.MenuID === 4003001);
    let subMenu = '/parking/parkingPermit';

    if (menuCards?.length === 1)
      subMenu = menuCards[0].children.filter(x => x.Visible === 0 && x.MenuID === 4003003);

    const { classes, listParkingCard, width, parkingPermitsList } = this.props;
    const iconEuroSymbol = <EuroIcon />
    const iconCreditCard = <CreditCard />
    return (
      <div>
        {!this.state.showDetail &&
          <div className='container w-margin w-padding'>
            <CircularProgress loading={this.props.isLoading || this.state.isLoad} />
            <Button
              variant="contained"
              color="secondary"
              size="large"
              type="success"
              className={classNames(classes.w100, classes.mt3, classes.mb3)}
              onClick={() => this.handleClickMenu(subMenu[0].Route || subMenu)}
            >
              <AddBlackIcon className={classes.mr1} />
              Žiadosť o vydanie karty
            </Button>


            <div className={classNames(classes.mb3)}>
              <CheckBox
                toolTip=""
                checked={this.state.showZiadosti}
                value={this.state.showZiadosti}
                onChange={this.handleShowZiadosti}
                label="Zobraziť žiadosti"
              />
              {((listParkingCard.length > 1 && this.state.I_KARTA_STAV === null)
                || this.state.I_KARTA_STAV !== null)
                && !this.state.showZiadosti
                &&
                <SelectFilterField
                  style={{ width: '50%', float: 'right' }}
                  floatingLabelText="Výber podľa aktuálneho stavu"
                  defaultLabelText="Všetky karty"
                  value={this.state.I_KARTA_STAV}
                  onChange={this.handleChange}
                  fullwidth={true}
                  schema={{ label: 'N_KARTA_STAV', value: 'I_KARTA_STAV' }}
                  data={this.state.zozCardState} //{this.props.listCardState}
                  name='I_KARTA_STAV'
                />
              }
            </div>
            {listParkingCard.length === 0 && this.state.I_KARTA_STAV !== null &&
              <div className={classNames(classes.mb3)}>
                <Typography variant="button" style={{ fontWeight: 'bold' }}>Podľa výberu stavu sa nenašla ani jedna karta.</Typography>
              </div>
            }
            {this.state.showZiadosti ?
              parkingPermitsList &&
              parkingPermitsList.map(x =>
                <Card
                  key={x.I_ZIADOST.toString()}
                  data={x}
                  classes={classes}
                  onShowDetail={() => this.handleShowDetail(x)}
                  onOpenAlertCancel={() => this.handleOpenAlert('openAlertCancel', x.I_ZIADOST, null, x.I_KARTA)}
                  getColor={() => this.getStavColor(x.stavy[x.stavy?.length - 1].I_KARTA_STAV)}
                  visibleTempEcv={this.state.visibleTempEcv}
                  isZiadost={true}
                />
              )
              :
              listParkingCard.map(x => <Card
                key={x.I_ZIADOST.toString()}
                data={x}
                classes={classes}
                onShowDetail={() => this.handleShowDetail(x)}
                onCredit={() => this.handleCredit(x.I_ZIADOST, x.I_KARTA)}
                onParkingModify={() => this.handleParkingModify(x.I_ZIADOST, x.I_KARTA)}
                onTempEcv={() => this.handleTempEcv(x.I_ZIADOST, x.I_KARTA)}
                onOpenAlertCancel={() => this.handleOpenAlert('openAlertCancel', x.I_ZIADOST, null, x.I_KARTA)}
                onOpenAlertPay={() => this.handleOpenAlert('openAlertPay', x.I_ZIADOST, null, x.I_KARTA)}
                onOpenAlertExtend={() => this.handleOpenAlert('openAlertExtend', x.I_ZIADOST, x.I_KARTA_TYP, x.I_KARTA)}
                getColor={() => this.getStavColor(x.I_KARTA_STAV)}
                visibleTempEcv={this.state.visibleTempEcv}
                cardExtendBefore={this.props.cardExtendBefore}
                cardExtendAfter={this.props.cardExtendAfter}
              />)}
          </div>
        }

        {
          this.state.showDetail &&
          <div className='container w-margin'>
            <Paper className={classes.paperCard} elevation={(width && this.isMobile(width)) ? 0 : 2}>
              <React.Fragment>
                <div >
                  <IconButton
                    className={classNames(classes.backButton, classes.p0)}
                    color="inherit"
                    onClick={this.handleCloseDetail}
                    toolTip="Späť"
                    toolTipPlace="right"
                    disableRipple
                    disableHover
                  >
                    <ArrowBack
                      color='secondary'
                      fontSize='default'
                      variant='fab'
                      style={{ fontSize: 30 }}
                    />
                  </IconButton>
                </div>
                <div className={classNames(this.props.classes.row, this.props.classes.alignItemsCenter, this.props.classes.justifyContentStart, this.props.classes.mb2, this.props.classes.parkingCardHeader, this.props.classes.mt3)}>
                  <ParkingCardIcon style={{ width: "45", height: "30" }}></ParkingCardIcon>
                  <div className={classNames(this.props.classes.ml2)}>
                    <Typography variant="button" style={{ fontWeight: 'bold' }}>{this.state.N_KARTA_TYP}</Typography>
                  </div>
                </div>
                {this.state.ECV &&
                  <div className={classNames(classes.row, classes.alignItemsCenter, classes.mt3)} >
                    <Typography variant="body1">EČV</Typography>
                    <Typography variant="body2">{this.state.ECV}</Typography>
                  </div>
                }
                <div className={classNames(classes.row, classes.alignItemsCenter)} >
                  <Typography variant="body1">Stav</Typography>
                  <Typography variant="button" classes={{ root: classes.textRight }} style={{ color: this.getStavColor(this.state.I_KARTA_STAV_DET) }}>{this.state.N_KARTA_STAV_DET}</Typography>
                </div>
                {this.state.D_DO &&
                  <div className={classNames(classes.row, classes.alignItemsCenter)} >
                    <Typography variant="body1">Platnosť</Typography>
                    <Typography variant="body2">{convert.convertDate(this.state.D_DO)}</Typography>
                  </div>
                }
                {this.state.ECV_TMP &&
                  <div className={classNames(classes.row, classes.alignItemsCenter)} >
                    <Typography variant="body1" style={{ color: '#4caf50' }}>Aktuálne platná dočasná zmena EČV</Typography>
                    <Typography variant="body2" style={{ color: '#4caf50' }}>{this.state.ECV_TMP}</Typography>
                  </div>
                }
                {this.state.ADRESA &&
                  <div className={classNames(classes.row, classes.alignItemsCenter)} >
                    <Typography variant="body1">Adresa&nbsp;parkovania</Typography>
                    <Typography variant="body2" className={classNames(classes.textRight)}>{this.state.ADRESA}</Typography>
                  </div>
                }
                <div className={classNames(classes.row, classes.alignItemsCenter)} >
                  <Typography variant="body1">Kategória</Typography>
                  <Typography variant="body2">{this.state.N_KARTA_KAT}</Typography>
                </div>
                <div className={classNames(classes.row, classes.alignItemsCenter)} >
                  <Typography variant="body1">Typ</Typography>
                  <Typography variant="body2">{this.state.N_KARTA_TYP}</Typography>
                </div>
                {this.state.N_POHON &&
                  <div className={classNames(classes.row, classes.alignItemsCenter)} >
                    <Typography variant="body1">Pohon</Typography>
                    <Typography variant="body2">{this.state.N_POHON}</Typography>
                  </div>
                }
                {this.state.C_KARTA &&
                  <div className={classNames(classes.row, classes.alignItemsCenter)} >
                    <Typography variant="body1">Číslo karty</Typography>
                    <Typography variant="body2">{this.state.C_KARTA}</Typography>
                  </div>
                }
                <div className={classNames(classes.row, classes.alignItemsCenter)} >
                  <Typography variant="body1">Číslo žiadosti</Typography>
                  <Typography variant="body2">{this.state.I_ZIADOST}</Typography>
                </div>
                {this.state.MIESTO &&
                  <div className={classNames(classes.row, classes.alignItemsCenter)} >
                    <Typography variant="body1">Číslo parkovacieho miesta</Typography>
                    <Typography variant="body2">{this.state.MIESTO}</Typography>
                  </div>}
                <VerticalStepper
                  steps={this.state.stavy}
                  className={classNames(classes.mt4, classes.mb4)}
                />
              </React.Fragment>
            </Paper>
          </div>
        }

        <Dialog
          open={this.state.openAlertCancel}
          onClose={() => this.handleCloseAlert('openAlertCancel')}
          contentText='Informácia: Po zrušení žiadosti nebude možné jej obnovenie. Bude potrebné zaevidovať novú žiadosť.'
          title='Naozaj chcete zrušiť žiadosť?'
          titleClasses={{ root: classes.dialogWarningText }}
          actions={[
            {
              label: 'Nie',
              color: 'primary',
              onClick: () => this.handleCloseAlert('openAlertCancel'),
              keyboardFocused: true
            },
            {
              label: 'Áno',
              color: 'secondary',
              onClick: this.cancelParkingPermit,
              keyboardFocused: false
            }
          ]}
        />
        <DialogIcon
          open={this.state.openAlertPay}
          onClose={() => this.handleCloseAlert('openAlertPay')}
          title='Spôsob platby'
          actions={[
            {
              label: 'Platobná brána',
              onClick: () => this.handlePay(1),
              icon: iconCreditCard
            },
            {
              label: 'Platobné údaje',
              onClick: () => this.handlePay(2),
              icon: iconEuroSymbol
            }
          ]}
        />
        <Dialog
          open={this.state.openAlertExtend}
          onClose={() => this.handleCloseAlert('openAlertExtend')}
          contentText='Predĺžiť platnosť karty?'
          title='Otázka'
          actions={[
            {
              label: 'Nie',
              color: 'primary',
              onClick: () => this.handleCloseAlert('openAlertExtend'),
              keyboardFocused: true
            },
            {
              label: 'Áno',
              color: 'secondary',
              onClick: this.handleParkingExtend,
              keyboardFocused: false
            }
          ]}
        />
        <Dialog
          open={this.state.openAttachmentsDialog}
          onClose={() => this.setState({ openAttachmentsDialog: false })}
          content={this.getAttachmentsDialogContent()}
          title='Prílohy'
          actions={
            [
              {
                label: 'Zavrieť',
                color: 'primary',
                onClick: () => this.setState({ openAttachmentsDialog: false, ATTACHS_FILE: [], ATTACHS_TP: [] }),
                keyboardFocused: true
              }
              ,
              {
                label: 'Odoslať',
                color: 'secondary',
                disabled: this.state.kartaTyp.L_PREDLZ_PRIL === true && (this.state.ATTACHS_FILE.length === 0 && this.state.ATTACHS_TP.length === 0),
                onClick: () => this.handleParkingExtendWithAttachments(),
                keyboardFocused: false
              }
            ]
          }
        />
      </div >
    );
  }
}

class Card extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      anchorEl: null,
      showExtendButton: false,
    };
  }

  componentDidMount = () => {
    const before = this.props.cardExtendBefore?.HODN;
    const after = this.props.cardExtendAfter?.HODN;

    if (before && after) {

      const dateBefore = new Date(this.props.data.D_DO);
      const dateAfter = new Date(this.props.data.D_DO);

      dateBefore.setDate(dateBefore.getDate() - Number(before));
      dateAfter.setDate(dateAfter.getDate() + Number(after));

      const now = new Date();

      dateAfter.setHours(23, 59, 59)

      if (dateBefore < now && now < dateAfter) {
        this.setState({ showExtendButton: true })
      }
    }
  }


  showCancel = (data) => {
    if (this.props.isZiadost) {
      const I_KARTA_STAV = data.stavy[data.stavy?.length - 1]?.I_KARTA_STAV
      return (
        I_KARTA_STAV !== 14 &&
        I_KARTA_STAV !== 15
      )
    } else {
      return data.I_KARTA_STAV === 1
    }
  }

  handleMenu = event => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { anchorEl } = this.state;
    return (

      <div className={classNames()}>
        <Paper elevation={2} className={classNames(this.props.classes.ziadostCard, this.props.classes.mb3)} >
          <div className={this.props.classes.ziadostCardContent}>
            <div className={classNames(this.props.classes.row, this.props.classes.alignItemsCenter, this.props.classes.justifyContentStart, this.props.classes.mb2, this.props.classes.parkingCardHeader)}>
              <ParkingCardIcon style={{ width: "45", height: "30" }}></ParkingCardIcon>
              <div className={classNames(this.props.classes.ml2)}>
                <Typography variant="button" style={{ fontWeight: 'bold' }}>{this.props.data.N_KARTA_TYP || this.props.data.typ?.N_KARTA_TYP}</Typography>
              </div>
              <div style={{ marginLeft: 'auto' }}>
                <IconButton
                  onClick={this.handleMenu}
                  color="inherit"
                  toolTip="Viac"
                  toolTipPlace="left"
                >
                  <MoreVertIcon />
                </IconButton>
                <Menu
                  id={this.props.data.I_ZIADOST.toString()}
                  anchorEl={anchorEl}
                  anchorOrigin={{ vertical: 'top', horizontal: 'right' }}
                  transformOrigin={{ vertical: 'top', horizontal: 'right' }}
                  open={Boolean(anchorEl)}
                  onClose={this.handleClose}
                >
                  <MenuItem onClick={event => this.props.onShowDetail()}>
                    <InfoIcon style={{ marginRight: '7px' }} />
                    <Typography style={{ marginRight: '22px' }}>Detail</Typography>
                  </MenuItem>
                  {this.props.data.I_KARTA_STAV !== 1 && this.props.data.I_KARTA_STAV !== 14 && this.props.data.I_KARTA_STAV !== 15 && this.props.data.L_KREDIT &&
                    <MenuItem onClick={event => this.props.onCredit()}>
                      <TimerIcon style={{ marginRight: '7px' }} />
                      <Typography style={{ marginRight: '22px' }}> Kredity</Typography>
                    </MenuItem>
                  }
                  {this.props.data.I_KARTA_STAV === 4 && this.props.data.POC_DNI_PLAT >= 0 &&
                    <MenuItem onClick={event => this.props.onParkingModify()}>
                      <EditIcon style={{ marginRight: '7px' }} />
                      <Typography>Zmena údajov</Typography>
                    </MenuItem>
                  }
                  {this.props.visibleTempEcv && this.props.data.L_ECV_DOC && this.props.data.I_KARTA_STAV === 4 && this.props.data.POC_DNI_PLAT >= 0 &&
                    <MenuItem onClick={event => this.props.onTempEcv()}>
                      <HourGlassIcon style={{ marginRight: '7px' }} />
                      <Typography>Dočasná zmena EČV</Typography>
                    </MenuItem>
                  }
                  {this.showCancel(this.props.data) &&
                    <MenuItem onClick={event => this.props.onOpenAlertCancel()}>
                      <RemoveBlackIcon style={{ marginRight: '7px', color: '#f44336' }} />
                      <Typography style={{ color: '#f44336' }}>Zrušiť žiadosť</Typography>
                    </MenuItem>
                  }
                </Menu>
              </div>
            </div>
            {this.props.data.ECV &&
              <div className={classNames(this.props.classes.row, this.props.classes.alignItemsCenter, this.props.classes.mt2)} >
                <Typography variant="body1">EČV</Typography>
                <Typography variant="body2">{this.props.data.ECV}</Typography>
              </div>
            }
            <div className={classNames(this.props.classes.row, this.props.classes.alignItemsCenter)} >
              <Typography variant="body1">Stav</Typography>
              <Typography variant="button" classes={{ root: this.props.classes.textRight }} style={{ color: this.props.getColor() }}>{this.props.isZiadost ? this.props.data.stavy[this.props.data.stavy?.length - 1].N_KARTA_STAV : this.props.data.N_KARTA_STAV}</Typography>
            </div>
            {this.props.data.D_DO && (
              <div className={classNames(this.props.classes.row, this.props.classes.alignItemsCenter)} >
                <Typography variant="body1">Platnosť</Typography>
                <Typography variant="body2">{convert.convertDate(this.props.data.D_DO)}</Typography>
              </div>
            )}
            {this.props.data.ECV_TMP &&
              <div className={classNames(this.props.classes.row, this.props.classes.alignItemsCenter)} >
                <Typography variant="body1" style={{ color: '#4caf50' }}>Aktuálne platná dočasná zmena EČV</Typography>
                <Typography variant="body2" style={{ color: '#4caf50' }}>{this.props.data.ECV_TMP}</Typography>
              </div>
            }
            {(this.props.data.I_KARTA_STAV === 2 || this.props.data.I_KARTA_STAV === 6 || this.props.data.I_KARTA_STAV === 11) && !this.props.data.L_UHR && !this.props.data.L_UHR_PB && this.props.data.CENA > 0 &&
              <div className={this.props.classes.row} >
                <Button variant="contained" color="secondary" size="large" type="success" className={classNames(this.props.classes.w100, this.props.classes.mt2)} onClick={() => this.props.onOpenAlertPay()} >
                  Platba {this.props.data.CENA}€
                </Button>
              </div>
            }
            {(this.props.data.I_KARTA_STAV === 4 || this.props.data.I_KARTA_STAV === 20) && this.state.showExtendButton &&
              <div className={this.props.classes.row} >
                <Button variant="contained" color="secondary" size="large" type="success" className={classNames(this.props.classes.w100, this.props.classes.mt2)} onClick={() => this.props.onOpenAlertExtend()}>
                  Predĺženie platnosti
                </Button>
              </div>
            }
          </div>
        </Paper>
      </div>
    );
  }
}

Card.propTypes = {
  onShowDetail: PropTypes.func,
  onCredit: PropTypes.func,
  onParkingModify: PropTypes.func,
  onOpenAlertCancel: PropTypes.func,
  onOpenAlertPay: PropTypes.func,
  onOpenAlertExtend: PropTypes.func,
  getColor: PropTypes.func,
  isZiadost: PropTypes.bool
}

const mapStateToProps = (state) => {
  return {
    person: state.person.data,
    listParkingCard: state.listParkingCard.data,
    isLoading: state.listParkingCard.isLoading,
    //listCardState: state.listCardState.data,
    //isLoadingState: state.listCardState.isLoading,
    parkingCard: state.parkingCard.data.Data,
    parkingPermitsList: state.parkingPermitsList?.data,
    visibleTempEcv: state.visibleTempEcv.data.Data,
    user: state.user,
    paymentGatewaySettings: state.paymentGatewaySettings.data.Data,
    cardExtendAfter: state.cardExtendAfter.data.Data,
    cardExtendBefore: state.cardExtendBefore.data.Data
  }
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(withWidth()(Parking))));
