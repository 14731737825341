import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import withWidth from '@material-ui/core/withWidth';
import classNames from 'classnames';

import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import getSharedStyles from '../../coraWebMComponents/sharedStyles'
import SelectFilterField from '../../coraWebMComponents/inputs/SelectFilterField';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';

import * as subsidiesRatingActions from '../../actions/subsidiesRatingActions';
import * as ciselnikyActions from '../../actions/ciselnikyActions';
import Subsidy from './Subsidy';
import SubsidyDetail from './SubsidyDetail';

const styles = theme => ({
  ...getSharedStyles(theme),
  expansionRoot: {
    boxShadow: 'none',
    borderTop: '1px solid #e1e1e1',
    '&:before': {
      display: 'none',
    },
  },
  subsidiesRatingHeader: {
    paddingBottom: '5px',
  },
  expansionExpanded: {
    margin: 'auto',
  },
  summaryRoot: {
    height: '50px',
    minHeight: '50px',
    padding: '0 20px'
  },
  summaryExpanded: {
    minHeight: '50px!important',
  },
  summaryContent: {
    minHeight: 'auto!important'
  },
  detailRoot: {
    padding: '0px 20px 20px 20px'
  },
  ziadostCardContent: {
    padding: '20px',
  },
})

const CStav = [
  {
    I_STAV: 1,
    N_STAV: 'Nehodnotené žiadosti',
  },
  {
    I_STAV: 2,
    N_STAV: 'Hodnotené žiadosti',
  },
  {
    I_STAV: 3,
    N_STAV: 'Čiastočne hodnotené žiadosti',
  }
];

class SubsidiesRating extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      I_STAV: 0,
      showDetail: false,
      projectName: '',
      edit: false,
    };
  }

  componentDidMount = async () => {
    if (this.props.person.I_O) {
      await this.props.getListSubsidiesRating(this.props.person.I_O, this.state.I_STAV);
    }

    // nacitaj configuracne data
    // ziskaj priznak pre zobrazenie ID dotácie
    if (!this.props.visibleIdNumberSubsidy?.isLoading) {
      await this.props.getVisibleIdNumberSubsidy();
    }
  }

  getStavColor = stav => {
    switch (stav) {
      case 1: return '#f44336'; //red
      case 2: return '#4caf50'; //green
      default: return ''
    }
  }

  handleChange = async (name, value) => {
    switch (name) {
      case 'I_STAV':
        await this.props.getListSubsidiesRating(this.props.person.I_O, value);
        break;
      default:
    }
    this.setState({ [name]: value });
  };

  handleShowDetail = async (upd, rec) => {
    this.setState({ edit: upd });
    this.setState({
      showDetail: true,
      projectName: rec.N_PROJ,
    });
    await this.props.getListSubsidyDetail(rec.I_DT);
    await this.props.getListSubsidyRatingDetail(rec.I_DT);
  };

  handleCloseDetail = () => {
    this.setState({ showDetail: false });
  };

  isMobile(width) {
    if (width === "xs" || width === "sm") {
      return true;
    }
    return false;
  };

  /*onSave = async () => {
    await this.props.listSubsidyRatingDetail.map(
      async d => this.props.changedId.indexOf(d.I_HOD) !== -1 &&
        await this.props.updSubsidyRating(d.I_HOD, typeof d.HOD === 'string' ? d.HOD : !d.HOD ? '' : d.HOD.toString()
        ));
    await this.props.getListSubsidiesRating(this.props.person.I_O, this.state.I_STAV);
    this.setState({ showDetail: false });
  };*/

  onSave = async () => {
    await this.props.updSubsidyRating({ TotalRecords: this.props.listSubsidyRatingDetail.length, Items: this.props.listSubsidyRatingDetail });
    await this.props.getListSubsidiesRating(this.props.person.I_O, this.state.I_STAV);
    this.setState({ showDetail: false });
  };

  render() {
    const { classes } = this.props;
    return (
      <div>
        <CircularProgress loading={this.props.isLoading || this.props.isLoadingDet || this.props.isLoadingRatDet} />
        {!this.state.showDetail &&
          <div className='container w-margin w-padding'>
            {((this.props.listSubsidiesRating.length > 1 && this.state.I_STAV === null) || this.state.I_STAV !== null) &&
              <div className={classNames(classes.mb3)} style={{ marginTop: '70px' }}>
                <SelectFilterField
                  floatingLabelText="Výber podľa aktuálneho stavu"
                  defaultLabelText="Všetky žiadosti"
                  value={this.state.I_STAV}
                  onChange={this.handleChange}
                  fullwidth={true}
                  schema={{ label: 'N_STAV', value: 'I_STAV' }}
                  data={CStav}
                  name='I_STAV'
                />
              </div>
            }
            {this.props.listSubsidiesRating.sort((a, b) => b.I_CLK_O.toString().localeCompare(a.I_CLK_O.toString()) || a.I_DT - b.I_DT).length === 0 && this.state.I_STAV !== null &&
              <div className={classNames(classes.mb3)}>
                <Typography variant="button" style={{ fontWeight: 'bold' }}>Podľa výberu stavu sa nenašla ani jedna žiadosť.</Typography>
              </div>
            }
            {this.props.visibleIdNumberSubsidy.data.Data && this.props.listSubsidiesRating.map(x =>
              <div>
                <Subsidy
                  key={x.I_CLK_O}
                  data={x}
                  classes={classes}
                  onShowDetail={(upd) => this.handleShowDetail(upd, x)}
                  getColor={() => this.getStavColor(x.I_STAV)}
                />
              </div>)
            }
          </div>
        }

        {this.state.showDetail &&
          <SubsidyDetail
            key={this.props.listSubsidyDetail.length > 0 ? this.props.listSubsidyDetail[0].I_PKRT_TXT : ''}
            data={this.props.listSubsidyDetail}
            dataRate={this.props.listSubsidyRatingDetail}
            classes={classes}
            handleClose={this.handleCloseDetail}
            projectName={this.state.projectName}
            edit={this.state.edit}
            onSave={this.onSave}
            onChange={this.props.changeSubsidyRating}
          />
        }
      </div >
    );
  }
}

const mapStateToProps = state => ({
  person: state.person.data,
  listSubsidiesRating: state.listSubsidiesRating.data,
  isLoading: state.listSubsidiesRating.isLoading,
  isLoadingDet: state.listSubsidyDetail.isLoading,
  isLoadingRatDet: state.listSubsidyRatingDetail.isLoading,
  listSubsidyDetail: state.listSubsidyDetail.data,
  listSubsidyRatingDetail: state.listSubsidyRatingDetail.data,
  visibleIdNumberSubsidy: state.visibleIdNumberSubsidy,
  //changedId: state.listSubsidyRatingDetail.changedId,
}
)

const mapDispatchToProps = dispatch => ({
  getListSubsidiesRating: (iO, iStav) => dispatch(subsidiesRatingActions.getListSubsidiesRating(iO, iStav)),
  getListSubsidyDetail: (iDt) => dispatch(subsidiesRatingActions.getListSubsidyDetail(iDt)),
  getListSubsidyRatingDetail: (iDt) => dispatch(subsidiesRatingActions.getListSubsidyRatingDetail(iDt)),
  updSubsidyRating: (data) => dispatch(subsidiesRatingActions.updSubsidyRating(data)),
  changeSubsidyRating: (id, value) => dispatch(subsidiesRatingActions.changeSubsidyRating(id, value)),
  getVisibleIdNumberSubsidy: () => dispatch(ciselnikyActions.getVisibleIdNumberSubsidy()),
}
)

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(SubsidiesRating)));