import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import { withStyles } from '@material-ui/core/styles';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import classNames from 'classnames';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import * as taxesActions from '../../actions/taxesActions';
import Button from '../../coraWebMComponents/inputs/Button';
import KeyboardArrowRight from '../../coraWebMComponents/dataDisplay/icons/KeyboardArrowRight';
import * as convert from '../../coraWebMComponents/utils/convert';
import { getColor, custStateCell, nbWithSpaces } from './Taxes';
import PropertyTaxesDetail from './PropertyTaxesDetail';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import Installments from './Installments';
// import { propTypes } from 'qrcode.react';

const styles = theme => ({
  ...getSharedStyles(theme),
})

let data, title;

class Proceedings extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      showTaxesDetail: false,
      showInstallments: false,
      title: '',
      SSY: '',
      VS: '',
      iKon: 0,
      PLATBA: 0,
      STAV: 0,
      L_UHR_PB: false,
      CIS_ROZH: 0,
      openAlertPayment: false,
    }
  }

  componentDidMount = async () => {

    await this.props.getListProceedings(this.props.zozCisRozh, this.props.person.I_O);
    data = this.props.listProceedings;

    if (data.length > 1) {
      title = `Spoločné rozhodnutie ${data[0].ROK}`
    }
    else {
      if (data.length > 0) {
        this.getCardTitle(data[0].N_PRJ_E);
      }
      else title = '';
    }

    this.setState({ title: title })
  }

  getCardTitle = (data) => {
    switch (data) {
      case 'Daň z nehnuteľností':
        title = 'Daň z nehnuteľností'
        break;
      case 'Pes':
        title = 'Daň za psa'
        break;
      case 'Verejné priestranstvo':
        title = 'Daň za užívanie verejného priestranstva'
        break;
      case 'Ubytovanie':
        title = 'Daň za ubytovanie'
        break;
      case 'Predajné automaty':
        title = 'Daň za predajné automaty'
        break;
      case 'Nevýherné hracie prístroje':
        title = 'Daň za nevýherné hracie prístroje'
        break;
      case 'Vjazd do historickej časti mesta':
        title = 'Daň za vjazd a zotrvanie motorového vozidla v historickej časti mesta'
        break;
      case 'Jadrové zariadenie':
        title = 'Daň za jadrové zariadenie'
        break;
      case 'Komunálny odpad':
        title = 'Poplatok za komunálny odpad'
        break;
      case 'Poplatok za rozvoj':
        title = 'Poplatok za rozvoj'
        break;
      default: title = ''
        break;
    }
    return title;
  }

  isMobile(width) {
    if (width === "xs" || width === "sm") {
      return true;
    }
    return false;
  }

  handleShowTaxesDetail = async (item) => {
    if (item.I_KON === undefined) return;
    await this.props.getListPropertyTaxes(item.I_KON, item.I_O);

    this.setState({
      showTaxesDetail: true,
      SSY: item.SSY,
      VS: item.VS,
      iKon: item.I_KON,
      PLATBA: item.PLATBA,
      STAV: item.STAV,
      L_UHR_PB: item.L_UHR_PB,
      CIS_ROZH: item.CIS_ROZH,
    });
  }

  handleCloseTaxesDetail = () => {
    this.setState({ showTaxesDetail: false });
  };

  showInstallments = () => {
    this.setState({ showInstallments: true });
  }

  handleCloseInstallments = () => {
    this.setState({ showInstallments: false });
  }

  render() {
    const { classes, cKon, listProceedings } = this.props;
    data = this.props.listProceedings.length > 0 ? this.props.listProceedings[0] : [];
    return (
      <div>
        <CircularProgress loading={this.props.isLoading} />
        {listProceedings.length === 0 &&
          <div className={classNames(classes.mb3, classes.pt3, classes.textCenter)}>
            <Typography variant="button" style={{ fontWeight: 'bold' }}>Nenašli sa žiadne položky.</Typography>
          </div>
        }
        {(!this.state.showTaxesDetail && !this.state.showInstallments) &&
          <div className='container w-margin w-padding'>
            <div className={classNames(classes.dFlex, classes.justifyContentStart, classes.pt3)}>
              <IconButton
                className={classNames(classes.backButton, classes.p0)}
                color="inherit"
                onClick={this.props.onClose}
                toolTip="Späť"
                toolTipPlace="right"
                disableRipple
                disableHover
              >
                <ArrowBack
                  color='secondary'
                  fontSize='default'
                  variant='fab'
                  style={{ fontSize: 30 }}
                />
              </IconButton>
              <Typography style={{ paddingTop: '5px', paddingLeft: '1em' }}><span className={classes.fontBold}>{this.state.title}</span><span className={classes.textItalic}>/detail</span></Typography>
            </div>
            <div className={classNames(classes.row, classes.alignItemsCenter, classes.mt3)} >
              <Typography variant="body1">Číslo konania</Typography>
              <Typography variant="body2">{cKon}</Typography>
            </div>
            <div className={classNames(classes.row, classes.alignItemsCenter, classes.mt3)} >
              <Typography variant="body1">Číslo rozhodnutia</Typography>
              <Typography variant="body2">{data.CIS_ROZH}/{data.ROK_ROZH}</Typography>
            </div>
            <div className={classNames(classes.row, classes.alignItemsCenter, classes.mt3, classes.mb3)} >
              <Typography variant="body1">Dátum rozhodnutia</Typography>
              <Typography variant="body2">{convert.convertDate(data.D_ROZH)}</Typography>
            </div>
            {listProceedings.map(item =>
              <Paper elevation={2} key={item.I_DN_PO} className={classNames(classes.ziadostCard, classes.mb3)} >
                <div className={classes.cardContent}>
                  <div className={classNames(classes.row, classes.alignItemsCenter, classes.justifyContentBetween, classes.mb2)}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{this.getCardTitle(item.N_PRJ_E)}</Typography>
                    {item.N_PRJ_E === "Daň z nehnuteľností" && (item.N_KON_TYP === "Vyrubenie dane" || item.N_KON_TYP === "Vyrubenie rozdielu dane") &&
                      <IconButton
                        onClick={() => this.handleShowTaxesDetail(item)}
                        handleClose={this.handleCloseTaxesDetail}
                        toolTip="Detail"
                      >
                        <KeyboardArrowRight
                          color='secondary'
                        />
                      </IconButton>
                    }
                  </div>
                  <div className={classNames(classes.row, classes.alignItemsCenter, classes.mt2)} >
                    <Typography variant="body1">Stav</Typography>
                    <Typography variant="body2" style={{ color: getColor(item.STAV, item.L_UHR_PB) }} >{custStateCell(item)}</Typography>
                  </div>
                  <div className={classNames(classes.row, classes.alignItemsCenter)} >
                    <Typography variant="body1">Dátum splatnosti</Typography>
                    <Typography variant="body2" classes={{ root: classes.textRight }} >{convert.convertDate(item.D_SPLAT)}</Typography>
                  </div>
                  {item.STAV !== 2 &&
                    <div className={classes.row} >
                      {(item.PLATBA > 0 && item.L_UHR_PB === false) &&
                        <Button
                          variant="contained"
                          color="secondary"
                          size="large"
                          type="success"
                          className={classNames(classes.w100, classes.mt2, classes.row, classes.justifyContentBetween)}
                          onClick={this.showInstallments}
                        >
                          <span style={{ fontWeight: "normal" }}>Platba</span>
                          {nbWithSpaces(item.PLATBA.toFixed(2))}{' '}{item.MENA === "EUR" ? "€" : ''}
                        </Button>
                      }
                    </div>
                  }
                </div>
              </Paper>
            )}
          </div>
        }
        {this.state.showInstallments &&
          <Installments
            onClose={this.handleCloseInstallments}
            zozCisRozh={this.props.zozCisRozh}
            zozIPoh={this.props.zozIPoh}
          />
        }
        {this.state.showTaxesDetail &&
          <PropertyTaxesDetail
            handleClose={this.handleCloseTaxesDetail}
            handlePayment={this.handlePayment}
            classes={classes}
            VS={this.state.VS}
            SSY={this.state.SSY}
            iKon={this.state.iKon}
            platba={this.state.PLATBA}
            stav={this.state.STAV}
            lUhrPb={this.state.L_UHR_PB}
            cRozh={this.state.CIS_ROZH}
          />
        }
      </div>
    )
  }
}

Proceedings.propTypes = {
  onClose: PropTypes.func,
  handlePayment: PropTypes.func,
  zozCisRozh: PropTypes.string,
  zozIPoh: PropTypes.string,
  cKon: PropTypes.string,
}

const mapStateToProps = (state) => ({
  person: state.person.data,
  listProceedings: state.listProceedings.data,
  isLoading: state.listProceedings.isLoading,
  user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
  getListProceedings: (zozCisRozh, iO) => dispatch(taxesActions.getListProceedings(zozCisRozh, iO)),
  getListPropertyTaxes: (iKon, iO) => dispatch(taxesActions.getListPropertyTaxes(iKon, iO)),
  dispatch,
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(Proceedings)));
