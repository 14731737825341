import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import { getParkZones } from '../../utils/parkingUtils';
import getSharedStyles from '../../coraWebMComponents/sharedStyles'
import Map from '../../coraWebMComponents/map/Map';
import MapPolygons from './MapPolygons';
import * as locationUtils from '../../utils/locationUtils';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';

import GpsFixedIcon from '../../coraWebMComponents/dataDisplay/icons/GpsFixedIcon';
import ButtonFab from '../../coraWebMComponents/inputs/ButtonFab';


const styles = theme => ({
  ...getSharedStyles(theme)
})


class ParkingMap extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      parkZones: [],
      dialogOpen: true,
      actualPosition: window.config.map.defaultPosition,
      isLoading: true,
    };
  }

  getActualPosition = async () => {
    const actPosition = await locationUtils.getPosition();
    const gpsx = actPosition?.coords?.latitude ?? window.config.map.defaultPosition[0];
    const gpsy = actPosition?.coords?.longitude ?? window.config.map.defaultPosition[1];
    return [gpsx, gpsy];
  }

  componentDidMount() {
    this.getActualPosition().then((position) => {
      this.setState({ actualPosition: position });
    })
    getParkZones().then((data) => {
      const parkZones = data.Data.Items.map((zone) => {
        return zone.geometry.type === "Polygon" ?
          // geometry.type: Polygon
          {
            coordinates: zone.geometry.coordinates.map((singlePolygon) => {
              return singlePolygon.map((coord) => {
                return [coord[1], coord[0]];
              })
            }),
            color: zone.properties.PARK_ZONA_STYLE,
            title: zone.properties.N_ZONA,
            toolTip: zone.properties.Label ?? "bezplatné",
            key: zone.properties.I_ZONA
          }
          :
          // geometry.type: MultiPolygon
          {
            coordinates: zone.geometry.coordinates.map((singlePolygon) => { return singlePolygon[0].map((coord) => { return [coord[1], coord[0]] }) }),
            color: zone.properties.PARK_ZONA_STYLE,
            title: zone.properties.N_ZONA,
            toolTip: zone.properties.Label ?? "bezplatné",
            key: zone.properties.I_ZONA
          }
      })
      this.setState({ parkZones });
    }).finally(() => {
      this.setState({ isLoading: false });
    })
  }

  handleLocalize = () => {
    this.getActualPosition().then((position) => {
      this.setState({ actualPosition: position });
    })
  }


  render() {
    return (
      <div>
        <CircularProgress loading={this.state.isLoading} />
        <Map
          center={this.state.actualPosition}
          position={this.state.actualPosition}
          zoom={14}
          maxZoom={window.config.map.maxZoomLevel}
          minZoom={window.config.map.minZoomLevel}
          scrollWheelZoom={true}
          height={window.innerHeight}
          markers={[]}
          zoomControlPosition="bottomleft"
          currentPositionMarker={true}
          maxBounds={[[window.config.map.defaultPosition[0] - window.config.map.maxBoundsOffsetX,
          window.config.map.defaultPosition[1] - window.config.map.maxBoundsOffsetY],
          [window.config.map.defaultPosition[0] + window.config.map.maxBoundsOffsetX,
          window.config.map.defaultPosition[1] + window.config.map.maxBoundsOffsetY]]}
        >
          <MapPolygons polygons={this.state.parkZones} />
        </Map>
        <ButtonFab
          label=""
          onClick={this.handleLocalize}
          size='medium'
          style={{ backgroundColor: '#000000', zIndex: '500', position: 'absolute', bottom: '20px', right: '5px' }}
        >
          <GpsFixedIcon style={{ color: '#ffffff' }} />
        </ButtonFab>
      </div>
    )
  }
}



const mapStateToProps = (state) => {
  return {

  }
}

// ParkingMap.propTypes = {

// }

export default connect(mapStateToProps)(withStyles(styles)(ParkingMap));


