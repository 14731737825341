import React, { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import withRouter from '../../coraWebMComponents/utils/withRouter';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';

import routes from '../../routes';
import * as userActions from '../../actions/userActions';
import * as locatorActions from '../../actions/locatorActions';
import * as locatorUtils from '../../utils/locatorUtils';
import { TextComponent, OptionGroupComponent, ButtonsComponent } from './ZstQuestionnaire';

const _ALLOWED_NUMBER_OF_CHARS = 200;

/**
 * Dotaznik spokojnosti - formular
 *
 * @param {*} props
 * @return {*} 
 */
const ZstLsDtzComponent = (props) => {

  const { classes } = props;

  const [answers, setAnswers] = useState([]);

  // inicializačné načítanie dát
  useEffect(() => {
    async function fetchData() {

      // zoznam otázok
      if (!props.listZstLsOtz.isLoaded) {
        await props.getZstLsOtzList();
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  useEffect(() => {
    if (props.listZstLsOtz.isLoaded && props.listZstLsOtz.data.length > 0 && answers.length < 1) {
      const obj = props.listZstLsOtz.data.map(x => ({ questId: x.ILsOtz, questText: x.NLsOtz, answerType: x.LsHod.ILsHod, answerValue: "" }));
      setAnswers(obj);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [props.listZstLsOtz.isLoaded]);

  const handleOnChange = (event, id) => {
    const text = event.target.value;
    if (_ALLOWED_NUMBER_OF_CHARS >= text?.length) {
      const obj = answers.map(x => x.questId === id ? { ...x, answerValue: text } : x);
      setAnswers(obj);
    }
  };

  const handleSubmitClick = async () => {
    let result = { isOK: false, message: "Odoslanie dotazníka sa nepodarilo." };
    props.dispatch(userActions.hideErrorMsg());
    props.dispatch(userActions.hideMsg());
    try {
      if (answers.length > 0 && !answers.some(x => x.answerValue.length < 1)) {
        const body = answers.map(x => ({ NLsOtz: x.questText, Vysl: x.answerValue }));
        const res = await locatorUtils.SaveZstLsDtz(body);
        console.debug(`Result: ${res}`);
        console.debug(`Result: ${res}`);
        if (Boolean(res) && res) {
          result = { isOK: true, message: "Ďakujeme za vyplnenie dotazníka." };
        }
      }
    } catch (error) {
      console.error(error);
    }
    props.navigate(routes.ZST, { state: result });
  };

  const handleCancelClick = () => {
    props.navigate(routes.ZST);
  };

  return <>
    <Paper elevation={5}
      className={classNames(classes.w100, classes.alignItemsCenter, classes.justifyContentLeft, classes.pl3, classes.pr3, classes.pt3, classes.pb3)}
    >
      <CircularProgress loading={props.listZstLsOtz.isLoading} />
      <div className={classNames(classes.w100, classes.alignItemsCenter, classes.justifyContentCenter)}>
        <Typography variant={"h5"}>Dotazník</Typography>
      </div>
      {answers.length > 0 && answers.map(x => {
        const { questId, questText, answerType, answerValue } = x;
        switch (answerType) {
          case 1:
            return (
              <OptionGroupComponent classes={classes}
                data={{ questId, questText, answerValue }}
                onChange={(event) => handleOnChange(event, questId)}
                items={[{ value: "1", label: "1" }, { value: "2", label: "2" }, { value: "3", label: "3" }, { value: "4", label: "4" }, { value: "5", label: "5" }]}
                inRow={true} />
            );
          case 2:
            return (
              <OptionGroupComponent classes={classes}
                data={{ questId, questText, answerValue }}
                onChange={(event) => handleOnChange(event, questId)}
                items={[{ value: "áno", label: "áno" }, { value: "nie", label: "nie" }]}
                inRow={true} />
            );
          case 3:
          default:
            return (
              <TextComponent classes={classes}
                data={{ questId, questText, answerValue }}
                onChange={(event) => handleOnChange(event, questId)}
                rowCount={1}
                maxChars={200} />
            );
        }
      }
      )}
      <ButtonsComponent classes={classes}
        onSubmitClick={handleSubmitClick}
        onCancelClick={handleCancelClick}
        disabledSubmitButton={answers.length < 1 || answers.some(x => x.answerValue.length < 1)} />
    </Paper>
  </>;
}

ZstLsDtzComponent.propTypes = {
  metadata: PropTypes.object.isRequired
}

const mapStateToProps = (state) => ({
  listZstLsOtz: state.listZstLsOtz,
})

const mapDispatchToProps = (dispatch) => ({
  getZstLsOtzList: () => dispatch(locatorActions.getZstLsOtzList()),
  dispatch
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ZstLsDtzComponent));

