import { Stack } from '@mui/material';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Paper from '../../coraWebMComponents/surfaces/Paper';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import InfoIcon from '../../coraWebMComponents/dataDisplay/icons/InfoIcon';

/**
 * Komponent s warningom pri neexistenii ZS alebo sluzby
 *
 * @param {*} { classes, infoText }
 * @return {*} 
 */
const WarningNoData = ({ classes, infoText }) => {
  return (
    <>
      <Paper elevation={1}
        className={classNames(classes.w100, classes.row, classes.alignItemsCenter, classes.justifyContentCenter, classes.pt3, classes.pb3)}
      >
        <Stack flexDirection={"row"} gap={1} p={1} alignItems="flex-start">
          <InfoIcon />
          <Typography variant={"body1"} style={{textAlign: "center"}}>{infoText}</Typography>
        </Stack>
      </Paper>
    </>
  );
};

WarningNoData.propTypes = {
  classes: PropTypes.object,
  infoText: PropTypes.string,
}

export default WarningNoData;