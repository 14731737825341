import React from 'react';
import MapIcon from '@material-ui/icons/MapOutlined';
import PropTypes from 'prop-types';

class MapIconComponent extends React.Component {
  render() {
    return (
      <MapIcon
        style={this.props.style}
        className={this.props.className}
      />
    )
  }
}

MapIconComponent.propTypes = {
  style: PropTypes.object,
  className: PropTypes.string
}
export default MapIconComponent;