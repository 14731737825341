import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles";
import classNames from "classnames";
import { TextField, Box, InputAdornment, IconButton } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';

import getSharedStyles from "../../coraWebMComponents/sharedStyles";

const styles = (theme) => {
  return {
    ...getSharedStyles(theme),
    root: {
      width: "100%",
      // [theme.breakpoints.up("sm")]: {
      // width: "100%",
      // },
    },
    input: {
      // flex: 1,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,

    },
    inputActive: {
      flex: 1,
      fontFamily: theme.typography.fontFamily,
      fontSize: theme.typography.fontSize,
      color: "green",
    },
    iconButton: {
      padding: 7,
      color: theme.palette.text.primary,
    },
    iconButtonActive: {
      padding: 7,
      color: "green",
    },
    fltSearch: {
      width: "100%",
      [theme.breakpoints.up("sm")]: {
        // marginLeft: theme.spacing.unit * 3,
        // width: "auto",
      },
      verticalAlign: "middle",
    },
    fltSearchActive: {
      width: "100%",
      color: "green",
      // border: "1px solid green",
      // [theme.breakpoints.up("sm")]: {
      // width: "auto",
      // },
      // verticalAlign: "middle",
    },
    fltDiv: {
      backgroundColor: alpha(theme.palette.common.white, 1),
      "&:hover": {
        backgroundColor: alpha(theme.palette.common.black, 0.1),
      },
      // position: "relative",
      // display: "inline-flex",
      // marginLeft: `${theme.spacing.unit * 2}px`,
      // padding: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
      // padding: `${theme.spacing.unit}px`,
      // width: `100%`,
      // borderRadius: theme.shape.borderRadius,
      // borderRadius: 0,
      // marginBottom: '2px',
    },
    fltDivActive: {
      // position: "relative",
      // display: "inline-flex",
      // width: `100%`,
      backgroundColor: "rgb(246, 255, 245)",
      "&:hover": {
        backgroundColor: "rgb(216, 225, 215)",
      },
      // borderRadius: 0,
    },
  };
};

class SearchFilterComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { searchText: props.searchText ?? "", searchActive: false };
  }

  handleOnClick = () => {
    if (this.state.searchText.length < 3) {
      this.setState({ searchActive: false });
      return this.props.onClick("");
    } else {
      this.setState({ searchActive: true });
      return this.props.onClick(this.state.searchText);
    }
  };

  handleChange = (event) => {
    this.setState({ searchText: event.target.value });
  };

  keyPress = (event) => {
    if (event.keyCode === 13) {
      if (this.state.searchText.length < 3) {
        this.setState({ searchActive: false });
        this.props.onClick("");
      } else {
        this.setState({ searchActive: true });
        this.props.onClick(this.state.searchText);
      }
    }
  };

  handleSearch = (text) => {
    if (this.state.searchText.length < 3) {
      this.setState({ searchActive: false });
      this.props.onClick("");
    } else {
      this.setState({ searchActive: true });
      this.props.onClick(this.state.searchText);
    }
  }

  render() {
    const { classes } = this.props;

    // const fnc = () => this.props.onClick(this.state.searchText);
    return (
      <>
        <Box
          // sx={{ width: matchesThemeMD ? "100%" : "22em" }}
          className={classNames(
            this.state.searchActive ? classes.fltDivActive : classes.fltDiv,
            this.state.searchActive ? classes.fltSearchActive : classes.fltSearch,
            classes.root
          )}
        >
          <TextField
            onChange={this.handleChange}
            onKeyDown={(e) => {
              if (e.key === 'Enter') {
                this.handleOnClick()
              }
            }}
            type="search"
            value={this.state.searchText}
            label={"Hľadanie"}
            aria-label={"Search"}
            // helperText={searchSuccess ? LOCALE.searchHelperDefault : LOCALE.searchHelperError}
            // error={!searchSuccess}
            fullWidth
            size="small"
            InputProps={{
              endAdornment:
                <InputAdornment position="end">
                  <IconButton
                    onClick={this.handleOnClick}
                    aria-label={"Search"}
                    edge="end"
                    type="button"
                    color='primary'
                  >
                    <SearchIcon />
                  </IconButton>
                </InputAdornment>
            }}
          >
          </TextField>
        </Box>

        {/* <Paper
          className={classNames(
            this.state.searchActive ? classes.fltDivActive : classes.fltDiv,
            this.state.searchActive ? classes.fltSearchActive : classes.fltSearch,
            classes.root
          )}
          elevation={0}
        >
          <IconButton
            className={
              this.state.searchActive
                ? classes.iconButtonActive
                : classes.iconButton
            }
            aria-label="Search"
            onClick={this.handleOnClick}
          >
            <SearchIcon />
          </IconButton>
          <InputBase
            className={
              this.state.searchActive ? classes.inputActive : classes.input
            }
            placeholder={this.props.placeholderText ?? "hľadanie..."}
            onChange={this.handleChange}
            value={this.state.searchText}
            onKeyDown={this.keyPress}
          />
        </Paper> */}
      </>
    );
  }
}

SearchFilterComponent.propTypes = {
  searchText: PropTypes.string,
  placeholderText: PropTypes.string,
  onClick: PropTypes.func.isRequired,
};

export default withStyles(styles)(SearchFilterComponent);
