import React from "react";
// import CircularProgress from "../../coraWebMComponents/feedBack/progress/CircularProgress";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import SaveIcon from "@material-ui/icons/Save";

import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import ArrowBack from "../../coraWebMComponents/dataDisplay/icons/ArrowBack";
import IconButton from "../../coraWebMComponents/inputs/IconButton";
import withRouter from "../../coraWebMComponents/utils/withRouter";
import ListItemMenu from "../../coraWebMComponents/navigation/ListItemMenu";
import Button from "../../coraWebMComponents/inputs/Button";
import EditIcon from "../../coraWebMComponents/dataDisplay/icons/EditIcon";

import routes from "../../routes";
import { adminModeSetCurrEdited, adminModeSetEditedData, adminModeUpdateOrAdd } from "../../actions/adminModeActions";
import { DRAWER_EL1, DRAWER_EL2, ISpMnu } from "../../constants/adminConsts";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  expansionRoot: {
    boxShadow: "none",
    borderTop: "1px solid #e1e1e1",
    //background: '#f6f6f6',
    "&:before": {
      display: "none",
    },
  },
  parkingCardHeader: {
    //borderBottom: '1px solid #e1e1e1',
    paddingBottom: "5px",
  },
  expansionExpanded: {
    margin: "auto",
  },
  summaryRoot: {
    height: "50px",
    minHeight: "50px",
    padding: "0 20px",
  },
  summaryExpanded: {
    minHeight: "50px!important",
  },
  summaryContent: {
    minHeight: "auto!important",
  },
  detailRoot: {
    padding: "0px 20px 20px 20px",
  },
  ziadostCardContent: {
    padding: "20px",
    //backgroundColor: '#e9e9e9',
  },
  paperCard: {
    padding: "15px",
    [theme.breakpoints.up("md")]: {
      padding: "20px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "25px",
    },
  },
  deleteZiadostBtn: {
    backgroundColor: "#e57373",
  },
  payBtn: {
    backgroundColor: "#4caf50",
  },
});

class RequiredPublic extends React.Component {
  handleClickMenu = (route) => {
    this.props.navigate({ pathname: route });
  };

  handleCurrEdited = (e, value) => {
    e.stopPropagation();
    this.props.dispatch(adminModeSetCurrEdited(value));
    this.props.dispatch(adminModeSetEditedData(null));
  };

  handleEditData = (data) => {
    this.props.dispatch(adminModeSetEditedData(data));
  };

  getEditButton = (drawer) => {
    const { classes } = this.props;
    return (
      <Button
        variant="contained"
        color="secondary"
        className={classNames(classes.w100, classes.textTransformNone, classes.adminButton)}
        onClick={(e) => this.handleCurrEdited(e, drawer)}
        startIcon={<EditIcon />}
      >
        Upraviť
      </Button>
    );
  };

  getSaveButton = () => {
    const { classes } = this.props;
    return (
      <Button
        variant="contained"
        color="secondary"
        type="success"
        className={classNames(classes.w100, classes.textTransformNone)}
        onClick={this.handleOpenDialog}
        startIcon={<SaveIcon />}
      >
        Uložiť
      </Button>
    );
  };

  render() {
    const menuA = this.props.menuTree.children;
    const menuPublic = menuA.filter((x) => x.MenuID === 4004007);
    const menuRequired = menuPublic[0].children.filter(
      (x) => x.MenuID === 4004001
    );
    const { currEdited, isActive } = this.props.adminMode;

    let subMenu = [];

    if (menuRequired.length === 1)
      subMenu = menuRequired[0].children.filter((x) => x.Visible === 1);

    const { classes, isAuth } = this.props;
    console.log("isAuth", isAuth);
    return (
      <div className="container" style={{ marginTop: "70px" }}>
        <Link to={isAuth ? routes.HOME : routes.PZ}>
          <IconButton
            className={classNames(classes.backButton, classes.ml1, classes.mr6)}
            color="inherit"
            toolTip={""}
            toolTipPlace="right"
          >
            <ArrowBack
              color="secondary"
              variant="fab"
              style={{ fontSize: 30 }}
            />
          </IconButton>
        </Link>
        <ListItemMenu
          color="secondary"
          menu={subMenu}
          onClick={this.handleClickMenu}
          paperClasses={classNames(classes.ml3, classes.mr3, classes.mb3)}
          inEdit={currEdited === DRAWER_EL2}
          onEdit={this.handleEditData}
        />
        {isActive &&
          currEdited !== DRAWER_EL2 &&
          this.getEditButton(DRAWER_EL2)}
        {isActive && currEdited === DRAWER_EL2 && this.getSaveButton()}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    isAuth: state.user.data !== null,
    listPeople: state.listPeople.data,
    person: state.person.data,
    visiblePublicZone: state.visiblePublicZone.data.Data,
    egovHandlerUrl: state.egovHandlerUrl.data.Data,
    adminMode: state.adminMode,
  };
};

export default withRouter(connect(mapStateToProps)(withStyles(styles)(RequiredPublic)));
