import React from "react";
import PropTypes from "prop-types";
import { Stack } from '@mui/material';
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp';

const ScrollTopButton = ({ onClick }) => {
  return (
    <>
      <Stack
        width={"2.5rem"}
        height={"2.5rem"}
        alignItems="center"
        justifyContent="center"
        sx={{
          backgroundColor: "#BBDEFB",
          color: "blue",
          position: 'fixed',
          bottom: '2rem',
          right: '1.5rem',
          zIndex: "1000",
          borderRadius: "0.5rem",
          opacity: 0.6,
          transition: 'opacity 0.3s ease',
          '&:hover': {
            opacity: 0.95,
          },
        }}
      >
        <Stack onClick={onClick}>
          <KeyboardArrowUpIcon />
        </Stack>
      </Stack>
    </>
  );
}

ScrollTopButton.propTypes = {
  onClick: PropTypes.func.isRequired,

};

export default ScrollTopButton;
