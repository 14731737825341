import React from 'react';
import { connect } from 'react-redux';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import Button from '../../coraWebMComponents/inputs/Button';
import ArrowForward from '../../coraWebMComponents/dataDisplay/icons/ArrowForward';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import TextField from '../../coraWebMComponents/inputs/TextField';
import CustomSwitch from '../../coraWebMComponents/inputs/CustomSwitch';
import RaisedButton from '../../coraWebMComponents/inputs/RaisedButton';
import Autocomplete from '../../coraWebMComponents/inputs/Autocomplete';
import DatePicker from '../../coraWebMComponents/inputs/DatePicker';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import Attachment from '../../coraWebMComponents/inputs/Attachment';
import AttachmentFile from '../../coraWebMComponents/inputs/AttachmentFile';
import AttachmentInput from '../../coraWebMComponents/inputs/AttachmentInput';
import FileInput from '../../coraWebMComponents/inputs/FileInput';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import routes from '../../routes';
import * as validator from '../../coraWebMComponents/utils/validator';
import * as convert from '../../coraWebMComponents/utils/convert';
import * as accountActions from '../../actions/accountActions';
import * as ciselnikyActions from '../../actions/ciselnikyActions';
import { withStyles } from '@material-ui/core/styles';
import getSharedStyles from '../../coraWebMComponents/sharedStyles'
import classNames from 'classnames';
import withWidth from '@material-ui/core/withWidth';
import { withLocalizationConsumer } from '../../coraWebMComponents/utils/withLocalization';
import * as userActions from '../../actions/userActions';
import Switch from '../../coraWebMComponents/inputs/Switch';
import withRouter from '../../coraWebMComponents/utils/withRouter';

const _autocompleteCountCharStart = window.config.autocompleteCountCharStart;
const _autocompleteTimeDelay = window.config.autocompleteTimeDelayInMs;

const styles = theme => ({
  ...getSharedStyles(theme),
  paperCard: {
    padding: '20px',
    [theme.breakpoints.up('sm')]: {
      padding: '30px',
    },
    [theme.breakpoints.up('md')]: {
      padding: '45px',
    },
    [theme.breakpoints.up('lg')]: {
      padding: '60px',
    },
  },
  customSwitchIndicator: {
    backgroundColor: '#424242!important',
    height: '100%'
  }
})

let _sizeAttachs = 0;

const _initState = {
  I_ZIADOST: 0,
  I_O_TYP: 1,
  MENO: '',
  PF: '',
  D_NAR: null,
  ICO_RC: '',
  DIC: '',
  N_U: '',
  CS: '',
  CO: '',
  N_M: '',
  krajina: {
    I_STAT: 1,
    N_STAT: 'Slovenská republika'
  },
  IC_DPH: '',
  D_EVID: null,
  I_O: null,
  I_ADR: null,
  I_UZ: null,
  I_ZDROJ: 1,
  ATTACHS: [],
  ATTACHS_FILE: [],
  errorTextRcToken: '',
  errorTextMeno: '',
  errorTextPf: '',
  errorTextIco_rc: '',
  errorTextCs: '',
  errorTextN_m: '',
  errorTextAttach: '',
  loading: true,
  done: false,
  tabs: 0,
  text: '',
  havePersonEid: false,
  openAlertDouble: false,
  icDphSwitch: false,
  URI: null,
}

class AccountAddPerson extends React.Component {
  constructor(props) {
    super(props);
    this.state = _initState;
  }

  componentDidMount = async () => {
    const search = window.location.href.split('?').pop();
    const params = new URLSearchParams(search);
    const code = params.get("code");
    const txt = await this.props.translateWithLocaleAsync(this.props.locale, 'DM_ACCOUNT_ADD_PERSON');

    if (code)
      await this.props.dispatch(userActions.getPersonEid(code, "subject"));

    const havePersonEid = this.props.personEid.I_O ? true : false;
    const I_O_TYP = havePersonEid ? this.props.personEid.I_O_TYP : 1;
    const MENO = havePersonEid ? this.props.personEid.MENO : '';
    const PF = havePersonEid ? this.props.personEid.PF : '';
    const ICO_RC = havePersonEid ? this.props.personEid.ICO_RC : '';
    const D_NAR = havePersonEid ? (I_O_TYP === 1 ? convert.rcToDnar(ICO_RC) : null) : null;
    const N_U = havePersonEid ? this.props.personEid.N_U : '';
    const CS = havePersonEid ? this.props.personEid.CS : '';
    const CO = havePersonEid ? this.props.personEid.CO : '';
    const N_M = havePersonEid ? this.props.personEid.N_M : '';
    const I_O = havePersonEid ? this.props.personEid.I_O : null;
    const I_ADR = havePersonEid ? this.props.personEid.I_ADR : null;
    const URI = code ? this.props.personEid.URI : null;
    const tabs = I_O_TYP - 1;

    this.setState({ tabs, loading: false, text: txt, I_O_TYP, MENO, D_NAR, PF, ICO_RC, N_U, CS, CO, N_M, I_O, I_ADR, URI, havePersonEid });
  }

  handleChange = (name, value) => {
    switch (name) {
      case 'MENO':
        value = convert.toUpperCaseFirst(value);
        this.setState({ errorTextMeno: value ? '' : (this.state.I_O_TYP === 1 ? 'Meno je povinný údaj!' : '') });
        break;
      case 'PF':
        value = convert.toUpperCaseFirst(value);
        this.setState({ errorTextPf: value ? '' : (this.state.I_O_TYP === 1 ? 'Priezvisko je povinný údaj!' : 'Obchodné meno je povinný údaj!') });
        break;
      case 'ICO_RC':
        this.setState({ errorTextIco_rc: (value && (!value || validator.isValidRc(value))) ? '' : (this.state.I_O_TYP === 1 ? 'RČ je povinný údaj a obsahuje iba číslice (max. 10)!' : 'IČO je povinný údaj a obsahuje iba číslice!') });
        break;
      case 'DIC':
        this.setState({ errorTextDic: value ? '' : (this.state.I_O_TYP === 1 ? '' : 'DIČ je povinný údaj!') });
        break;
      case 'CS':
        this.setState({ errorTextCs: value ? '' : 'Súpisné číslo je povinný údaj!' });
        break;
      case 'N_M':
        value = convert.toUpperCaseFirst(value);
        this.setState({ errorTextN_m: value ? '' : 'Obec je povinný údaj!' });
        break;
      case 'IC_DPH':
        if (this.state.icDphSwitch) {
          this.setState({ errorTextIc_Dph: value ? '' : 'IČ DPH je povinný údaj!' });
        }
        break;
      case 'N_U':
        value = convert.toUpperCaseFirst(value);
        break;
      default:
    }
    this.setState({ [name]: value });
  }

  handleSearchCountries = (searchText) => {
    this.props.dispatch(ciselnikyActions.getListCountries(searchText || ''));
  }

  handleGoHome = () => {
    this.props.navigate(routes.HOME);
  };

  handleCloseAlertDouble = () => {
    this.setState({ openAlertDouble: false });
  };

  handleChangeTypOs = (event, value) => {
    if (value === 0) {
      this.setState({
        tabs: value,
        I_O_TYP: 1,
        ICO_RC: '',
        DIC: '',
        PF: '',
        I_O: null,
        I_ADR: null,
      })
    } else {
      this.setState({
        tabs: value,
        I_O_TYP: 2,
        ICO_RC: '',
        MENO: '',
        PF: '',
        D_NAR: null,
        I_O: null,
        I_ADR: null
      })
    }
    this.validAttachs(false, 0, value === 0 ? 1 : 2);
  };

  handleBlurRc = async (name, value) => {
    let existIO = false;
    if (value.length > 0) {
      await this.props.dispatch(accountActions.getPeopleByRc(value || ''));
      if (this.props.peopleByRc !== null) {
        existIO = true;
      }
    }

    this.setState({
      I_O: existIO ? this.props.peopleByRc.I_O : null,
      D_NAR: this.state.I_O_TYP === 1 ? convert.rcToDnar(value) : null,
      I_ADR: existIO && this.state.I_O_TYP === 1 ? this.props.peopleByRc.I_ADR : null
    });
  }

  handleAdd = async () => {
    const Meno = this.state.MENO;
    const Pf = this.state.PF;
    const Ico_rc = this.state.ICO_RC;
    const Dic = this.state.DIC;
    const Cs = this.state.CS;
    const N_m = this.state.N_M;
    const I_o_typ = this.state.I_O_TYP;
    const I_o = this.state.I_O;
    const Attachs = this.state.ATTACHS;
    const AttachsFile = this.state.ATTACHS_FILE;
    const havePersonEid = this.state.havePersonEid;
    const URI = this.state.URI;
    const Ic_dph = this.state.IC_DPH;

    if (this.validDouble(I_o))
      return;

    const errorTextMeno = Meno ? '' : (I_o_typ === 1 ? 'Meno je povinný údaj!' : '');
    const errorTextPf = Pf ? '' : (I_o_typ === 1 ? 'Priezvisko je povinný údaj!' : 'Obchodné meno je povinný údaj!');
    const errorTextIco_rc = (Ico_rc && (!Ico_rc || validator.isValidRc(Ico_rc))) ? '' : (I_o_typ === 1 ? 'RČ je povinný údaj a obsahuje iba číslice (max. 10)!' : 'IČO je povinný údaj a obsahuje iba číslice!');
    const errorTextDic = Dic ? '' : (I_o_typ === 1 ? '' : 'DIČ je povinný údaj!');
    const errorTextCs = Cs || I_o !== null ? '' : 'Súpisné číslo je povinný údaj!';
    const errorTextN_m = N_m || I_o !== null ? '' : 'Obec je povinný údaj!';
    const errorTextAttach = _sizeAttachs === 0 && I_o_typ === 1 && !URI ? 'Prílohy sú povinné!' : this.state.errorTextAttach;
    const errorTextIc_Dph = Ic_dph ? '' : (this.state.icDphSwitch ? 'IČ DPH je povinný údaj!' : '')

    this.setState({ errorTextMeno, errorTextPf, errorTextIco_rc, errorTextDic, errorTextCs, errorTextN_m, errorTextAttach, errorTextIc_Dph });

    if (!errorTextMeno &&
      !errorTextPf &&
      !errorTextIco_rc &&
      !errorTextDic &&
      !errorTextCs &&
      !errorTextN_m &&
      !errorTextAttach &&
      !errorTextIc_Dph) {

      let AttachsNew = Attachs;
      for (let i = 0; i < Attachs.length; i++) {
        AttachsNew[i].data = Attachs[i].data.replace(/^.+,/, '');
      }

      let AttachsFileNew = AttachsFile;
      for (let i = 0; i < AttachsFile.length; i++) {
        AttachsFileNew[i].data = AttachsFile[i].data.replace(/^.+,/, '');
      }

      const data = {
        ...this.state, I_STAT: this.state.krajina ? this.state.krajina.I_STAT : null,
        D_EVID: convert.addOffset(new Date()),
        D_NAR: convert.addOffset(this.state.D_NAR),
        I_UZ: this.props.user.i_uz,
        ATTACHS: AttachsNew,
        ATTACHS_FILE: AttachsFileNew,
        I_ZDROJ: havePersonEid ? 4 : 1
      };

      this.setState({ loading: true });
      const res = await this.props.dispatch(accountActions.AddAccountPerson(data));
      this.setState({ loading: false });
      if (res) {
        this.setState({ done: true });
      }
    }
  }

  handleChangeImage = (attach) => {
    this.setState(prevState => ({ ATTACHS: [...prevState.ATTACHS, attach] }));
    this.validAttachs(true, attach.size, this.state.I_O_TYP);
  }

  handleRemoveImage = (index) => {
    this.setState(prevState => ({
      ATTACHS: [
        ...prevState.ATTACHS.slice(0, index),
        ...prevState.ATTACHS.slice(index + 1)
      ]
    }));
    const item = this.state.ATTACHS[index];
    this.validAttachs(false, item.size, this.state.I_O_TYP);
  }

  handleChangeFile = (attach) => {
    const ATTACHS_FILE = this.state.ATTACHS_FILE;
    for (let i = 0; i < ATTACHS_FILE.length; i++) {
      if (ATTACHS_FILE[i].name === attach.name) {
        return;
      }
    }
    this.setState(prevState => ({ ATTACHS_FILE: [...prevState.ATTACHS_FILE, attach] }));
    this.validAttachs(true, attach.size, this.state.I_O_TYP);
  };

  handleRemoveFile = (index) => {
    this.setState(prevState => ({
      ATTACHS_FILE: [
        ...prevState.ATTACHS_FILE.slice(0, index),
        ...prevState.ATTACHS_FILE.slice(index + 1)
      ]
    }));

    const item = this.state.ATTACHS_FILE[index];
    this.validAttachs(false, item.size, this.state.I_O_TYP);
  };

  validAttachs = (add, sizeItem, iOTyp) => {
    if (add)
      _sizeAttachs = _sizeAttachs + sizeItem;
    else
      _sizeAttachs = _sizeAttachs - sizeItem;

    let txtErr = '';
    if (_sizeAttachs <= 0 && iOTyp === 1)
      txtErr = 'Prílohy sú povinné!';

    if (_sizeAttachs > window.config.maxUploadSize) {
      let sizeInMB = (window.config.maxUploadSize / 1024) / 1024;
      txtErr = `Prekročený limit na prílohy! Max. ${sizeInMB}MB!`;
    }

    this.setState({ errorTextAttach: txtErr });
  }

  validDouble = (iO) => {
    let bFind = false;

    if (!iO)
      return bFind;

    this.props.listPeople.forEach(findDouble);

    function findDouble(value) {
      if (value.I_O_ZASTUP === iO)
        bFind = true;
    }

    this.setState({ openAlertDouble: bFind });
    return bFind;
  }

  isMobile(width) {
    if (width === "xs" || width === "sm") {
      return true;
    }
    return false;
  }

  handleChangeSwitch = name => event => {
    this.setState({ [name]: event.target.checked });
  };

  render() {
    const { classes, width } = this.props;
    return (
      <div className='container' style={{ marginTop: '70px' }}>
        <CircularProgress loading={this.state.loading} />
        <Paper className={classes.paperCard} elevation={(width && this.isMobile(width)) ? 0 : 2} >
          <React.Fragment>
            {!this.state.done &&
              <div>
                <Typography variant="subtitle2" className={classNames(classes.textCenter, classes.mb2)}>
                  Typ osoby
                </Typography>
                <div className={classNames(classes.row, classes.justifyContentCenter)}>
                  <CustomSwitch
                    value={this.state.tabs}
                    onChange={this.handleChangeTypOs}
                    onLabel=" FYZICKÁ "
                    offLabel="PRÁVNICKÁ"
                    //indicatorClasses={classes.customSwitchIndicator}
                    mobileFullWidth={true}
                  />
                </div>
                {this.state.I_O_TYP === 1 &&
                  <div >
                    <Typography variant="subtitle2" className={classNames(classes.textCenter, classes.mt4)}>
                      Osobné údaje
                    </Typography>
                    <div>
                      <TextField
                        label="Meno"
                        value={this.state.MENO}
                        onChange={this.handleChange}
                        errorText={this.state.errorTextMeno}
                        error={this.state.errorTextMeno ? true : false}
                        name='MENO'
                        fullwidth={true}
                        className={classNames(classes.mt2)}
                      />
                    </div>
                    <div >
                      <TextField
                        label="Priezvisko"
                        value={this.state.PF}
                        onChange={this.handleChange}
                        errorText={this.state.errorTextPf}
                        error={this.state.errorTextPf ? true : false}
                        name='PF'
                        fullwidth={true}
                        className={classNames(classes.mt2)}
                      />
                    </div>
                    <div >
                      <TextField
                        label="Rodné číslo"
                        disabled={this.state.havePersonEid}
                        fullwidth={true}
                        value={this.state.ICO_RC}
                        errorText={this.state.errorTextIco_rc}
                        error={this.state.errorTextIco_rc ? true : false}
                        onChange={this.handleChange}
                        onBlur={this.handleBlurRc}
                        name='ICO_RC'
                        className={classNames(classes.mt2)}
                      />
                    </div>
                    <div >
                      <DatePicker
                        label="Dátum narodenia"
                        value={this.state.D_NAR ? this.state.D_NAR.toISOString() : null}
                        onChange={this.handleChange}
                        clearable={true}
                        name='D_NAR'
                        keyboards={true}
                        disableFuture={true}
                        disablePast={false}
                        fullWidth={true}
                        className={classNames(classes.mt2)}
                      />
                    </div>
                  </div>
                }
                {this.state.I_O_TYP === 2 &&
                  <div >
                    <Typography variant="subtitle2" className={classNames(classes.textCenter, classes.mt4)}>
                      Údaje o spoločnosti, živnosti
                    </Typography>
                    <div >
                      <TextField
                        label="IČO"
                        disabled={this.state.havePersonEid}
                        fullwidth={true}
                        value={this.state.ICO_RC}
                        errorText={this.state.errorTextIco_rc}
                        error={this.state.errorTextIco_rc ? true : false}
                        onChange={this.handleChange}
                        onBlur={this.handleBlurRc}
                        name='ICO_RC'
                      />
                    </div>
                    <div >
                      <TextField
                        fullwidth={true}
                        label="Obchodné meno"
                        value={this.state.PF}
                        onChange={this.handleChange}
                        errorText={this.state.errorTextPf}
                        error={this.state.errorTextPf ? true : false}
                        name='PF'
                        className={classNames(classes.mt2)}
                      />
                    </div>
                    <div >
                      <TextField
                        fullwidth={true}
                        label="DIČ"
                        value={this.state.DIC}
                        errorText={this.state.errorTextDic}
                        error={this.state.errorTextDic ? true : false}
                        onChange={this.handleChange}
                        name='DIC'
                        className={classNames(classes.mt2)}
                      />
                    </div>
                    <Switch
                      color='secondary'
                      checked={this.state.icDphSwitch}
                      onChange={this.handleChangeSwitch('icDphSwitch')}
                      value='icDphSwitch'
                      label={this.state.icDphSwitch ? 'Som platcom DPH' : 'Nie som platcom DPH'}
                    />
                    {this.state.icDphSwitch &&
                      <div >
                        <TextField
                          fullwidth={true}
                          label="IČ DPH"
                          value={this.state.IC_DPH}
                          errorText={this.state.errorTextIc_Dph}
                          error={this.state.errorTextIc_Dph ? true : false}
                          onChange={this.handleChange}
                          name='IC_DPH'
                          inputProps={{ maxLength: 15 }}
                          className={classNames(classes.mt2)}
                        />
                      </div>
                    }
                  </div>
                }
                {(this.state.I_ADR === null || this.state.I_O_TYP === 2) &&
                  <div >
                    <Typography variant="subtitle2" className={classNames(classes.textCenter, classes.mt4, classes.mb3)}>
                      {this.state.I_O_TYP === 1 ? 'Adresa' : 'Sídlo'}
                    </Typography>
                    <div>
                      <Autocomplete
                        data={this.props.listCountries}
                        schema={{ label: 'N_STAT', value: 'I_STAT' }}
                        onChange={this.handleSearchCountries}
                        onSelect={this.handleChange}
                        selectedItem={this.state.krajina}
                        label="Štát"
                        name='krajina'
                        fullWidth={true}
                        countCharStart={_autocompleteCountCharStart}
                        timeDelay={_autocompleteTimeDelay}
                      />
                    </div>
                    <div>
                      <TextField
                        label="Obec"
                        fullwidth={true}
                        value={this.state.N_M}
                        onChange={this.handleChange}
                        errorText={this.state.errorTextN_m}
                        error={this.state.errorTextN_m ? true : false}
                        name='N_M'
                        className={classNames(classes.mt2)}
                      />
                    </div>
                    <div >
                      <TextField
                        label="Ulica"
                        fullwidth={true}
                        value={this.state.N_U}
                        onChange={this.handleChange}
                        name='N_U'
                        className={classNames(classes.mt2)}
                      />
                    </div>
                    <div className='row'>
                      <div className={classNames(classes.mr1, classes.w50)}>
                        <TextField
                          label="Súpisné číslo"
                          fullwidth={true}
                          value={this.state.CS}
                          onChange={this.handleChange}
                          errorText={this.state.errorTextCs}
                          error={this.state.errorTextCs ? true : false}
                          name='CS'
                          className={classNames(classes.mt2)}
                        />
                      </div>
                      <div className={classNames(classes.ml1, classes.w50)}>
                        <TextField
                          label="Orientačné číslo"
                          fullwidth={true}
                          value={this.state.CO}
                          onChange={this.handleChange}
                          name='CO'
                          className={classNames(classes.mt2)}
                        />
                      </div>
                    </div>
                  </div>
                }
                <div className={classNames(classes.row, classes.justifyContentStart, classes.alignItemsCenter, classes.mt3)} >
                  <AttachmentInput
                    disabled={this.props.disabled}
                    onChange={this.handleChangeImage}
                    iconType={1}
                  />
                  <Typography className={classes.ml3}>Foto prílohy</Typography>
                </div>
                <div className='attachments'>
                  {this.state.ATTACHS.map((image, index) =>
                    <Attachment
                      disabled={this.props.disabled}
                      key={index}
                      image={image}
                      onRemove={() => this.handleRemoveImage(index)}
                    />
                  )}
                </div>
                <div className={classNames(classes.row, classes.alignItemsCenter, classes.justifyContentStart, classes.mt3)}>
                  <FileInput
                    disabled={this.props.disabled}
                    onChange={this.handleChangeFile}
                    mimetype={'application/pdf'}
                    title={'súbory typu PDF'}
                    iconType={2}
                  />
                  <Typography className={classes.ml3}>Prílohy (formát PDF)</Typography>
                </div>
                <div className='attachments'>
                  {this.state.ATTACHS_FILE.map((file, index) =>
                    <AttachmentFile
                      disabled={this.props.disabled}
                      key={index}
                      image={file}
                      onRemove={() => this.handleRemoveFile(index)}
                    />
                  )}
                </div>
                {this.state.errorTextAttach &&
                  <Typography style={{ color: 'red' }}> {this.state.errorTextAttach}</Typography>
                }
                {this.state.text.length > 0 &&
                  <div className={classNames(classes.mb4, classes.mt1,)}>
                    <Typography variant="caption" >
                      {this.state.text}
                    </Typography>
                  </div>
                }
                <div className={classNames(classes.row, classes.justifyContentCenter)}>
                  <RaisedButton
                    className={classNames(classes.raisedButton)}
                    onClick={this.handleAdd}
                  >
                    <div className={classNames(classes.row, classes.w100, classes.alignItemsCenter, classes.justifyContentBetween)}>
                      <Typography variant="button" style={{ color: '#fff' }}>
                        Pridať osobu
                      </Typography>
                      <ArrowForward
                        color='primary'
                        fontSize='large'
                      />
                    </div>
                  </RaisedButton>
                </div>
              </div>
            }
            {this.state.done &&
              <div>
                <div className={classNames(classes.mb4)}>
                  <Button
                    color="secondary"
                    aria-label="Späť"
                    onClick={this.handleGoHome}
                  >
                    <ArrowBack
                      color='secondary'
                      fontSize='default'
                      variant='fab'
                    />
                    <Typography variant="body2" className={classNames(classes.ml2)}>Späť na moje údaje</Typography>
                  </Button>
                </div>
                <div className='center'>
                  <img style={{ width: 140, height: 140 }} className={classNames(classes.mb4)} src={'assets/check_green.svg'} alt='ŽIADOSŤ ODOSLANÁ' />
                </div>
                <div className={classNames(classes.mt3, classes.textCenter)}>
                  <Typography className={classNames(classes.textCenter, classes.fontBold)} style={{ color: 'green' }}>
                    VAŠA ŽIADOSŤ O <br />
                    PRIDANIE OSOBY K VÁŠMU <br />
                    ÚČTU BOLA ÚSPEŠNE <br />
                    ODOSLANÁ
                  </Typography>
                </div>

                <div className={classNames(classes.mt4, classes.mb4)}>
                  <Typography className={classNames(classes.textCenter)}>
                    POČKAJTE PROSÍM <br />
                    NA OVERENIE VAŠEJ OSOBY <br />
                    INFORMAČNÝM SYSTÉMOM MESTA.
                  </Typography>
                </div>

                <div className={classNames(classes.mt4)}>
                  <Typography className={classNames(classes.textCenter)}>
                    OSOBA SA PO AKTIVÁCII AUTOMATICKY <br />
                    ZABRAZÍ VO VAŠOM ZOZNAME <br />
                    ZASTUPOVANÝCH OSÔB.
                  </Typography>
                </div>
              </div>
            }
          </React.Fragment>
        </Paper>
        <Dialog
          open={this.state.openAlertDouble}
          onClose={this.handleCloseAlertDouble}
          contentText={'Zadaná osoba už patrí medzi vaše zastupované osoby!'}
          title={'Upozornenie'}
          actions={[
            {
              label: 'Zrušiť',
              color: 'primary',
              onClick: this.handleCloseAlertDouble,
              keyboardFocused: true
            }
          ]}
        />
      </div >
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    listCountries: state.listCountries.data,
    peopleByRc: state.peopleByRc.data,
    locale: state.locale.locale,
    personEid: state.personEid.data,
    listPeople: state.listPeople.data,
  }
}

export default withRouter(withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(withWidth()(AccountAddPerson)))));