import React from "react";
import SaveIcon from "@material-ui/icons/Save";
import { connect } from "react-redux";
import { withStyles } from "@material-ui/core/styles";
import classNames from "classnames";
import AppBarMultiDrawer from "../../coraWebMComponents/surfaces/AppBarMultiDrawer";
import IconButton from "../../coraWebMComponents/inputs/IconButton";
import MenuIcon from "../../coraWebMComponents/dataDisplay/icons/MenuIcon";
import EditIcon from "../../coraWebMComponents/dataDisplay/icons/EditIcon";
import Typography from "../../coraWebMComponents/dataDisplay/Typography";
import ListItemMenu from "../../coraWebMComponents/navigation/ListItemMenu";
import Button from "../../coraWebMComponents/inputs/Button";
import * as storage from "../../coraWebMComponents/utils/storage";
import withRouter from "../../coraWebMComponents/utils/withRouter";
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import Dialog from "../../coraWebMComponents/feedBack/Dialog";
import withMeta from "../../coraWebMComponents/utils/withMeta";
import User from "./User";
import * as userActions from "../../actions/userActions";
import * as accountActions from "../../actions/accountActions";
import * as ciselnikyActions from "../../actions/ciselnikyActions";
import routes from "../../routes";
import { adminModeSetCurrEdited, adminModeSetEditedData, adminModeUpdateOrAdd } from "../../actions/adminModeActions";
import { DRAWER_EL1, DRAWER_EL2, ISpMnu } from "../../constants/adminConsts";
import QuickActionsDialog from "../quickActions/QuickActionsDialog";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  navTitle: {
    color: "white",
    fontSize: 18,
  },
  adminButton: {
    backgroundColor: theme.palette.adminBlue.main,
    "&:hover": {
      backgroundColor: theme.palette.adminBlue.dark,
    }
  }
});

class AppBarComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: false,
      tabIndex: 0,
      visiblePublicZone: false,
      visibleQuickActions: false,
      openQuickActionsDialog: false,
      openDialog: false,
    };
  }

  componentDidMount = async () => {
    await this.props.dispatch(ciselnikyActions.getVisiblePublicZone());
    let visibleQuickActions = false
    let bShwPZ = false;
    await this.props.meta.init(!this.props.isAuth);
    if (this.props.visiblePublicZone?.HODN.trim() === "1") {
      if (
        this.props.meta.menu.find((x) => x.MenuID === 4004019)?.Visible === 1
      ) {
        bShwPZ = true;
      }
    }
    if (this.props.isAuth) {
      await this.props.dispatch(accountActions.getListActiveQuickActions());
      if (this.props.activeQuickActionsCount > 0) {
        visibleQuickActions = true
      }
    }

    if (!this.props.egovHandlerUrl)
      await this.props.dispatch(ciselnikyActions.getEgovHandlerUrl());

    this.setState({ visiblePublicZone: bShwPZ, visibleQuickActions });
  };

  componentDidUpdate = async (prevProps) => {
    if (prevProps.adminMode.isActive !== this.props.adminMode.isActive) {
      if (this.props.adminMode.isActive) {
        this.setState({ visiblePublicZone: true });
      } else {
        var bShwPZ = false;
        await this.props.meta.init(!this.props.isAuth);
        if (this.props.visiblePublicZone?.HODN.trim() === "1") {
          if (
            this.props.meta.menu.find((x) => x.MenuID === 4004019)?.Visible ===
            1
          ) {
            bShwPZ = true;
          }
        }
        this.setState({ visiblePublicZone: bShwPZ });
      }
    }
    if (prevProps.isAuth !== this.props.isAuth) {
      if (this.props.isAuth) {
        await this.props.dispatch(accountActions.getListActiveQuickActions());
        let visibleQuickActions = false
        if (this.props.activeQuickActionsCount > 0) {
          visibleQuickActions = true
        }
        this.setState({ visibleQuickActions });
      }
    }

    if (prevProps.activeQuickActionsCount !== this.props.activeQuickActionsCount) {
      let visibleQuickActions = false
      if (this.props.activeQuickActionsCount > 0) {
        visibleQuickActions = true
      }
      this.setState({ visibleQuickActions });
    }
  };

  handleCurrEdited = (e, value) => {
    e.stopPropagation();
    this.props.dispatch(adminModeSetCurrEdited(value));
    this.props.dispatch(adminModeSetEditedData(null));
  };

  handleEditData = (data) => {
    this.props.dispatch(adminModeSetEditedData(data));
  };

  handleSaveSection = async (e) => {
    e.stopPropagation();
    const { dataEdited } = this.props.adminMode;
    this.props.dispatch(adminModeSetCurrEdited(null));
    await this.props.dispatch(adminModeUpdateOrAdd(ISpMnu, dataEdited));
    this.props.initMenu();
    this.handleCloseDialog();
  };

  setOpen = (open) => {
    this.setState({ open });
  };

  handleToggle = (_drawTyp) => {
    this.setState({
      open: !this.state.open,
      tabIndex: this.props.isAuth ? 0 : 1,
    });
  };

  handleChangeTabs = (_event, value) => {
    this.setState({ tabIndex: value });
  };

  handleChangeIndex = (index) => {
    this.setState({ tabIndex: index });
  };

  handleLogout = async () => {
    storage.removeUser();
    await this.props.dispatch(userActions.logout());
    this.setState({ tabIndex: 1 });
    this.props.navigate(routes.LOGIN);
  };

  handleClickMenu = (route) => {
    this.handleToggle();
    this.props.navigate(route);
  };

  handleAddPerson = () => {
    this.props.navigate(routes.ACCOUNT_ADD_PERSON);
  };

  handleAddPersonEid = () => {
    let url = encodeURIComponent(
      window.location.origin + "/#/accountAddPerson"
    );
    url =
      this.props.egovHandlerUrl.HODN +
      "/UpvsForClientHttpHandler.ashx?returnUrl=" +
      url;
    window.open(url, "_parent").focus();
  };

  handleCancelPerson = async (i_o) => {
    await this.props.dispatch(accountActions.CancelAccountPerson(i_o));
    await this.props.dispatch(accountActions.getListPeople());
    this.handleGoHome(this.props.user.i_o);
  };

  handleModifyAccount = () => {
    this.props.navigate(routes.ACCOUNT_MODIFY);
  };

  handleGoHome = async (i_o) => {
    await this.props.dispatch(accountActions.getPerson(i_o));
    this.props.navigate(routes.HOME);
  };

  handleCloseDialog = () => {
    this.setState({ openDialog: false });
  };

  handleOpenDialog = () => {
    // const { dataEdited } = this.props.adminMode;
    // if (dataEdited) {
    this.setState({ openDialog: true });
    // }
  };

  getEditButton = (drawer) => {
    const { classes } = this.props;
    return (
      <Button
        variant="contained"
        color="secondary"
        className={classNames(classes.w100, classes.textTransformNone, classes.adminButton)}
        onClick={(e) => this.handleCurrEdited(e, drawer)}
        startIcon={<EditIcon />}
      >
        Upraviť
      </Button>
    );
  };

  getSaveButton = () => {
    const { classes } = this.props;
    return (
      <Button
        variant="contained"
        color="secondary"
        type="success"
        className={classNames(classes.w100, classes.textTransformNone)}
        onClick={this.handleOpenDialog}
        startIcon={<SaveIcon />}
      >
        Uložiť
      </Button>
    );
  };

  render() {
    if (
      this.props.location.pathname === routes.PRELOGIN ||
      this.props.location.pathname === routes.LOGIN ||
      this.props.location.pathname === routes.LOGINEID ||
      this.props.location.pathname === routes.ACCOUNT_PERMIT ||
      this.props.location.pathname === routes.ACCOUNT_PERMIT_SUCCESS
    )
      return null;

    const { classes, menu } = this.props;
    const { currEdited, isActive } = this.props.adminMode;

    const menuA = menu.children;
    const menuPublic = menuA.find((x) => x.MenuID === 4004007); // verejna zona
    const menuPrivate = menuA.find((x) => x.MenuID === 4002001); // privatna zona
    const menuService = menuA.find((x) => x.MenuID === 3001054); // spravca

    let menuPubl = []; // verejna zona
    let menuPriv = []; // privatna zona
    let menuServ = []; // spravca

    if (menuPublic)
      menuPubl = menuPublic.children; /*.filter(x => x.Visible === 1);*/ // verejna zona

    if (menuPrivate)
      menuPriv = menuPrivate.children.filter(
        (x) =>
          x.MenuID !== 4002005 && x.MenuID !== 4002011 && x.MenuID !== 4002002
      ); /*.filter(x => x.Visible === 1);*/ // privatna zona

    if (menuService)
      menuServ = menuService.children; /*.filter(x => x.Visible === 1);*/ // spravca

    const menuPri = menuPriv.concat(menuServ); // privatna zona & spravca

    const appLogo = (
      <img
        style={{ width: "200px", height: "auto" }}
        className={classNames()}
        src={"assets/datamesta_logo_light.svg"}
        alt="datamesta"
      />
    );

    const toolbarEl = (
      <div
        style={{ position: "relative" }}
        className={classNames(
          classes.row,
          classes.w100,
          classes.alignItemsCenter
        )}
      >
        <div>
          <IconButton
            className="menuButton"
            color="primary"
            aria-label="Nastavenie"
            onClick={this.handleToggle}
            toolTip="Hlavná ponuka"
            toolTipPlace="bottom"
          >
            <MenuIcon style={{ color: "white" }} />
          </IconButton>
        </div>
        <div>
          <Typography variant="h5" className={classNames(classes.navTitle)}>
            {this.props.appBarTitle}
          </Typography>
        </div>
        <div className={classNames(classes.row, classes.justifyContentCenter)}>
          {this.state.visibleQuickActions && (
            <IconButton toolTip="Rýchle akcie" onClick={() => this.setState({ openQuickActionsDialog: true })}>
              <img style={{ height: "50%", width: "50%" }} src={'assets/icon-qa-active.svg'} alt="icon-qa-active.svg"></img>
            </IconButton >
          )}
          {this.props.isAuth && (
            <div>
              <User
                onLogout={this.handleLogout}
                onGoHome={this.handleGoHome}
                onAddPerson={this.handleAddPerson}
                onAddPersonEid={this.handleAddPersonEid}
                onCancelPerson={this.handleCancelPerson}
                onModifyAccount={this.handleModifyAccount}
                listPeople={this.props.listPeople}
                user={this.props.user}
                person={this.props.person}
                egovHandlerUrl={this.props.egovHandlerUrl}
              />
            </div>
          )}
          {!this.props.isAuth && (
            <Button
              variant="contained"
              color="secondary"
              size="medium"
              onClick={() => this.handleLogout()}
            >
              Prihlásenie
            </Button>
          )}
        </div>
      </div >
    );

    /** HomeButton - Úvodná obrazovka */
    const homeButton = (
      <Button
        variant="contained"
        color="primary"
        style={{ backgroundColor: "#ffffff" }}
        className={classNames(classes.w100, classes.textTransformNone)}
        onClick={() => this.props.navigate("/uvod")}
      >
        Úvodná obrazovka
      </Button>
    );

    /** privatna zona */
    const drawerEl1 = (
      <div key="1" style={{ width: "100%" }}>
        <ListItemMenu
          color="secondary"
          menu={menuPri}
          onClick={currEdited !== DRAWER_EL1 ? this.handleClickMenu : null}
          onEdit={this.handleEditData}
          inEdit={currEdited === DRAWER_EL1}
          setOpen={this.setOpen}
        />
        {isActive &&
          currEdited !== DRAWER_EL1 &&
          this.getEditButton(DRAWER_EL1)}
        {isActive && currEdited === DRAWER_EL1 && this.getSaveButton()}
      </div>
    );

    /** verejna zona */
    const drawerEl2 = (
      <div key="2" style={{ width: "100%" }}>
        <ListItemMenu
          color="secondary"
          menu={menuPubl}
          onClick={currEdited !== DRAWER_EL2 ? this.handleClickMenu : null}
          onEdit={this.handleEditData}
          inEdit={currEdited === DRAWER_EL2}
          setOpen={this.setOpen}
        />
        {isActive &&
          currEdited !== DRAWER_EL2 &&
          this.getEditButton(DRAWER_EL2)}
        {isActive && currEdited === DRAWER_EL2 && this.getSaveButton()}
      </div>
    );

    //const { adminMode } = this.props.adminMode;
    return (
      <>
        <AppBarMultiDrawer
          tabsVisible={this.props.isAuth && this.state.visiblePublicZone}
          appLogo={appLogo}
          toolbarEl={toolbarEl}
          tabLabel1="MOJA ZÓNA"
          tabLabel2="VEREJNÁ ZÓNA"
          drawerEl1={drawerEl1}
          drawerEl2={drawerEl2}
          tabIndex={this.state.tabIndex}
          open={this.state.open}
          onChangeTabs={this.handleChangeTabs}
          onChangeIndex={this.handleChangeIndex}
          onClose={this.handleToggle}
          onOpen={this.handleToggle}
          homeButton={this.props.adminMode.isActive ? homeButton : null}
        />
        <Dialog
          modal={false}
          open={this.state.openDialog}
          onClose={() => { }}
          contentText="Informácia: Potvrdením uložíte zmeny"
          title="Uloženie nastavení"
          actions={[
            {
              label: "Nie",
              color: "primary",
              onClick: this.handleCloseDialog,
              keyboardFocused: false,
            },
            {
              label: "Áno",
              color: "secondary",
              onClick: (e) => this.handleSaveSection(e),
              keyboardFocused: true,
            },
          ]}
        />
        {this.props.isAuth && (
          <>
            <QuickActionsDialog open={this.state.openQuickActionsDialog} onClose={() => { this.setState({ openQuickActionsDialog: false }) }} />
          </>)
        }
      </>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    user: state.user.data,
    isAuth: state.user.data !== null,
    listPeople: state.listPeople.data,
    person: state.person.data,
    visiblePublicZone: state.visiblePublicZone.data.Data,
    egovHandlerUrl: state.egovHandlerUrl.data.Data,
    adminMode: state.adminMode,
    activeQuickActionsCount: state.activeQuickActions.total,
  };
};

export default withMeta(
  withRouter(connect(mapStateToProps)(withStyles(styles)(AppBarComponent)))
);
