import * as requests from '../../coraWebMComponents/utils/requests';

export const addParkingShort = async (parkingShort) => {
  try {
    let body = {
      I_DOC_PARK: 0,
      ECV: parkingShort.ECV,
      D_OD: parkingShort.D_OD,
      D_DO: parkingShort.D_DO,
      I_ZONA: parkingShort.I_ZONA,
      N_ZONA: '',
      CENA: parkingShort.CENA,
      EMAIL: parkingShort.EMAIL,
      POZN: parkingShort.LANGUAGE === 'sk-SK' ? '' : `language:${parkingShort.LANGUAGE}`
    }

    return await requests.post('/api/parking/parkingShort', body);
  }
  catch (error) {
    throw error;
  }
}

export const getListCardTypeEShop = async (iOtyp) => {
  try {

    const response = await requests.get(`/api/ciselniky/cardTypeEShop/${iOtyp}`);
    if (response.Data === null)
      return { Data: { Items: [], TotalRecords: 0 } };

    return response;
  }
  catch (error) {
    if (error.code === '404.000') {
      return {
        Data: {
          TotalRecords: 0,
          Items: []
        }
      };
    }
    else {
      throw error;
    }
  }
}

export const addParkCard = async (data) => {
  try {
    let body = {
      I_KARTA: 0,
      ECV: data.ECV,
      CENA: data.CENA,
      VS: '',
      EMAIL: data.EMAIL,
      POZN: data.LANGUAGE === 'sk-SK' ? '' : `language:${data.LANGUAGE}`,
      I_O_TYP: data.I_O_TYP,
      PF: data.PF,
      MENO: data.MENO,
      ICO: data.ICO,
      DIC: data.DIC,
      L_DPH: data.L_DPH,
      IC_DPH: data.IC_DPH,
      I_STAT: data.krajina === null ? null : data.krajina.I_STAT,
      N_M: data.N_M,
      N_U: data.N_U,
      CS_CO: data.CS_CO,
      I_KARTA_TYP: data.I_KARTA_TYP,
      N_KARTA_TYP: '',
      C_KARTA: '',
      D_OD: null,
      D_DO: null,
      L_UHR_PB: false
    }

    return await requests.post('/api/parking/parkCard', body);
  }
  catch (error) {
    throw error;
  }
}

export const checkParkingTicket = async (iZone, ecv) => {
  try {
    return await requests.get(`/api/parking/parkingShortCheck?iZone=${iZone}&ecv=${ecv}`);
  }
  catch (error) {
    throw error;
  }
}

export const checkParkingTicketPlan = async (iZone, ecv, timeFrom, timeTo, dtFrom, dtTo) => {
  try {
    return await requests.get(`/api/parking/parkingShortCheckPlan?iZone=${iZone}&ecv=${ecv}&timeFrom=${timeFrom}&timeTo=${timeTo}&dtFrom=${dtFrom}&dtTo=${dtTo}`);
  }
  catch (error) {
    throw error;
  }
}

export const getParkingLimit = async (iZona, ecv, dtFrom, dtTo) => {
  try {
    return await requests.get(`/api/parking/parkingLimit?iZona=${iZona}&ecv=${ecv}&dtFrom=${dtFrom}&dtTo=${dtTo}`);
  }
  catch (error) {
    throw error;
  }
}

export const getTicketTimeSums = async (iZona, ecv, dtFrom, dtTo) => {
  try {
    return await requests.get(`/api/parking/ticketTimeSums?iZona=${iZona}&ecv=${ecv}&dtFrom=${dtFrom}&dtTo=${dtTo}`);
  }
  catch (error) {
    throw error;
  }
}