import * as requests from '../coraWebMComponents/utils/requests';
import * as convert from '../coraWebMComponents/utils/convert';
import saveAs from 'file-saver';

/**
 * Získanie zoznamu rozhodnutí
 * @param {number} iO ID osoby
 * @returns GridItemsVm of DnPoOVm
 */
export const getListTaxesDecision = async (iO) => {
  try {

    const res = await requests.get(`/api/taxes/taxesDecision?iO=${iO}`);
    if (res.Data) {
      res.Data.Items = res.Data.Items.map((item) => {
        const cardPaymentStatus = item.DETAIL.every(x => x.L_UHR_PB === true)
        const paymentStatus = cardPaymentStatus === true ? 2 : Number(item.STAV) + 1;
        return { ...item, paymentStatus };
      });
    }
    return res;
  }
  catch (error) {
    throw error;
  }
}

/**
 * Získanie detailu rozhodnutí pre zastupovanú osobu - zoznam konaní
 * @param {string} zozCisRozh Zoznam identifikátorov rozhodnutí
 * @param {number} iO ID osoby
 * @returns GridItemsVm of DnPoRozpVm
 */
export const getListProceedings = async (zozCisRozh, iO) => {
  try {
    return await requests.get(`/api/taxes/proceedings?zozCisRozh=${zozCisRozh}&iO=${iO}`);
  }
  catch (error) {
    throw error;
  }
}

/**
 * Získanie detailu konania
 * @param {number} iKon ID konania
 * @param {number} cisRozh Identifikátor rozhodnutia
 * @returns DnPoRozpVm
 */
export const getProceedingDetail = async (iKon, cisRozh) => {
  try {
    return await requests.get(`/api/taxes/proceeding?iKon=${iKon}&cisRozh=${cisRozh}`);
  }
  catch (error) {
    throw error;
  }
}

/**
 * Získanie listu daní z nehnutelností
 * @param {*} iKon ID konania
 * @param {*} iO ID osoby
 * @returns GridItemsVm of DnRozpVm
 */
export const getListPropertyTaxes = async (iKon, iO) => {
  try {
    const res = await requests.get(`/api/taxes/propertyTaxes?iKon=${iKon}&iO=${iO}`);

    if (res.Data) {
      res.Data.Items = res.Data.Items.map((item) => {
        const arr = item.ZOZ_POPIS_OBJ.split('-');

        item.ZOZ_POPIS_OBJ = [...new Set(arr)];
        return item;
      });
    }
    return res;
  }
  catch (error) {
    throw error;
  }
}

/**
 * Získanie zoznamu splátok
 * @param {*} zozIPoh Zoznam pohybov splátok
 * @param {*} iO ID osoby
 * @returns GridItemsVm of DnRozpVm
 */
export const getListInstallments = async (zozIPoh, iO) => {
  try {
    return await requests.get(`/api/taxes/installments?zozIPoh=${zozIPoh}&iO=${iO}`);
  }
  catch (error) {
    throw error;
  }
}

/**
 * Získanie zoznamu úhrad
 * @param {*} zozIPoh 
 * @param {*} iO 
 * @returns GridItemsVm of DnPoUhrVm
 */
export const getListPayments = async (zozIPoh, iO) => {
  try {
    const res = await requests.get(`/api/taxes/payments?zozIPoh=${zozIPoh}&iO=${iO}`);
    if (res.Data) {
      res.Data.Items = res.Data.Items.map((item) => {
        item.D_POH = convert.dateToDateStr(new Date(item.D_POH));
        return item;
      });
    }
    return res;
  }
  catch (error) {
    throw error;
  }
}

/**
 * Získanie zoznamu nedoplatkov
 * @param {number} iO ID osoby
 * @returns GridItemsVm of DnPoNedopVm
 */
export const getListTaxesArrears = async (iO) => {

  try {
    const res = await requests.get(`/api/taxes/taxesArrears?iO=${iO}`);

    if (res.Data) {
      res.Data.Items = res.Data.Items.map((item) => {
        const paymentStatus = item.Platba === 0 ? 2 : 1;
        return { ...item, paymentStatus };
      });
    }
    return res;
  }
  catch (error) {
    throw error;
  }
}

/**
 * Získanie/vygenerovania externého variabilného symbolu platby (vygenerovanie záznamu v tabuľke NUM.E_B_VS_EXT)
 * @param {*} VS Variabilný symbol
 * @param {*} SSY Špecifický symbol
 * @param {*} iKon ID konania
 * @param {*} rok Rok
 * @param {*} items Metadáta platby - zoznam hradených pohybov
 * @returns VsExtVm
 */
export const getVsExt = async (VS, SSY = null, iKon = null, rok = null, items = null) => {
  try {
    return await requests.get(`/api/taxes/vs_ext?VS=${VS}&SSY=${SSY ?? ''}&iKon=${iKon ?? ''}&rok=${rok ?? ''}&installments=${items ? JSON.stringify(items) : ''}`);
  }
  catch (error) {
    throw error;
  }
}

export const getTaxesBySquare = async (iban, vs, amount, recipient, ss) => {
  const i = iban.replace(/\s/g, '');
  try {
    const url = `/api/taxes/bysquare?iban=${i}&vs=${vs}&amount=${amount}&recipient=${recipient}&ss=${ss}`
    return await requests.get(url);
  }
  catch (error) {
    throw error;
  }
}

// download PDF konania
export const getPdfTaxProceeding = async (iFile) => {
  let response = await requests.getBlobWithFileName(`/api/taxes/${iFile}/pdfDownload`);
  saveAs(response.blob, response.fileName);
}