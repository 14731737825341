import React from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles";
import MenuItem from "@material-ui/core/MenuItem";
import { Typography } from "@material-ui/core";
import NavigateNextIcon from "@material-ui/icons/NavigateNext";
import { Box, Stack, Button } from '@mui/material';

// import { green } from "@material-ui/core/colors";
import FilterIcon from '@mui/icons-material/FilterAltOutlined';
import FilterNoneIcon from '@mui/icons-material/FilterAltOffOutlined';
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
// import IconButton from "../../coraWebMComponents/inputs/IconButton";
import Menu from "../../coraWebMComponents/dataDisplay/Menu";
import FilterListIcon from "../../coraWebMComponents/dataDisplay/icons/FilterListIcon";
// import FilterIcon from "../../coraWebMComponents/dataDisplay/icons/FilterIcon";
// import FilterNoneIcon from "../../coraWebMComponents/dataDisplay/icons/filterRemoveIcon";
import { getCboFltState } from "./support";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  riRoot: {
    display: "inline-flex",
    alignItems: "center",
  },
  riBtn: {
    backgroundColor: "transparent",
    borderRadius: theme.shape.borderRadius,
    opacity: 0.7,
    transition: 'opacity 0.3s ease',
    '&:hover': {
      backgroundColor: alpha(theme.palette.common.black, 0.10),
      opacity: 1,
      cursor: "pointer",
    },
  },
  riIcon: {
    width: "1.8rem",
    textAlign: "center",
  },
  rim: {},
  rimItem: {
  },
  rimBtn: {
    backgroundColor: "transparent",
    '&:hover': {
      backgroundColor: "transparent",
    },
  },
  rimItemIcon: {
    color: alpha(theme.palette.common.black, 0.87),
  },
  rimText: {
    color: alpha(theme.palette.common.black, 0.87),
    fontSize: theme.palette.secondary.fontSize,
    textTransform: "none",
  },
  fltHasValue: {
    color: "green",
  },
  fltNoHasValue: {
    color: alpha(theme.palette.common.black, 0.87),
  },
});

export class ComboFiltersButtonComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = { anchorEl: null };
  }

  handleMenu = (event) => {
    this.setState({ anchorEl: event.currentTarget });
  };

  handleClose = () => {
    this.setState({ anchorEl: null });
  };

  render() {
    const { classes, comboFilters, cboFltValues } = this.props;
    const { anchorEl } = this.state;
    const anchorOrigin = this.props.anchorOrigin ?? {
      vertical: "top",
      horizontal: "right",
    };
    const transformOrigin = this.props.transformOrigin ?? {
      vertical: "top",
      horizontal: "right",
    };

    return (
      <Box className={classNames(classes.riRoot, this.props.className)}>
        <Stack className={classNames(classes.riBtn)}
          width={"2.5rem"}
          height={"2.5rem"}
          alignItems="center"
          justifyContent="center"

        >
          <Stack onClick={this.handleMenu} flexDirection={"row"} justifyContent={"flex-end"}>
            <FilterListIcon className={classNames(classes.riIcon)} />
          </Stack>
        </Stack>

        <Menu
          className={classNames(classes.rim)}
          anchorEl={anchorEl}
          anchorOrigin={anchorOrigin}
          transformOrigin={transformOrigin}
          open={Boolean(anchorEl)}
          onClose={this.handleClose}
        >
          {comboFilters &&
            comboFilters.filter(
              (flt) => flt.dataItems && flt.dataItems.length > 0
            ).length > 0 &&
            comboFilters
              .filter((flt) => flt.dataItems && flt.dataItems.length > 0)
              .map((flt) => {
                const value = cboFltValues[flt.id];
                const { hasValue, text } = getCboFltState(flt, value);
                return (
                  <MenuItem
                    className={classNames(classes.rimItem)}
                    key={`cboFlt${flt.id}`}
                    onClick={() =>
                      this.setState(
                        { anchorEl: null },
                        this.props.onComboFilterClick(flt.id)
                      )
                    }
                  >
                    <Button variant="text"
                      className={classNames(classes.rimBtn,
                        hasValue ? classes.fltHasValue : classes.fltNoHasValue
                      )}
                      toolTip={flt.title}
                      startIcon={hasValue ?
                        <FilterIcon style={{ width: "24", height: "24" }} viewBox="0 0 24 24" className={classNames(classes.fltHasValue)} />
                        :
                        <FilterNoneIcon style={{ width: "24", height: "24" }} viewBox="0 0 24 24" className={classNames(classes.rimItemIcon, classes.fltNoHasValue)} />
                      }
                      endIcon={<NavigateNextIcon className={classNames(classes.rimItemIcon)} />}
                    >
                      <Typography
                        className={classNames(
                          classes.rimText, hasValue ? classes.fltHasValue : classes.fltNoHasValue
                        )}
                        title={flt.title}
                      >
                        {text}
                      </Typography>
                    </Button>
                  </MenuItem>
                );
              })}
        </Menu>
      </Box >
    );
  }
}

ComboFiltersButtonComponent.propTypes = {
  comboFilters: PropTypes.array.isRequired,
  cboFltValues: PropTypes.object.isRequired,
  onComboFilterClick: PropTypes.func.isRequired,
  anchorOrigin: PropTypes.objectOf(
    PropTypes.shape({
      vertical: PropTypes.string,
      horizontal: PropTypes.string,
    })
  ),
  transformOrigin: PropTypes.objectOf(
    PropTypes.shape({
      vertical: PropTypes.string,
      horizontal: PropTypes.string,
    })
  ),
};

export default withStyles(styles)(ComboFiltersButtonComponent);
