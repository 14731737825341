import React, { useState, useMemo } from 'react';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';
import { withStyles } from '@material-ui/core/styles';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import { useNavigate } from 'react-router';
import routes from '../../routes';
import * as taxesActions from '../../actions/taxesActions';
import * as taxesUtils from '../../utils/taxesUtils';
import * as userActions from '../../actions/userActions';
import classNames from 'classnames';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import ProgressBar from '../../coraWebMComponents/dataDisplay/ProgressBar';
import EuroCardIcon from '../../coraWebMComponents/dataDisplay/icons/EuroCard';
import SelectFilterField from '../../coraWebMComponents/inputs/SelectFilterField';
import TaxesCard from './TaxesCard';
import Proceedings from './Proceedings';
import Installments from './Installments';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import Payments from './Payments';
import FilterListIconComponent from '../../coraWebMComponents/dataDisplay/icons/FilterListIcon';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import FormModal from '../../coraWebMComponents/inputs/FormModal';
import SelectField from '../../coraWebMComponents/inputs/SelectField';
import Chip from '../../coraWebMComponents/inputs/Chip';
import Divider from '../../coraWebMComponents/dataDisplay/Divider';
import Button from '../../coraWebMComponents/inputs/Button';
import DateRangeIcon from '../../coraWebMComponents/dataDisplay/icons/DateRangeIcon';
import CreditCard from '../../coraWebMComponents/dataDisplay/icons/CreditCardIcon';
import DialogIcon from '../../coraWebMComponents/feedBack/DialogIcon';
import EuroIcon from '../../coraWebMComponents/dataDisplay/icons/EuroIcon';

const styles = theme => ({
  ...getSharedStyles(theme),
})

const _currencyCode = 'EUR';

const EMPTY_DAN_FLT = { I_PRJ_E: '', N_PRJ_E: '' };
const EMPTY_KON_FLT = { I_KON_TYP: '', N_KON_TYP: '' };
const EMPTY_ROK_FLT = { I_ROK: '', ROK: '' };

const paymentStatesList = [
  { val: 1, lbl: "Neuhradené" },
  { val: 2, lbl: "Uhradené" },
  { val: 3, lbl: "V exekučnom konaní" }
];

const getTypDanList = (listTaxesDecision, listTaxesArrears) => {

  let correctName;
  const arrArrears = (listTaxesArrears || []).map(x => ({ I_PRJ_E: x.IPrjE.toString(), N_PRJ_E: setTitle(x.NPrjE, correctName, 2) }));

  let hasMulti = false;
  const arrDecision = [];
  (listTaxesDecision || []).forEach((x) => {
    x.DETAIL.forEach((z) => {
      arrDecision.push({ I_PRJ_E: z.I_PRJ_E.toString(), N_PRJ_E: setTitle(z.N_PRJ_E, correctName, 1) });
    });
    if (x.DETAIL.length > 1 && !hasMulti) {
      hasMulti = true;
    };
  });
  if (hasMulti)
    arrDecision.push({ I_PRJ_E: "SpolRozh", N_PRJ_E: "Spoločné rozhodnutie" })

  return [...new Set([...arrDecision, ...arrArrears])];
};

const getTypKonList = (listTaxesDecision) => {
  const arrDecision = (listTaxesDecision || []).map(x => x.N_KON_TYP);
  return [...new Set([...arrDecision])].map((x) => ({ I_KON_TYP: x, N_KON_TYP: x }));
};

const getRokList = (listTaxesDecision, listTaxesArrears) => {
  const arrDecision = (listTaxesDecision || []).map(x => x.ROK);
  const arrArrears = (listTaxesArrears || []).map(x => x.Rok);
  return [...new Set([...arrDecision, ...arrArrears])].map((x) => ({ I_ROK: x.toString(), ROK: x.toString() }));
};

const getFilteredList = (sourceMark, sourceData, fltDan, fltKon, fltRok) => {

  let list = [];

  if (sourceMark === 1) {

    list = sourceData;

    if (fltDan.I_PRJ_E.length > 0) {
      const listBezSpolR = list
        .map(x => ({ ...x, DETAIL: x.DETAIL.filter(y => y.I_PRJ_E.toString() === fltDan.I_PRJ_E) }))
        .filter(x => x.DETAIL.length > 0);
      if (fltDan.I_PRJ_E === "SpolRozh") {
        list = list.filter(x => x.DETAIL.length > 1);
      } else {
        list = list.filter(x => listBezSpolR.map(y => y.ZOZ_CIS_ROZH).includes(x.ZOZ_CIS_ROZH));
      }
    }

    if (fltKon.I_KON_TYP.length > 0) {
      list = list.filter(x => x.N_KON_TYP === fltKon.I_KON_TYP);
    }

    if (fltRok.I_ROK.length > 0) {
      list = list.filter(x => x.ROK.toString() === fltRok.I_ROK);
    }
  }
  else if (sourceMark === 2 && fltKon.I_KON_TYP.length < 1) {

    list = sourceData;

    if (fltDan.I_PRJ_E.length > 0) {
      list = list.filter(x => x.IPrjE.toString() === fltDan.I_PRJ_E);
    }

    if (fltRok.I_ROK.length > 0) {
      list = list.filter(x => x.Rok.toString() === fltRok.I_ROK);
    }
  }

  return list;
}

const TaxesDecisionComponent = ({ props, filteredList, showDetail, showInstallments, showPayments }) => {

  const { classes } = props;

  return (
    <>
      {filteredList.map(item => {
        return (
          <TaxesCard
            key={item.C_KON}
            classes={classes}
            data={item}
            showDetail={(event) => showDetail(event, item)}
            showInstallments={(event) => showInstallments(event, item)}
            showPayments={(event) => showPayments(event, item)}
            title={setCardTitle(item)}
          />
        );
      })}
    </>
  );
};

const TaxesArrearsComponent = ({ props, filteredList }) => {

  const { classes } = props;

  const navigate = useNavigate();

  // vybraný nedoplatok
  const [actualItem, setActualItem] = useState(null);

  // otvorenie dialógu pre výber spôsobu platby (Platobná brána, resp. Platobné údaje)
  const handleOpenAlert = (item) => {
    setActualItem(item);
  };

  // zatvorenie dialógu pre výber spôsobu platby (Platobná brána, resp. Platobné údaje)
  const handleCloseAlert = () => {
    setActualItem(null);
  }

  // platba
  const handlePayment = async (type) => {

    // načítanie metadát prebiehajúcej platby
    const itemPayment = actualItem;

    setActualItem(null);

    if (type === 1) { // platobna brana

      // údaje pre platbu - vygenerovanie sprievodky
      const payData = { 'items': [], 'totalAmount': 0, 'totalAmountDec': 0, currencyCode: _currencyCode };

      const name = `${itemPayment.NPrjE}, rok: ${itemPayment.Rok}, VS: ${itemPayment.Vs}`;

      const amountDec = Number(itemPayment.Platba);
      payData.totalAmountDec = Number(payData.totalAmountDec) + amountDec;

      const amount = Math.round(amountDec * 100);
      payData.totalAmount = Number(payData.totalAmount) + amount;

      payData.items.push({ name, amount, amountDec });

      let vsExt;
      try {
        // získanie externého variabilného symbolu platby (vygenerovanie záznamu v tabuľke NUM.E_B_VS_EXT)
        const res = await taxesUtils.getVsExt(itemPayment.Vs, null, null, itemPayment.Rok, null);
        vsExt = res.Data;
      } catch (error) {
        console.error(error);
      }
      if (!vsExt || !vsExt.IBVsExt || vsExt.IBVsExt <= 0 || !vsExt.VsExt || vsExt.VsExt.length <= 0) {
        props.dispatch(userActions.showErrorMsg('Platba sa nepodarila.'))
        return;
      }

      // pôvodné riesenie - TODO: odstrániť nepotrebné údaje (VS, CENA, ...)
      let paymentData = { VS: vsExt.VsExt, CENA: payData.totalAmountDec, LANG: 'sk', EMAIL: props.user.data.n_uz };

      // už iba hodnota 1 (0 pre pôvodné riešenie sa už nepoužíva)
      const payMethod = 1;
      // ID projektu
      const prjId = 4005;
      // typ: 3 - Dane a Poplatky
      const payType = 3;
      // ID tabuľky NUM.E_B_VS_EXT
      const iPk = vsExt.IBVsExt;
      // variabilný symbol platby - VS_EXT z tabuľky NUM.E_B_VS_EXT
      const vs = vsExt.VsExt;
      // požadovaná jazyková mutácia platby
      const language = 'SK';
      // mailová adresa pre zaslanie informácie o platbe
      const email = paymentData.EMAIL;

      // sprievodka
      const waybill = { payMethod, prjId, payType, iPk, vs, language, email, 'transactionId': null };

      paymentData = { ...paymentData, waybill, payData };

      navigate(routes.PAYMENT_GATEWAY, { state: paymentData });
    }
    else {

      const iban = itemPayment.Iban?.trim();
      if (!iban || !new RegExp(/^[A-Z]{2}[0-9]{2}[A-Z0-9]{0,30}$/).test(iban)) {
        props.dispatch(userActions.showErrorMsg('Údaj IBAN nie je korektný.'))
        return;
      }

      const totalAmount = Number(itemPayment.Platba);
      const waybill = { vs: itemPayment.Vs, iban, suma: itemPayment.Platba };
      navigate(routes.PAYMENT_PAY, { state: { payType: 2, totalAmount, waybill } }) // payType 1 = parkovanie, 2 = Dane a poplatky
    }
  }

  return (
    <>
      {filteredList.map(data => {
        const title = setCardTitle(data.NPrjE, 2);
        const value = data.Platba * 100 / data.Md;
        return (
          <>
            <Paper key={`key${data.IDnPoNedop}`} elevation={2} className={classNames(classes.ziadostCard, classes.mb3)} >
              <div className={classes.cardContent}>
                <div className={classNames(classes.row, classes.alignItemsCenter, classes.justifyContentStart, classes.mb2)}>
                  <EuroCardIcon style={{ width: "45", height: "30" }}></EuroCardIcon>
                  <div className={classNames(classes.ml2)}>
                    <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>{title}</Typography>
                  </div>
                </div>
                <div className={classNames(classes.row, classes.alignItemsCenter, classes.mt2)} >
                  <Typography variant="body1">Rok</Typography>
                  <Typography variant="body2">{data.Rok}</Typography>
                </div>
                <div className={classNames(classes.row, classes.alignItemsCenter, classes.mt2)} >
                  <Typography variant="body1">Variabilný symbol</Typography>
                  <Typography variant="body2">{data.Vs}</Typography>
                </div>
                <div className={classNames(classes.row, classes.alignItemsCenter)} >
                  <Typography variant="body1">Stav</Typography>
                  <Typography variant="body2" style={{ color: getColor(data.paymentStatus === 2 ? 1 : 0, data.paymentStatus === 2) }}>{data.paymentStatus === 2 ? "Uhradené" : "Neuhradené"}</Typography>
                </div>
                {data.Platba < 0 &&
                  <div className={classNames(classes.row, classes.alignItemsCenter)} >
                    <Typography variant="body1">Preplatok</Typography>
                    <Typography variant="body2">{nbWithSpaces(Math.abs(data.Platba).toFixed(2)) + ' '}{data.SkrMena === "EUR" ? "€" : data.SkrMena}</Typography>
                  </div>
                }
                {data.Platba >= 0 && data.paymentStatus === 2 &&
                  <React.Fragment>
                    <div className={classNames(classes.row, classes.alignItemsCenter)} >
                      <Typography variant="body1">Celková suma</Typography>
                      <Typography variant="body2">{nbWithSpaces(Math.abs(data.Md).toFixed(2)) + ' '}{data.SkrMena === "EUR" ? "€" : data.SkrMena}</Typography>
                    </div>
                    <div className={classNames(classes.row, classes.alignItemsCenter)} >
                      <Typography variant="body1">Uhradená suma</Typography>
                      <Typography variant="body2">{nbWithSpaces(data.Uhrada.toFixed(2))}{' '}{data.SkrMena === "EUR" ? "€" : data.SkrMena}</Typography>
                    </div>
                    <div className={classNames(classes.row, classes.alignItemsCenter)} >
                      <Typography variant="body1">Zostáva k úhrade</Typography>
                      <Typography variant="body2">0.00 {data.SkrMena === "EUR" ? "€" : data.SkrMena}</Typography>
                    </div>
                    <div className={classNames(classes.row, classes.alignItemsCenter)} >
                      <ProgressBar style={{ width: '7em', borderRadius: '5px' }} value={0} color="secondary" />
                    </div>
                  </React.Fragment>
                }
                {data.Platba > 0 && data.paymentStatus === 1 &&
                  <React.Fragment>
                    <div className={classNames(classes.row, classes.alignItemsCenter)} >
                      <Typography variant="body1">Celková suma</Typography>
                      <Typography variant="body2">{nbWithSpaces(Math.abs(data.Md).toFixed(2)) + ' '}{data.SkrMena === "EUR" ? "€" : data.SkrMena}</Typography>
                    </div>
                    <div className={classNames(classes.row, classes.alignItemsCenter)} >
                      <Typography variant="body1">Uhradená suma</Typography>
                      <Typography variant="body2">{nbWithSpaces(data.Uhrada.toFixed(2))}{' '}{data.SkrMena === "EUR" ? "€" : data.SkrMena}</Typography>
                    </div>
                    <div className={classNames(classes.row, classes.alignItemsCenter)} >
                      <Typography variant="body1">Zostáva k úhrade</Typography>
                      <Typography variant="body2">{nbWithSpaces(data.Platba.toFixed(2))}{' '}{data.SkrMena === "EUR" ? "€" : data.SkrMena}</Typography>
                    </div>
                    <div className={classNames(classes.row, classes.alignItemsCenter)} >
                      <ProgressBar style={{ width: '7em', borderRadius: '5px' }} value={value} color="secondary" />
                    </div>
                  </React.Fragment>
                }
                {data.paymentStatus === 1 &&
                  <Button
                    disabled={data.Platba <= 0}
                    variant="contained"
                    color="secondary"
                    size="large"
                    type="success"
                    className={classNames(classes.w100, classes.mt2, classes.row, classes.justifyContentBetween)}
                    onClick={() => handleOpenAlert(data)}
                  >
                    <span style={{ fontWeight: "normal" }}>Platba</span>
                    {nbWithSpaces(data.Platba.toFixed(2))}{' '}{data.SkrMena === "EUR" ? "€" : data.SkrMena}
                  </Button>
                }
              </div>
            </Paper>
            <DialogIcon
              open={actualItem}
              onClose={() => handleCloseAlert()}
              title='Spôsob platby'
              actions={[
                {
                  label: 'Platobná brána',
                  onClick: () => handlePayment(1),
                  icon: <CreditCard />
                },
                {
                  label: 'Platobné údaje',
                  onClick: () => handlePayment(2),
                  icon: <EuroIcon />
                }
              ]}
            />
          </>
        );
      })}
    </>
  );
};

const TaxesListComponent = ({ props, flt, onChange, onShowFilter, onDeleteFilter, onShowDetail, onShowInstallments, onShowPayments }) => {

  const { classes } = props;

  const filteredDecisionList = getFilteredList(1, props.listTaxesDecision, flt.fltDan, flt.fltKon, flt.fltRok).filter(x => x.paymentStatus === flt.paymentStatus);

  const filteredArrearsList = getFilteredList(2, props.listTaxesArrears, flt.fltDan, flt.fltKon, flt.fltRok).filter(x => x.paymentStatus === flt.paymentStatus);

  return (
    <>
      <div className={classNames(classes.row, classes.mb2, classes.pt3, classes.alignItemsCenter)}>
        <SelectFilterField
          value={flt.paymentStatus}
          onChange={onChange}
          fullwidth={true}
          required={true}
          schema={{ label: 'lbl', value: 'val' }}
          data={paymentStatesList}
          name='paymentStatus'
          style={{ display: 'inline-block' }}
        />
        <IconButton
          color="inherit"
          onClick={() => onShowFilter()}
          toolTip="Filter"
          toolTipPlace="top"
          style={{ marginRight: '-7px' }}
        >
          <FilterListIconComponent
            className={classNames(classes.paperWrapper, classes.pl1, classes.pr1, classes.pt1)} />
        </IconButton>
      </div>
      {flt.fltDan.I_PRJ_E.length > 0 &&
        <Chip
          //key={'1'}
          //icon={}
          label={flt.fltDan.N_PRJ_E}
          onDelete={() => onDeleteFilter('fltDan')}
          style={{ backgroundColor: '#fff' }}
          className={classNames(classes.ml2, classes.mb2)} />}
      {flt.fltKon.I_KON_TYP.length > 0 &&
        <Chip
          //key={'2'}
          //icon={}
          label={flt.fltKon.N_KON_TYP}
          onDelete={() => onDeleteFilter('fltKon')}
          style={{ backgroundColor: '#fff' }}
          className={classNames(classes.ml2, classes.mb2)} />}
      {flt.fltRok.I_ROK.length > 0 &&
        <Chip
          //key={'3'}
          //icon={}
          label={flt.fltRok.ROK}
          onDelete={() => onDeleteFilter('fltRok')}
          style={{ backgroundColor: '#fff' }}
          className={classNames(classes.ml2, classes.mb2)} />}
      {filteredDecisionList.length < 1 && filteredArrearsList.length < 1 &&
        <Paper elevation={2} className={classNames(classes.justifyContentCenter, classes.alignItemsCenter)}>
          <div className={classNames(classes.cardContent, classes.justifyContentCenter, classes.alignItemsCenter)}>
            <Typography variant="subtitle2">{'Zadaným podmienkam nevyhovuje žiaden záznam'}</Typography>
          </div>
        </Paper>
      }
      {filteredDecisionList.length > 0 &&
        <TaxesDecisionComponent
          props={props}
          filteredList={filteredDecisionList}
          showDetail={onShowDetail}
          showInstallments={onShowInstallments}
          showPayments={onShowPayments} />}
      {filteredArrearsList.length > 0 &&
        <TaxesArrearsComponent
          props={props}
          filteredList={filteredArrearsList}
        />
      }
    </>
  );
}

const DialogFilterComponent = ({ classes, listTaxesDecision, listTaxesArrears, flt, onSubmit, onCancel }) => {

  const [fltDan, setDan] = useState(flt.fltDan);
  const [fltKon, setKon] = useState(flt.fltKon);
  const [fltRok, setRok] = useState(flt.fltRok);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const frmTypDanList = useMemo(() => getTypDanList(listTaxesDecision, listTaxesArrears), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const frmTypKonList = useMemo(() => getTypKonList(listTaxesDecision), []);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  const frmRokList = useMemo(() => getRokList(listTaxesDecision, listTaxesArrears), []);

  const handleChange = (name, value) => {
    switch (name) {
      case 'frmDan':
        const selectedDan = frmTypDanList.find(x => x.I_PRJ_E === value);
        if (selectedDan)
          setDan(selectedDan);
        break;
      case 'frmKon':
        const selectedKon = frmTypKonList.find(x => x.I_KON_TYP === value);
        if (selectedKon) {
          setKon(selectedKon);
        }
        break;
      case 'frmRok':
        const selectedRok = frmRokList.find(x => x.I_ROK === value);
        if (selectedRok)
          setRok(selectedRok);
        break;
      default:
        break;
    }
  };

  return (
    <div >
      <Typography variant="h6" className={classNames(classes.ml4, classes.mr4, classes.mt4, classes.mb1)}>Filtrovanie</Typography>
      <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4, classes.ml4)}>
        <img style={{ fontSize: 40, marginRight: '7px' }} src={'assets/datamesta_dane.svg'} alt='typy' />
        <SelectField
          floatingLabelText="Typ dane"
          value={fltDan.I_PRJ_E}
          onChange={handleChange}
          fullwidth={true}
          schema={{ label: 'N_PRJ_E', value: 'I_PRJ_E' }}
          data={frmTypDanList}
          name='frmDan'
          className={classNames(classes.mr4)}
        />
      </div>
      <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4, classes.ml4)}>
        <img style={{ fontSize: 40, marginRight: '7px' }} src={'assets/datamesta_typy.svg'} alt='typy' />
        <SelectField
          floatingLabelText="Typ konania"
          value={fltKon.I_KON_TYP}
          onChange={handleChange}
          fullwidth={true}
          schema={{ label: 'N_KON_TYP', value: 'I_KON_TYP' }}
          data={frmTypKonList}
          name='frmKon'
          className={classNames(classes.mr4)}
        />
      </div>
      <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4, classes.ml4)}>
        <DateRangeIcon style={{ fontSize: 40, marginRight: '7px' }} />
        <SelectField
          floatingLabelText="Rok"
          value={fltRok.I_ROK}
          onChange={handleChange}
          fullwidth={true}
          schema={{ label: 'ROK', value: 'I_ROK' }}
          data={frmRokList}
          name='frmRok'
          className={classNames(classes.mr4)}
        />
      </div>
      <Divider variant='fullWidth' style={{ height: '2px' }} />
      <div className={classNames(classes.w100, classes.row)}>
        <Button
          variant="text"
          color="primary"
          size="large"
          className={classNames(classes.w100, classes.formButton)}
          onClick={() => onCancel()}
          style={{ height: '45px' }}
        ><Typography variant="body1" >Zrušiť</Typography></Button>
        <Button
          variant="contained"
          color="secondary"
          size="large"
          className={classNames(classes.w100, classes.formButton)}
          onClick={() => onSubmit(fltDan, fltKon, fltRok)}
          style={{ height: '45px' }}
        ><Typography variant="body1" color="inherit">Filtrovať</Typography></Button>
      </div>
    </div>
  );
};

const setCardTitle = (data, sourceMark = 1) => {
  let title;
  if (sourceMark === 2) {
    title = setTitle(data, title, sourceMark);
  } else {
    if (data.DETAIL.length > 1) {
      title = `Spoločné rozhodnutie ${data.ROK}`
    }
    else {
      if (data.DETAIL.length > 0) {
        title = setTitle(data, title, sourceMark);
      } else {
        title = '';
      }
    }
  }
  return title;
}

export const setTitle = (data, title, sourceMark = 1) => {

  const text = sourceMark === 2 ? data : (data.DETAIL ? data.DETAIL[0].N_PRJ_E : data);
  switch (text) {
    case 'Daň z nehnuteľností':
      title = 'Daň z nehnuteľností'
      break;
    case 'Pes':
      title = 'Daň za psa'
      break;
    case 'Verejné priestranstvo':
      title = 'Daň za užívanie verejného priestranstva'
      break;
    case 'Ubytovanie':
      title = 'Daň za ubytovanie'
      break;
    case 'Predajné automaty':
      title = 'Daň za predajné automaty'
      break;
    case 'Nevýherné hracie prístroje':
      title = 'Daň za nevýherné hracie prístroje'
      break;
    case 'Vjazd do historickej časti mesta':
      title = 'Daň za vjazd a zotrvanie motorového vozidla v historickej časti mesta'
      break;
    case 'Jadrové zariadenie':
      title = 'Daň za jadrové zariadenie'
      break;
    case 'Komunálny odpad':
      title = 'Poplatok za komunálny odpad'
      break;
    case 'Poplatok za rozvoj':
      title = 'Poplatok za rozvoj'
      break;
    case 'Spoločné rozhodnutie':
      title = 'Spoločné rozhodnutie'
      break;
    default:
      title = ''
      break;
  }
  return title;
}

export const custStateCell = (data, sourceMark = 1) => {
  let paid;
  if (data && data.DETAIL) {
    paid = data.DETAIL.every(item =>
      item.L_UHR_PB === true,
    )
  }
  return (
    <span>
      {(data && data.STAV === 0 && (data.L_UHR_PB === false || paid === false)) &&
        "Neuhradené"
      }
      {(data && data.STAV === 0 && (data.L_UHR_PB === true || paid === true)) &&
        "Uhradené"
      }
      {(data && data.STAV === 1 && (data.L_UHR_PB === true || paid === true)) &&
        "Uhradené"
      }
      {(data && data.STAV === 1 && (data.L_UHR_PB === false || paid === false)) &&
        "Uhradené"
      }
      {(data && data.STAV === 2) &&
        "V exekučnom konaní"
      }
    </span>
  )
}

export const getColor = (stav, uhrPb) => {
  if (stav === 0 && !uhrPb)
    return '#f44336'; // red
  else if (stav === 0 && uhrPb)
    return '#4caf50'; // green
  else if (stav === 1 && !uhrPb)
    return '#4caf50'; // green
  else if (stav === 1 && uhrPb)
    return '#4caf50'; // green
  else if (stav === 2)
    return '#f57c00'; // orange
  else return '';
}

export const nbWithSpaces = x => {
  var parts = x.toString().split(".");
  parts[0] = parts[0].replace(/\B(?=(\d{3})+(?!\d))/g, " ");
  return parts.join(".");
}

class Taxes extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      paymentStatus: 0,
      fltDan: EMPTY_DAN_FLT,
      fltKon: EMPTY_KON_FLT,
      fltRok: EMPTY_ROK_FLT,
      openFilter: false,
      showDetail: null,
      showInstallments: null,
      showPayments: null,
    }
  }

  componentDidMount = async () => {
    await this.props.getListTaxesDecision(this.props.person.I_O);
    await this.props.getListTaxesArrears(this.props.person.I_O);
  }

  componentDidUpdate = async (prevProps) => {
    if ((prevProps.isTaxesDecisionLoading || prevProps.isTaxesArrearsLoading) && !this.props.isTaxesDecisionLoading && !this.props.isTaxesArrearsLoading) {
      let paymentStatus = 0;
      if (this.props.listTaxesDecision.some(x => x.paymentStatus === 1) || this.props.listTaxesArrears.some(x => x.paymentStatus === 1))
        paymentStatus = 1;
      else if (this.props.listTaxesDecision.some(x => x.paymentStatus === 2) || this.props.listTaxesArrears.some(x => x.paymentStatus === 2))
        paymentStatus = 2;
      this.changePaymentStatus(paymentStatus);
    }
  }

  changePaymentStatus = (paymentStatus) => {
    this.setState({ paymentStatus, fltDan: EMPTY_DAN_FLT, fltKon: EMPTY_KON_FLT, fltRok: EMPTY_ROK_FLT });
  }

  handleChange = (name, value) => {
    switch (name) {
      case 'paymentStatus':
        this.changePaymentStatus(value);
        break;
      default:
        break;
    }
  }

  handleShowFilter = () => {
    this.setState({ openFilter: true });
  };

  handleCloseFilter = () => {
    this.setState({ openFilter: false });
  };

  handleConfirmFilter = (fltDan, fltKon, fltRok) => {
    this.setState({ openFilter: false, fltDan, fltKon, fltRok });
  }

  handleDeleteFilter = (ident) => {
    switch (ident) {
      case 'fltDan':
        this.setState({ fltDan: EMPTY_DAN_FLT });
        break;

      case 'fltKon':
        this.setState({ fltKon: EMPTY_KON_FLT });
        break;

      case 'fltRok':
        this.setState({ fltRok: EMPTY_ROK_FLT });
        break;
      default:
        break;
    }
  }

  handleShowDetail = (event, item) => {
    event.stopPropagation();
    this.setState({ showDetail: { rok: item.ROK, zozCisRozh: item.ZOZ_CIS_ROZH, zozIPoh: item.ZOZ_I_POH, cKon: item.C_KON } });
  }

  handleCloseDetail = () => {
    this.setState({ showDetail: null });
  };

  handleShowInstallments = (event, item) => {
    event.stopPropagation();
    this.setState({ showInstallments: { zozCisRozh: item.ZOZ_CIS_ROZH, zozIPoh: item.ZOZ_I_POH } });
  }

  handleCloseInstallments = () => {
    this.setState({ showInstallments: false });
  };

  handleShowPayments = (event, item) => {
    event.stopPropagation();
    this.setState({ showPayments: { zozIPoh: item.ZOZ_I_POH } });
  }

  handleClosePayments = () => {
    this.setState({ showPayments: false });
  };

  render() {
    const { classes } = this.props;

    if (this.state.showDetail) {
      return (
        <Proceedings
          onClose={this.handleCloseDetail}
          zozCisRozh={this.state.showDetail.zozCisRozh}
          zozIPoh={this.state.showDetail.zozIPoh}
          cKon={this.state.showDetail.cKon}
        />
      );
    } else if (this.state.showInstallments) {
      return (
        <Installments
          onClose={this.handleCloseInstallments}
          zozCisRozh={this.state.showInstallments.zozCisRozh}
          zozIPoh={this.state.showInstallments.zozIPoh}
        />
      );
    } else if (this.state.showPayments) {
      return (
        <Payments
          onClose={this.handleClosePayments}
          zozIPoh={this.state.showPayments.zozIPoh}
        />
      );
    } else {
      return (
        <>
          <div className='container w-margin w-padding'>
            <CircularProgress loading={this.props.isTaxesDecisionLoading || this.props.isTaxesArrearsLoading} />
            {this.props.listTaxesDecision.length < 1 && this.props.listTaxesArrears.length < 1 &&
              <div className={classNames(classes.mb3, classes.pt3, classes.textCenter)}>
                <Typography variant="button" style={{ fontWeight: 'bold' }}>Nenašli sa žiadne položky.</Typography>
              </div>
            }
            {(this.props.listTaxesDecision.length > 0 || this.props.listTaxesArrears.length > 0) &&
              <TaxesListComponent
                props={this.props}
                flt={{ paymentStatus: this.state.paymentStatus, fltDan: this.state.fltDan, fltKon: this.state.fltKon, fltRok: this.state.fltRok }}
                onChange={this.handleChange}
                onShowFilter={this.handleShowFilter}
                onDeleteFilter={this.handleDeleteFilter}
                onShowDetail={this.handleShowDetail}
                onShowInstallments={this.handleShowInstallments}
                onShowPayments={this.handleShowPayments}
              />
            }
          </div>
          <FormModal
            open={this.state.openFilter}
            onClose={() => this.handleCloseFilter()}
            elements={
              <DialogFilterComponent
                classes={classes}
                listTaxesDecision={this.props.listTaxesDecision}
                listTaxesArrears={this.props.listTaxesArrears}
                flt={{ fltDan: this.state.fltDan, fltKon: this.state.fltKon, fltRok: this.state.fltRok }}
                onSubmit={this.handleConfirmFilter}
                onCancel={this.handleCloseFilter}
              />
            }
          />
        </>
      );
    }
  }
}

Taxes.propTypes = {
}

const mapStateToProps = (state) => ({
  isTaxesDecisionLoading: state.listTaxesDecision.isLoading,
  listTaxesDecision: state.listTaxesDecision.data,
  isTaxesArrearsLoading: state.listTaxesArrears.isLoading,
  listTaxesArrears: state.listTaxesArrears.data,
  person: state.person.data,
  user: state.user,
})

const mapDispatchToProps = (dispatch) => ({
  getListTaxesDecision: (iO) => dispatch(taxesActions.getListTaxesDecision(iO)),
  getListTaxesArrears: (iO) => dispatch(taxesActions.getListTaxesArrears(iO)),
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(Taxes)));
