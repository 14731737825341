import { useEffect } from 'react'
import { connect } from 'react-redux';
import { Box } from '@mui/material';
// import { withStyles } from '@material-ui/core/styles';

import "./progRozp.css";

import * as rozpocetActions from '../../actions/rozpocetActions';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import ProgRozpocet from "./ProgRozpocet";
import sk from './sk.json';
// import getSharedStyles from '../../coraWebMComponents/sharedStyles'

// const styles = theme => ({
//   ...getSharedStyles(theme),
// });

const LOCALE = sk.progRozp;
/**
 * Inicializacny komponent programaveho rozpoctu (PR)
 *
 * @param {*} { initProgRozp, getInitProgRozp, isInitLoading, isListLoading }
 * @return {*} 
 */
const ProgRozpComponent = ({ initProgRozp, getInitProgRozp, isInitLoading, isListLoading }) => {
  /**
   * Nacitanie konfiguračných metadát pre Programový rozpočet pri 1. vykresleni
   */
  useEffect(() => {
    async function fetchData() {
      await getInitProgRozp();
    }

    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box className={"programovy-rozpocet"}>
      <CircularProgress loading={isInitLoading || isListLoading} />
      {!initProgRozp.orgList &&
        <div className="programovy-rozpocet-info-text">{LOCALE.noData}</div>
      }
      {
        initProgRozp.orgList && <ProgRozpocet />
      }
    </Box>
  );
};

ProgRozpComponent.propTypes = {
}

const mapStateToProps = (state) => ({
  isInitLoading: state.initProgRozp.isLoading,
  initProgRozp: state.initProgRozp.data,
  isListLoading: state.listProgRozp.isLoading,
  // listProgRozp: state.listProgRozp.data,
})

const mapDispatchToProps = (dispatch) => ({
  getInitProgRozp: () => dispatch(rozpocetActions.getInitProgRozp()),
});

// export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(ProgRozpComponent));
export default connect(mapStateToProps, mapDispatchToProps)(ProgRozpComponent);