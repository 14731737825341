import React from 'react';
import { connect } from 'react-redux';
import { withStyles } from '@material-ui/core/styles';
import classNames from 'classnames';
import CloseIcon from '@material-ui/icons/Close';
import { ButtonBase } from '@material-ui/core';
import { DirectionsCarOutlined } from '@material-ui/icons';
import InputAdornment from '@material-ui/core/InputAdornment';
import withWidth from '@material-ui/core/withWidth';
import EditIcon from "@material-ui/icons/Edit";
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import getSharedStyles from '../../coraWebMComponents/sharedStyles'
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import Button from '../../coraWebMComponents/inputs/Button';
import ButtonFab from '../../coraWebMComponents/inputs/ButtonFab';
import SelectField from '../../coraWebMComponents/inputs/SelectField';
import * as userActions from '../../actions/userActions';
import * as ciselnikyActions from '../../actions/ciselnikyActions';
import * as publicActions from '../../actions/publicZone/publicActions';
import * as parkingActions from '../../actions/parkingActions';
import * as adminModeUtils from '../../utils/adminModeUtils';
import * as parkingUtils from '../../utils/parkingUtils';
import PlaceIcon from '../../coraWebMComponents/dataDisplay/icons/PlaceIcon';
import CarIcon from '../../coraWebMComponents/dataDisplay/icons/CarIcon';
import AddIcon from '../../coraWebMComponents/dataDisplay/icons/AddIcon';
import * as validator from '../../coraWebMComponents/utils/validator';
import * as convert from '../../coraWebMComponents/utils/convert';
import TextField from '../../coraWebMComponents/inputs/TextField';
import EmailIcon from '../../coraWebMComponents/dataDisplay/icons/EmailIcon';
import SelectFilterField from '../../coraWebMComponents/inputs/SelectFilterField';
import { withLocalizationConsumer } from '../../coraWebMComponents/utils/withLocalization';
import storage from '../../coraWebMComponents/utils/withStorage';
import routes from '../../routes';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import SwitchComponent from '../../coraWebMComponents/inputs/CustomSwitch';
import DatePicker from '../../coraWebMComponents/inputs/DatePicker';
import ScheludeIcon from '../../coraWebMComponents/dataDisplay/icons/ScheludeIcon';
import TimeDialog from '../parking/TimeDialog';
import MapIcon from '../../coraWebMComponents/dataDisplay/icons/MapIcon';
import withMeta from "../../coraWebMComponents/utils/withMeta";
import withRouter from '../../coraWebMComponents/utils/withRouter';

const styles = theme => ({
  ...getSharedStyles(theme),
  payBtn: {
    backgroundColor: '#4caf50',
    borderRadius: '30px',
    height: '55px'
  },
  setBtn: {
    borderRadius: '30px',
    height: '55px'
  },
  paper: {
    borderRadius: '30px'
  },
  timeControlHolder: {
    borderRadius: '30px',
    border: '1px solid #e1e1e1'
  },
  dialogContent: {
    marginBottom: '60px',
  },
  ECVDialog: {
    marginTop: '20px',
  },
  ECVDialogItem: {
    borderBottom: '1px solid #e1e1e1',
    wordBreak: 'break-all',
    padding: '10px 0px',
    width: '100%',
    paddingLeft: 20,
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between',
  },
});

let _Zona = null;
let _Ecv = '';
const _currencyCode = 'EUR';
const _listLanguage = [
  { "LANGUAGE": "sk-SK", "N_LANGUAGE": "Slovenčina", "ICON": "assets/svk-flag-icon.svg" },
  { "LANGUAGE": "en-US", "N_LANGUAGE": "English", "ICON": "assets/uk-flag-icon.svg" },
  { "LANGUAGE": "de-DE", "N_LANGUAGE": "Deutsch", "ICON": "assets/ger-flag-icon.svg" }
]


class ParkingShort extends React.Component {
  constructor(props) {
    super(props);
    const dt = new Date(this.dateToString(new Date()));

    this.state = {
      isLoad: true,
      openNotify: false,
      openAlert: false,
      notifyText: "",
      openAlertCheck: false,
      EMAIL: '',
      ECV: '',
      I_ZONA: 0,
      CENA: 0,
      CAS: 0,
      C_MIN_J: 0,
      HOUR: 0,
      MINUTES: 0,
      den: null,
      showInfo: false,
      infoText: 'bezplatné.',
      infoText2: '',
      infoTextParkTo: '',
      errorTextZona: '',
      errorTextEcv: '',
      errorTextEmail: '',
      timeMin: null,
      timeMax: null,
      LANGUAGE: 'sk-SK',
      d_do_previous: null,
      tabIndex: 0,
      dtOd: dt,
      dtDo: dt,
      minutesOd: -1,
      minutesDo: -1,
      errorTextDate: '',
      calcData: null,
      showTimeDialog: false,
      visibleMapButton: false,
      mapButtonDisabled: false,
      isECVDialogOpen: false,
      isAddECVDialogOpen: false,
      isDeleteECVDialogOpen: false,
      noFreeParkingZoneList: [],
      isOpenFreeParkingZonesDialog: false,
      CHANGE_I_ZONA: [],
      errorTextAddCarEcv: '',
      errorTextAddCarName: '',
      newCarECV: '',
      newCarName: '',
      ECVToDelete: null,
      time_to: null,
      isInfoText: false,
      isInfoText2: false,
      isZoneTimeList: false,
      isCalcData: false,
    };

    this.zoneTime = null;
    this.ticketTimeSums = [];
    this.parkingLimits = []
    this.zoneTimeList = { TotalRecords: 0, Items: [] };
  }

  componentDidMount = async () => {
    const search = window.location.href.split('?').pop();
    const params = new URLSearchParams(search);
    _Zona = params.get("zoneID");
    _Ecv = params.get("ecv");
    await this.props.dispatch(ciselnikyActions.getListZoneParkShort(_Zona));
    await this.props.dispatch(ciselnikyActions.getVisibleParkShort());
    await this.props.dispatch(ciselnikyActions.getNotAvailableFreeParking());

    if (this.props.isAuth) {
      await this.props.dispatch(parkingActions.getECVList());
      await this.props.dispatch(parkingActions.getActiveParkingCard(this.props.person.I_O));
    }

    var bShwMB = this.props.meta.menu.find(x => x.MenuID === 4004016)?.Visible === 1
    var ecvLs = _Ecv ? _Ecv : this.props.storage.getItemLocal('DM_ECV');
    var emailLs = this.props.storage.getItemLocal('DM_EMAIL');

    if (!this.props.paymentGatewaySettings) {
      await this.props.dispatch(ciselnikyActions.getBesteronPaymentGatewaySettings());
    }

    this.setState({
      isLoad: false,
      LANGUAGE: this.props.locale,
      ECV: ecvLs ? ecvLs : '',
      EMAIL: this.props.isAuth ? this.props.user.data.n_uz : emailLs ? emailLs : '',
      visibleMapButton: bShwMB,
      mapButtonDisabled: this.props.meta.menu.find(x => x.MenuID === 4004016)?.Visible === 2,
      noFreeParkingZoneList: this.props.noFreeParkingZoneList?.HODN?.split(',').map(Number)
    });


    if (this.props.location.state?.zona) {
      await this.handleChange('I_ZONA', this.props.location.state.zona)
    }

    if (this.props.listZone.length === 1)
      await this.handleChange('I_ZONA', this.props.listZone[0].I_ZONA);
  }

  handleShowTimeDialog = (value) => {
    this.setState({ showTimeDialog: value });
    if (value === 0) {
      this.calculateData();
    }
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  };

  handleCloseNotify = () => {
    this.setState({ openNotify: false, notifyText: "" });
  }

  handleCloseAlertCheck = () => {
    this.setState({ openAlertCheck: false });
  };

  dateStripHours = (date) => {
    const newDate = new Date(date);
    newDate.setUTCHours(0);
    newDate.setUTCMinutes(0);
    newDate.setUTCSeconds(0);
    newDate.setUTCMilliseconds(0);
    return newDate;
  }

  getTicketTimeSums = async (I_ZONA, dateFrom, dateTo, oldTicketTimeSums, ECV) => {
    return this.getSums(I_ZONA, dateFrom, dateTo, oldTicketTimeSums, ECV, false)
  }

  getParkingLimits = async (I_ZONA, dateFrom, dateTo, oldParkingLimits, ECV) => {
    return this.getSums(I_ZONA, dateFrom, dateTo, oldParkingLimits, ECV, true)
  }

  getSums = async (I_ZONA, dateFrom, dateTo, oldData, ECV, isLimits) => {
    const data = oldData ?? [];
    let dateFromTmp = this.dateStripHours(dateFrom)
    let dateToTmp = this.dateStripHours(dateTo)

    for (let currentDate = new Date(dateFromTmp); currentDate <= dateToTmp; currentDate.setDate(currentDate.getDate() + 1)) {
      const zoneTime = this.zoneTimeList.Items.find(x => new Date(x.DATUM).toDateString() === currentDate.toDateString())
      if ((isLimits && zoneTime.LIMIT !== null) || (!isLimits && zoneTime.C_MIN_J2 !== null)) {
        dateFromTmp = currentDate;
        break;
      } else if (currentDate.toDateString() === dateToTmp.toDateString()) {
        return []
      }
    }
    const action = isLimits ? publicActions.getParkingLimit : publicActions.getTicketTimeSums

    await this.props.dispatch(action(I_ZONA, ECV, dateFromTmp.toJSON().slice(0, 10), dateToTmp.toJSON().slice(0, 10)));

    const storeData = isLimits ? this.props.parkingLimit : this.props.ticketTimeSums

    storeData?.forEach((item) => {
      const newItem = {
        ...item,
        DATUM: new Date(item.DATUM),
      }

      if (!data.find(x => x.DATUM.getTime() === newItem.DATUM.getTime())) {
        data.push(newItem);
      }
    });
    return data;
  }

  getLimitValidatedPrice = (price, today) => {
    const LIMIT = this.parkingLimits.find(x => new Date(x.DATUM).toDateString() === today.toDateString())
    if (LIMIT) {
      if (price > LIMIT.LIMIT_WITHOUT_SUM) {
        price = LIMIT.LIMIT_WITHOUT_SUM
      }
    }
    return price;
  }

  handleECVBlur = async () => {
    if (this.state.ECV && this.state.I_ZONA) {
      this.setState({ isLoad: true });
      this.ticketTimeSums = await this.getTicketTimeSums(this.state.I_ZONA, new Date(), this.state.dtDo, [], this.state.ECV)
      this.parkingLimits = await this.getParkingLimits(this.state.I_ZONA, new Date(), this.state.dtDo, [], this.state.ECV)
      this.resetTimeAndPrice()
      this.calculateData()
      this.setState({ isLoad: false });
    }
    if (this.state.den && this.state.time_to) {
      this.setInfoTextParkTo(this.state.den, this.state.time_to)
    }
  }

  handleChange = async (name, value) => {

    switch (name) {
      case 'EMAIL':
        this.setState({ errorTextEmail: value ? validator.isValidMail(value, this.state.LANGUAGE) : '' });
        break;
      case 'LANGUAGE':
        await this.props.dispatch(userActions.setLocale(value.trim()));
        if (this.state.den && this.state.time_to) {
          this.setInfoTextParkTo(this.state.den, this.state.time_to)
        }
        this.setInfoText()
        break;
      case 'ECV':
        if (this.state.CENA === 0 && this.state.infoText2)
          this.handleAddTime(0);

        value = value.toUpperCase();
        value = value.trim()
        this.setState({ errorTextEcv: value ? validator.isValidEcv(value, this.state.LANGUAGE) : this.props.translate('ECV') + this.props.translate('isRequired') });
        break;
      case 'I_ZONA':
        this.setState({ isLoad: true, isZoneTimeList: false });
        let { dtDo } = this.state;
        this.setState({ errorTextZona: value ? '' : this.props.translate('zone') + this.props.translate('isRequired') });
        await this.props.dispatch(ciselnikyActions.getZoneTimeList(value, 2));
        this.zoneTimeList = this.getZoneTimeList(this.props.zoneTimeList)

        if (this.state.ECV) {
          this.ticketTimeSums = await this.getTicketTimeSums(value, new Date(), dtDo, [], this.state.ECV);
          this.parkingLimits = await this.getParkingLimits(value, new Date(), dtDo, [], this.state.ECV);
        }
        this.resetTimeAndPrice();
        this.setState({ isLoad: false, isZoneTimeList: this.zoneTimeList.Items.length > 0 });
        this.calculateData();
        break;
      case "newCarECV":
        value = value.toUpperCase();
        value = value.trim()
        this.setState({ errorTextAddCarEcv: value ? validator.isValidEcv(value, this.state.LANGUAGE) : this.props.translate('ECV') + this.props.translate('isRequired') });
        break;
      case "newCarName":
        this.setState({ errorTextAddCarName: value.length > 200 ? "Maximálna dĺžka názvu vozidla je 200 znakov." : "" });
        break;
      default:
    }

    this.setState({ [name]: value });
  }

  getCena = (time, zoneTime, previousTime) => {
    let price = 0;

    let sk = 0;
    let sk2 = 0;
    let sk3 = 0

    let c_min_j = 0;
    let c_min_j2 = 0;
    let c_min_j3 = 0;

    if (zoneTime) {
      c_min_j = zoneTime.C_MIN_J ? zoneTime.C_MIN_J : 0;
      c_min_j2 = zoneTime.C_MIN_J2 ? zoneTime.C_MIN_J2 : 0;
      c_min_j3 = zoneTime.C_MIN_J3 ? zoneTime.C_MIN_J3 : 0;

      sk = zoneTime.SK ? zoneTime.SK : 0;
      sk2 = zoneTime.D_SK2 ? zoneTime.D_SK2 : 0;
      sk3 = zoneTime.D_SK3 ? zoneTime.D_SK3 : 0;
    }

    if (time <= 0 || c_min_j === 0) {
      return 0;
    }

    if (c_min_j && !c_min_j2 && !c_min_j3) {
      price = Math.ceil(time / c_min_j) * sk;
    }

    if (c_min_j && c_min_j2 && !c_min_j3) {
      if (time > 0) {
        if (previousTime < c_min_j) {
          price += sk;
          time -= c_min_j;
          time = time > 0 ? time : 0;
        }

        const podiel = Math.ceil(time / c_min_j2);
        price += podiel * sk2;
      }
    }

    if (c_min_j && c_min_j2 && c_min_j3) {
      if (time > 0) {
        if (previousTime < c_min_j) {
          price += sk;
          time -= c_min_j;
          time = time > 0 ? time : 0;
        }

        if (time > 0) {
          if (previousTime < c_min_j + c_min_j2) {
            price += sk2;
            time -= c_min_j2;
            time = time > 0 ? time : 0;
          }

          const podiel = Math.ceil(time / c_min_j3)
          price += podiel * sk3;
        }
      }
    }
    price = Number(price.toFixed(2));

    return price;
  };

  validateTime = (minuty) => {
    const C_MIN_J_LOWEST = this.getC_MIN_J(this.state.den, 0)
    if (minuty < C_MIN_J_LOWEST) {
      minuty = C_MIN_J_LOWEST
    }

    var timeNow = new Date();
    if (this.state.timeMin > timeNow)
      timeNow = new Date(this.state.timeMin);

    const toEndOfDay = (new Date(this.state.timeMax) - timeNow) / 60000;
    if (minuty > toEndOfDay)
      minuty = toEndOfDay;

    return minuty;
  };

  handleAddTime = (addHour) => {
    var time = this.state.CAS;
    switch (addHour) {
      case 1:
        time = time - this.state.C_MIN_J;
        break;
      case 2:
        time = time + this.state.C_MIN_J;
        break;
      case 3:
        time = time - 60;
        break;
      case 4:
        time = time + 60;
        break;
      case 5:
        time = time + 1440;
        break;
      default:
    }

    time = this.validateTime(time);
    const hodiny = parseInt(time / 60);
    const minuty = parseInt(time - (60 * hodiny));
    const previousTime = this.ticketTimeSums.find(x => new Date(x.DATUM).toDateString() === this.state.den.toDateString())?.TICKET_TIME_SUM ?? 0
    let cena = this.getCena(time, this.zoneTime, previousTime);
    cena = this.getLimitValidatedPrice(cena, this.state.den)

    var time_to = new Date();

    if (this.state.timeMin > time_to)
      time_to = new Date(this.state.timeMin);

    time_to.setMinutes(time_to.getMinutes() + time);
    this.setState({ time_to })
    this.setInfoTextParkTo(this.state.den, time_to)
    const C_MIN_J_CURRENT = this.getC_MIN_J(this.state.den, time)
    this.setState({ CAS: time, HOUR: hodiny, MINUTES: minuty, CENA: cena, C_MIN_J: C_MIN_J_CURRENT });
  };

  getHodiny = (time) => {
    const pos = time.indexOf(":");
    return Number(time.substring(0, pos));
  };

  getMinuty = (time) => {
    const pos = time.indexOf(":");
    return Number(time.substring(pos + 1));
  }

  getDateOfParking = (dayOfParking) => {
    var ret = " ";
    if (new Date() < dayOfParking) {
      ret = convert.dataToLocaleDateStr(dayOfParking, this.props.locale);
      ret = ret.replace(" ", "");
      ret = " " + ret.replace(" ", "") + " ";
    }

    return ret;
  };

  handleBack = () => {
    if (this.props.isAuth)
      this.props.navigate({ pathname: routes.PARKING_TICKET });
    else
      this.props.navigate(routes.PRELOGIN);
  };

  resetTimeAndPrice = () => {
    var den = new Date();
    var ret = this.resetTimeAndPriceOneDay(den);

    while (!ret) {
      const year = den.getFullYear();
      const mon = den.getMonth();
      const day = den.getDate();
      den = new Date(year, mon, day + 1);

      ret = this.resetTimeAndPriceOneDay(den);
    }
    this.setState({ den: den });
  }

  resetTimeAndPriceOneDay = (den) => {
    var dnes = new Date(den);
    this.zoneTime = this.props.zoneTimeList.Items.find(x => new Date(x.DATUM).toDateString() === dnes.toDateString())

    var haveDate = false;
    if (this.zoneTime !== null && this.zoneTime.D_OD && this.zoneTime.D_DO)
      haveDate = true;

    if (haveDate) {
      const rok = dnes.getFullYear();
      const mesiac = dnes.getMonth();
      const den = dnes.getDate();
      const hodinyMin = this.getHodiny(this.zoneTime.D_OD);
      const minutyMin = this.getMinuty(this.zoneTime.D_OD);
      const hodinyMax = this.getHodiny(this.zoneTime.D_DO);
      const minutyMax = this.getMinuty(this.zoneTime.D_DO);
      var timMin = new Date(rok, mesiac, den, hodinyMin, minutyMin);
      var timMax = new Date(rok, mesiac, den, hodinyMax, minutyMax);

      var hour = 0;
      var minutes = this.getC_MIN_J(dnes, 0);
      var time_to = new Date();

      if (timMin > time_to) {
        time_to = new Date(timMin);
      }

      time_to.setMinutes(time_to.getMinutes() + minutes);
      this.setState({ time_to })

      const previousTime = this.ticketTimeSums.find(x => new Date(x.DATUM).toDateString() === dnes.toDateString())?.TICKET_TIME_SUM ?? 0
      var cena = this.getCena(minutes, this.zoneTime, previousTime);
      cena = this.getLimitValidatedPrice(cena, dnes)

      if (minutes >= 60) {
        hour = minutes / 60;
        minutes = minutes - (60 * hour);
      }

      this.setInfoTextParkTo(dnes, time_to)
    }

    const isInfoText = haveDate
    const isInfoText2 = dnes < timMax
    const C_MIN_J_CURRENT = this.getC_MIN_J(dnes, minutes)
    const CAS = minutes + (hour * 60)

    this.setState({
      isInfoText,
      isInfoText2,
      showInfo: true,
      isLoad: false,
      timeMin: haveDate ? timMin : null,
      timeMax: haveDate ? timMax : null,
      CENA: haveDate ? cena : 0,
      C_MIN_J: haveDate ? C_MIN_J_CURRENT : 0,
      CAS: haveDate ? CAS : 0,
      HOUR: hour,
      MINUTES: haveDate ? minutes : 0,
      errorTextZona: '',
    }, () => this.setInfoText());

    return haveDate ? (timMax > new Date() ? true : false) : false;
  }

  setInfoText = () => {
    let infoText = this.state.isInfoText && this.zoneTime ? `${this.props.translate('isPaid')} ${this.props.translate('from')} ${this.zoneTime.D_OD} ${this.props.translate('to')} ${this.zoneTime.D_DO} ${this.props.translate('hour')}.` : `${this.props.translate('free')}.`
    let infoText2 = this.state.isInfoText2 && this.zoneTime ? `${this.zoneTime.SK.toString().replace('.', ',')}${this.props.translate('parkingShort.infoText2')}${this.zoneTime.C_MIN_J} min.` : ``

    if (this.zoneTime && this.zoneTime.C_MIN_J2 !== null) {
      if (this.zoneTime.C_MIN_J3 === null) {
        infoText2 = `${this.zoneTime.SK.toString().replace('.', ',')}${this.props.translate('parkingShort.firstStarted')}${this.zoneTime.C_MIN_J} min., ${this.zoneTime.D_SK2.toString().replace('.', ',')}${this.props.translate('parkingShort.nextStarted')}${this.zoneTime.C_MIN_J2} min.`
      } else {
        infoText2 = `${this.zoneTime.SK.toString().replace('.', ',')}${this.props.translate('parkingShort.firstStarted')}${this.zoneTime.C_MIN_J} min., ${this.zoneTime.D_SK2.toString().replace('.', ',')}${this.props.translate('parkingShort.secondStarted')}${this.zoneTime.C_MIN_J2} min., ${this.zoneTime.D_SK3.toString().replace('.', ',')}${this.props.translate('parkingShort.nextStarted')}${this.zoneTime.C_MIN_J3} min.`
      }
    }

    this.setState({
      infoText: infoText,
      infoText2: infoText2,
    })
  }

  getC_MIN_J = (currentDay, currentMinutes) => {
    const zoneTime = this.zoneTimeList.Items.find(x => new Date(x.DATUM).toDateString() === currentDay.toDateString())
    const ticketTimeSum = this.ticketTimeSums.find(x => new Date(x.DATUM).toDateString() === currentDay.toDateString())
    const ticketTimeSumMinutes = ticketTimeSum?.TICKET_TIME_SUM ?? 0
    const minutesSum = ticketTimeSumMinutes + currentMinutes

    const C_MIN_J = zoneTime.C_MIN_J
    const C_MIN_J2 = zoneTime.C_MIN_J2
    const C_MIN_J3 = zoneTime.C_MIN_J3

    let C_MIN_J_CURRENT = 0;

    if (C_MIN_J) {
      C_MIN_J_CURRENT = C_MIN_J

      if (C_MIN_J2 && minutesSum >= C_MIN_J) {
        C_MIN_J_CURRENT = C_MIN_J2
      }

      if (C_MIN_J2 && C_MIN_J3 && minutesSum >= C_MIN_J + C_MIN_J2) {
        C_MIN_J_CURRENT = C_MIN_J3
      }
    }
    return C_MIN_J_CURRENT;
  }

  setInfoTextParkTo = (today, time_to) => {
    let infTxtParkTo = this.props.translate('parkingShort.title') + " " + this.props.translate('to') + this.getDateOfParking(today) + time_to.toLocaleTimeString(this.props.locale, { hour: '2-digit', minute: '2-digit' }) + " " + this.props.translate('hour');
    const LIMIT = this.parkingLimits.find(x => new Date(x.DATUM).toDateString() === today.toDateString())
    if (LIMIT) {
      if ((LIMIT.LIMIT_WITHOUT_SUM) > 0) {
        infTxtParkTo += ". " + this.props.translate('parkingShort.limitLeft') + " " + LIMIT.LIMIT_WITHOUT_SUM + "€"
      } else {
        infTxtParkTo += ". " + this.props.translate('parkingShort.noLimitLeft')
      }
    }
    this.setState({ infoTextParkTo: infTxtParkTo })
  }

  handleMap = () => {
    this.props.navigate({ pathname: routes.PARKING_MAP });
  }

  getFreeParkingZonesDialogContent = () => {
    return (
      <>
        <SelectField
          floatingLabelText={this.props.translate('zone')}
          value={this.state.CHANGE_I_ZONA}
          onChange={this.handleChange}
          fullwidth={true}
          schema={{ label: 'N_ZONA', value: 'I_ZONA' }}
          data={this.props.listZone}
          name='CHANGE_I_ZONA'
          icon={<PlaceIcon />}
          multiple={true}
          wrap={true}
        />
      </>
    )
  }

  handleOpenFreeParkingZonesDialog = () => {
    this.setState({ isOpenFreeParkingZonesDialog: true, CHANGE_I_ZONA: this.state.noFreeParkingZoneList ? [...this.state.noFreeParkingZoneList] : [] });
  }

  handleCloseFreeParkingZonesDialog = () => {
    this.setState({ isOpenFreeParkingZonesDialog: false, CHANGE_I_ZONA: [] });
  }

  handleSaveFreeParkingZones = async () => {
    await adminModeUtils.updateSpDefOb(4003001013, this.state.CHANGE_I_ZONA.toString())
    this.setState({ isOpenFreeParkingZonesDialog: false, CHANGE_I_ZONA: [] });
  }

  getECVDialogContent = () => {
    const { classes } = this.props;
    return (
      <>
        {
          this.props.ECVList && this.props.ECVList.length > 0 ?
            <div className={classNames(classes.ECVDialog)}
            >
              {this.props.ECVList.map((item) => {
                return (
                  <ButtonBase className={classNames(classes.ECVDialogItem)} key={item.I_ECV_UZ}
                    onClick={() => this.selectECV(item)}
                  >
                    <div style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'space-between',
                    }}>
                      <DirectionsCarOutlined />
                      <div style={{
                        marginLeft: 10,
                        display: 'flex',
                        flexDirection: 'column',
                      }}>
                        <Typography align="left" style={{ fontStyle: "italic" }}>
                          {item.N_VOZ}
                        </Typography>
                        <Typography align="left">
                          {item.ECV}
                        </Typography>
                      </div>
                    </div>
                    <IconButton
                      disableTooltip={true}
                      onClick={(event) => this.handleOpenDeleteECVDialog(event, item)}
                    >
                      <CloseIcon fontSize="small" style={{ color: 'red' }} />
                    </IconButton>
                  </ButtonBase>
                )
              })}
            </div >
            :
            null
        }
        <ButtonFab
          disableTooltip={true}
          size="small"
          onClick={() => this.setState({ isAddECVDialogOpen: true })}
          style={{
            backgroundColor: "#4caf50",
            color: "#ffffff",
            position: 'absolute',
            bottom: 20,
            right: 20,
          }}
        >
          <AddIcon />
        </ButtonFab>
      </>

    )
  }

  selectECV = (item) => {
    this.setState({
      isECVDialogOpen: false,
      ECV: item.ECV,
      errorTextEcv: '',
    })
  }

  handleAddECV = async () => {
    let res = await parkingUtils.addECV(this.state.newCarECV, this.state.newCarName)
    switch (res.Data) {
      case 0:
        this.props.dispatch(userActions.showMsg("EČV úspešne zaradené do zoznamu vozidiel."));
        await this.props.dispatch(parkingActions.getECVList());
        break;
      case -998:
        this.props.dispatch(userActions.showErrorMsg("EČV už existuje v zozname."));
        break;
      case -999:
        this.props.dispatch(userActions.showErrorMsg("Požiadavku sa nepodarilo spracovať."));
        break;
      default:
        this.props.dispatch(userActions.showErrorMsg("Neočakávaná chyba."));
        break;
    }
    const ECV = this.state.newCarECV;
    this.setState({ isAddECVDialogOpen: false, newCarECV: '', newCarName: '', ECV: ECV, isECVDialogOpen: false });
  }

  getAddECVDialogContent = () => {
    return (
      <>
        <TextField
          label={this.props.translate('ECV')}
          fullwidth={true}
          value={this.state.newCarECV}
          errorText={this.state.errorTextAddCarEcv}
          error={this.state.errorTextAddCarEcv ? true : false}
          onChange={this.handleChange}
          name='newCarECV'
        />
        <TextField
          label="Názov"
          fullwidth={true}
          value={this.state.newCarName}
          errorText={this.state.errorTextAddCarName}
          error={this.state.errorTextAddCarName ? true : false}
          onChange={this.handleChange}
          name='newCarName'
        />
      </>
    )
  }

  handleOpenDeleteECVDialog = (event, item) => {
    event.stopPropagation();
    this.setState({ isDeleteECVDialogOpen: true, ECVToDelete: item });
  }

  handleCloseDeleteECVDialog = () => {
    this.setState({ isDeleteECVDialogOpen: false, ECVToDelete: null });
  }

  handleDeleteECV = async () => {
    if (this.state.ECVToDelete && this.state.ECVToDelete.I_ECV_UZ) {
      await parkingUtils.deleteECV(this.state.ECVToDelete.I_ECV_UZ)
      await this.props.dispatch(parkingActions.getECVList());
      this.setState({ isDeleteECVDialogOpen: false });
      this.props.dispatch(userActions.showMsg("EČV úspešne vymazané zo zoznamu vozidiel"));
    }
  }

  getDeleteECVDialogContent = () => {
    return (
      <>
        <Typography
          style={{
            textAlign: 'center',
            fontWeight: 'bold',
            marginBottom: 20
          }}
        >
          {this.state.ECVToDelete ? this.state.ECVToDelete.ECV : null}
        </Typography>
        <Typography
          style={{
            textAlign: 'center',
            fontStyle: 'italic',
            wordWrap: 'break-word',
          }}
          cursive={true}
        >
          {this.state.ECVToDelete ? this.state.ECVToDelete.N_VOZ : null}
        </Typography>
      </>
    )
  }

  AddParking = async (isFree) => {

    const errorTextZona = this.state.I_ZONA ? '' : this.props.translate('zone') + this.props.translate('isRequired');
    const errorEcv = validator.isValidEcv(this.state.ECV, this.state.LANGUAGE);
    const errorTextEcv = this.state.ECV ? errorEcv : this.props.translate('ECV') + this.props.translate('isRequired');
    const errorMail = validator.isValidMail(this.state.EMAIL, this.state.LANGUAGE);
    const errorTextEmail = this.state.EMAIL ? errorMail : this.props.translate('mail') + this.props.translate('isRequired');
    this.setState({ errorTextZona, errorTextEcv, errorTextEmail });

    if (!errorTextZona && !errorTextEcv && !errorTextEmail) {
      const card = this.props.activeParkingCards?.find(x => x.ECV === this.state.ECV)

      if (card) {
        const zoneList = card.ZOZ_I_ZONA.split(',').map(x => Number(x))
        if (zoneList.includes(this.state.I_ZONA)) {
          this.setState({ openNotify: true, notifyText: "V danej zóne existuje platné parkovacie oprávnenie." });
          return;
        }
      }

      await this.AddParkingCheck(card, isFree);
    }
  }

  AddParkingCheck = async (card, isFree) => {
    this.setState({ openAlert: false });

    if (this.state.tabIndex === 1) { // Planovane parkovanie
      await this.props.dispatch(publicActions.checkParkingTicketPlan(this.state.I_ZONA, this.state.ECV, this.state.minutesOd, this.state.minutesDo, this.state.dtOd.toJSON(), this.state.dtDo.toJSON()));
    } else {
      await this.props.dispatch(publicActions.checkParkingTicket(this.state.I_ZONA, this.state.ECV));
    }
    const d_do_previous = this.props.parkingTicketCheck?.D_DO ? this.props.parkingTicketCheck.D_DO : null;

    if (d_do_previous) {
      const d_do_previous_date = new Date(d_do_previous);
      let timeTo = this.state.dtDo;
      if (this.state.tabIndex === 1) {
        timeTo.setHours(this.state.minutesDo / 60);
        timeTo.setMinutes(this.state.minutesDo % 60);
      } else {
        const now = new Date();
        timeTo.setHours(now.getHours() + this.state.HOUR);
        timeTo.setMinutes(now.getMinutes() + this.state.MINUTES);
      }

      if (this.state.dtDo <= d_do_previous_date) {
        this.setState({ openNotify: true, d_do_previous, notifyText: this.props.translate('parkingShort.dialogContextNotify1') + (d_do_previous ? convert.convertDateTime(d_do_previous) : '') + this.props.translate('parkingShort.dialogContextNotify2') });
      } else {
        if (this.state.tabIndex === 1) {
          const previousMinutes = d_do_previous_date.getHours() * 60 + d_do_previous_date.getMinutes()
          const minutesDifference = previousMinutes - this.state.minutesOd
          let minutesDoTemp = this.state.minutesDo + minutesDifference
          const maxMinutes = this.zoneTimeList.Items.find(x => x.sDatum == this.dateToString(this.state.dtDo))?.minutesDo
          if (minutesDoTemp > maxMinutes) {
            minutesDoTemp = maxMinutes
          }

          const dtDoTemp = new Date(this.state.dtDo)
          dtDoTemp.setHours(minutesDoTemp / 60)
          dtDoTemp.setMinutes(minutesDoTemp % 60)

          this.setState((prevState) => ({
            ...prevState,
            dtOd: d_do_previous_date,
            dtDo: dtDoTemp,
            minutesOd: previousMinutes,
            minutesDo: minutesDoTemp
          }));
          this.calculateData();
        }
        if (isFree) {
          this.setState({
            openNotify: true, notifyText: this.props.translate('parkingShort.dialogContextNotify1') + (d_do_previous ? convert.convertDateTime(d_do_previous) : ''),
          })
        } else {
          this.setState({ openAlertCheck: true, d_do_previous });
        }
      }
    } else {
      await this.AddParkingShort(card, isFree);
    }
  }

  AddParkingShort = async (card, isFree) => {

    this.setState({ isLoad: true, openAlertCheck: false });

    this.props.storage.setItemLocal('DM_ECV', this.state.ECV);
    this.props.storage.setItemLocal('DM_EMAIL', this.state.EMAIL);

    let data = (() => {
      let { ECV, I_ZONA, EMAIL, LANGUAGE } = this.state;
      return { ECV, I_ZONA, EMAIL, LANGUAGE };
    })();

    // 0 = povodne riesenie / 1 = riesenie HD 70888
    const payMethod = Number(this.props.paymentGatewaySettings.HODN);

    // udaje pre platbu
    const payData = { 'items': [], 'totalAmount': 0, 'totalAmountDec': 0, currencyCode: _currencyCode };

    let d_od, d_do;
    if (this.state.tabIndex === 0) {  // jednorazove parkovanie

      const minuty = this.validateTime(this.state.d_do_previous ? Math.ceil(Math.abs(this.state.dtDo - new Date(this.state.d_do_previous)) / (1000 * 60)) : this.state.CAS);
      const previousTime = this.ticketTimeSums.find(x => new Date(x.DATUM).toDateString() === this.state.den.toDateString())?.TICKET_TIME_SUM ?? 0
      let cena = this.getCena(minuty, this.zoneTime, previousTime);
      d_od = this.state.d_do_previous ? new Date(this.state.d_do_previous) : new Date();
      d_do = this.state.d_do_previous ? new Date(this.state.d_do_previous) : new Date();

      if (this.state.timeMin > d_do) {
        d_od = new Date(this.state.timeMin);
        d_do = new Date(this.state.timeMin);
      }
      cena = this.getLimitValidatedPrice(cena, d_od)

      d_do.setMinutes(d_do.getMinutes() + minuty);

      data = { ...data, D_OD: convert.addOffset(d_od), D_DO: convert.addOffset(d_do), CENA: cena };

      const minutesOd = this.calculateMinutes(d_od, new Date(this.dateToString(d_od)));
      const minutesDo = this.calculateMinutes(d_do, new Date(this.dateToString(d_do)));
      const name = this.generPayName(data.ECV, d_od, minutesOd, minutesDo);

      const amountDec = Number(data.CENA);
      payData.totalAmountDec = Number(payData.totalAmountDec) + amountDec;

      const amount = Math.round(amountDec * 100);
      payData.totalAmount = Number(payData.totalAmount) + amount;

      payData.items.push({ name, amount, amountDec });

      if (isFree) {
        this.AddParkingFree(card, data)
        return
      }
    } else {  // planovane parkovanie

      d_od = this.state.calcData.dtOd;
      d_do = this.state.calcData.dtDo;
      data = { ...data, D_OD: convert.addOffset(d_od), D_DO: convert.addOffset(d_do), CENA: this.state.calcData.nAllSumma };
      this.state.calcData.items.forEach(item => {

        if (item.nDaySumma > 0) {

          const name = this.generPayName(data.ECV, item.dtDatum, item.minutesOd, item.minutesDo);

          const amountDec = Number(item.nDaySumma);
          payData.totalAmountDec = Number(payData.totalAmountDec) + amountDec;

          const amount = Math.round(amountDec * 100);
          payData.totalAmount = Number(payData.totalAmount) + amount;

          payData.items.push({ name, amount, amountDec });
        }
      });

    }

    let dataPay;
    let iDocPark = 0;
    if (payData.totalAmountDec === 0) {
      iDocPark = await this.props.dispatch(publicActions.addParkingShort(data))
      if (iDocPark > 0) {
        this.props.dispatch(userActions.showMsg("Parkovanie úspešne zaevidované."))
      }
    } else if (payData.totalAmountDec > 0) {
      iDocPark = await this.props.dispatch(publicActions.addParkingShort(data));
      if (iDocPark > 0) {
        // povodne riesenie
        dataPay = { VS: `9${iDocPark.toString().padStart(9, 0)}`, CENA: data.CENA, LANG: data.LANGUAGE.substring(3, 5), EMAIL: data.EMAIL };

        if (payMethod === 1) {  // riesenie HD 70888

          const prjId = 4003;
          const payType = 2;
          const iPk = iDocPark;
          const vs = dataPay.VS;
          const language = dataPay.LANG;
          const email = dataPay.EMAIL;

          // sprievodka
          const waybill = { payMethod, prjId, payType, iPk, vs, language, email, 'transactionId': null };

          dataPay = { ...dataPay, waybill, payData };
        }
      }
      if (iDocPark > 0) {
        this.props.navigate(routes.PAYMENT_GATEWAY, { state: dataPay });
      } else {
        this.props.dispatch(userActions.showErrorMsg(this.props.translate('parkingShort.failure')));
      }
    }
    this.setState({ isLoad: false });
  }

  AddParkingFree = async (card, ticketData) => {
    if (card) {
      const data = {
        ...ticketData,
        CENA: 0,
      }
      await parkingUtils.addParkingShortFree(card.I_KARTA_TYP, data).then(response => {
        if (response.Code === "201.000") {
          this.handleFreeParkingResponse(response);
        } else {
          this.props.dispatch(userActions.showErrorMsg("Nastala neočakávaná chyba pri pridávaní bezplatného parkovania"));
        }
      })
    } else {
      this.props.dispatch(userActions.showErrorMsg("Pre zadané EČV nie je možné bezplatné parkovanie."));
    }
    this.setState({ isLoad: false })
  }

  handleFreeParkingResponse = (response) => {
    const { ResponseCode, Data } = response.Data;

    switch (ResponseCode) {
      case 0:
        const difference = new Date(Data?.D_DO) - new Date(Data?.D_OD);
        const rawMinutes = Math.floor(difference / 1000 / 60)
        const timeString = this.calculateTimeString(rawMinutes);
        this.props.dispatch(userActions.showMsg(`Bezplatné parkovanie bolo úspešne pridané na dobu ` + timeString));
        this.props.navigate({ pathname: routes.PARKING_TICKET });
        break;
      case 1:
        this.props.dispatch(userActions.showErrorMsg("Prekročený povolený čas bezplatného parkovania."));
        break;
      case 2:
        this.props.dispatch(userActions.showErrorMsg("Prekročený počet bezplatných parkovaní."));
        break;
      case 3:
        this.props.dispatch(userActions.showErrorMsg("Nemáte oprávnenie na bezplatné parkovanie"));
        break;
      default:
        this.props.dispatch(userActions.showErrorMsg("Nastala neočakávaná chyba pri pridávaní bezplatného parkovania"));
    }
  }

  calculateTimeString = (minutes) => {
    const hours = Math.floor(minutes / 60);
    const minutesLeft = minutes % 60;
    let timeString
    if (hours === 0) {
      timeString = `${minutesLeft} min.`;
    } else if (minutesLeft === 0) {
      timeString = `${hours} hod.`;
    } else {
      timeString = `${hours} hod. a ${minutesLeft} min.`;
    }
    return timeString;
  }

  generPayName = (ecv, dt, minutesOd, minutesDo) => {
    let res = `${this.props.translate('parkingShort.title')}`;
    res += `, ${this.props.translate('ECV')}${ecv}`;
    res += `, ${this.props.translate('date')} ${this.dateToString(dt)}`;
    res += ` ${this.props.translate('from')} ${this.minutesToString(minutesOd)}`;
    res += ` ${this.props.translate('to')} ${this.minutesToString(minutesDo)}`;
    return res;
  }

  handleSwitchChange = (event, value) => {
    this.setState({ tabIndex: value });
  };

  setHourAndMinutes = (d, t) => {
    let dt = null;
    if (null != d && d.length > 0) {
      dt = new Date(d);
      if (null != t && t.length > 0) {
        const aa = t.split(":");
        if (aa.length === 2) {
          const h = Number(aa[0]);
          dt.setHours(h);
          const m = Number(aa[1]);
          dt.setMinutes(m);
        }
      }
    }
    return dt;
  };

  getZoneTimeList = (sour) => {

    if (sour && sour.TotalRecords > 0) {
      sour.Items.forEach(element => {

        element.dtDatum = null;
        element.sDatum = null;
        element.iDatum = 0;
        element.dtOd = null;
        element.dtDo = null;
        element.minutesOd = -1;
        element.minutesDo = -1;

        if (element.DATUM && element.DATUM.length > 0) {
          element.dtDatum = new Date(element.DATUM);
          element.sDatum = this.dateToString(element.dtDatum);
          element.iDatum = this.dateToNumber(element.dtDatum);
          if (element.D_OD && element.D_OD.length > 0) {
            element.dtOd = this.setHourAndMinutes(element.DATUM, element.D_OD);
            element.minutesOd = this.calculateMinutes(element.dtOd, element.dtDatum);
          }
          if (element.D_DO && element.D_DO.length > 0) {
            element.dtDo = this.setHourAndMinutes(element.DATUM, element.D_DO);
            element.minutesDo = this.calculateMinutes(element.dtDo, element.dtDatum);
          }
        }
        element.hasCasy = element.minutesOd > -1 && element.minutesDo > 0;
      });
    }
    return sour;
  }

  roundTime = (dt, bUp, limit) => {
    dt.setSeconds(0, 0);
    let h = dt.getHours();
    let m = h * 60 + dt.getMinutes();
    let tt = m % limit;
    if (tt > 0) {
      if (bUp) {
        tt = limit - tt;
        m += tt;
      } else {
        m -= tt;
      }
      h = Math.floor(m / 60);
      dt.setHours(h);
      m -= h * 60;
      dt.setMinutes(m);
    }
    return dt;
  }

  getNowData = () => {
    const dtNow = new Date();
    const iDatum = this.dateToNumber(dtNow);
    let dtRounded = null;
    let minutes = -3;
    const actualMeta = this.zoneTimeList.Items.find(item => item.sDatum === this.dateToString(dtNow));
    if (null != actualMeta) {
      if (actualMeta.hasCasy) {

        dtRounded = this.roundTime(dtNow, true, actualMeta.C_MIN_J);
        minutes = this.calculateMinutes(dtRounded, new Date(actualMeta.sDatum));
      } else {
        minutes = -2;
      }
    }
    const isSuccess = minutes >= 0;
    return { isSuccess, dtNow, iDatum, actualMeta, dtRounded, minutes };
  }

  handleDatePickersChange = async (isToTime, value) => {

    const iDate = this.dateToNumber(value);
    if (iDate === this.dateToNumber(isToTime ? this.state.dtDo : this.state.dtOd)) {
      // hodnota sa nezmenila
    } else {
      const actualMeta = this.zoneTimeList.Items.find(item => item.sDatum === this.dateToString(value));
      // ma spoplatnene hodiny
      let minutes = null != actualMeta && actualMeta.hasCasy ? -1 : -2;

      const nowData = this.getNowData();
      if (nowData.iDatum > iDate) {
        // uplynule dni
        minutes = -2;
      }

      if (isToTime) {
        this.ticketTimeSums = this.state.ECV ? await this.getTicketTimeSums(this.state.I_ZONA, this.state.dtOd, value, [...this.ticketTimeSums], this.state.ECV) : null
        this.parkingLimits = this.state.ECV ? await this.getParkingLimits(this.state.I_ZONA, this.state.dtOd, value, [...this.parkingLimits], this.state.ECV) : null
        this.setState({
          dtDo: value,
          minutesDo: minutes,
        }, () => {
          this.calculateData();
        });
      } else {
        if (nowData.isSuccess && nowData.iDatum === iDate && nowData.minutes > actualMeta.minutesDo) {
          //aktualny den po ukonceni spoplatnenia
          minutes = -2;
        }
        this.ticketTimeSums = this.state.ECV ? await this.getTicketTimeSums(this.state.I_ZONA, value, this.state.dtDo, [...this.ticketTimeSums], this.state.ECV) : null
        this.parkingLimits = this.state.ECV ? await this.getParkingLimits(this.state.I_ZONA, value, this.state.dtDo, [...this.parkingLimits], this.state.ECV) : null
        this.setState({
          dtOd: value,
          minutesOd: minutes,
        }, () => {
          this.calculateData();
        });
      }
    }
  };

  handleModifyTime = async (isToTime, addHour) => {

    let minutes = isToTime ? this.state.minutesDo : this.state.minutesOd;

    const dt = isToTime ? this.state.dtDo : this.state.dtOd;
    const actualItem = this.zoneTimeList.Items.find(item => item.sDatum === this.dateToString(dt));
    const minutesOfDay = this.state.calcData.items.find(x => x.iDatum === this.dateToNumber(dt))?.nDayMinCount ?? 0
    const C_MIN_J_CURRENT = this.getC_MIN_J(dt, minutesOfDay)

    if (actualItem && actualItem.hasCasy) {
      switch (addHour) {
        case 1:
          minutes -= C_MIN_J_CURRENT
          break;
        case 2:
          minutes += C_MIN_J_CURRENT
          break;
        case 3:
          minutes -= 60;
          break;
        case 4:
          minutes += 60;
          break;
        default:
      }

      switch (addHour) {
        case 1:
        case 3:
          if (minutes < actualItem.minutesOd) {
            minutes = actualItem.minutesOd;
          }
          const nowData = this.getNowData();
          if (nowData.isSuccess && nowData.iDatum === this.dateToNumber(dt) && nowData.minutes > minutes) {
            //aktualny den pred aktualnym casom
            minutes = nowData.minutes;
          }
          break;
        case 2:
        case 4:
          if (minutes > actualItem.minutesDo) {
            minutes = actualItem.minutesDo;
          }
          break;
        default:
      }
    } else {
      minutes = -1;
    }

    this.setState({ [isToTime ? "minutesDo" : "minutesOd"]: minutes }, this.calculateData);
  };

  calculateData = () => {
    const calcData = { dtOd: this.state.dtOd, dtDo: this.state.dtDo, nAllMinCount: 0, nAllSumma: 0.0, items: [] };

    const dataOd = this.getData4Data(false);
    const dataDo = this.getData4Data(true);

    calcData.iOd = dataOd.iDate;
    calcData.iDo = dataDo.iDate;
    let errorTextDate = "";
    if (dataOd.isSuccess && dataDo.isSuccess) {
      calcData.dtOd = dataOd.dtDateTime;
      calcData.dtDo = dataDo.dtDateTime;
      if (dataOd.dtDateTime > dataDo.dtDateTime) {
        errorTextDate = this.props.translate('parkingShort.plan.errMess');
      }
      const sour = this.zoneTimeList;
      const sourItems = sour.Items.filter(item => item.iDatum > 0 && item.iDatum >= calcData.iOd && item.iDatum <= calcData.iDo);
      sourItems.forEach(item => {
        if (item.iDatum > 0 && item.iDatum >= calcData.iOd && item.iDatum <= calcData.iDo) {

          let { minutesOd, minutesDo } = item;

          if (item.iDatum === calcData.iOd) {
            if (minutesOd >= 0 && minutesDo >= 0) {
              if (dataOd.minutes > minutesDo) {
                minutesOd = -2;
                minutesDo = -2;
              } else if (minutesOd < dataOd.minutes) {
                minutesOd = dataOd.minutes;
              }
            }
          }
          if (item.iDatum === calcData.iDo) {
            if (minutesOd >= 0 && minutesDo >= 0) {
              if (dataDo.minutes < minutesOd) {
                minutesOd = -2;
                minutesDo = -2;
              } else if (minutesDo > dataDo.minutes) {
                minutesDo = dataDo.minutes;
              }
            }
          }
          let resItem = { dtDatum: item.dtDatum, iDatum: item.iDatum, sOd: item.D_OD, sDo: item.D_DO, nTarifa: item.SK, iLimit: item.C_MIN_J, nTarifa2: item.D_SK2, iLimit2: item.C_MIN_J2, nTarifa3: item.D_SK3, iLimit3: item.C_MIN_J3 };

          resItem = { ...resItem, minutesOd: -1, minutesDo: -1, iMin: 0, nDayMinCount: 0, nDaySumma: 0 };
          if (item.hasCasy) {
            resItem.minutesOd = minutesOd;
            resItem.minutesDo = minutesDo;
            resItem.nDayMinCount = resItem.minutesDo - resItem.minutesOd;
            if (resItem.nDayMinCount > 0) {
              calcData.nAllMinCount += resItem.nDayMinCount;
              const previousTime = this.ticketTimeSums.find(x => this.dateToNumber(new Date(x.DATUM)) === this.dateToNumber(item.dtDatum))?.TICKET_TIME_SUM ?? 0
              resItem.nDaySumma = this.getCena(resItem.nDayMinCount, item, previousTime)
              resItem.nDaySumma = this.getLimitValidatedPrice(resItem.nDaySumma, resItem.dtDatum);
              calcData.nAllSumma += resItem.nDaySumma;
            }
          }
          calcData.items.push(resItem);
        }
      });
    }
    this.setState({ calcData: calcData, isCalcData: !(null == calcData || calcData.nAllMinCount <= 0), errorTextDate });
  }

  getData4Dialog = () => {

    const { showTimeDialog } = this.state;
    const result = { showTimeDialog, dateTitle: null, dateValue: null, timeTitle: null, timeValue: "-", captionOdDo: null, captionCena: null, btnMinutes: null, btnHours: null };

    let isToTime;
    switch (showTimeDialog) {
      case 1:
        isToTime = false;
        result.dateTitle = this.props.translate('parkingShort.plan.dateFrom');
        result.timeTitle = this.props.translate('parkingShort.plan.timeFrom');
        break;
      case 2:
        isToTime = true;
        result.dateTitle = this.props.translate('parkingShort.plan.dateTo');
        result.timeTitle = this.props.translate('parkingShort.plan.timeTo');
        break;
      default:
        return result;
    }

    let data = this.getData4Data(isToTime);
    if (data.minutes === -1) {
      this.initMinutes(isToTime);
      data = this.getData4Data(isToTime);
    }

    const minutes = this.state.calcData.items.find(x => x.iDatum === this.dateToNumber(data.dtDate))?.nDayMinCount ?? 0
    const C_MIN_J_CURRENT = this.getC_MIN_J(data.dtDate, minutes)

    if (data.isSuccess) {

      result.dateValue = `${('00' + data.dtDate?.getDate()).slice(-2)}.${('00' + (data.dtDate?.getMonth() + 1)).slice(-2)}.${data.dtDate?.getFullYear()}`;
      result.timeValue = this.minutesToString(data.minutes);
      result.captionOdDo = `${this.props.translate('isPaid')} ${this.props.translate('from')} ${data.actualMeta?.D_OD} ${this.props.translate('to')} ${data.actualMeta?.D_DO} ${this.props.translate('hour')}.`;
      result.captionCena = `${data.actualMeta?.SK.toFixed(2).replace('.', ',')}${this.props.translate('parkingShort.infoText2')}${data.actualMeta?.C_MIN_J} min.`;
      result.btnMinutes = `${C_MIN_J_CURRENT} min.`;
      result.btnHours = `1 ${this.props.translate('hour')}`;
    }

    return result;
  }

  getData4Data = (isToTime) => {

    const dtDate = isToTime ? this.state.dtDo : this.state.dtOd;
    const sDate = this.dateToString(dtDate);
    const iDate = this.dateToNumber(dtDate);
    let minutes = isToTime ? this.state.minutesDo : this.state.minutesOd;

    let actualMeta = null;
    if (this.zoneTimeList && this.zoneTimeList.TotalRecords > 0) {
      actualMeta = this.zoneTimeList.Items.find(item => item.sDatum === sDate);
      if (!(actualMeta && actualMeta.hasCasy)) {
        minutes = -2;
      }
    } else {
      minutes = -2;
    }
    const isSuccess = minutes >= 0;
    let dtDateTime = null;
    if (isSuccess) {
      dtDateTime = this.setHourAndMinutes(sDate, this.minutesToString(minutes));
    }

    return { dtDate, sDate, iDate, minutes, actualMeta, isSuccess, dtDateTime };
  }

  initMinutes = (isToTime) => {

    const dt = isToTime ? this.state.dtDo : this.state.dtOd;
    const actualItem = this.zoneTimeList.Items.find(item => item.sDatum === this.dateToString(dt));

    let minutes = -2;
    if (null != actualItem && actualItem.hasCasy) {
      const nowData = this.getNowData();
      if (actualItem.iDatum >= nowData.iDatum) {

        minutes = actualItem.minutesOd;

        if (nowData.isSuccess && actualItem.iDatum === nowData.iDatum && nowData.minutes > minutes) {
          //aktualny den pred aktualnym casom
          minutes = nowData.minutes;
        }
      }
    }

    this.setState({ [isToTime ? "minutesDo" : "minutesOd"]: minutes });
  }

  getSumma4Show = () => {
    let { CENA } = this.state;
    if (this.state.tabIndex === 1) {
      CENA = 0.00;
      if (null != this.state.calcData) {
        CENA = this.state.calcData.nAllSumma;
      }
    }
    return `${CENA.toFixed(2)}`.replace('.', ',');
  }

  getMinutes4Show = () => {
    let { HOUR, MINUTES } = this.state;
    if (this.state.tabIndex === 1) {
      HOUR = 0;
      MINUTES = 0;
      if (null != this.state.calcData) {
        MINUTES = this.state.calcData.nAllMinCount;
        HOUR = Math.floor(MINUTES / 60);
        MINUTES -= HOUR * 60;
      }
    }
    return `${HOUR ? HOUR.toString().padStart(2, 0) : '00'}:${MINUTES ? MINUTES.toString().padStart(2, 0) : '00'}`;
  }

  minutesToString = (value) => {
    if (null != value && value >= 0) {
      const h = Math.floor(value / 60);
      const m = value - h * 60;

      return `${('00' + (h)).slice(-2)}:${('00' + m).slice(-2)}`;
    } else {
      return "-";
    }
  }

  dateToString = (dt) => `${dt.getFullYear()}-${('00' + (dt.getMonth() + 1)).slice(-2)}-${('00' + dt.getDate()).slice(-2)}`;

  dateToNumber = (dt) => Number(`${dt.getFullYear()}${('00' + (dt.getMonth() + 1)).slice(-2)}${('00' + dt.getDate()).slice(-2)}`);

  calculateMinutes = (dt, dt2) => (dt.getDate() - dt2.getDate()) * 24 * 60 + dt.getHours() * 60 + dt.getMinutes();

  render() {
    const { classes } = this.props;

    return (
      <div className='container w-padding' style={{ marginTop: '70px' }}>
        <CircularProgress loading={this.props.isLoadingCheck || this.state.isLoad} />
        <div className={classNames(classes.row, classes.w100, classes.alignItemsCenter, classes.mt3)} >
          <IconButton
            className={classNames(classes.p0)}
            color="inherit"
            onClick={this.handleBack}
            toolTip={this.props.translate('back')}
            toolTipPlace="right"
            disableHover
            disableRipple
          >
            <ArrowBack
              color='secondary'
              variant='fab'
              style={{ fontSize: 30 }}
            />
          </IconButton>
          <div >
            <SelectFilterField
              floatingLabelText="Výber jazyka"
              required={true}
              value={this.state.LANGUAGE}
              onChange={this.handleChange}
              fullwidth={true}
              showIcon={true}
              iconStyle={{ height: '20px', marginRight: '5px' }}
              schema={{ label: 'N_LANGUAGE', value: 'LANGUAGE', icon: 'ICON' }}
              data={_listLanguage}
              name='LANGUAGE'
            />
          </div>
        </div>
        <Typography variant="h6" className={classNames(classes.mt3, classes.mb3)}>
          {this.props.translate('parkingShort.title')}
        </Typography>
        <div className={classNames(classes.row, classes.mb3)}>
          <SelectField
            disabled={_Zona && !_Ecv ? true : false}
            errorText={this.state.errorTextZona}
            error={this.state.errorTextZona ? true : false}
            floatingLabelText={this.props.translate('zone')}
            value={this.state.I_ZONA}
            onChange={this.handleChange}
            fullwidth={true}
            schema={{ label: 'N_ZONA', value: 'I_ZONA' }}
            data={this.props.listZone}
            name='I_ZONA'
            icon={<PlaceIcon />}
            required
          />
          {this.props.adminMode.isActive &&
            <ButtonFab
              toolTip="Zoznam zón bez nároku na bezplatné parkovanie"
              toolTipPlace="right-start"
              style={{ backgroundColor: '#ffffff', flexShrink: 0, marginRight: '10px' }}
              size="small"
              onClick={this.handleOpenFreeParkingZonesDialog}
            >
              <EditIcon />
            </ButtonFab>}
          {this.state.visibleMapButton && (
            <ButtonFab
              style={{ backgroundColor: '#ffffff', flexShrink: 0 }}
              size="small"
              disabled={this.state.mapButtonDisabled}
              onClick={this.handleMap}>
              <MapIcon />
            </ButtonFab>
          )}
        </div>
        <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb3)}>
          <TextField
            label={this.props.translate('ECV')}
            fullwidth={true}
            value={this.state.ECV}
            errorText={this.state.errorTextEcv}
            error={this.state.errorTextEcv ? true : false}
            onChange={this.handleChange}
            name='ECV'
            onBlur={() => this.handleECVBlur()}
            imputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ marginLeft: '-2px' }}>
                  <CarIcon />
                </InputAdornment>
              ),
            }}
          />
          {this.props.isAuth && (
            <ButtonFab
              style={{ backgroundColor: '#ffffff', flexShrink: 0 }}
              size="small"
              disabled={this.state.mapButtonDisabled}
              onClick={() => this.props.ECVList ? this.setState({ isECVDialogOpen: true }) : this.setState(state => ({ isAddECVDialogOpen: true, newCarECV: state.ECV }))}>
              <DirectionsCarOutlined />
            </ButtonFab>
          )}
        </div>
        <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb3)}>
          <TextField
            type="email"
            label={this.props.translate('parkingShort.email')}
            fullwidth={true}
            value={this.state.EMAIL}
            errorText={this.state.errorTextEmail}
            error={this.state.errorTextEmail ? true : false}
            onChange={this.handleChange}
            name='EMAIL'
            imputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ marginLeft: '-2px' }}>
                  <EmailIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb3)}>
          <SwitchComponent
            onLabel={this.props.translate('parkingShort.modeNow')}
            offLabel={this.props.translate('parkingShort.modePlan')}
            value={this.state.tabIndex}
            onChange={this.handleSwitchChange}
          />
        </div>
        {this.state.tabIndex === 0 &&
          <div>
            {this.state.showInfo &&
              <div className={classes.textCenter}>
                <Typography variant="caption" className={classNames(classes.textCenter)}  >{this.state.infoText}</Typography>
                <Typography variant="caption" className={classNames(classes.textCenter)}  >{this.state.infoText2}</Typography>
              </div>}
            <Typography variant="subtitle1" className={classNames(classes.mb2, classes.mt3, classes.textCenter)} >{this.props.translate('parkingShort.parkingTime')}</Typography>

            <div className={classNames(classes.timeControlHolder, classes.mb3)}>
              <div className={classNames(classes.row, classes.alignItemsCenter)} >
                <ButtonFab
                  disabled={this.state.I_ZONA && this.state.infoText2 ? false : true}
                  style={{ backgroundColor: '#ffffff' }}
                  onClick={_event => this.handleAddTime(1)}
                  size='small'
                >
                  <Typography variant="h5" className={classNames(classes.textCenter)} >-</Typography>
                </ButtonFab>
                <Typography variant="body1" style={{ textAlign: 'center' }} >{this.state.C_MIN_J} min.</Typography>
                <ButtonFab
                  disabled={this.state.I_ZONA && this.state.infoText2 ? false : true}
                  style={{ backgroundColor: '#ffffff' }}
                  onClick={_event => this.handleAddTime(2)}
                  size='small'
                >
                  <Typography variant="h5" className={classNames(classes.textCenter)} >+</Typography>
                </ButtonFab>
              </div>
            </div>
            <div className={classNames(classes.timeControlHolder, classes.mb3)}>
              <div className={classNames(classes.row, classes.alignItemsCenter)} >
                <ButtonFab
                  disabled={this.state.I_ZONA && this.state.infoText2 ? false : true}
                  style={{ backgroundColor: '#ffffff' }}
                  onClick={_event => this.handleAddTime(3)}
                  size='small'
                >
                  <Typography variant="h5" className={classNames(classes.textCenter)} >-</Typography>
                </ButtonFab>
                <Typography variant="body1" style={{ textAlign: 'center' }} >1 {this.props.translate('hour')}</Typography>
                <ButtonFab
                  disabled={this.state.I_ZONA && this.state.infoText2 ? false : true}
                  style={{ backgroundColor: '#ffffff' }}
                  onClick={_event => this.handleAddTime(4)}
                  size="small"
                >
                  <Typography variant="h5" className={classNames(classes.textCenter)} >+</Typography>
                </ButtonFab>
              </div>
            </div>
            <Paper elevation={3} className={classNames(classes.paper, classes.mb3)}>
              <div className={classNames(classes.row, classes.alignItemsCenter)} >
                <Button
                  disabled={this.state.I_ZONA && this.state.infoText2 ? false : true}
                  variant="contained"
                  style={{ backgroundColor: '#ffffff' }}
                  className={classNames(classes.w100, classes.setBtn)}
                  onClick={_event => this.handleAddTime(5)}
                  size='large'
                >
                  {this.props.translate('wholeDay')}
                </Button>
              </div>
            </Paper>
          </div>
        }
        {this.state.tabIndex === 1 &&
          <div>
            <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb3)}>
              <DatePicker
                name='dateOd'
                label={this.props.translate('parkingShort.plan.dateFrom')}
                value={this.state.dtOd == null ? null : this.state.dtOd.toString()}
                onChange={(name, value) => this.handleDatePickersChange(false, value)}
                fullWidth={false}
                clearable={false}
                keyboards={true}
                disabled={!(this.state.isZoneTimeList)}
                disableFuture={false}
                disablePast={true}
                className={classNames(classes.mr4)}
                maxDate={this.props.zoneTimeList?.Items?.slice(-1)[0]['dtDatum']}
              />
              <div className={classNames(classes.mr4)}>
                <TextField
                  name='timeOd'
                  type="text"
                  label={this.props.translate('parkingShort.plan.timeFrom')}
                  value={this.minutesToString(this.state.minutesOd)}
                  disabled={!(this.state.isZoneTimeList && this.state.minutesOd > -2)}
                  fullwidth={false}
                  imputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          disabled={!(this.state.isZoneTimeList && this.state.minutesOd > -2)}
                          disableTooltip={true}
                          size="small"
                          onClick={_event => this.handleShowTimeDialog(1)}
                        >
                          <ScheludeIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb3)}>
              <DatePicker
                name='dateDo'
                label={this.props.translate('parkingShort.plan.dateTo')}
                value={this.state.dtDo == null ? null : this.state.dtDo.toString()}
                onChange={(name, value) => this.handleDatePickersChange(true, value)}
                fullWidth={false}
                clearable={false}
                keyboards={true}
                disabled={!(this.state.isZoneTimeList)}
                disableFuture={false}
                disablePast={true}
                className={classNames(classes.mr4)}
                maxDate={this.props.zoneTimeList?.Items?.slice(-1)[0]['dtDatum']}
              />
              <div className={classNames(classes.mr4)}>
                <TextField
                  name='timeDo'
                  type="text"
                  label={this.props.translate('parkingShort.plan.timeTo')}
                  value={this.minutesToString(this.state.minutesDo)}
                  disabled={!(this.state.isZoneTimeList && this.state.minutesDo > -2)}
                  fullwidth={false}
                  imputProps={{
                    endAdornment: (
                      <InputAdornment>
                        <IconButton
                          disabled={!(this.state.isZoneTimeList && this.state.minutesDo > -2)}
                          disableTooltip={true}
                          size="small"
                          onClick={_event => this.handleShowTimeDialog(2)}
                        >
                          <ScheludeIcon />
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
              </div>
            </div>
            {this.state.errorTextDate &&
              <div>
                <Typography variant="body1" style={{ textAlign: 'center', color: 'red', marginBottom: '10px' }} >{this.state.errorTextDate}</Typography>
              </div>}
          </div>
        }
        <Typography variant="h4" style={{ textAlign: 'center', fontWeight: 'bold' }}>{this.getMinutes4Show()}</Typography>
        {this.state.tabIndex === 0 && this.state.CAS > 0 &&
          <div style={{ marginTop: '10px', textAlign: 'center' }}>
            <Typography variant="caption" className={classNames(classes.textCenter, classes.mb4,)}>{this.state.infoTextParkTo}</Typography>
          </div>
        }
        {this.state.tabIndex === 1 &&
          <div style={{ textAlign: 'center' }}>
            <Typography variant="caption" className={classNames(classes.textCenter, classes.mb4,)}>{this.props.translate('parkingShort.plan.titlePaidTime')}</Typography>
          </div>
        }
        <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4)}  >
          <Button
            disabled={(this.state.tabIndex === 0 && this.state.CAS === 0) || (this.state.tabIndex === 1 && !this.state.isCalcData)}
            variant="contained"
            color="secondary"
            size="large"
            className={classNames(classes.payBtn, classes.w100)}
            onClick={() => this.AddParking(false)}
          >
            {this.props.translate('pay')}   {this.getSumma4Show()} €
          </Button>
        </div>
        <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4)}  >
          {this.props.isAuth &&
            this.props.activeParkingCards?.length > 0 &&
            !this.state.noFreeParkingZoneList?.includes(this.state.I_ZONA) &&
            this.state.tabIndex === 0 &&
            <Button
              disabled={this.state.ECV === '' || this.state.I_ZONA === 0 || this.state.I_ZONA === ""}
              variant="contained"
              color="secondary"
              size="large"
              type="blue"
              className={classNames(classes.setBtn, classes.w100)}
              onClick={() => this.AddParking(true)}
            >
              {this.props.translate('parkingMap.free')}
            </Button>}
        </div>
        <Dialog
          open={this.state.openNotify}
          onClose={this.handleCloseAlert}
          contentText={this.state.notifyText}
          title={this.props.translate('warning')}
          actions={[
            {
              label: this.props.translate('back'),
              color: 'primary',
              onClick: this.handleCloseNotify,
              keyboardFocused: true
            }
          ]}
        />
        <Dialog
          open={this.state.openAlert}
          onClose={this.handleCloseAlert}
          contentText={this.props.translate('parkingShort.dialogContext')}
          title={this.props.translate('warning')}
          actions={[
            {
              label: this.props.translate('back'),
              color: 'primary',
              onClick: this.handleCloseAlert,
              keyboardFocused: true
            },
            {
              label: this.props.translate('continue'),
              color: 'primary',
              onClick: this.AddParkingCheck,
              keyboardFocused: false
            }
          ]}
        />
        <Dialog
          open={this.state.openAlertCheck}
          onClose={this.handleCloseAlertCheck}
          contentText={this.props.translate('parkingShort.dialogContextCheck1') + (this.state.d_do_previous ? convert.convertDateTime(this.state.d_do_previous) : '') + this.props.translate('parkingShort.dialogContextCheck2')}
          title={this.props.translate('warning')}
          actions={[
            {
              label: this.props.translate('back'),
              color: 'primary',
              onClick: this.handleCloseAlertCheck,
              keyboardFocused: true
            },
            {
              label: this.props.translate('extend'),
              color: 'primary',
              onClick: this.AddParkingShort,
              keyboardFocused: false
            }
          ]}
        />
        <TimeDialog
          handleClose={_event => this.handleShowTimeDialog(0)}
          handleModifyTime={this.handleModifyTime}
          data={this.getData4Dialog()}
        />
        <Dialog
          open={this.state.isOpenFreeParkingZonesDialog}
          onClose={this.handleCloseFreeParkingZonesDialog}
          title="Zoznam zón bez nároku na bezplatné parkovanie"
          content={this.getFreeParkingZonesDialogContent()}
          actions={[
            {
              label: "Zavrieť",
              color: "primary",
              onClick: () => this.handleCloseFreeParkingZonesDialog(),
            },
            {
              label: "Uložiť",
              color: "secondary",
              onClick: () => this.handleSaveFreeParkingZones(),
              disabled: false

            },
          ]}
        />
        <Dialog
          open={this.state.isECVDialogOpen}
          onClose={() => this.setState({ isECVDialogOpen: false })}
          title="Moje vozidlá"
          titleClasses={{ root: classNames(classes.textCenter, classes.titlePadding) }}
          actions={[]}
          content={this.getECVDialogContent()}
          contentClasses={{ root: classes.dialogContent }}
          closeButton={<div style={{ textAlign: 'right' }}><IconButton disableTooltip={true} size='small' onClick={() => this.setState({ isECVDialogOpen: false })} ><CloseIcon /></IconButton></div>}
        />
        <Dialog
          open={this.state.isAddECVDialogOpen}
          onClose={() => this.setState({ isAddECVDialogOpen: false, errorTextAddCarEcv: '', errorTextAddCarName: '', newCarECV: '', newCarName: '' })}
          title="Nové vozidlo"
          titleClasses={{ root: classNames(classes.textCenter, classes.titlePadding) }}
          actions={[
            {
              label: "Zrušiť",
              color: "primary",
              onClick: () => this.setState({ isAddECVDialogOpen: false, errorTextAddCarEcv: '', errorTextAddCarName: '', newCarECV: '', newCarName: '' }),
            },
            {
              label: "Uložiť",
              color: "secondary",
              disabled: this.state.errorTextAddCarEcv || this.state.errorTextAddCarName || this.state.newCarECV === '' || this.state.newCarECV.length === '',
              onClick: this.handleAddECV,
            }
          ]}
          content={this.getAddECVDialogContent()}
        />
        <Dialog
          open={this.state.isDeleteECVDialogOpen}
          onClose={this.handleCloseDeleteECVDialog}
          title="Zmazať"
          titleClasses={{ root: classNames(classes.textCenter, classes.titlePadding) }}
          actions={[
            {
              label: "Zrušiť",
              color: "primary",
              onClick: this.handleCloseDeleteECVDialog,
            },
            {
              label: "Zmazať",
              color: "secondary",
              onClick: this.handleDeleteECV,
            }
          ]}
          content={this.getDeleteECVDialogContent()}
        />
      </div >
    );
  };
};

const mapStateToProps = (state) => {
  return {
    listZone: state.listZoneParkShort.data,
    locale: state.locale.locale,
    isAuth: state.user.data !== null,
    user: state.user,
    parkingTicketCheck: state.parkingTicketCheck.data,
    isLoadingCheck: state.parkingTicketCheck.isLoading,
    zoneTimeList: state.zoneTimeList.data.Data,
    ECVList: state.ECVList.data,
    ECVListTotal: state.ECVList.total,
    paymentGatewaySettings: state.paymentGatewaySettings.data.Data,
    parkingLimit: state.parkingLimit.data,
    ticketTimeSums: state.ticketTimeSums.data,
    activeParkingCards: state.listActiveParkingCard.data,
    noFreeParkingZoneList: state.notAvailableFreeParking.data,
    adminMode: state.adminMode,
    person: state.person.data,
  }
}

export default withRouter(withMeta(storage(withLocalizationConsumer(connect(mapStateToProps)(withStyles(styles)(withWidth()(ParkingShort)))))));

