import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, Button } from '@mui/material';

import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';

/**
 * Navigacny baner k zoznamu sluzieb
 *
 * @param {*} { classes, handleClick }
 * @return {*} 
 */
const ZstSlzNavigation = ({ classes, handleClick }) => {
  return (
    <>
      <Button onClick={() => handleClick(1)} title={`Návrat na životné situácie`}>
        <Link >
          <Box className={classNames(classes.navigationButton, classes.navigationBackButton, classes.textCenter)}>
            <ArrowBack />
          </Box>
        </Link>
      </Button>
    </>
  );
};

ZstSlzNavigation.propTypes = {
  classes: PropTypes.object,
  handleClick: PropTypes.func,
}

export default ZstSlzNavigation;