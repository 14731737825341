const routes = {
  HOME: '/',
  PRELOGIN: '/uvod',
  LOGIN: '/prihlasenie',
  LOGINEID: '/prihlasenieeid',
  ACCOUNT: '/account',
  ACCOUNT_PERMIT: '/permit',
  ACCOUNT_PERMIT_SUCCESS: '/permitSuccess',
  ACCOUNT_ADD_PERSON: '/accountAddPerson',
  ACCOUNT_MODIFY: '/accountModify',
  PARKING: '/parking',
  PARKING_PERMIT: '/parking/parkingPermit',
  PARKING_MAP: '/parking/map',
  PARKING_MAP_PLACES: '/parking/parkingPermit/mapPlaces',
  PARKING_MODIFY: '/parking/parkingModify',
  PARKING_CREDIT: '/parking/credit',
  PARKING_CREDIT_DRAW_LIST: '/parking/creditDrawList',
  PARKING_CREDIT_DRAW: '/parking/creditDrawList/creditDraw',
  PARKING_TEMP_ECV: '/parking/tempEcv',
  PARKING_TICKET: '/parkingTicket',
  PAYMENT_PAY: '/payment/pay',
  PAYMENT_GATEWAY: '/payment/gateway',
  PAYMENT_RECIEVED: '/payment/recieved',
  TAXES: '/taxes',
  PZ: '/pz',
  PZ_RP: '/pz/rp',
  PZ_RP_ID: '/pz/rp/:id',
  PZ_RP_DOT: '/pz/rp/dot',
  PZ_MNU_ID: '/pz/mnu/:id',
  PZ_FRM_ID: '/pz/frm/:id',
  PZ_PARKING_SHORT: '/parkovanie',
  PZ_PARKING_CARD: '/pz/parkCard',
  PZ_PARKING_CARD_VERIFY: '/pz/parkCardVerify',
  SUBSIDIES_RATING: '/subsidiesRating',
  ZST: '/zst',
  ZST_DOT: '/zst/dot',
  SP_PRJ: '/form/SpPrj',
  SP_PRJ_PRJ: '/form/IPrj',
  SP_PRJ_UZ: '/form/IPrjSkupUz',
  PZ_ROZP: '/pz/rozp'
}

export default routes;