import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import withRouter from '../../coraWebMComponents/utils/withRouter';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';

import routes from '../../routes';
import * as userActions from '../../actions/userActions';
import * as locatorActions from '../../actions/locatorActions';
import * as locatorUtils from '../../utils/locatorUtils';
import { TextComponent, OptionGroupComponent, ButtonsComponent } from './ZstQuestionnaire';

const _ALLOWED_NUMBER_OF_CHARS = 4000;

/**
 * Spätná väzba na službu
 *
 * @param {*} props
 * @return {*} 
 */
const ZstDtzComponent = (props) => {

  const { classes } = props;

  const [answers, setAnswers] = useState([
    { questId: 1, questText: "Ako ste spokojný so službou, ktorú ste práve využili?", answerValue: "" },
    { questId: 2, questText: "Ako by sme mohli túto službu zlepšiť?", answerValue: "" }
  ]);

  // inicializačné načítanie dát
  useEffect(() => {
    async function fetchData() {

      // zoznam odpovedí
      if (!props.listZstCDtzOtz.isLoaded) {
        await props.getZstCDtzOtzList();
      }
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const handleOnChange = (event, id) => {

    const text = event.target.value;
    if (id === 1 || _ALLOWED_NUMBER_OF_CHARS >= text.length) {
      const obj = answers.map(x => x.questId === id ? { ...x, answerValue: text } : x);
      setAnswers(obj);
    }
  };

  const handleSubmitClick = async () => {
    let result = { isOK: false, message: "Odoslanie dotazníka sa nepodarilo." };
    try {
      props.dispatch(userActions.hideErrorMsg());
      props.dispatch(userActions.hideMsg());
      const { nZstZs, nSlz, nFeForm } = props.metadata;
      const iDtzOtz = Number(answers[0].answerValue);
      const nDtzOtz = props.listZstCDtzOtz.data.find(x => x.IDtzOtz === iDtzOtz).NDtzOtz;
      const res = await locatorUtils.saveZstDtz(nDtzOtz, answers[1].answerValue, nZstZs, nSlz, nFeForm);
      console.debug(`Result: ${res}`);
      if (Boolean(res) && res > 0) {
        result = { isOK: true, message: "Ďakujeme za vyplnenie dotazníka." };
      }
    } catch (error) {
      console.error(error);
    }
    props.navigate(routes.ZST, { state: result });
  };

  const handleCancelClick = () => {
    props.navigate(routes.ZST);
  };

  const isAnswered = answers[0].answerValue.length > 0;

  return (
    <>
      <CircularProgress loading={props.listZstCDtzOtz.isLoading} />
      {props.listZstCDtzOtz.isLoaded && props.listZstCDtzOtz.data.length > 0 &&
        <Paper elevation={5}
          className={classNames(classes.w100, classes.alignItemsCenter, classes.justifyContentLeft, classes.pl3, classes.pr3, classes.pt3, classes.pb3, classes.mt6)}
        >
          <div className={classNames(classes.w100, classes.alignItemsCenter, classes.justifyContentCenter)}>
            <Typography variant={"h5"}>Spätná väzba na službu</Typography>
          </div>
          <OptionGroupComponent classes={classes}
            data={answers[0]}
            onChange={(event) => handleOnChange(event, answers[0].questId)}
            items={props.listZstCDtzOtz.data.map(x => ({ value: x.IDtzOtz, label: x.NDtzOtz }))}
            inRow={false} />
          {isAnswered &&
            <TextComponent classes={classes}
              data={answers[1]}
              onChange={(event) => handleOnChange(event, answers[1].questId)}
              maxChars={_ALLOWED_NUMBER_OF_CHARS}
              rowCount={5} />}
          <ButtonsComponent classes={classes}
            onSubmitClick={handleSubmitClick}
            onCancelClick={handleCancelClick}
            disabledSubmitButton={!isAnswered} />
        </Paper>
      }
    </>
  );
}

ZstDtzComponent.propTypes = {
  metadata: PropTypes.object.isRequired,
}

const mapStateToProps = (state) => ({
  listZstCDtzOtz: state.listZstCDtzOtz,
})

const mapDispatchToProps = (dispatch) => ({
  getZstCDtzOtzList: () => dispatch(locatorActions.getZstCDtzOtzList()),
  dispatch
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(ZstDtzComponent));

