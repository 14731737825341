import React from "react";
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import { alpha } from "@material-ui/core/styles";
import { Box, Stack, Button } from '@mui/material';
import { Link } from "react-router-dom";
import classNames from "classnames";

import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import ArrowBack from "../../coraWebMComponents/dataDisplay/icons/ArrowBack";

import SearchFilter from "./SearchFilter";
import ComboFilter from "./ComboFilter";
import ComboFiltersButton from "./ComboFiltersButton";
import RecordCountInfo from "./RecordCountInfo";
import ExportBtn from "./ExportBtn";
import routes from "../../routes";
import AdminModeOptions from "./AdminModeOptions";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  fhRoot: {
    // position: "relative",
    // display: 'table',
    // margin: `${theme.spacing.unit * 4}px 0 ${theme.spacing.unit * 2}px`,
    // width: `fit-content`,
    // marginHorizontal: `${theme.spacing.unit * 2}px`,
    backgroundColor:
      theme.palette.grey[theme.palette.type === "dark" ? 800 : 200],
  },
  cfButton: {
    backgroundColor: alpha(theme.palette.common.black, 0.25),
    "&:hover": {
      backgroundColor: alpha(theme.palette.common.black, 0.15),
    },
    borderRadius: theme.shape.borderRadius,
  },
  searchTextField: {
    maxWidth: "16em",
  },
});

class FrmHeader extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();
  }

  render() {
    const { classes, primaryField, schema, frmData, frmState, isMobile } = this.props;

    const customUI = schema.Args;
    const comboFilters = customUI.comboFilters.filter(
      (flt) => flt.dataItems && flt.dataItems.length > 0
    );
    const { cboFltValues } = frmState;

    return (
      <Stack justifyContent="space-between" alignItems={"flex-end"} width="100%" spacing={1} direction={'row'} p={1}
        style={{
          // backgroundColor: "#1DE9B6",
          boxShadow: "none",
          position: "sticky ",
          /* rovne vyske titulku v datamesta*/
          top: "65px",
          boxSizing: "border-box",
          zIndex: "2",
        }}
      >
        {/* <EnhancedTable /> */}
        <Stack flexGrow="1" justifyContent="flex-start" spacing={2} direction={'row'}
          style={{
            // backgroundColor: "#80CBC4",
            boxSizing: "border-box"
          }}
        >
          <Button title={`Návrat späť`} size="small" className={classNames(classes.cfButton)}>
            <Link to={this.props.backRoute ? this.props.backRoute : routes.PZ} title={`Návrat späť`}>
              <Box className={classNames(classes.navigationButton, classes.navigationBackButton, classes.textCenter)}
                backgroundColor="transparent">
                <ArrowBack />
              </Box>
            </Link>
          </Button>

          <Stack justifyContent={"center"} alignItems={"center"} className={[classes.searchTextField]} flexGrow={1}>
            <SearchFilter
              searchText={frmState.searchText}
              onClick={this.props.onSearchFilterClick}
            />
          </Stack>

          {!isMobile && (
            <Stack flexGrow="1" justifyContent="flex-start" spacing={2} direction={'row'}>
              {comboFilters && comboFilters.length > 0 && comboFilters.map((flt) => {
                const value = cboFltValues[flt.id];
                return (
                  <ComboFilter
                    fltMeta={flt}
                    fltValue={value}
                    key={`cboFlt${flt.id}`}
                    onClick={() => this.props.onComboFilterClick(flt.id)}
                  />
                );
              })}

              <ExportBtn
                onClick={this.props.onExportBtnClick}
                handleCsvExportBtnClick={this.props.handleCsvExportBtnClick}
                handleJsonExportBtnClick={this.props.handleJsonExportBtnClick}
                postExportData={this.props.postExportData}
              />

              <AdminModeOptions
                classes={classes}
                primaryField={primaryField}
                schema={schema}
                reloadSchema={this.props.reloadSchema}
                isMobile={isMobile} />
            </Stack>
          )}
        </Stack>

        <Stack flexGrow="0" justifyContent="flex-end" spacing={1} direction={'row'} >
          {!isMobile && (
            <RecordCountInfo
              frmData={frmData}
              loadingCount={true}
              onClick={() => this.onEnd(10)}
            />
          )
          }
          {isMobile && comboFilters && comboFilters.length > 0 && (
            <Stack direction={'column'} justifyContent="flex-end" spacing={2} alignItems={"flex-end"}>
              <RecordCountInfo
                frmData={frmData}
                loadingCount={true}
                onClick={() => this.onEnd(10)}
              />
              <ComboFiltersButton
                // className={classes.cfButton}
                comboFilters={comboFilters}
                cboFltValues={cboFltValues}
                onComboFilterClick={this.props.onComboFilterClick}
              />
            </Stack>
          )}
        </Stack>
      </Stack>
    );
  }
}

FrmHeader.propTypes = {
  primaryField: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  frmData: PropTypes.object.isRequired,
  frmState: PropTypes.object.isRequired,
  onComboFilterClick: PropTypes.func.isRequired,
  onSearchFilterClick: PropTypes.func.isRequired,
  handleCsvExportBtnClick: PropTypes.func,
  handleJsonExportBtnClick: PropTypes.func,
  postExportData: PropTypes.func.isRequired,
  isMobile: PropTypes.bool,
  backRoute: PropTypes.string,
  reloadSchema: PropTypes.func,
};

export default withStyles(styles)(FrmHeader);
