import * as requests from '../coraWebMComponents/utils/requests';
import * as convert from '../coraWebMComponents/utils/convert';

const buildGetListParkingCardFilter = (io, iKartaStav = '') => {

  if (iKartaStav === 0)
    iKartaStav = '';

  return { filters: [{ field: "I_O", operator: "in", value: io }, { field: "I_KARTA_STAV", operator: "in", value: iKartaStav }] };
}

export const processFilter = (zone, ecv, dOd, dDo) => {
  let filters = [];
  if (zone)
    filters = [...filters, { field: "I_ZONA", operator: "in", value: zone }];

  if (ecv)
    filters = [...filters, { field: "ECV", operator: "contains", value: ecv }];

  if (dOd)
    filters = [...filters, { field: "D_OD", operator: ">=", value: new Date(dOd) }];

  if (dDo)
    filters = [...filters, { field: "D_DO", operator: "<=", value: new Date(dDo) }];

  const processedFilter = { filters: filters.map((x) => requests.processFilter(x)) };
  return processedFilter;
}

export const getListParkingCard = async (io, iKartaStav) => {
  try {
    let filter = buildGetListParkingCardFilter(io, iKartaStav);
    let page = { skip: 1, take: 1000 };
    return await requests.getList('/api/parking/parkingCard', filter, '', page);

  }
  catch (error) {
    throw error;
  }
}

export const getListValidParkingCard = async (io) => {
  try {
    return await requests.get(`/api/parking/validParkingCard/${io}`);
  }
  catch (error) {
    throw error;
  }
}

export const getParkingCard = async (iKarta) => {
  try {
    return await requests.get(`/api/parking/parkingCard/${iKarta}`);
  }
  catch (error) {
    throw error;
  }
}

const buildParkingPermitUrl = (id = '') => {
  return `/api/parking/parkingPermit/${id}`;
}

export const addParkingPermit = async (parkingPermit) => {
  const url = buildParkingPermitUrl();
  try {
    return await requests.post(url, parkingPermit);
  }
  catch (error) {
    throw error;
  }
}

export const getParkingPermitsList = async (id) => {
  const url = `/api/parking/parkingPermit/list/${id}`;
  try {
    return await requests.get(url);
  }
  catch (error) {
    throw error;
  }
}

export const addParkingModify = async (parkingModify) => {
  try {
    return await requests.post('/api/parking/parkingPermit/modify', parkingModify);
  }
  catch (error) {
    throw error;
  }
}

export const addParkingExtend = async (idKarta, attachments) => {
  try {
    return await requests.post(`/api/parking/parkingPermit/${idKarta}/extend`, attachments);
  }
  catch (error) {
    throw error;
  }
}

export const cancelParkingPermit = async (id) => {
  const url = buildParkingPermitUrl(id);
  try {
    await requests.cancel(url);
  }
  catch (error) {
    throw error;
  }
}

export const getBySquare = async (iban, vs, amount, recipient) => {
  const i = iban.replace(/\s/g, '');
  try {
    const url = `/api/parking/bysquare?iban=${i}&vs=${vs}&amount=${amount}&recipient=${recipient}`
    return await requests.get(url);
  }
  catch (error) {
    throw error;
  }
}

export const getListParkingCredit = async (iKartaTyp, iKarta) => {
  try {
    let page = { skip: 1, take: 1000 };
    return await requests.getList(`/api/parking/parkingCreditList/${iKarta}`, { filters: [{ field: "I_KARTA_TYP", operator: "in", value: iKartaTyp }] }, '', page);

  }
  catch (error) {
    throw error;
  }
}

export const getParkingCredit = async (iKredit) => {
  try {
    return await requests.get(`/api/parking/parkingCredit/${iKredit}`);
  }
  catch (error) {
    throw error;
  }
}

export const getListCreditDraw = async (iKarta, iKredit) => {
  try {
    return await requests.get(`/api/parking/creditDraw?iKarta=${iKarta}&iKredit=${iKredit}`)
  }
  catch (error) {
    throw error;
  }
}

const processCreditDraw = (data) => {
  data.D_OD = convert.convertDateTime(data.D_OD);
  data.D_DO = convert.convertDateTime(data.D_DO);

  return data;
}

export const getCreditDraw = async (iKreditCerp) => {
  try {
    let res = await requests.get(`/api/parking/creditDraw/${iKreditCerp}`);
    res.Data = processCreditDraw(res.Data);
    return res;
  }
  catch (error) {
    throw error;
  }
}

export const addDraw = async (draw) => {
  try {
    let body = {
      I_KARTA: draw.I_KARTA,
      I_KARTA_AKTUAL: draw.I_KARTA_AKTUAL,
      I_KREDIT: draw.I_KREDIT,
      I_ZONA: draw.I_ZONA,
      ECV: draw.ECV,
      D_OD: draw.D_OD,
      D_DO: draw.D_DO,
    }

    return await requests.post('/api/parking/creditDraw', body);
  }
  catch (error) {
    throw error;
  }
}

export const cancelDraw = async (iKreditCerp) => {
  try {
    return await requests.cancel(`/api/parking/creditDraw/${iKreditCerp}`);
  }
  catch (error) {
    throw error;
  }
}

export const getListTempEcv = async (iKarta) => {
  try {
    let page = { skip: 1, take: 1000 };
    return await requests.getList('/api/parking/tempEcv', { filters: [{ field: "I_KARTA", operator: "in", value: iKarta }] }, '', page);
  }
  catch (error) {
    throw error;
  }
}

export const addTempEcv = async (iKarta, eCv, iO, dOd, dDo) => {
  try {
    let body = {
      I_KARTA_VOZ: 0,
      I_KARTA: iKarta,
      I_O: iO,
      ECV: eCv,
      D_OD: dOd,
      D_DO: dDo,
    }
    return await requests.post('/api/parking/tempEcv/', body);
  }
  catch (error) {
    throw error;
  }
}

export const cancelTempEcv = async (iKarta) => {
  try {
    return await requests.cancel(`/api/parking/tempEcv/${iKarta}`);
  }
  catch (error) {
    throw error;
  }
}


export const unikParkingPermit = async (parkingPermit) => {
  try {
    return await requests.post('/api/parking/parkingPermitUnik', parkingPermit);
  }
  catch (error) {
    throw error;
  }
}

export const unikParkingModify = async (iKarta) => {
  try {
    return await requests.get(`/api/parking/parkingModifyUnik/${iKarta}`);
  }
  catch (error) {
    throw error;
  }
}

export const getParkingTickets = async (zone, ecv, dOd, dDo, page) => {
  try {
    const processedFilter = processFilter(zone, ecv, dOd, dDo)
    return await requests.get(`/api/parking/parkingShort?filter=${JSON.stringify(processedFilter)}&page=${page}`);
  }
  catch (error) {
    throw error;
  }
}

export const getParkZones = async () => {
  try {
    return await requests.get('/api/parking/map/parkZone');
  }
  catch (error) {
    throw error;
  }
}

export const getParkZoneTimes = async (iZona) => {
  try {
    return await requests.get(`/api/parking/map/zoneTime?iZona=${iZona}`);
  }
  catch (error) {
    throw error;
  }
}

export const getParkPlaces = async () => {
  try {
    return await requests.get('/api/parking/map/parkPlaces');
  }
  catch (error) {
    throw error;
  }
}

export const getECVList = async () => {
  try {
    return await requests.get('/api/parking/ECVList');
  }
  catch (error) {
    throw error;
  }
}

export const addECV = async (ECV, N_VOZ) => {
  try {
    let body = {
      ECV: ECV,
      N_VOZ: N_VOZ,
    }
    return await requests.post('/api/parking/addECV', body);
  }
  catch (error) {
    throw error;
  }
}

export const deleteECV = async (I_ECV_UZ) => {
  try {
    return await requests.del('/api/parking/deleteECV/' + I_ECV_UZ);
  }
  catch (error) {
    throw error;
  }
}

export const getActiveParkingCard = async (iO) => {
  try {
    return await requests.get(`/api/parking/activeParkCard/${iO}`);
  }
  catch (error) {
    throw error;
  }
}

export const addParkingShortFree = async (iKartaTyp, parkingShort) => {
  try {
    let body = {
      I_DOC_PARK: 0,
      ECV: parkingShort.ECV,
      D_OD: parkingShort.D_OD,
      D_DO: parkingShort.D_DO,
      I_ZONA: parkingShort.I_ZONA,
      N_ZONA: '',
      CENA: parkingShort.CENA,
      EMAIL: parkingShort.EMAIL,
      POZN: parkingShort.LANGUAGE === 'sk-SK' ? '' : `language:${parkingShort.LANGUAGE}`
    }

    return await requests.post(`/api/parking/parkingShortFree/${iKartaTyp}`, body);
  }
  catch (error) {
    throw error;
  }
} 