import React from "react";
import PropTypes from "prop-types";
import _ from "lodash";
import { connect } from "react-redux";

import withMeta, { getValueField } from "../../coraWebMComponents/utils/withMeta";
import { fieldFormat } from "../../coraWebMComponents/utils/helper";
import CoraError from "../../coraWebMComponents/utils/coraError";
import * as requestsNoAuth from "../../utils/publicZone/requestsNoAuth";

import metaReducer from "../../meta/reducer";
import Frm from "./Frm";

class MetaFrmComponent extends React.Component {
  constructor(props) {
    super(props);
    this.ref = React.createRef();

    const primaryField = props.primaryField;
    this.state = {
      primaryField,
      isSchemaLoading: true,
      schema: null,
      [primaryField]: {
        isLoading: false,
        data: [],
        hasMore: true,
        total: 0,
      },
    };
  }

  getSchemaNoAuth = async (primaryField, db = null) => {
    let url = `/api/nam/schema/${primaryField}`;
    if (db) url = url + `?db=${db}`;

    try {
      let res = await requestsNoAuth.get(url);
      const schemaDet = res.Data.Detail.map(item => { item.format = fieldFormat(item.format); return item; });
      res.Data.Detail = getValueField(schemaDet);
      res.Data.Grid = getValueField(res.Data.Grid);
      return res.Data;
    }
    catch (error) {
      if (error instanceof CoraError && error.code === "503") {
        await this.props.meta.checkAppOffline();
      }
      return null;
    }
  }

  getListNoAuth = async (primaryField, filter, sort, page, db = null) => {
    const url = `/api/nam/form/${primaryField}`;
    const args = db ? { db } : null;
    try {
      let res = await requestsNoAuth.getList(url, filter, sort, page, args);
      return res;
    }
    catch (error) {
      if (error instanceof CoraError && error.code === "503") {
        await this.props.meta.checkAppOffline();
      }
      return { Data: { Items: [], TotalRecords: 0 } };
    }
  }

  /**
   * Asynchronné vygenerovanie exportu
   * @function postPrint
   * @param {string} primaryField Identifikátor formulára
   * @param {object} filter Filter
   * @param {object} sort Triedenie
   * @param {object} fileType Typ výstupu xlsx/pdf
   * @param {object} schema Schema formulára
   * @param {object} db DB schéma
   * @returns {Object} Vygenerovaný export blob + filename
   */
  postPrintNoAuth = async (primaryField, filter, sort, fileType, schema, db = null) => {
    const url = `/api/nam/print/${primaryField}`;
    try {
      let res = await requestsNoAuth.postPrint(url, filter, sort, fileType, schema, db);
      return res;
    }
    catch (error) {
      if (error instanceof CoraError && error.code === "503") {
        await this.props.meta.checkAppOffline();
      }
    }
  }

  /** Znovu načíta objekt schema po zmenách v admin mode */
  reloadSchema = async (_data) => {
    this.setState({ isSchemaLoading: true });
    const schema = await this.getSchemaNoAuth(this.state.primaryField);
    this.setState({ ...this.state, isSchemaLoading: false, schema });
  }

  /** Načítanie záznamov zo servera  */
  getFrmData = async (filter, sort, take = 5, reset = false) => {
    const { primaryField } = this.state;

    try {
      let frmData = this.state[primaryField];
      if (reset) {
        frmData.data = [];
        frmData.total = 0;
        frmData.hasMore = true;
      }
      frmData.isLoading = true;
      this.setState((_state) => ({ [primaryField]: frmData }));

      let { data, total, hasMore } = frmData;

      const flt = this.copyFilter(filter);

      const res = await this.getListNoAuth(primaryField, flt, sort, {
        skip: data.length,
        take,
      });

      if (total !== res.Data.TotalRecords) {
        total = res.Data.TotalRecords;
      }

      data = frmData.data.concat(res.Data.Items);
      hasMore = total > 0 && total > data.length;

      frmData = {
        [primaryField]: {
          ...this.state[primaryField],
          isLoading: false,
          data,
          total,
          hasMore,
        },
      };

      const reducerState = await this.getReducerState("GET_LIST", {}, frmData);

      await this.setStateAsync((_s) => reducerState);

      this.props.getListSuccess(res);
    } catch (error) {
      this.setState((state) => ({
        [primaryField]: {
          ...state[primaryField],
          isLoading: false,
          data: [],
          total: 0,
        },
      }));
      this.props.getListFailure(error);
    }
  };

  setStateAsync = (fnc) => {
    return new Promise((resolve) => {
      this.setState(fnc, resolve);
    });
  };

  getReducerState = async (action, data, state) => {
    const { reducer } = this.props;
    const reducerState = await metaReducer(
      action,
      { ...data, obj: this.getObj() },
      { ...state, primaryField: this.state.primaryField },
      this.state,
      reducer
    );
    return reducerState;
  };

  processReducer = async (action, data) => {
    const state = await this.getReducerState(
      action,
      data,
      _.cloneDeep(this.state)
    );
    await this.setStateAsync((_s) => state);
  };

  /** Úprava hodnoty filtra ak je to potrebné (napr. dátumy) */
  copyFilter = (filter) => {
    return {
      ...filter,
      filters: filter.filters.map((x) => {
        if (x.value instanceof Date) {
          return {
            ...x,
            value: new Date(x.value.getTime()),
          };
        }

        return x;
      }),
    };
  };

  getObj = (_akc, _isDetailAkc = false) => {
    return {
      processReducer: this.processReducer,
      getList: this.getFrmData,
      filter: this.state.filter,
      sort: this.state.sort,
      page: this.page,
      ref: this.ref,
      self: this,
    };
  };

  async componentDidMount() {
    const schema = await this.getSchemaNoAuth(this.state.primaryField);
    this.setState({ ...this.state, isSchemaLoading: false, schema });
  }

  render() {
    if (!this.state.schema) {
      return null;
    }
    const { primaryField, schema } = this.state;
    const frmData = this.state[primaryField];

    return (
      <Frm
        primaryField={primaryField}
        schema={schema}
        frmData={frmData}
        getFrmData={this.getFrmData}
        postExportData={this.postPrintNoAuth}
        backRoute={this.props.backRoute}
        reloadSchema={this.reloadSchema}
      />
    );
  }
}

MetaFrmComponent.propTypes = {
  primaryField: PropTypes.string,
  backRoute: PropTypes.string,
};

const mapStateToProps = (_state) => ({});

const mapDispatchToProps = (dispatch) => ({
  getListSuccess: (res) => dispatch({ type: "GET_LIST_SUCCESS", res }),
  getListFailure: (error) => dispatch({ type: "GET_LIST_FAILURE", error }),
  showMsg: (msg, msgType = "ok") => dispatch({ type: "SHOW_MSG", msg, msgType }),
});

export default withMeta(connect(mapStateToProps, mapDispatchToProps)(MetaFrmComponent));