import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import classNames from 'classnames';
import { Box, Button } from '@mui/material';

import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';

/**
 * Navigacny baner k detailu sluzby
 *
 * @param {*} { classes, handleClick }
 * @return {*} 
 */
const ZstSlzDetailNavigation = ({ classes, handleClick }) => {
  return (
    <>
      <Button  onClick={() => handleClick(2)} title={`Návrat na výber služby`}>
        {/* <Link> */}
        <Box className={classNames(classes.navigationButton, classes.navigationBackButton, classes.textCenter)}>
          <ArrowBack />
        </Box>
        {/* </Link> */}
      </Button>
    </>
  );
};

ZstSlzDetailNavigation.propTypes = {
  classes: PropTypes.object,
  handleClick: PropTypes.func,
}

export default ZstSlzDetailNavigation;