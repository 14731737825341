import React from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import withWidth from "@material-ui/core/withWidth";
import { withStyles } from "@material-ui/core/styles";

import getSharedStyles from "../../coraWebMComponents/sharedStyles";

import OptionsButtonAdmin from "./OptionsButtonAdmin";
import AdminModeSpFrmTxt from "./AdminModeSpFrmTxt";
import AdminModeSpColumns from "./AdminModeSpColumns";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  root: {
    borderBottom: `1px solid ${theme.palette.divider}`,
    margin: 0,
    padding: theme.spacing.unit * 2,
  },
  closeButton: {
    position: "absolute",
    right: theme.spacing.unit,
    top: theme.spacing.unit,
    color: theme.palette.grey[500],
  },
});

/**
 * Vygeneruje tlacidlo a k nemu menu s polozkamy pre editaciu v admin mode
 *
 * @class AdminModeOptionsComponent
 * @extends {React.Component}
 */
class AdminModeOptionsComponent extends React.Component {
  menuItems = [
    { id: "spFrmTxt", text: "Zmena textu nad formulárom" },
    { id: "spFrmCols", text: "Zmena položiek formulára" },
  ];

  constructor(props) {
    super(props);
    this.state = {
      frmType: null,
    };
  }

  /** Udalosť Otvorenie */
  handleDialogOpen = (frmType) => {
    this.setState({ frmType });
  };

  handleDialogClose = () => {
    this.setState({ frmType: null });
  };

  render() {
    const { _classes, primaryField, isMobile, schema } = this.props;
    const befTxt = schema.Args.befTxt;

    if (!isMobile && this.props.adminMode.isActive) {
      const { frmType } = this.state;

      const dialogParams = {};
      const items = this.menuItems.map((x) => {
        dialogParams[x.id] = {
          id: x.id,
          isOpen: x.id === frmType,
          title: x.text,
        };
        return { itemText: x.text, onClick: () => this.handleDialogOpen(x.id) };
      });

      return (
        <>
          <OptionsButtonAdmin items={items} />

          {dialogParams["spFrmTxt"].isOpen && (
            <AdminModeSpFrmTxt
              params={dialogParams["spFrmTxt"]}
              onClose={this.handleDialogClose}
              befTxt={befTxt}
              reloadSchema={this.props.reloadSchema}
            />
          )}

          {dialogParams["spFrmCols"].isOpen && (
            <AdminModeSpColumns
              primaryField={primaryField}
              params={dialogParams["spFrmCols"]}
              onClose={this.handleDialogClose}
              reloadSchema={this.props.reloadSchema}
            />
          )}
        </>
      );
    } else {
      return "";
    }
  }
}

AdminModeOptionsComponent.propTypes = {
  _classes: PropTypes.object,
  primaryField: PropTypes.string.isRequired,
  schema: PropTypes.object.isRequired,
  reloadSchema: PropTypes.func,
};

const mapStateToProps = (state) => {
  return {
    adminMode: state.adminMode,
  };
};

export default withWidth()(
  connect(mapStateToProps)(withStyles(styles)(AdminModeOptionsComponent))
);
