import React from 'react';
import { connect } from 'react-redux';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import { withStyles } from '@material-ui/core/styles';
import getSharedStyles from '../../coraWebMComponents/sharedStyles'
import classNames from 'classnames';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import ScheludeIcon from '../../coraWebMComponents/dataDisplay/icons/ScheludeIcon';
import ScheludeTwoToneIcon from '../../coraWebMComponents/dataDisplay/icons/ScheludeTwoToneIcon';
import CarIcon from '../../coraWebMComponents/dataDisplay/icons/CarIcon';
import PlaceIcon from '../../coraWebMComponents/dataDisplay/icons/PlaceIcon';
import AlarmOnIcon from '../../coraWebMComponents/dataDisplay/icons/AlarmOnIcon';
import TimerIcon from '../../coraWebMComponents/dataDisplay/icons/TimerIcon';
import DeleteIcon from '../../coraWebMComponents/dataDisplay/icons/DeleteIcon';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import * as parkingActions from '../../actions/parkingActions';
import * as userActions from '../../actions/userActions';
import withRouter from '../../coraWebMComponents/utils/withRouter';


const styles = theme => ({
  ...getSharedStyles(theme),
  addButton: {
    borderRadius: '7px'
  }
})

let _iKreditCerp;

class CreditDraw extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      isLoad: false,
      openAlert: false,
    };
  }

  componentDidMount = async () => {
    const search = window.location.href.split('?').pop();
    const params = new URLSearchParams(search);
    _iKreditCerp = params.get("iKreditCerp");

    await this.props.dispatch(parkingActions.getCreditDraw(_iKreditCerp));
  }


  handleBack = () => {
    this.props.navigate(-1);
  }

  handleOpenAlert = () => {
    this.setState({ openAlert: true });
  };

  handleCloseAlert = () => {
    this.setState({ openAlert: false });
  };


  handleCancelDraw = async () => {
    this.setState({ openAlert: false, isLoad: true });
    const res = await this.props.dispatch(parkingActions.cancelDraw(this.props.creditDraw.I_KREDIT_CERP));

    if (res)
      await this.props.dispatch(parkingActions.getListCreditDraw(this.props.creditDraw.I_KARTA, this.props.creditDraw.I_KREDIT));

    this.setState({ isLoad: false });

    if (res) {
      this.props.dispatch(userActions.showMsg("Čerpanie kreditu bolo zrušené."));
      this.props.navigate(-1);
    }
    else
      this.props.dispatch(userActions.showErrorMsg("Čerpanie kreditu sa nepodarilo zrušiť!"));
  }


  render() {
    const { classes, creditDraw } = this.props;
    return (
      <div>
        {creditDraw &&
          <div className='container' style={{ marginTop: '70px', marginBottom: '20px' }}>
            <CircularProgress loading={this.props.isLoading || this.state.isLoad} />
            <IconButton
              className={classNames(classes.backButton, classes.ml1, classes.mt4)}
              color="inherit"
              onClick={this.handleBack}
              toolTip="Späť"
              toolTipPlace="right"
            >
              <ArrowBack
                color='secondary'
                variant='fab'
                style={{ fontSize: 30 }}
              />
            </IconButton>
            <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
              <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml4)}>
                <TimerIcon fontSize='large' style={{ marginRight: '12px' }} />
                <Typography variant='body1' >Kredit</Typography>
              </div>
              <Typography variant='body2' className={classNames(classes.mr4)}>{creditDraw.N_KREDIT_TYP}</Typography>
            </div>
            <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
              <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml4)}>
                <CarIcon fontSize='large' style={{ marginRight: '12px' }} />
                <Typography variant='body1' >EČV</Typography>
              </div>
              <Typography variant='body2' className={classNames(classes.mr4)}>{creditDraw.ECV}</Typography>
            </div>
            <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
              <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml4)}>
                <ScheludeIcon fontSize='large' style={{ marginRight: '12px' }} />
                <Typography variant='body1' >Od</Typography>
              </div>
              <Typography variant='body2' className={classNames(classes.mr4)}>{creditDraw.D_OD}</Typography>
            </div>
            <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
              <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml4)}>
                <ScheludeTwoToneIcon fontSize='large' style={{ marginRight: '12px' }} />
                <Typography variant="body1" >Do</Typography>
              </div>
              <Typography variant="body2" className={classNames(classes.mr4)}>{creditDraw.D_DO}</Typography>
            </div>
            <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
              <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml4)}>
                <PlaceIcon fontSize='large' style={{ marginRight: '12px' }} />
                <Typography variant="body1" >Zóna</Typography>
              </div>
              <Typography variant="body2" className={classNames(classes.mr4)}>{creditDraw.N_ZONA}</Typography>
            </div>
            <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
              <div className={classNames(classes.row, classes.alignItemsCenter, classes.ml4)}>
                <AlarmOnIcon fontSize='large' style={{ marginRight: '12px' }} />
                <Typography variant="body1" >Zarátané</Typography>
              </div>
              <Typography variant="body2" className={classNames(classes.mr4)}>{creditDraw.C_CERP} {creditDraw.N_MJ ? creditDraw.N_MJ : 'hod.'}</Typography>
            </div>

            {creditDraw.L_DEL === 1 &&
              <div className={classNames(classes.w100, classes.alignItemsCenter, classes.row, classes.mt3)}  >
                <div></div>
                <IconButton
                  className={classNames(classes.backButton, classes.mr4)}
                  color="inherit"
                  onClick={this.handleOpenAlert}
                  toolTip="Zrušiť čerpanie kreditu"
                  toolTipPlace="left"
                >
                  <DeleteIcon fontSize='large' />
                </IconButton>
              </div>
            }
            <Dialog
              open={this.state.openAlert}
              onClose={this.handleCloseAlert}
              contentText='Prajete si zrušiť čerpanie kreditu?'
              title='Otázka'
              actions={[
                {
                  label: 'Nie',
                  color: 'primary',
                  onClick: this.handleCloseAlert,
                  keyboardFocused: true
                },
                {
                  label: 'Áno',
                  color: 'primary',
                  onClick: this.handleCancelDraw,
                  keyboardFocused: false
                }
              ]}
            />
          </div >
        }</div>
    );
  };
};

const mapStateToProps = (state) => {
  return {
    creditDraw: state.creditDraw.data.Data,
    isLoading: state.creditDraw.isLoading,
  }
}

export default connect(mapStateToProps)(withRouter(withStyles(styles)(CreditDraw)));



