import { Link } from "react-router-dom";
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import classNames from 'classnames';

import Typography from '../../coraWebMComponents/dataDisplay/Typography';

/**
 * Baner - link na dotaznik spokojnosti
 *
 * @param {*} props
 * @return {*} 
 */
const ZstDotaznik = (props) => {
  const { classes } = props;
  return (
    <Box>
      {props.listZstZs.data.length > 0 &&
        <Link
          component="button"
          variant="caption"
          onClick={props.dotClick}
          className={classNames(classes.textDecorationNone)}
        >
          <Typography variant="caption" className={classNames(classes.uxTextCaption)}>
            Dotazník spokojnosti k lokátoru služieb
          </Typography>
        </Link>
      }
    </Box>
  );
};

ZstDotaznik.propTypes = {
  classes: PropTypes.object,
  listZstZs: PropTypes.object,
  dotClick: PropTypes.func,
}

export default ZstDotaznik;