import React from 'react';
import { connect } from 'react-redux';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import withRouter from '../../coraWebMComponents/utils/withRouter';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import * as convert from '../../coraWebMComponents/utils/convert';
import { withStyles } from '@material-ui/core/styles';
import * as ciselnikyActions from "../../actions/ciselnikyActions";
import getSharedStyles from '../../coraWebMComponents/sharedStyles'
import classNames from 'classnames';
import withWidth from '@material-ui/core/withWidth';
import * as userActions from '../../actions/userActions';
import PulseInfoButton from "../info/PulseInfoButton";

const styles = theme => ({
  ...getSharedStyles(theme),
})
class Home extends React.Component {
  isMobile(width) {
    if (width === "xs" || width === "sm") {
      return true;
    }
    return false;
  }

  componentDidMount() {
    if (this.props.versionInfo.length === 0) {
      this.props.dispatch(ciselnikyActions.getVersionInfo());
    }
  }

  render() {
    const { person, classes, width } = this.props;
    return (
      <div className='container w-margin'>
        <Paper className={classes.paperCard} elevation={(width && this.isMobile(width)) ? 0 : 2} >
          <React.Fragment>
            {!this.props.isAuth &&
              <div>
                <Typography variant="subtitle2">Verejná zóna</Typography>
              </div>
            }
            {this.props.isAuth &&
              <div>
                {person.I_O_TYP === 1 &&
                  <div>
                    <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb1)}>
                      <Typography variant="subtitle2">Osobné údaje</Typography>
                    </div>
                    <div className={classNames(classes.row, classes.alignItemsCenter)}>
                      <Typography variant="body1">Meno</Typography>
                      <Typography variant="body2">{person.MENO}</Typography>
                    </div>
                    <div className={classNames(classes.row, classes.alignItemsCenter)}>
                      <Typography variant="body1">Priezvisko</Typography>
                      <Typography variant="body2">{person.PF}</Typography>
                    </div>
                    <div className={classNames(classes.row, classes.alignItemsCenter)}>
                      <Typography variant="body1">Dátum narodenia</Typography>
                      <Typography variant="body2">{convert.convertDate(person.D_NAR)}</Typography>
                    </div>
                    <div className={classNames(classes.row, classes.alignItemsCenter)}>
                      <Typography variant="body1">Rodné číslo</Typography>
                      <Typography variant="body2">{person.ICO_RC}</Typography>
                    </div>

                    <hr className={classNames(classes.separator, classes.mt3, classes.mb3)} />

                    <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb1)}>
                      <Typography variant="subtitle2">Adresa</Typography>
                    </div>

                  </div>
                }
                {person.I_O_TYP === 2 &&
                  <div>
                    <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb1)}>
                      <Typography variant="subtitle2">Údaje o spoločnosti, živnosti</Typography>
                    </div>
                    <hr className={classNames(classes.separator, classes.mt1, classes.mb2)} />
                    <div className={classNames(classes.row, classes.alignItemsCenter)}>
                      <Typography variant="body1">Názov firmy</Typography>
                      <Typography variant="body2">{person.PF}</Typography>
                    </div>
                    <div className={classNames(classes.row, classes.alignItemsCenter)}>
                      <Typography variant="body1">IČO</Typography>
                      <Typography variant="body2">{person.ICO_RC}</Typography>
                    </div>

                    <hr className={classNames(classes.separator, classes.mt3, classes.mb3)} />

                    <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb1)}>
                      <Typography variant="subtitle2">Sídlo firmy</Typography>
                    </div>
                  </div>
                }
                <div className={classNames(classes.row, classes.alignItemsCenter)}>
                  <Typography variant="body1">Štát</Typography>
                  <Typography variant="body2">{person.N_STAT}</Typography>
                </div>
                <div className={classNames(classes.row, classes.alignItemsCenter)}>
                  <Typography variant="body1">Mesto/Obec</Typography>
                  <Typography variant="body2">{person.N_M}</Typography>
                </div>
                <div className={classNames(classes.row, classes.alignItemsCenter)}>
                  <Typography variant="body1">Ulica</Typography>
                  <Typography variant="body2">{person.N_U}</Typography>
                </div>
                <div className={classNames(classes.row, classes.alignItemsCenter)}>
                  <Typography variant="body1">Súpisné číslo</Typography>
                  <Typography variant="body2">{person.CS}</Typography>
                </div>
                <div className={classNames(classes.row, classes.alignItemsCenter)}>
                  <Typography variant="body1">Orientačné číslo</Typography>
                  <Typography variant="body2">{person.CO}</Typography>
                </div>

                <hr className={classNames(classes.separator, classes.mt3, classes.mb3)} />

                <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb1)}>
                  <Typography variant="subtitle2">Kontakt</Typography>
                </div>
                <div className={classNames(classes.row, classes.alignItemsCenter)}>
                  <Typography variant="body1">E-mail</Typography>
                  <Typography variant="body2">{person.EMAIL}</Typography>
                </div>
                <div className={classNames(classes.row, classes.alignItemsCenter)}>
                  <Typography variant="body1">Telefón</Typography>
                  <Typography variant="body2">{person.TEL}</Typography>
                </div>
              </div>

            }

          </React.Fragment>
          <PulseInfoButton N_KLUC={"DM_NEWS"} currEdited={"infoText"} />
        </Paper>

      </div >
    );
  } pe
}

const mapStateToProps = (state) => {
  return {
    isLoading: state.person.isLoading,
    person: state.person.data,
    isAuth: state.user.data !== null,
    versionInfo: state.versionInfo.data,
  }
}

const mapDispatchToProps = dispatch => ({
  dispatch,
  showMsg: (msg) => { dispatch(userActions.showMsg(msg)) }
});

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(Home))));