import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import { withStyles } from '@material-ui/core/styles';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import classNames from 'classnames';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import * as taxesActions from '../../actions/taxesActions';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';

const styles = theme => ({
  ...getSharedStyles(theme),

})

class Payments extends React.Component {

  componentDidMount = async () => {
    await this.props.getListPayments(this.props.zozIPoh, this.props.person.I_O);
  }

  buildSchema = () => [
    { field: "D_POH", title: "Dátum úhrady", filter: 'numeric', width: 150 },
    { field: "SK_VYR", title: "Zaplatené", filter: 'text', width: 150 },
    { field: "MENA", title: "Mena", width: 100 },
    { field: "SPOS_UHR", title: "Spôsob úhrady", width: 'auto' },
  ];

  isMobile(width) {
    if (width === "xs" || width === "sm") {
      return true;
    }
    return false;
  }

  render() {
    const { classes, listPayments } = this.props;
    return (
      <div>
        <CircularProgress loading={this.props.isLoading} />
        <div className='container w-margin w-padding'>
          <div className={classNames(classes.dFlex, classes.justifyContentStart, classes.alignItemsCenter, classes.pt3, classes.mb2)}>
            <IconButton
              className={classNames(classes.backButton, classes.p0)}
              color="inherit"
              onClick={this.props.onClose}
              toolTip="Späť"
              toolTipPlace="right"
              disableRipple
              disableHover
            >
              <ArrowBack
                color='secondary'
                fontSize='default'
                variant='fab'
                style={{ fontSize: 30 }}
              />
            </IconButton>
            <Typography style={{ paddingLeft: '1em' }} className={classes.fontBold}>Úhrady</Typography>
          </div>
          <Paper elevation={2} className={classNames(classes.ziadostCard, classes.mb3)} >
            <div className={classes.cardContent}>
              <div className={classNames(classes.row, classes.alignItemsCenter, classes.justifyContentBetween, classes.mb2)}>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>Dátum úhrady</Typography>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>Zaplatené</Typography>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold' }}>Spôsob úhrady</Typography>
              </div>
              {listPayments.map(item =>
                <div key={item.I_DN_PO} className={classNames(classes.row, classes.alignItemsCenter)} >
                  <Typography variant="body1" style={{ flex: '1', textAlign: 'left' }}>{item.D_POH}</Typography>
                  <Typography variant="body1" style={{ flex: '1', textAlign: 'center' }}>{item.SK_VYR.toFixed(2)}{' '}{item.MENA === "EUR" ? "€" : item.MENA}</Typography>
                  <Typography variant="body1" style={{ flex: '1', textAlign: 'right' }}>{item.SPOS_UHR}</Typography>
                </div>
              )}
            </div>
          </Paper>
        </div>
      </div>
    )
  }
}

Payments.propTypes = {
  onClose: PropTypes.func,
  zozIPoh: PropTypes.string,
}

const mapStateToProps = (state) => ({
  person: state.person.data,
  listPayments: state.listPayments.data,
  isLoading: state.listPayments.isLoading,
})

const mapDispatchToProps = (dispatch) => ({
  getListPayments: (zozIPoh, iO) => dispatch(taxesActions.getListPayments(zozIPoh, iO)),
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(Payments)));
