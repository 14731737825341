import React from 'react';
import PropTypes from 'prop-types';
import withWidth from '@material-ui/core/withWidth';
import classNames from 'classnames';

import Paper from '../../coraWebMComponents/surfaces/Paper';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import RadioButton from '../../coraWebMComponents/inputs/RadioButton';
import TextField from '../../coraWebMComponents/inputs/TextField';
import Button from '../../coraWebMComponents/inputs/Button';
import RemoveBlackIcon from '../../coraWebMComponents/dataDisplay/icons/RemoveBlackIcon';
import NumberMoneyInput from '../../coraWebMComponents/inputs/NumberMoneyInput';

const SubsidyDetail = (props) => {
  const buildRateInput = (hod) => {
    switch (hod.I_HOD_TYP) {
      case 1: case 2:
        return (
          <div key={hod.I_HOD} className={classNames(props.classes.row, props.classes.alignItemsCenter)} style={{ justifyContent: 'center' }}>
            <RadioButton
              value={getValue(hod.I_HOD)}
              radioButtonStyle={{
                padding: window.innerWidth < 400 ? '3.5px' : window.innerWidth < 600 ? '5px' : null
              }}
              onChange={(id, value) => props.onChange(Number(id), value)}
              inputData={buildRadio(hod.I_HOD_TYP)}
              classes={props.classes}
              horizontal={true}
              disabled={!props.edit}
              name={hod.I_HOD.toString()}
            />
            <IconButton
              onClick={() => props.onChange(hod.I_HOD, null)}
              color="inherit"
              toolTip="Zrušiť hodnotenie"
              toolTipPlace="top"
              disabled={!props.edit || !hod.HOD}
            >
              <RemoveBlackIcon style={{ color: props.edit && hod.HOD && '#f44336' }} />
            </IconButton>
          </div>
        );
      case 3:
        return (
          <div key={hod.I_HOD} className={classNames(props.classes.row, props.classes.alignItemsCenter)} style={{ justifyContent: 'center' }}>
            <RadioButton
              value={getValue(hod.I_HOD)}
              onChange={(id, value) => props.onChange(Number(id), value)}
              inputData={[{ id: '1', name: 'Áno' }, { id: '0', name: 'Nie' }]}
              classes={props.classes}
              disabled={!props.edit}
              name={hod.I_HOD.toString()}
            />
            <IconButton
              onClick={() => props.onChange(hod.I_HOD, null)}
              color="inherit"
              toolTip="Zrušiť hodnotenie"
              toolTipPlace="top"
              disabled={!props.edit || !hod.HOD}
            >
              <RemoveBlackIcon style={{ color: props.edit && hod.HOD && '#f44336' }} />
            </IconButton>
          </div>
        );
      case 4:
        return (
          <div key={hod.I_HOD} className={classNames(props.classes.row, props.classes.alignItemsCenter)}>
            <NumberMoneyInput
              label={hod.POPIS_HOD_TYP ? hod.POPIS_HOD_TYP : undefined}
              fullwidth={true}
              value={typeof getValue(hod.I_HOD) === 'number' ? getValue(hod.I_HOD) : !getValue(hod.I_HOD) ? null : Number(getValue(hod.I_HOD))}
              onChange={(id, value) => props.onChange(Number(id), value)}
              name={hod.I_HOD.toString()}
              disabled={!props.edit}
              decimalScale={2}
              inputProps={{ maxLength: 20 }}
            />
            <IconButton
              onClick={() => props.onChange(hod.I_HOD, NaN)}
              color="inherit"
              toolTip="Zrušiť hodnotenie"
              toolTipPlace="top"
              disabled={!props.edit || !hod.HOD}
            >
              <RemoveBlackIcon style={{ color: !(!props.edit || !hod.HOD) && '#f44336' }} />
            </IconButton>
          </div>
        );
      case 5:
        return (
          <div key={hod.I_HOD} className={classNames(props.classes.row, props.classes.alignItemsCenter)}>
            <TextField
              label={hod.POPIS_HOD_TYP ? hod.POPIS_HOD_TYP : "Text"}
              fullwidth={true}
              value={getValue(hod.I_HOD) ? getValue(hod.I_HOD) : ''}
              onChange={(id, value) => props.onChange(Number(id), value)}
              name={hod.I_HOD.toString()}
              disabled={!props.edit}
              inputProps={{ maxLength: 100 }}
            />
            <IconButton
              onClick={() => props.onChange(hod.I_HOD, '')}
              color="inherit"
              toolTip="Zrušiť hodnotenie"
              toolTipPlace="top"
              disabled={!props.edit || !hod.HOD}
            >
              <RemoveBlackIcon style={{ color: props.edit && hod.HOD && '#f44336' }} />
            </IconButton>
          </div>
        );
      case 6:
        break;
      default:
    }
  };

  const getValue = (id) => {
    let val = '';
    props.dataRate.map(d => {
      if (d.I_HOD === id) val = d.HOD;
      return d;
    });
    return val;
  };

  const buildRadio = (iHodTyp) => {
    let input = [{ id: '1', name: '1' }];
    let i = 0;
    let _i = iHodTyp === 1 ? 11 : 10;
    for (i = 2; i < _i; i++) {
      input.push({ id: i.toString(), name: i.toString() });
    }
    return input;
  };

  return (
    <div className='container w-margin'>
      <div className={classNames(props.classes.dFlex, props.classes.justifyContentStart, props.classes.alignItemsLeft, props.classes.pt3, props.classes.mb2)}>
        <div>
          <IconButton
            className={classNames(props.classes.backButton, props.classes.p0)}
            color="inherit"
            onClick={props.handleClose}
            toolTip="Späť"
            toolTipPlace="right"
            disableRipple
            disableHover
          >
            <ArrowBack
              color='secondary'
              fontSize='default'
              variant='fab'
              style={{ fontSize: 30 }}
            />
          </IconButton>
        </div>
        <div className={classNames(props.classes.ml2)}>
          <Typography variant="button" style={{ fontWeight: 'bold' }}>{props.projectName}</Typography>
        </div>
      </div>

      {props.dataRate.length === 0 &&
        <div className={classNames(props.classes.mb3)}>
          <Typography variant="button" style={{ fontWeight: 'bold' }}>Pre danú žiadosť sa nenašli žiadne údaje.</Typography>
        </div>
      }

      {props.dataRate.map(kriterium =>
        <div key={kriterium.I_HOD}>
          <Paper key={kriterium.I_HOD} elevation={2} className={classNames(props.classes.ziadostCard, props.classes.mb3)} >
            <div className={props.classes.cardContent}>
              <div className={classNames(props.classes.row, props.classes.alignItemsCenter, props.classes.justifyContentBetween, props.classes.mb2)}>
                <Typography variant="subtitle2" style={{ fontWeight: 'bold', color: kriterium.KOD_KRT_FARBA }}>{kriterium.N_KRT}</Typography>
              </div>
              {props.data.filter(x => x.I_KRT === kriterium.I_KRT).map(podKriterium =>
                <div key={podKriterium.I_PKRT_TXT} style={{ border: "1px solid rgba(0, 0, 0, 0.06)", borderRadius: "4px", padding: '1em' }} className={props.classes.mb2}>
                  <div className={classNames(props.classes.row, props.classes.alignItemsCenter, props.classes.mb2)} >
                    <Typography variant="body1" style={{ letterSpacing: '0.1em', fontWeight: 500 }}>{podKriterium.N_PKRT}</Typography>
                  </div>
                  <div className={classNames(props.classes.row, props.classes.alignSelfCenter)} >
                    <Typography variant="body1">{podKriterium.TXT}</Typography>
                  </div>
                </div>)}
              <div className={classNames(props.classes.alignSelfCenter)}>
                {buildRateInput(kriterium)}
              </div>
            </div>
          </Paper>
        </div>
      )}

      {props.edit && (props.data.length > 0 || props.dataRate.length > 0) &&
        <div>
          <div className={props.classes.row} >
            <Button
              variant="contained"
              color="secondary"
              size="large"
              type="success"
              className={classNames(props.classes.w100, props.classes.mt2)}
              onClick={props.onSave}
              style={{ marginBottom: '50px' }}
            >
              Uložiť hodnotenie
            </Button>
          </div>
        </div>}
    </div>
  );
};

SubsidyDetail.propTypes = {
  key: PropTypes.number,
  data: PropTypes.array,
  dataRate: PropTypes.array,
  classes: PropTypes.object,
  handleClose: PropTypes.func,
  projectName: PropTypes.string,
  edit: PropTypes.bool,
  onSave: PropTypes.func,
  onChange: PropTypes.func,
};

export default (withWidth()(SubsidyDetail));