import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import MenuItem from '@material-ui/core/MenuItem';

import Button from "../../coraWebMComponents/inputs/Button";
import InfoIcon from "../../coraWebMComponents/dataDisplay/icons/InfoIcon";

export class OptionsButtonMenuItemComponent extends React.Component {
  render() {
    const { classes, itemText, disabled, onClick } = this.props;
    if (disabled) {
      return (
        <MenuItem
          className={classNames(classes.rimItem)}
          onClick={() => onClick()} disabled>
          <Button
            variant="text"
            className={classNames(classes.textTransformNone, classes.bgColorTransparent)}
            startIcon={<InfoIcon />}
          >
            {itemText}
          </Button>
        </MenuItem>
      );
    }
    return (
      <MenuItem
        className={classNames(classes.rimItem)}
        onClick={() => onClick()}>
        <Button
          variant={"text"}
          className={classNames(classes.textTransformNone, classes.bgColorTransparent)}
          startIcon={<InfoIcon />}
        >
          {itemText}
        </Button>
      </MenuItem>
    );
  }
}

OptionsButtonMenuItemComponent.propTypes = {
  classes: PropTypes.object.isRequired,
  itemText: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
  disabled: PropTypes.bool
};

export default OptionsButtonMenuItemComponent;
