import actionTypes from './actionTypes';
import * as subsidiesRatingUtils from '../utils/subsidiesRatingUtils';

const getListSubsidiesRatingRequest = () => ({
  type: actionTypes.GET_LIST_SUBSIDIES_RATING_REQUEST
});

const getListSubsidiesRatingSuccess = (res) => ({
  type: actionTypes.GET_LIST_SUBSIDIES_RATING_SUCCESS,
  res
});

const getListSubsidiesRatingFailure = (error) => ({
  type: actionTypes.GET_LIST_SUBSIDIES_RATING_FAILURE,
  error
});

export const getListSubsidiesRating = (io, iStav) => {
  return async (dispatch) => {
    try {
      dispatch(getListSubsidiesRatingRequest());
      let res = await subsidiesRatingUtils.getListSubsidiesRating(io, iStav);
      dispatch(getListSubsidiesRatingSuccess(res));
    } catch (error) {
      dispatch(getListSubsidiesRatingFailure(error));
    }
  }
}

const getListSubsidyDetailRequest = () => ({
  type: actionTypes.GET_LIST_SUBSIDY_DETAIL_REQUEST
});

const getListSubsidyDetailSuccess = (res) => ({
  type: actionTypes.GET_LIST_SUBSIDY_DETAIL_SUCCESS,
  res
});

const getListSubsidyDetailFailure = (error) => ({
  type: actionTypes.GET_LIST_SUBSIDY_DETAIL_FAILURE,
  error
});

export const getListSubsidyDetail = (iDt) => {
  return async (dispatch) => {
    try {
      dispatch(getListSubsidyDetailRequest());
      let res = await subsidiesRatingUtils.getListSubsidyDetail(iDt);
      dispatch(getListSubsidyDetailSuccess(res));
    } catch (error) {
      dispatch(getListSubsidyDetailFailure(error));
    }
  }
}

const getListSubsidyRatingDetailRequest = () => ({
  type: actionTypes.GET_LIST_SUBSIDY_RATING_DETAIL_REQUEST
});

const getListSubsidyRatingDetailSuccess = (res) => ({
  type: actionTypes.GET_LIST_SUBSIDY_RATING_DETAIL_SUCCESS,
  res
});

const getListSubsidyRatingDetailFailure = (error) => ({
  type: actionTypes.GET_LIST_SUBSIDY_RATING_DETAIL_FAILURE,
  error
});

export const getListSubsidyRatingDetail = (iDt) => {
  return async (dispatch) => {
    try {
      dispatch(getListSubsidyRatingDetailRequest());
      let res = await subsidiesRatingUtils.getListSubsidyRatingDetail(iDt);
      dispatch(getListSubsidyRatingDetailSuccess(res));
    } catch (error) {
      dispatch(getListSubsidyRatingDetailFailure(error));
    }
  }
}


const updSubsidyRatingRequest = () => ({
  type: actionTypes.UPD_SUBSIDY_RATING_REQUEST
});

const updSubsidyRatingSuccess = (res) => ({
  type: actionTypes.UPD_SUBSIDY_RATING_SUCCESS,
  res
});

const updSubsidyRatingFailure = (error) => ({
  type: actionTypes.UPD_SUBSIDY_RATING_FAILURE,
  error
});
export const updSubsidyRating = (data) => {
  return async (dispatch) => {
    try {
      dispatch(updSubsidyRatingRequest());
      let res = await subsidiesRatingUtils.updSubsidyRating(data);
      dispatch(updSubsidyRatingSuccess(res));
      return true;
    } catch (error) {
      dispatch(updSubsidyRatingFailure(error));
      return false;
    }
  }
}

export const changeSubsidyRating = (id, value) => ({
  type: actionTypes.CHANGE_SUBSIDY_RATING,
  id,
  value
});