import React from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import CircularProgress from '../../coraWebMComponents/feedBack/progress/CircularProgress';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import { withStyles } from '@material-ui/core/styles';
import getSharedStyles from '../../coraWebMComponents/sharedStyles'
import classNames from 'classnames';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import AddOutlineIcon from '../../coraWebMComponents/dataDisplay/icons/AddOutlineIcon';
import Divider from '../../coraWebMComponents/dataDisplay/Divider';
import Button from '../../coraWebMComponents/inputs/Button';
import FormModal from '../../coraWebMComponents/inputs/FormModal';
import * as parkingActions from '../../actions/parkingActions';
import * as userActions from '../../actions/userActions';
import * as ciselnikyActions from '../../actions/ciselnikyActions';
import DatePicker from '../../coraWebMComponents/inputs/DatePicker';
import CarIcon from '../../coraWebMComponents/dataDisplay/icons/CarIcon';
import DateRangeIcon from '../../coraWebMComponents/dataDisplay/icons/DateRangeIcon';
import DateIcon from '../../coraWebMComponents/dataDisplay/icons/DateIcon';
import * as validator from '../../coraWebMComponents/utils/validator';
import * as convert from '../../coraWebMComponents/utils/convert';
import TextField from '../../coraWebMComponents/inputs/TextField';
import InputAdornment from '@material-ui/core/InputAdornment';
import Paper from '../../coraWebMComponents/surfaces/Paper';
import Dialog from '../../coraWebMComponents/feedBack/Dialog';
import withRouter from '../../coraWebMComponents/utils/withRouter';

const styles = theme => ({
  ...getSharedStyles(theme),
  addButton: {
    backgroundColor: '#4caf50',
    borderRadius: '30px',
    height: '55px',
    "&:hover": {
      backgroundColor: '#4caf50'
    }
  },
  formButton: {
    margin: 0,
    borderRadius: '0px',
  }
})
let _iZiadost, _iKarta;
let _iKartaVoz = 0;

class TempEcv extends React.Component {
  constructor(props) {
    super(props);
    const dnes = new Date();
    dnes.setHours(0);
    dnes.setMinutes(0);
    dnes.setSeconds(0);
    dnes.setMilliseconds(0);

    this.state = {
      isLoad: true,
      openAdd: false,
      openCanc: false,
      permitAdd: true,
      ECV: '',
      ECV_OLD: '',
      I_KARTA_VOZ: null,
      I_KARTA: null,
      I_KARTA_AKTUAL: null,
      I_O: null,
      D_OD: new Date(),
      D_DO: null,
      P_ECV_DNI: 0,
      P_ECV_ZMEN: 0,
      infoText: '',
      infoText2: '',
      errorTextEcv: '',
      maxDate: new Date(),
      D_DO_KARTA: new Date(),
      dnes: new Date(dnes),
      POC_CELK: 0,
    };
  }

  componentDidMount = async () => {
    const search = window.location.href.split('?').pop();
    const params = new URLSearchParams(search);
    _iZiadost = params.get("iZiadost");
    _iKarta = params.get("iKarta");
    await this.props.dispatch(parkingActions.getParkingCard(_iKarta));
    await this.props.dispatch(ciselnikyActions.getCardType(this.props.parkingCard.I_KARTA_TYP));
    await this.props.dispatch(parkingActions.getListTempEcv(this.props.parkingCard.I_KARTA_AKTUAL));
    const permitAdd = this.GetPermitAdd();

    const zmen = this.props.cardType && this.props.cardType.P_ECV_ZMEN ? this.props.cardType.P_ECV_ZMEN : 0;
    const dni = this.props.cardType && this.props.cardType.P_ECV_DNI ? this.props.cardType.P_ECV_DNI : 0;
    const pocCelk = this.props.listTempEcv.length > 0 ? this.props.listTempEcv[0].POC_CELK : 0;

    const infoTxt = `vykonať ${zmen}-krát počas platnosti karty.`;
    const infoTxt2 = `Maximálna dĺžka trvania zmeny je ${dni} dní.`;

    var d_do_karta = new Date();
    if (this.props.parkingCard.D_DO)
      d_do_karta = convert.strToDate(this.props.parkingCard.D_DO);
    else
      d_do_karta.setDate(d_do_karta.getDate() + 365);

    this.setState({
      isLoad: false,
      ECV_OLD: this.props.parkingCard.ECV,
      I_KARTA: _iKarta,
      I_KARTA_AKTUAL: this.props.parkingCard.I_KARTA_AKTUAL,
      I_O: this.props.parkingCard.I_O,
      P_ECV_DNI: dni,
      P_ECV_ZMEN: zmen,
      infoText: infoTxt,
      infoText2: infoTxt2,
      D_DO_KARTA: d_do_karta,
      POC_CELK: pocCelk,
      permitAdd: permitAdd
    });

    this.handleChange('D_OD', this.state.D_OD);

  }

  handleChange = (name, value) => {
    switch (name) {
      case 'ECV':
        value = value.toUpperCase();
        value = value.trim();
        const errTxt = value ? validator.isValidEcv(value) : 'Evidené číslo vozidla je povinný údaj!';
        this.setState({ ECV: value, errorTextEcv: value === this.state.ECV_OLD ? 'EČV sa zhoduje s pôvodným EČV!' : errTxt });
        break;
      case 'D_OD':
        value = this.validDod(value);
        const maxD = this.getMaxDate(value);
        this.setState({ D_OD: value, D_DO: maxD, maxDate: maxD });
        break;
      case 'D_DO':
        if (value < this.state.D_OD)
          value = this.state.D_OD;

        this.setState({ D_DO: value });
        break;
      default:
    }
  };

  getMaxDate = (d_od) => {
    const maxDat = new Date(d_od);
    maxDat.setDate(maxDat.getDate() + this.state.P_ECV_DNI - 1);

    if (this.state.D_DO_KARTA < maxDat)
      return this.state.D_DO_KARTA;

    return maxDat;
  };

  validDod = (datum) => {

    if (!datum)
      datum = this.state.dnes;
    else {
      if (datum < this.state.dnes)
        datum = this.state.dnes;
    }
    return datum;
  };

  handleAdd = () => {
    this.setState({ openAdd: true });
  };

  handleAddClose = () => {
    this.setState({ openAdd: false });
  };

  handleCancelTempEcv = (iKartaVoz) => {
    _iKartaVoz = iKartaVoz;
    this.setState({ openCanc: true });
  };

  handleCloseAlertCanc = () => {
    this.setState({ openCanc: false });
  };

  cancelTempEcv = async () => {
    this.setState({ openCanc: false, isLoad: true });
    const res = await this.props.dispatch(parkingActions.cancelTempEcv(_iKartaVoz));

    if (res)
      await this.props.dispatch(parkingActions.getListTempEcv(this.state.I_KARTA_AKTUAL));

    const pocCelk = this.props.listTempEcv.length > 0 ? this.props.listTempEcv[0].POC_CELK : 0;
    const permitAdd = this.GetPermitAdd();
    this.setState({ isLoad: false, POC_CELK: pocCelk, permitAdd: permitAdd });

    if (res)
      this.props.dispatch(userActions.showMsg("Dočasná zmena EČV bola ukončená."));
    else
      this.props.dispatch(userActions.showErrorMsg("Dočasnú zmenu EČV sa nepodarilo ukončiť!"));

  };

  GetPermitAdd = () => {
    let permitAdd = true;
    if (this.props.listTempEcv.length > 0) {
      this.props.listTempEcv.forEach(getPermit);

      function getPermit(value) {
        const dnes = new Date();
        dnes.setHours(0);
        dnes.setMinutes(0);
        dnes.setSeconds(0);
        dnes.setMilliseconds(0);

        if (new Date(value.D_DO) >= dnes)
          permitAdd = false;
      }
    }
    return permitAdd;
  }

  AddTempEcv = async () => {
    const Ecv = this.state.ECV;
    const errTxt = Ecv ? validator.isValidEcv(Ecv) : 'Evidené číslo vozidla je povinný údaj!';
    const errorTextEcv = Ecv === this.state.ECV_OLD ? 'EČV sa zhoduje s pôvodným EČV!' : errTxt;
    const dOd = new Date(this.state.D_OD);
    const dDo = new Date(this.state.D_DO);
    this.setState({ errorTextEcv });

    if (!errorTextEcv) {
      this.setState({ isLoad: true, openAdd: false });
      const res = await this.props.dispatch(parkingActions.addTempEcv(this.state.I_KARTA_AKTUAL, Ecv, this.state.I_O, dOd, dDo));

      if (res)
        await this.props.dispatch(parkingActions.getListTempEcv(this.state.I_KARTA_AKTUAL));

      const permitAdd = this.GetPermitAdd();
      const pocCelk = this.props.listTempEcv.length > 0 ? this.props.listTempEcv[0].POC_CELK : 0;
      this.setState({ isLoad: false, POC_CELK: pocCelk, permitAdd: permitAdd });

      if (res)
        this.props.dispatch(userActions.showMsg("Dočasná zmena EČV bola zaevidovaná."));
      else
        this.props.dispatch(userActions.showErrorMsg("Dočasnú zmenu EČV sa nepodarilo zaevidovať."));

    }
  }

  render() {
    const { classes, listTempEcv } = this.props;
    const fMelements =
      <div >
        <Typography variant="h6" className={classNames(classes.ml4, classes.mr4, classes.mt4, classes.mb1)}>Dočasná zmena EČV</Typography>
        <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4)}>
          <DatePicker
            label="Dátum od"
            fullWidth={true}
            value={this.state.D_OD}
            onChange={this.handleChange}
            clearable={true}
            name='D_OD'
            keyboards={true}
            disableFuture={false}
            disablePast={true}
            className={classNames(classes.mr4, classes.ml4)}
            maxDate={this.state.D_DO_KARTA.toDateString()}
          />
        </div>
        <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4)}>
          <DatePicker
            label="Dátum do"
            fullWidth={true}
            value={this.state.D_DO}
            onChange={this.handleChange}
            clearable={true}
            name='D_DO'
            keyboards={true}
            disableFuture={false}
            disablePast={true}
            className={classNames(classes.mr4, classes.ml4)}
            maxDate={this.state.maxDate.toDateString()}
          />
        </div>
        <div className={classNames(classes.row, classes.alignItemsCenter, classes.mb4)}>
          <TextField
            label="EČV"
            fullwidth={true}
            value={this.state.ECV}
            errorText={this.state.errorTextEcv}
            error={this.state.errorTextEcv ? true : false}
            onChange={this.handleChange}
            name='ECV'
            className={classNames(classes.mr4, classes.ml4)}
            imputProps={{
              startAdornment: (
                <InputAdornment position="start" style={{ marginLeft: '-2px' }}>
                  <CarIcon />
                </InputAdornment>
              ),
            }}
          />
        </div>
        <Divider variant='fullWidth' style={{ height: '2px' }} />
        <div className={classNames(classes.w100, classes.row)}>
          <Button
            variant="text"
            color="primary"
            size="large"
            className={classNames(classes.w100, classes.formButton)}
            onClick={() => this.handleAddClose()}
            style={{ height: '45px' }}
          ><Typography variant="body1" >Zrušiť</Typography></Button>
          <Button
            variant="contained"
            color="secondary"
            size="large"
            className={classNames(classes.w100, classes.formButton)}
            onClick={() => this.AddTempEcv()}
            style={{ height: '45px' }}
          ><Typography variant="body1" color="inherit">Potvrdiť</Typography></Button>
        </div>
      </div>
    return (

      <div className='container' style={{ marginTop: '70px', marginBottom: '20px' }}>
        <CircularProgress loading={this.props.isLoading || this.state.isLoad} />
        <div className={classNames(classes.row, classes.w100, classes.alignItemsCenter, classes.mt4)} >
          <IconButton
            className={classNames(classes.ml3)}
            color="inherit"
            onClick={() => this.props.navigate(-1)}
            toolTip="Späť"
            toolTipPlace="right"
          >
            <ArrowBack
              color='secondary'
              variant='fab'
              style={{ fontSize: 30 }}
            />
          </IconButton>
          <Typography variant="subtitle1" className={classNames(classes.mr4)}>Dočasná zmena EČV </Typography>
          <div className={classNames(classes.ml6)}></div>
        </div>

        {this.state.P_ECV_DNI > 0 && this.state.POC_CELK < this.state.P_ECV_ZMEN && this.state.permitAdd &&
          <div className={classNames(classes.row, classes.mt2, classes.mb3)} >
            <Button
              variant="contained"
              color="secondary"
              size="large"
              className={classNames(classes.w100, classes.addButton, classes.mr4, classes.ml4, classes.mb4, classes.justifyContentStart)}
              onClick={() => this.handleAdd()}
              style={{ padding: '8px 4px' }}
            >
              <AddOutlineIcon style={{ backgroundColor: '#4caf50', color: 'white', width: '50px', height: '50px', marginRight: '20px', borderRadius: '30px' }} />
              Zmeniť EČV {this.state.ECV_OLD}
            </Button>
          </div>
        }

        {this.state.P_ECV_DNI > 0 && this.state.POC_CELK >= this.state.P_ECV_ZMEN &&
          <Typography variant="caption" style={{ color: 'red' }} className={classNames(classes.textCenter, classes.mt4, classes.mb4, classes.ml4, classes.mr4)}>Počet dočasných zmien EČV na tejto karte je vyčerpaný!</Typography>
        }

        {/* {this.state.P_ECV_DNI === 0 && !this.props.isLoading && !this.state.isLoad &&
          <Typography variant="caption" className={classNames(classes.ml4, classes.textCenter, classes.mr4)}>Tento typ parkovacej karty neumožňuje dočasnú zmenu EČV.</Typography>
        } */}

        {listTempEcv.map(x =>
          <TmpE
            key={x.I_KARTA_VOZ.toString()}
            data={x}
            dnes={this.state.dnes}
            classes={classes}
            onCancelTempEcv={() => this.handleCancelTempEcv(x.I_KARTA_VOZ)}
          />)
        }

        {this.state.P_ECV_DNI > 0 &&
          <div className={classes.textCenter}>
            <Typography variant="caption" className={classNames(classes.mt4)}>Dočasnú zmenu pre Vašu kartu je možné {this.state.infoText} {this.state.infoText2}</Typography>
          </div>
        }
        <FormModal
          open={this.state.openAdd}
          onClose={this.handleAddClose}
          elements={fMelements}
        />
        <Dialog
          open={this.state.openCanc}
          onClose={this.handleCloseAlertCanc}
          contentText={'Prajete si ukončiť dočasnú zmenu EČV?'}
          title={'Otázka'}
          actions={[
            {
              label: 'Zrušiť',
              color: 'primary',
              onClick: this.handleCloseAlertCanc,
              keyboardFocused: true
            },
            {
              label: 'Ukončiť',
              color: 'primary',
              onClick: this.cancelTempEcv,
              keyboardFocused: false
            }
          ]}
        />
      </div >
    );
  };
};


class TmpE extends React.Component {
  render() {
    return (
      <div className={classNames(this.props.classes.mb3)} >
        <Paper className={classNames(this.props.classes.ml4, this.props.classes.mr4)} style={{ borderRadius: '12px' }} elevation={2}>
          <React.Fragment>
            <div className={classNames(this.props.classes.w100, this.props.classes.row, this.props.classes.alignItemsCenter)} >
              <div className={classNames(this.props.classes.w100, this.props.classes.row, this.props.classes.alignItemsCenter,
                this.props.classes.justifyContentStart, this.props.classes.ml3, this.props.classes.mt3)} >
                <CarIcon className={classNames(this.props.classes.mr2)} />
                <Typography
                  variant="body1"
                  style={{ fontWeight: 'bold', color: (new Date(this.props.data.D_OD) <= this.props.dnes && new Date(this.props.data.D_DO) >= this.props.dnes) ? '#4caf50' : '' }}
                  className={classNames(this.props.classes.mr1)}
                >
                  {this.props.data.ECV}
                </Typography>
              </div>
              <div className={classNames(this.props.classes.w100, this.props.classes.row, this.props.classes.alignItemsCenter,
                this.props.classes.justifyContentStart, this.props.classes.ml3, this.props.classes.mt3, this.props.classes.mr3)} >
                <Typography
                  variant="body1"
                  style={{ fontWeight: 'bold', color: (new Date(this.props.data.D_OD) <= this.props.dnes && new Date(this.props.data.D_DO) >= this.props.dnes) ? '#4caf50' : '' }}
                  className={classNames(this.props.classes.mr1)}>
                  {(new Date(this.props.data.D_OD) <= this.props.dnes && new Date(this.props.data.D_DO) >= this.props.dnes) ? 'aktuálne' : 'neaktuálne'}
                </Typography>
              </div>
            </div>
            <div className={classNames(this.props.classes.w100, this.props.classes.row, this.props.classes.alignItemsCenter)} >
              <div className={classNames(this.props.classes.w100, this.props.classes.row, this.props.classes.alignItemsCenter,
                this.props.classes.justifyContentStart, this.props.classes.ml3, this.props.classes.mt3, this.props.classes.mb3)} >
                <DateRangeIcon className={classNames(this.props.classes.mr2)} />
                <Typography variant="body1" style={{ fontWeight: 'bold' }} className={classNames(this.props.classes.mr1)}>od</Typography>
                <Typography variant="body1"  >{convert.convertDate(this.props.data.D_OD)}</Typography>
              </div>
              <div className={classNames(this.props.classes.w100, this.props.classes.row, this.props.classes.alignItemsCenter,
                this.props.classes.justifyContentStart, this.props.classes.ml3, this.props.classes.mt3, this.props.classes.mb3, this.props.classes.mr3)} >
                <DateIcon className={classNames(this.props.classes.mr2)} />
                <Typography variant="body1" style={{ fontWeight: 'bold' }} className={classNames(this.props.classes.mr1)}>do</Typography>
                <Typography variant="body1" >{convert.convertDate(this.props.data.D_DO)}</Typography>
              </div>
            </div>

            {(new Date(this.props.data.D_OD) <= this.props.dnes && new Date(this.props.data.D_DO) > this.props.dnes) &&
              <div className={classNames(this.props.classes.w100, this.props.classes.row)} >
                <Button
                  className={classNames(this.props.classes.w100, this.props.classes.ml3, this.props.classes.mr3, this.props.classes.mb3)}
                  onClick={() => this.props.onCancelTempEcv()}
                  color="primary"
                  style={{ height: '50px', borderRadius: '12px', color: 'white', backgroundColor: 'red', "&:hover": { backgroundColor: 'red' } }}
                >
                  Ukončiť
                </Button>
              </div>
            }
          </React.Fragment>
        </Paper >
      </div >
    );
  }
}

TmpE.propTypes = {
  onCancelTempEcv: PropTypes.func,
}


const mapStateToProps = (state) => {
  return {
    parkingCard: state.parkingCard.data.Data,
    cardType: state.cardType.data.Data,
    listTempEcv: state.listTempEcv.data,
    isLoading: state.listTempEcv.isLoading,
  }
}

export default withRouter(connect(mapStateToProps)(withStyles(styles)(TempEcv)));




