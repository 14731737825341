import * as React from 'react';
import PropTypes from 'prop-types';
import { Grid, GridColumn as Column, GridToolbar } from '@progress/kendo-react-grid';
import Toolbar from './Toolbar';
import ToolTip from "../inputs/ToolTip";
import withStorage from '../utils/withStorage';
import withMeta from '../utils/withMeta';
import { eachDeep, fieldFormat, getPrimaryField, removeAccents } from '../utils/helper';
import eventEmitter from '../utils/eventEmitter';
import DropdownFilterCell from './DropdownFilterCell';
import Cell from './Cell';
import ColumnMenu from './ColumnMenu';
import LoadingPanel from './LoadingPanel';
import flags from '../utils/flags';
import Dialog from '../feedBack/Dialog';
import DialogSort from './DialogSort';
import html2canvas from 'html2canvas';
import { saveAs } from 'file-saver';
//import './Grid.scss';
import './Grid_old.css';
import DialogFilter from './DialogFilter';
import SelectionMenu from './SelectionMenu';
import { MemoRow } from './MemoRow';
import { MemoCell } from './MemoCell';
import moment from 'moment'
import _ from 'lodash';

const SORT = [];
const PAGE = {
  skip: 0,
  take: 5
};
const FILTER = {
  filters: []
};
const ENUM_DEFAULT = '-';
const DATE_FORMATS = ['DD.MM.YYYY', 'D.M.YYYY', 'YYYYMMDD', 'YYYY-MM-DD HH:mm:ss', 'YYYY-MM-DDTHH:mm:ssZ'];

const FILTER_OPERATORS = {
  text: [
    { text: 'grid.filterContainsOperator', operator: 'contains' },
    { text: 'grid.filterNotContainsOperator', operator: 'doesnotcontain' },
    { text: 'grid.filterEqOperator', operator: 'eq' },
    { text: 'grid.filterNotEqOperator', operator: 'neq' },
    { text: 'grid.filterStartsWithOperator', operator: 'startswith' },
    { text: 'grid.filterEndsWithOperator', operator: 'endswith' },
    { text: 'grid.filterIsEmptyOperator', operator: 'isempty' },
    { text: 'grid.filterIsNotEmptyOperator', operator: 'isnotempty' }
  ],
  numeric: [
    { text: 'grid.filterEqOperator', operator: 'eq' },
    { text: 'grid.filterNotEqOperator', operator: 'neq' },
    { text: 'grid.filterGteOperator', operator: 'gte' },
    { text: 'grid.filterGtOperator', operator: 'gt' },
    { text: 'grid.filterLteOperator', operator: 'lte' },
    { text: 'grid.filterLtOperator', operator: 'lt' },
    { text: 'grid.filterIsNullOperator', operator: 'isnull' },
    { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' }
  ],
  date: [
    { text: 'grid.filterEqOperator', operator: 'eq' },
    { text: 'grid.filterNotEqOperator', operator: 'neq' },
    { text: 'grid.filterAfterOrEqualOperator', operator: 'gte' },
    { text: 'grid.filterAfterOperator', operator: 'gt' },
    { text: 'grid.filterBeforeOperator', operator: 'lt' },
    { text: 'grid.filterBeforeOrEqualOperator', operator: 'lte' },
    { text: 'grid.filterIsNullOperator', operator: 'isnull' },
    { text: 'grid.filterIsNotNullOperator', operator: 'isnotnull' }
  ],
  boolean: [
    { text: 'grid.filterBooleanAll', operator: '' },
    { text: 'grid.filterIsTrue', operator: true },
    { text: 'grid.filterIsFalse', operator: false }
  ]
};

/**
 * Grid component
 * @module
 * @param {array} data - Grid data
 * @param {array} schema - Grid schema
 * @param {object} [parentProps] - Parent props
 * @param {number} [disabled] - Disabled flag
 * @param {object} [filter] - Grid filter
 * @param {number} [hidden] - Hidden flag
 * @param {bool} isLoading - Whether the data is loading
 * @param {func} [onFilter] - Filter handler
 * @param {func} [onSelect] - Select handler
 * @param {string} primaryField - Primary field name
 * @param {array} [selected] - Selected item
 * @param {object} [style] - Styles
 * @param {number} total - Total number of data items
 * @param {element} [toolbar] - Toolbar element
 * @param {object} [initFilter] - Initial state of the filter
 * @param {array} [initSort] - Initial sorting
 * @param {element} [detail] - Detail element
 * @param {func} [onExpandChange] - Expand grid handler
 * @param {func} [parseValue] - Parse value function
 */

let _timer;
let _lastRowClick;
let _lastRowEvent;
let _lastRowIndex = 0;
let _lastCell = null;
let _refresh, _reselect;

class GridComponent extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      page: PAGE,
      filter: FILTER,
      sort: SORT,
      isOpenDelete: false,
      selected: [],
      selectedAll: [],
      columns: props.schema,
      isOpenDiscard: false,
      isOpenFilter: false,
      isOpenSort: false,
      isOpenReportDialog: false,
      isOpenReset: false,
      filterable: !((props.disabled | props.hidden) & flags.FILTER),
      blob: null,
      fileName: null,
      data: this.props.data || [],
      dataInvalid: false,
      selectedCells: [],
      selectedCellsAll: [],
      contextMenuPopupOpen: false,
      selectedFile: null,
      userFilter: [],
      userFilterExtended: [],
      isSchemaLoading: false,
      fixedFilter: props.fixedFilter,
      fastFilter: FILTER,
      extendedFilter: props.withMeta && !props.isPicker,
      managedUsers: []
    };

    //this.DialogFilter = this.state.extendedFilter ? ExtendedDialogFilter : DialogFilter;
    this.columns = [];
    this.visibleColumns = [];
    this.oldData = [];

    this.setColumns();
    this.ref = React.createRef();
    this.selected = [];
    this.selectedCells = [];
  }


  handleOpenSort = () => {
    this.setState({ isOpenSort: true });
  }

  handleCloseSort = () => {
    this.setState({ isOpenSort: false });
  }

  handleConfirmSort = (sort) => {
    this.handleCloseSort();
    this.onSortChange({ sort })
  }

  handleOpenDelete = () => {
    this.setState({ isOpenDelete: true });
  }

  handleCloseDelete = () => {
    this.setState({ isOpenDelete: false });
  }

  handleConfirmDelete = async () => {
    await Promise.all(this.state.selected.map(async (item) => {
      await this.props.onDelete(item);
      return item + 1;
    }));
    this.setState({ selected: [], selectedCells: [], selectedAll: [], selectedCellsAll: [] })
    this.handleCloseDelete();
  }

  handleOpenFilter = () => {
    this.setState({ isOpenFilter: true })
  }

  handleCloseFilter = () => {
    this.setState({ isOpenFilter: false })
  }

  handleConfirmFilter = (filter) => {
    this.onFilterChange({ filter }, false)
    this.handleCloseFilter();
  }

  handleRemoveFilter = () => {
    this.onFilterChange({ filters: [] }, false)
  }

  handleSwitchFilterable = () => {
    this.setState(prevState => {

      const filterable = !prevState.filterable;
      this.props.storage.setFilterable(filterable);
      return { filterable };
    });
  }

  handleOpenReset = () => {
    this.setState({ isOpenReset: true });
  }

  handleCloseReset = () => {
    this.setState({ isOpenReset: false });
  }

  handleConfirmReset = () => {
    this.handleReset();
    this.handleCloseReset();
  }

  handleCloseDiscard = () => {
    this.setState({ isOpenDiscard: false });
  }

  handleCloseFile = () => {
    this.setState({ selectedFile: null });
  }

  handleConfirmDiscard = () => {
    this.rowClick(_lastRowEvent);
    this.handleCloseDiscard();
  }

  handleExport = async () => {
    const canvas = await html2canvas(this.ref.current);
    canvas.toBlob((blob) => {
      saveAs(blob, this.props.imgName + ".png");
    });
  }

  handlePaste = (e) => {
    const clipboardData = e.clipboardData.getData('text/plain');
    if (!clipboardData) return;
    const editcell = !(this.props.hidden & flags.EDITCELL);
    const rows = clipboardData.replace(/\r\n/g, '\n').replace(/\r/g, '\n').replace(/\n$/, '').split('\n');
    const columns = this.visibleColumns;
    const data = this.state.data.map(item => item);

    if (this.state.selectedCells.length === 0) return;
    const cell = this.state.selectedCells[0];
    const cellColIndex = columns.findIndex(col => col.field === cell.field);

    for (var i = 0; i < rows.length; i++) {
      const values = rows[i].split('\t');
      for (var j = 0; j < values.length; j++) {
        const rowIndex = cell.rowIndex + i;
        const columnIndex = cellColIndex + j;
        const dataItem = data[rowIndex];
        const column = columns[columnIndex];
        const edit = this.props.enterEdit ? this.props.enterEdit(dataItem, column.field) : true;

        if (dataItem && column && column.editable && editcell && edit) {
          const value = this.parseValue(values[j].replace(/(\r\n|\n|\r)/g, ''), dataItem, column);
          const hasChanged = this.isValueChanged({ ...dataItem, [column.field]: value }) ? true : undefined;

          if (value !== undefined) {
            data[rowIndex][column.field] = value;
            delete data[rowIndex].hasChanged;
            data[rowIndex].hasChanged = hasChanged;
          }
        }
      }
    }
    this.setState({ data }, () => { if (this.props.onPaste) { this.props.onPaste(this.state.data); } });
  }

  parseValue = (textValue, dataItem, column) => {
    if (this.props.parseValue) {
      return this.props.parseValue(textValue, dataItem, column)
    }

    let value = textValue;
    if (value === undefined || value === null) return undefined;

    switch (column.filter) {
      case 'numeric':
      case 'unitnumeric':
        value = value.replace(',', '.');
        value = value.match(/\./) ? parseFloat(value) : parseInt(value);
        return isNaN(value) ? undefined : value;
      case 'date':
      case 'datetime':
        const m = moment(value, DATE_FORMATS, true);
        return m.isValid() ? m.toDate() : undefined;
      case 'boolean':
        value = removeAccents(value.toLowerCase());
        return new RegExp(/^[ano|yes|1|true]$/).test(value) ? true :
          new RegExp(/^[nie|no|0|false]$/).test(value) ? false : undefined;
      default:
        return value;
    }
  }

  buildFilter = (x) => {
    switch (x.filter) {
      case 'numeric':
      case 'unitnumeric':
        return 'numeric';
      case 'boolean':
        return 'boolean';
      case 'date':
      case 'datetime':
        return 'date';
      case 'time':
      case 'enum':
      case 'buttongroup':
      case 'radio':
      case 'container':
      case 'group':
        return null;
      default:
        return 'text';
    }
  }

  buildFormat = (x) => {
    if (x.filter === 'date' || x.filter === 'datetime') {
      return x.format ? x.format : "{0:dd. MM. y}"
    }
    else if (x.filter === 'numeric' || x.filter === 'unitnumeric') {
      return x.format ? x.format : "{0:n}"
    }
    else return null;
  }

  buildWidth = (x) => {
    if (!this.state.filterable && x.compactWidth) {
      return x.compactWidth === 'auto' ? undefined : x.compactWidth;
    }
    if (x.width) {
      return x.width === 'auto' ? undefined : x.width;
    }

    switch (x.filter) {
      case 'numeric':
        return this.state.filterable ? 130 : 80;
      case 'boolean':
        return this.state.filterable ? 100 : 70;
      case 'cmd':
        return this.state.filterable ? 100 : 70;
      case 'date':
        return this.state.filterable ? 190 : 105;
      case 'text':
      case 'enum':
      default:
        return this.state.filterable ? 220 : 105;
    }
  }

  updateSort = (sort) => {
    if (!this.props.isPicker) {
      const s = [...sort, ...this.columns.map(col => ({ field: col.field + (col.filter === "container" ? "." + col.textField : ""), disabled: true })).filter(col => !sort.find(s => s.field === col.field))];
      this.props.meta.updateSort(this.getPrimaryField(), s);
    }
  }

  getColumn = (columns, col) => {
    const field = col.field + (col.filter === "container" ? "." + col.textField : "");
    let column = undefined;
    eachDeep(columns, null, (item, parent) => {
      if (item.field && !item.disabled && !item.hidden && item.field === field) {
        column = item;
      }
    });
    return column;
  }

  getCurrentCell = () => {
    return this.state.selectedCells[this.state.selectedCells.length - 1];
  }

  setColumns = () => {
    this.columns = this.getColumns();
    this.visibleColumns = this.getVisibleColumns();
  }

  getParentProps = () => {
    return this.props;
  }

  getColumns = () => {
    let columns = [];
    eachDeep(this.state.columns, null, (item, parent) => {
      if (item.filter !== 'group' && item.field && (!parent || parent.filter !== 'container')) {
        columns.push(item);
      }
    });
    return columns;
  }

  getVisibleColumns = () => {
    return _.sortBy(this.columns
      .filter(x => !x.disabled && !x.hidden)
      .map(col => ({ ...col, field: col.field + (col.filter === "container" ? "." + col.textField : "") }))
      , x => { return x.order ? x.order : 0 });
  }

  getPropsPage = () => {
    return this.props.page || this.state.page;
  }

  getChecked = (selected) => {
    if (!selected.length) {
      return false
    }
    else if (this.props.data && this.props.data.length === selected.length) {
      return true;
    }
    else {
      return null;
    }
  }

  onColumnReorder = event => {
    const columns = _.cloneDeep(this.state.columns);
    eachDeep(columns, null, (item, parent) => {
      const column = this.getColumn(event.columns, item);
      if (column) {
        item.order = column.orderIndex;
      }
    });

    this.setState({ columns }, () => { this.updateSchema(); });
  }

  onColumnResize = event => {
    if (event.end) {
      const columns = _.cloneDeep(this.state.columns);
      eachDeep(columns, null, (item, parent) => {
        const column = this.getColumn(event.columns, item);
        if (column) {
          item.width = column.width;
        }
      });

      this.setState({ columns }, () => { this.updateSchema(); });
    }
  }

  prepareFilter = (filter) => {

    return {
      id: filter.id,
      filters: filter.filters.map(x => {
        if (typeof x.value !== 'string') {
          return x;
        }

        if (x.value.startsWith('=')) {
          x.operator = 'eq';
        }
        else if (x.value.startsWith('?')) {
          x.operator = 'isnull';
        }
        else if (x.value.startsWith('!?')) {
          x.operator = 'isnotnull';
        }
        else if (x.value.startsWith('*') || x.value.startsWith('%')) {
          x.operator = 'contains';
        }
        else if (x.value.startsWith('!*') || x.value.startsWith('!%')) {
          x.operator = 'doesnotcontain';
        }
        else if (x.value.startsWith('!=') || x.value.startsWith('!')) {
          x.operator = 'neq';
        }

        return x;
      })
    };
  }

  onFilterChange = (event, timeout = true) => {
    let filter = event.filter ? this.prepareFilter(event.filter) : FILTER;


    let _page = this.getPropsPage();
    const page = { ..._page, skip: 0 };
    this.prepareRememberSelection();
    if (this.state.extendedFilter && event.nativeEvent) {
      this.setState({ fastFilter: filter, page });
      filter = this.state.filter;
    }
    else {
      this.setState({ filter, page });
    }
    if (this.props.onFilter) {
      this.props.onFilter(filter);
    }

    if (timeout) {
      if (_timer) {
        clearTimeout(_timer);
      }
      _timer = setTimeout(() => {
        this.props.onChange(this.processFilter(filter, this.state.fixedFilter), this.state.sort, page);
      }, 500);
    }
    else {
      this.props.onChange(this.processFilter(filter, this.state.fixedFilter), this.state.sort, page);
    }
  }

  onPageChange = (event) => {
    const page = event.page;
    const filter = this.props.filter || this.state.filter;
    this.prepareRememberSelection();

    if (this.state.page.take !== page.take) {
      this.props.storage.setPage(page);
    }
    this.setState({ page });
    if (_timer) {
      clearTimeout(_timer);
    }
    _timer = setTimeout(() => {
      this.props.onChange(this.processFilter(filter, this.state.fixedFilter), this.state.sort, page);
    }, 200);
  }

  onSortChange = (event) => {
    if (!((this.props.disabled | this.props.hidden) & flags.SORT)) {
      const sort = event.sort;
      const filter = this.props.filter || this.state.filter;
      this.prepareRememberSelection();
      this.props.onChange(this.processFilter(filter, this.state.fixedFilter), sort, this.getPropsPage());
      this.setState({ sort });
      //this.applyGlobalization();
      this.updateSort(sort);
    }
  }

  onColumnsSubmit = (columns) => {
    this.setState({ columns });
  }

  onSelect = (selected, selectedCells) => {
    this.props.onSelect(selected, false, this.state.selectedAll, selectedCells);
    this.setState({ selected, selectedCells });
  }

  onCopy = () => {
    this.setState({ selected: [], selectedCells: [] });
    this.props.onSelect([], true, this.state.selectedAll, []);
    this.props.onCopy();
  }

  onCancel = () => {
    this.props.onCancel(this.state.selected[0]);
  }

  prepareRememberSelection = () => {
    if (this.props.rememberSelection) {
      const primaryField = this.getPrimaryField();
      let currentPageIds = this.props.data.map(item => item[primaryField]);
      let selectedAll = this.state.selectedAll.filter(item => currentPageIds.indexOf(item[primaryField]) === -1);
      let selectedCellsAll = this.state.selectedCellsAll.filter(item => currentPageIds.indexOf(item[primaryField]) === -1);
      selectedAll = _.unionBy(this.state.selected, this.state.selectedAll, primaryField);
      selectedCellsAll = _.unionWith(this.state.selectedCells, this.state.selectedCellsAll, _.isEqual);
      this.setState({ selected: [], selectedCells: [], selectedAll, selectedCellsAll });
      this.props.onSelect([], false, selectedAll, []);
      _reselect = true;
    }
    else {
      this.setState({ selected: [], selectedCells: [] });
      this.props.onSelect([], false, [], []);
    }
  }


  onAdd = (event) => {
    this.clearSelected();
    this.props.onAdd(event);
  }

  onClear = () => {
    this.setState({ fastFilter: FILTER }, () => {
      this.onFilterChange({ filter: FILTER, }, false);
    });

  }

  selectionChange = (event) => {
    const primaryfield = this.getPrimaryField();
    const select = !event.dataItem.selected;
    const selectedCells = this.getSelectedCells([event.dataItem], select, this.state.selectedCells);
    const selected = select ? [...this.state.selected, event.dataItem] : this.state.selected.filter(x => x[primaryfield] !== event.dataItem[primaryfield]);

    this.setState((state) => {
      this.props.onSelect(selected, false, state.selectedAll, selectedCells);
      return { selected, selectedCells };
    });
  }


  headerSelectionChange = (event) => {
    const checked = event.syntheticEvent.target.checked;
    const selected = checked && this.state.selected.length === 0 ? this.props.data : [];
    const selectedCells = checked && this.state.selected.length === 0 ? this.getSelectedCells(this.props.data, true, []) : [];
    this.props.onSelect(selected, false, this.state.selectedAll, selectedCells);
    this.setState({ selected, selectedCells });
  }

  buttonSelection = (action) => {
    switch (action) {
      case 0:
        const selectedCells = this.getSelectedCells(this.props.data, true, []);
        this.setState({ selected: this.props.data, selectedCells: selectedCells });
        this.props.onSelect(this.props.data, false, this.state.selectedAll, selectedCells);
        break;
      case 1:
        this.setState({ selected: [], selectedCells: [] });
        this.props.onSelect([], false, this.state.selectedAll, []);
        break;
      default:
        break;
    }
  }

  tryRowClick = (event) => {
    const primaryField = this.getPrimaryField();

    if (this.props.modified && !this.state.selected.find(x => x[primaryField] === event.dataItem[primaryField])) {
      _lastRowEvent = event;
      this.handleOpenDiscard();
    }
    else {
      this.rowClick(event);
    }
  }

  rowClick = (event) => {
    let selected = [];
    const primaryField = this.getPrimaryField();
    if (this.props.multiple) {
      const select = !event.dataItem.selected;
      const current = this.state.data.findIndex(dataItem => dataItem[primaryField] === event.dataItem[primaryField]);

      if (event.nativeEvent && !event.nativeEvent.shiftKey) {
        _lastRowIndex = current;
      }

      if (event.nativeEvent && event.nativeEvent.ctrlKey) {
        const deselect = event.selectedCells.filter(x => x.rowIndex === current).length === 0;
        selected = select ? [...this.state.selected, event.dataItem] :
          (deselect ? this.state.selected.filter(x => x[primaryField] !== event.dataItem[primaryField]) : this.state.selected);
        if (select || deselect) { this.props.onSelect(selected, false, this.state.selectedAll, event.selectedCells); }
      }
      else {
        selected = this.state.data.filter((x, index) => index >= Math.min(_lastRowIndex, current) && index <= Math.max(_lastRowIndex, current));
      }
    }
    else {
      selected = [event.dataItem];
    }
    this.onSelect(selected, event.selectedCells);

    const lastClick = Date.now();

    if (event.type === "click" && selected.length === 1 && this.state.selected.length === 1
      && selected[0][primaryField] === this.state.selected[0][primaryField] && !this.props.onRowDoubleClick && !(flags.DETAIL & this.props.hidden)) {
      if (lastClick - _lastRowClick < 500 && this.props.onConfirm) {
        for (const row of selected) {
          if (row.inEdit)
            return;
        }

        this.props.onConfirm();
      }
    }

    _lastRowClick = lastClick;
  }

  getFilter = async () => {
    if (this.props.filter) {
      return this.processFilter(this.props.filter, this.props.fixedFilter);
    }
    let filter, fixedFilter;
    if (this.props.withMeta) {
      const f = await this.props.meta.getFilters(this.props.identif || this.props.primaryField, this.state.columns);
      filter = this.props.initFilter || { filters: f.filter(x => !x.disabled && x.CanDisabled).map(x => x.filters).flat(), id: f.find(x => !x.disabled && x.CanDisabled)?.id };
      filter.filters = filter.filters.map(x => {
        if (x.frmPod) {
          const frm = this.props.parentProps.frms.find(f => f.IFrm === x.frmPod);
          x.childField = frm.ChildField;
          x.parentField = frm.ParentField;
        }

        return x;
      });
      fixedFilter = { filters: [...(this.props.fixedFilter ? this.props.fixedFilter.filters : []), ...f.filter(x => !x.disabled && !x.CanDisabled).map(x => x.filters).flat()] };
    }
    else {
      filter = this.props.initFilter || await this.props.storage.getFilter(this.props.filterField || this.props.primaryField);
      fixedFilter = this.props.fixedFilter;
    }
    this.setState({ filter, fixedFilter, fastFilter: FILTER });
    return this.processFilter(filter, fixedFilter);
  }

  getSort = async () => {
    const sort = this.props.initSort || await this.props.meta.getSort(this.getPrimaryField(), this.state.columns);
    this.setState({ sort });
    return sort;
  }

  getPage = async () => {
    let page = await this.props.storage.getPage();
    if (page) {
      page.skip = 0;
      this.setState({ page });
      return page;
    }
    else {
      return PAGE;
    }
  }

  getSchema = async () => {
    const { onGetSchema } = this.props;

    if (!this.props.isPicker) {
      this.setState({ isSchemaLoading: true });
      const schema = await this.props.meta.getSchema(this.props.primaryField);
      if (this.props.schema.length === 0 || this.props.withMeta) {
        this.setState({ columns: schema.Grid }, () => {
          this.setColumns();
          if (onGetSchema) {
            this.props.onGetSchema(schema);
          }
        });
      }
      else {
        if (schema && schema.Grid && schema.Grid.length > 0) {
          const columns = this.props.schema.map(column => {
            const c = schema.Grid.find(x => x.field === column.field);
            if (c) {
              column.order = c.order;
              column.disabled = c.disabled || column.disabled;
              column.hidden = c.hidden || column.hidden;
              column.width = c.width || column.width;
            }
            return column;
          })
          this.setState({ columns }, () => { this.setColumns(); });
        }
      }

      this.setState({ isSchemaLoading: false });
    }
  }

  getPrimaryField = () => {
    return getPrimaryField(this.props.primaryField);
  }

  getSelected = () => {
    if (this.props.selected && this.props.rememberSelection) {
      _reselect = true;
      const primaryField = this.getPrimaryField();
      const pageIds = this.props.data.map(item => item[primaryField]);
      const selected = this.props.selected.filter(item => pageIds.indexOf(item[primaryField]) !== -1);
      const selectedAll = this.props.selected.filter(item => pageIds.indexOf(item[primaryField]) === -1);
      this.setState({ selected, selectedAll });
    } else {
      this.setState({ selected: [], selectedAll: [], selectedCells: [], selectedCellsAll: [] });
    }
  };

  getColOffset = () => {
    return (this.props.hidden & flags.COLUMN_MENU ? 0 : 1) + (this.props.multiple && (!(this.props.hidden & flags.COLUMN_SELECTED)) ? 1 : 0) + (this.props.detail ? 1 : 0);
  }

  async init() {
    //await this.getFilterList();
    await this.getSchema();
    //await this.getFilterable();
    //await this.getManagedUsers();
    const sort = await this.getSort();
    let page = await this.getPage();
    const filter = await this.getFilter();
    this.props.onChange(filter, sort, page);
    this.getSelected();
    this.oldData = this.state.data;

  }

  async componentDidMount() {
    //eventEmitter.on('CHANGE_PAGE', this.handleChangePage);
    //eventEmitter.on('CHANGE_FILTERABLE', this.handleChangeFilterable);
    //eventEmitter.on('CHANGE_SELECTED', this.handleChangeSelected);

    await this.init();
  }

  isValueChanged = (dataItem, field) => {
    const primaryField = this.getPrimaryField();
    const oldDataItem = this.oldData.find(x => x[primaryField] === dataItem[primaryField]);

    for (let j = 0; j < (field ? 1 : this.columns.length); j++) {
      const f = field ? field : this.columns[j].field.split('.');
      const oldValue = oldDataItem ? _.get(oldDataItem, f) : null;
      const newValue = _.get(dataItem, f);
      const oldValueS = (oldValue && oldValue.getTime ? oldValue.getTime() : oldValue);
      const newValueS = (newValue && newValue.getTime ? newValue.getTime() : newValue);
      if (!_.isEqual(oldValueS, newValueS)) return true;
    }
    return false;
  }

  isDataChanged = (oldData, newData) => {
    if (!oldData || !newData) {
      return false;
    }

    if (newData.length === oldData.length) {
      for (let i = 0; i < newData.length; i++) {
        for (let j = 0; j < this.columns.length; j++) {
          const field = this.columns[j].field.split('.');
          if (_.get(newData[i], field) !== _.get(oldData[i], field)) {
            return true;
          }
        }
      }
    } else return true;
    return false;
  }

  isSchemaChanged = (oldSchema, newSchema) => {
    if (newSchema.length === oldSchema.length) {
      let oldCols = [];
      let newCols = [];
      eachDeep(oldSchema, null, (item) => {
        if (item.filter !== 'group' && item.field) {
          oldCols.push({ field: item.field, disabled: item.disabled, editable: item.editable });
        }
      });
      eachDeep(newSchema, null, (item) => {
        if (item.filter !== 'group' && item.field) {
          newCols.push({ field: item.field, disabled: item.disabled, editable: item.editable });
        }
      });
      return !_.isEqual(newCols, oldCols)
    } else return true;
  }

  async componentDidUpdate(prevProps, prevState) {
    const isDataChanged = this.isDataChanged(prevProps.data, this.props.data);
    const isSchemaChanged = this.isSchemaChanged(prevProps.schema, this.props.schema);

    if (isSchemaChanged && this.props.schema.length > 0) {
      this.setState({ columns: this.props.schema }, () => { this.setColumns(); });
    }
    if (_refresh || isSchemaChanged || isDataChanged) {
      _refresh = false;
      this.setState({ data: this.props.data }, () => { this.oldData = this.state.data; });
    }
    if (prevProps.selected !== this.props.selected) {
      this.setState({ selected: this.props.selected });
    }
    const cell = this.getCurrentCell();
    if (!(this.props.hidden & flags.EDITCELL) && cell && prevState.data && this.state.data && prevState.data[cell.rowIndex] && this.state.data[cell.rowIndex] && prevState.data[cell.rowIndex].inEdit !== this.state.data[cell.rowIndex].inEdit) {
      this.focusCell();
    }
    if (_reselect && isDataChanged) {
      _reselect = false;
      const primaryField = this.getPrimaryField();
      const oldSelectedAll = [...this.state.selectedAll, ...this.state.selected]
      const pageIds = this.props.data.map(item => item[primaryField]);
      const selected = oldSelectedAll.filter(item => pageIds.indexOf(item[primaryField]) !== -1);
      const selectedAll = oldSelectedAll.filter(item => pageIds.indexOf(item[primaryField]) === -1);
      const selectedCells = this.state.selectedCellsAll.filter(item => pageIds.indexOf(item.id) !== -1);
      const selectedCellsAll = this.state.selectedCellsAll.filter(item => pageIds.indexOf(item.id) === -1);
      this.setState({ selected, selectedCells, selectedAll, selectedCellsAll });
    }

    if (this.props.withMeta && (prevProps.primaryField !== this.props.primaryField || prevProps.fixedFilter !== this.props.fixedFilter)) {
      await this.init();
    }
  }

  handleReset = async () => {
    await this.props.meta.deleteSchema(this.props.primaryField);
    await this.init();
  }

  componentWillUnmount() {
    eventEmitter.removeListener('CHANGE_PAGE', this.handleChangePage);
    eventEmitter.removeListener('CHANGE_FILTERABLE', this.handleChangeFilterable);
    eventEmitter.removeListener('CHANGE_SELECTED', this.handleChangeSelected);
  }

  handleSaveFile = async (x, dataItem) => {
    const url = x.url.replace('{id}', dataItem[x.idField]);
    const name = x.name || dataItem[x.nameField];

    try {
      const data = await this.props.requests.getBlob(url);
      saveAs(data, name);
    }
    catch (error) {
      console.error(error);
    }
  }

  buildCell = (props) => {
    const column = this.getColumn(this.state.columns, { field: props.field });
    return <Cell {...props} column={column} />;
  }

  focusCell = () => {
    const input = this.ref.current.querySelector('td.k-state-selected.k-grid-edit-cell input');
    const td = this.ref.current.querySelector('td.k-state-selected');
    const activeElement = document.activeElement;

    //Auto focus na bunky v editovatelnom gride. #start
    const editableInput = this.ref.current.querySelector('tr.k-master-row.k-state-selected.k-grid-edit-row input');

    if (editableInput) {
      if (editableInput.type === "text")
        editableInput.focus();
    }
    //Auto focus na bunky v editovatelnom gride. #end

    if (input) {
      if ((activeElement && input !== activeElement) || !activeElement) {
        if (input.type === 'checkbox') {
          input.focus();
        } else {
          input.select();
        }
      }
    } else {
      if (td) {
        td.focus();
      }
    }
  }

  onCellClick = (event, cellProps) => {
    const { dataItem, field } = cellProps;
    if (field === "selected" || field === "") { return; }
    this.selectCell(event, cellProps);
    if (!(this.props.hidden & flags.EDITCELL) && !event.shiftKey && !event.ctrlKey) {
      this.enterEdit(dataItem, field);
    }
  }

  cellRender = (tdElement, cellProps) => {
    //console.log(">> cell render");
    const primaryField = this.getPrimaryField();
    const { dataItem, field } = cellProps;
    const rowIndex = this.state.data.findIndex(x => x[primaryField] === dataItem[primaryField]);
    const colIndex = this.visibleColumns.findIndex(x => x.field === field);
    const columnIndex = this.getColOffset() + colIndex;
    const column = this.visibleColumns[colIndex];

    const additionalProps = (field && field === dataItem['inEdit'] && colIndex !== -1) ? {} :
      {
        onClick: (e) => {
          this.onCellClick(e, { dataItem, field, rowIndex, columnIndex });
        },
        tabIndex: "0"
      };

    var tdProps = { ...tdElement.props, ...additionalProps };
    const selectedCells = this.state.selectedCells;
    const newValue = _.get(dataItem, field);

    tdProps.className = tdProps.className.replace(" k-state-selected", "");
    tdProps.className = tdProps.className.replace(" k-state-changed", "");

    if (column && column.locked && !tdProps.className.match(/k-grid-content-sticky/)) {
      tdProps.className += ' k-grid-content-sticky';
    }
    if (selectedCells.find(item => item.rowIndex === rowIndex && item.columnIndex === columnIndex && this.props.cellSelect)) {
      tdProps.className += " k-state-selected";
    }
    if (dataItem.hasChanged && field && this.isValueChanged(dataItem, field)) {
      tdProps.className += " k-state-changed";
    }

    const td = React.cloneElement(tdElement, { ...tdProps, ...additionalProps }, tdElement.props.children);
    const tdRendered = this.props.cellRender ? this.props.cellRender(td, cellProps) : td;

    return <MemoCell tdElement={tdRendered} isPicker={this.props.isPicker} value={newValue} Id={dataItem[primaryField]} />;
  }

  filterCellRender = (element, filterCellProps) => {
    const { field, filterType } = filterCellProps;
    const colIndex = this.visibleColumns.findIndex(x => x.field === field);
    const column = this.visibleColumns[colIndex];

    if (filterType == "numeric" && column && column.format && !column.filterCell) {
      const el = element.props.children.props.children[0];
      const operatorEl = element.props.children.props.children[1];
      const formattedEl = React.cloneElement(el, { ...el.props, format: fieldFormat(column.format) }, el.props.children);
      const formattedElRendered = this.props.filterCellRender ? this.props.filterCellRender(formattedEl, filterCellProps) : formattedEl;
      return (
        <div className={"k-filtercell"}>
          <div className={"k-filtercell-wrapper"}>
            {formattedElRendered}
            {operatorEl}
          </div>
        </div>
      );
    }
    return element;
  }

  selectCell = (e, cellProps) => {
    const minCol = this.getColOffset();
    const primaryField = this.getPrimaryField();
    let { dataItem, field } = cellProps;
    let selectedCells = this.state.selectedCells;
    const colIndex = this.visibleColumns.findIndex(x => x.field === field);

    if (!e.nativeEvent.ctrlKey || e.nativeEvent.shiftKey || !this.props.multiple || colIndex === -1) { selectedCells = []; }

    if (colIndex !== -1) {
      let columnIndex = minCol + colIndex;
      let rowIndex = this.state.data.findIndex(x => x[primaryField] === dataItem[primaryField]);
      const id = dataItem[primaryField];
      const cell = { id, field, rowIndex, columnIndex };

      _lastCell = _lastCell || this.getCurrentCell();

      if (e.nativeEvent.shiftKey && this.props.multiple && _lastCell) {
        const minRowIndex = Math.min(rowIndex, _lastCell.rowIndex);
        const maxRowIndex = Math.max(rowIndex, _lastCell.rowIndex);
        const minColIndex = Math.min(columnIndex, _lastCell.columnIndex);
        const maxColIndex = Math.max(columnIndex, _lastCell.columnIndex);
        for (let i = minRowIndex; i <= maxRowIndex; i++) {
          for (let j = minColIndex; j <= maxColIndex; j++) {
            const _i = (rowIndex >= _lastCell.rowIndex) ? i : maxRowIndex - i + minRowIndex;
            const _j = (columnIndex >= _lastCell.columnIndex) ? j : maxColIndex - j + minColIndex;
            const dataItemS = this.props.data[_i];
            const idS = dataItemS[primaryField];
            const fieldS = this.visibleColumns[_j - minCol].field;
            const cellS = { id: idS, field: fieldS, rowIndex: _i, columnIndex: _j };
            selectedCells.push(cellS)
          }
        }
      } else {
        _lastCell = null;
        if (!selectedCells.find(item => item.id === id && item.field === field)) {
          selectedCells = [...selectedCells, cell];
        } else {
          selectedCells = selectedCells.filter(item => item.id !== id || item.field !== field);
        }
      }
    }

    e.dataItem = dataItem;
    e.selectedCells = selectedCells;
    this.tryRowClick(e);
  }

  getSelectedCells = (dataItems, select, oldSelectedCells) => {
    const primaryField = this.getPrimaryField()
    const minCol = this.getColOffset();
    const maxCol = this.visibleColumns.length - 1 + minCol;

    let selectedCells = oldSelectedCells;
    for (let i = 0; i < dataItems.length; i++) {
      const dataItem = dataItems[i];
      const id = dataItem[primaryField];
      const rowIndex = this.state.data.findIndex(item => item[primaryField] === id);

      selectedCells = selectedCells.filter(cell => cell.rowIndex !== rowIndex)
      if (select) {
        for (let j = minCol; j <= maxCol; j++) {
          const field = this.visibleColumns[j - minCol].field;
          const cell = { id: id, field: field, rowIndex: rowIndex, columnIndex: j };
          selectedCells.push(cell);
        }
      }
    }

    return selectedCells;
  }

  itemChange = (event) => {
    const primaryField = this.getPrimaryField();
    const { onItemChange } = this.props;
    const hasChanged = this.isValueChanged({ ...event.dataItem, [event.field]: event.value }) ? true : undefined;

    this.setState(state => {
      const data = onItemChange ? onItemChange(event, state.data)
        :
        state.data.map(item =>
          item[primaryField] === event.dataItem[primaryField] ?
            { ...item, [event.field]: event.value, hasChanged: hasChanged } : item
        );
      return { data };
    });
  }

  rowRender = (trElement, props) => {
    const primaryField = this.getPrimaryField();
    const rowIndex = this.state.data.findIndex(x => x[primaryField] === props.dataItem[primaryField]);
    const selectedRowCells = this.state.selectedCells.filter(x => x.rowIndex === rowIndex);

    const rowProps = {
      ...this.props.rowProps && this.props.rowProps(props),
      onContextMenu: (e) => {
        if (this.props.contextMenu && this.props.contextMenu.length > 0) {
          e.preventDefault();
          this.handleContextMenuOpen(e, props);
        }
      }
    }

    const tr = React.cloneElement(trElement, { ...trElement.props, ...rowProps }, trElement.props.children);

    return <MemoRow
      trElement={tr}
      dataItem={props.dataItem}
      selectedRowCells={selectedRowCells}
      columns={this.state.columns}
      multiple={this.props.multiple}
      hidden={this.props.hidden}
      disabled={this.props.disabled}
      isPicker={this.props.isPicker}
    />;
  }

  buildText = (id) => {
    return this.props.discardText ? this.props.discardText : id ? `Máte rozpracovaný záznam ${id}. Chcete zahodiť zmeny?` : "Máte rozpracovaný nový záznam. Chcete zahodiť zmeny?"
  }

  buildDeleteText = (selected) => {
    let ids = selected && selected.map(id => ` ${id[this.getPrimaryField()]}`);
    return selected && selected.length > 1 ? `Určite chcete zmazať záznamy ${ids}?` : `Určite chcete zmazať záznam ${ids}?`;
  }

  buildMinResizableWidth = (x) => {
    if (x.minResizableWidth) {
      return x.minResizableWidth;
    }

    switch (x.filter) {
      case 'boolean':
        return 40;
      case 'date':
        return 80;
      default:
        return 10;
    }
  }

  buildColumns() {
    let cols = [];

    if (!((this.props.hidden & flags.COLUMN_MENU)) && !(this.props.disabled & flags.COLUMN_MENU)) {
      cols.push(<Column
        filterable={false}
        sortable={false}
        key="columnMenu"
        field=""
        resizable={false}
        reorderable={false}
        width="15px"
        locked={true}
        columnMenu={props => <ColumnMenu {...props}
          columns={this.state.columns}
          onColumnsSubmit={this.onColumnsSubmit}
          filterable={this.state.filterable}
          onSwitchFilterable={this.handleSwitchFilterable}
        />}
      />);
    }

    if (this.state.columns.length === 0) {
      cols.push(<Column
        filterable={false}
        sortable={false}
        key="empty"
        field=""
        resizable={false}
        reorderable={false}
        width="auto"
        locked={true}
      />);
    }

    if (this.props.multiple && (!(this.props.hidden & (flags.COLUMN_SELECTED | flags.ONLY_SELECT)) && !(this.props.disabled & (flags.COLUMN_SELECTED | flags.ONLY_SELECT)))) {
      cols.push(<Column
        filterable={false}
        sortable={false}
        key="selected"
        field={this.props.disabled & flags.COLUMN_SELECTED ? false : "selected"}
        resizable={false}
        reorderable={false}
        width="50px"
        locked={true}
        headerCell={props =>
          <SelectionMenu
            {...props}
            actions={this.props.actions}
            disabled={Boolean(this.props.disabled & flags.COLUMN_SELECTED)}
            onHeaderSelectionChange={this.headerSelectionChange}
            checked={this.getChecked(this.state.selected)}
            buttonSelection={this.buttonSelection}
            selectedCount={this.state.selected.length}
          />}
      />);
    }

    eachDeep(this.state.columns, null, (x) => {
      x.subcols = undefined;
    });

    eachDeep(this.state.columns, null, (x, parent) => {
      if (x.field && !x.disabled && !x.hidden && !(parent && parent.filter !== 'group' && parent.schema)) {
        const isActionOrGroup = x.filter === 'cmd' || x.filter === 'group' || x.filter === 'file';
        const buildedFilter = this.buildFilter(x);

        const col = (x.filter === 'group' ?
          <Column
            key={x.field}
            field={x.field}
            title={x.title}
            filter={buildedFilter}
            width={x.width}
            orderIndex={x.order}
            className={x.filter}
            filterable={x.Filterable}
            sortable={x.Sortable}
            editable={false}
            locked={x.locked}
          >
            {x.subcols ? x.subcols : null}
          </Column>
          :
          <Column
            key={x.field}
            field={x.filter === 'container' ? `${x.field}.${x.textField}` : x.field}
            title={x.title}
            filter={buildedFilter}
            format={this.buildFormat(x)}
            width={this.buildWidth(x)}
            cell={(x.cell ? this.buildCell :
              x.filter === 'boolean' ? this.EditBooleanCell :
                (x.filter === 'enum' || x.filter === 'buttongroup' || x.filter === 'radio') ? this.EditDropdownCell :
                  x.filter === 'file' ? this.FileCell :
                    x.filter === 'container' ? this.EditPickerCell :
                      x.filter === 'dateTime' ? this.EditDateTimeCell :
                        x.filter === 'time' ? this.EditTimeCell :
                          x.filter === 'text' ? this.EditTextCell :
                            x.filter === 'phoneNumber' ? this.EditPhoneNoCell : null)
            }
            filterCell={(x.filter === 'enum' || x.filter === 'buttongroup' || x.filter === 'radio') ? DropdownFilterCell(this.props.parentProps[x.dataField], ENUM_DEFAULT, null, null, x.textField, x.primaryField) : x.filterCell}
            orderIndex={x.order}
            className={x.filter + (x.required ? ' required' : '')}
            filterable={!isActionOrGroup && x.Filterable} // Je potrebné aby bol zapnutý aj filter zo schémy
            sortable={!isActionOrGroup && x.Sortable} // Je potrebné aby bol zapnutý aj sort zo schémy
            editable={x.editable}
            editor={buildedFilter}
            locked={x.locked}
            headerCell={props =>
              <div className="k-link" onClick={typeof props.onClick === 'function' ? props.onClick : (e) => e.preventDefault()}>
                <ToolTip place="top" label={x.tooltip || ''}>
                  <React.Fragment>
                    <span >{props.title}</span>{props.children}
                  </React.Fragment>
                </ToolTip>
              </div>
            }
            minResizableWidth={this.buildMinResizableWidth(x)}
          />);

        if (!parent) {
          cols.push(col)
        } else {
          if (!parent.subcols) {
            parent.subcols = [];
          }
          parent.subcols.push(col);
        }
      }
    });

    if (!this.props.isPicker && !(this.props.hidden & flags.EDIT)) {
      cols.push(
        <Column
          key="akcie"
          field="akcie"
          cell={this.EditCommandCell}
          filterable={false}
          sortable={false}
          reorderable={false}
          orderIndex={1000000}
          locked={true}
          width="200px"
        />);
    }

    return cols;
  }

  processFilter = (filter, fixedFilter) => {
    if (!fixedFilter) {
      return { filters: [...filter.filters, ...this.state.fastFilter.filters] };
    }

    return { filters: [...filter.filters, ...fixedFilter.filters, ...this.state.fastFilter.filters] };
  }

  handleRefresh = () => {
    _refresh = true;
    this.props.onChange(this.processFilter(this.props.filter || this.state.filter, this.state.fixedFilter), this.state.sort, this.getPropsPage());
  }

  render() {
    const primaryField = this.getPrimaryField();
    const selected = this.props.selected || this.props.rememberSelection ? _.unionBy(this.state.selected, this.state.selectedAll, primaryField) : this.state.selected;
    const data = this.state.columns.length === 0 || !this.state.data ? [] : this.state.data.map(x => ({ ...x, selected: selected.find(e => e[primaryField] === x[primaryField]) !== undefined }));
    const id = selected.length === 1 ? selected[0][primaryField] : null;
    const filter = this.state.extendedFilter ? this.state.fastFilter : this.props.filter || this.state.filter;
    const expandChange = this.props.detail ? this.expandChange : null;
    const page = this.getPropsPage();

    return (
      <div >
        <Grid
          cellRender={this.cellRender}
          //filterCellRender={this.filterCellRender}
          className={this.props.className}
          data={data}
          detail={this.props.detail}
          editField="inEdit"
          expandField="expanded"
          filter={filter}
          filterOperators={FILTER_OPERATORS}
          filterable={false} //{this.state.filterable} filtre na stlpcami schovane
          onColumnReorder={this.onColumnReorder}
          onColumnResize={this.onColumnResize}
          onExpandChange={expandChange}
          onFilterChange={this.onFilterChange}
          onHeaderSelectionChange={this.headerSelectionChange}
          onItemChange={this.itemChange}
          onPageChange={this.onPageChange}
          onRowDoubleClick={this.props.onRowDoubleClick}
          onSelectionChange={this.selectionChange}
          onSortChange={this.onSortChange}
          pageable={this.props.disabled & flags.PAGING ? false : {
            info: true,
            type: 'input',
            pageSizes: [5, 10, 25, 50, 100],
            previousNext: true
          }}
          resizable
          reorderable
          rowRender={this.rowRender}
          selectedField="selected"
          skip={page.skip}
          sort={(this.props.disabled | this.props.hidden) & flags.SORT ? [] : this.state.sort}
          sortable={{
            allowUnsort: true,
            mode: 'multiple'
          }}
          style={this.props.style}
          take={page.take}
          total={this.props.total}
        >
          {!(this.props.hidden & flags.TOOLBAR) && //false &&//zatial je toolbar schovany
            <GridToolbar>
              <Toolbar
                handleOpenFilter={this.handleOpenFilter}
                handleOpenSort={this.handleOpenSort}
                onAdd={this.onAdd}
                onDelete={this.handleOpenDelete}
                onCopy={this.onCopy}
                onCancel={this.onCancel}
                disabled={this.props.disabled}
                hidden={this.props.hidden}
                selected={selected}
                onRefresh={this.handleRefresh}
                onClear={this.onClear}
                toolbarHidden={this.props.toolbarHidden}
                filterLength={this.props.filter?.filters.length || this.state.filter?.filters.length}
                onSwitchFilterable={this.handleSwitchFilterable}
                filterable={this.state.filterable}
                imgName={this.props.imgName}
                onExport={this.handleExport}
                onReset={this.handleOpenReset}
                showToolbarButtons={this.props.showToolbarButtons}
                customToolbarBreadcrumb={this.props.customToolbarBreadcrumb}
                data={this.state.data}
                frms={this.props.parentProps?.frms || []}
                selectedFrm={this.props.parentProps?.selectedFrm}
                handleChangeFrm={this.props.parentProps?.handleChangeFrm}
                withMeta={this.props.withMeta}
                isPicker={this.props.isPicker}
                userFilterList={this.state.userFilter}
                handleConfirmFilter={this.handleConfirmFilter}
                filter={this.props.filter || this.state.filter}
              >
                {typeof this.props.toolbarActions === 'function' ? this.props.toolbarActions() : this.props.toolbarActions}
              </Toolbar>
            </GridToolbar>
          }
          {this.buildColumns()}
        </Grid>
        <LoadingPanel isLoading={this.props.isLoading} />
        <Dialog
          open={this.state.isOpenDelete}
          onClose={this.handleCloseDelete}
          contentText={this.buildDeleteText(selected)}
          title={"Otázka"}
          actions={[
            {
              label: "Zatvoriť",
              color: 'primary',
              onClick: this.handleCloseDelete,
              keyboardFocused: true
            },
            {
              label: "Potvrdiť",
              color: 'primary',
              onClick: this.handleConfirmDelete,
              keyboardFocused: true
            }
          ]}
        />
        <Dialog
          open={this.state.isOpenDiscard}
          onClose={this.handleCloseDiscard}
          contentText={this.buildText(id)}
          title={"Otázka"}
          actions={[
            {
              label: "Áno",
              color: 'primary',
              onClick: this.handleConfirmDiscard,
              keyboardFocused: true
            },
            {
              label: "Nie",
              color: 'primary',
              onClick: this.handleCloseDiscard,
              keyboardFocused: true
            }
          ]}
        />
        <Dialog
          open={this.state.isOpenDiscard}
          onClose={this.handleCloseDiscard}
          contentText={this.buildText(id)}
          title={"Otázka"}
          actions={[
            {
              label: "Áno",
              color: 'primary',
              onClick: this.handleConfirmDiscard,
              keyboardFocused: true
            },
            {
              label: "Nie",
              color: 'primary',
              onClick: this.handleCloseDiscard,
              keyboardFocused: true
            }
          ]}
        />
        <Dialog
          open={this.state.isOpenReset}
          onClose={this.handleCloseReset}
          contentText={'Obnoviť predvolené nastavenia formulára?'}
          title={"Otázka"}
          actions={[
            {
              label: "Áno",
              color: 'primary',
              onClick: this.handleConfirmReset,
              keyboardFocused: true
            },
            {
              label: "Nie",
              color: 'primary',
              onClick: this.handleCloseReset,
              keyboardFocused: true
            }
          ]}
        />
        <DialogFilter
          schema={this.columns}
          isOpen={this.state.isOpenFilter}
          filter={this.props.filter || this.state.filter}
          enumDefault={ENUM_DEFAULT}
          primaryField={this.getPrimaryField()}
          userFilter={!Boolean(this.props.hidden & flags.USER_FILTER)}
          userFilterDisabled={Boolean(this.props.disabled & flags.USER_FILTER)}
          parentProps={this.props.parentProps}
          handleOpenFilter={this.handleOpenFilter}
          handleCloseFilter={this.handleCloseFilter}
          handleConfirmFilter={this.handleConfirmFilter}
        />
        <DialogSort
          isOpen={this.state.isOpenSort}
          handleCloseSort={this.handleCloseSort}
          handleConfirmSort={this.handleConfirmSort}
          sort={this.state.sort}
          schema={this.columns.filter(col => !(col.Sortable === false))}
        />
      </div>
    );
  }
}

GridComponent.propTypes = {
  actions: PropTypes.array,
  cellSelect: PropTypes.bool,
  cellRender: PropTypes.func,
  className: PropTypes.string,
  contextMenu: PropTypes.array,
  data: PropTypes.array.isRequired,
  dataItem: PropTypes.object,
  detail: PropTypes.any,
  disabled: PropTypes.number,
  discardText: PropTypes.string,
  enterEdit: PropTypes.func,
  filter: PropTypes.object,
  filterCellRender: PropTypes.func,
  fixedFilter: PropTypes.object,
  page: PropTypes.object,
  hidden: PropTypes.number,
  isLoading: PropTypes.bool.isRequired,
  imgName: PropTypes.string,
  initFilter: PropTypes.object,
  initSort: PropTypes.array,
  isPicker: PropTypes.bool,
  modified: PropTypes.bool,
  multiple: PropTypes.bool,
  withMeta: PropTypes.bool,
  onAdd: PropTypes.func,
  onCancel: PropTypes.func,
  onChange: PropTypes.func.isRequired,
  onConfirm: PropTypes.func,
  onCopy: PropTypes.func,
  onDelete: PropTypes.func,
  onExpandChange: PropTypes.func,
  onFilter: PropTypes.func,
  onGetSchema: PropTypes.func,
  onRowDoubleClick: PropTypes.func,
  onSelect: PropTypes.func,
  parentProps: PropTypes.object,
  parseValue: PropTypes.func,
  primaryField: PropTypes.string.isRequired,
  rememberSelection: PropTypes.bool,
  rowProps: PropTypes.func,
  schema: PropTypes.array.isRequired,
  showToolbarButtons: PropTypes.bool,
  customToolbarBreadcrumb: PropTypes.string,
  selected: PropTypes.array,
  storage: PropTypes.object.isRequired,
  style: PropTypes.object,
  toolbarHidden: PropTypes.bool,
  total: PropTypes.number.isRequired,
  filterField: PropTypes.string
}

export default withMeta(withStorage(GridComponent));