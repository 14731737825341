import actionTypes from '../../actions/actionTypes';


const _init = {
  I_ADR: null,
  ADRESA: '',
  ZOZ_I_ZONA: ''
}

const _initState = {
  isLoading: false,
  data: _init
}

const adresa = (state = _initState, action) => {
  switch (action.type) {
    case actionTypes.GET_ADRESA_REQUEST:
      return { data: [], isLoading: true };

    case actionTypes.GET_ADRESA_SUCCESS:
      return { isLoading: false, data: action.res }

    case actionTypes.GET_ADRESA_FAILURE:
      return _initState;

    default:
      return state
  }
}

export default adresa;