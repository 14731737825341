import * as React from "react";
import { connect } from "react-redux";
import Typography from "../../coraWebMComponents/dataDisplay/Typography";
import getSharedStyles from "../../coraWebMComponents/sharedStyles";
import classNames from "classnames";
import { withStyles } from "@material-ui/core/styles";
import withWidth from "@material-ui/core/withWidth";
import { withLocalizationConsumer } from "../../coraWebMComponents/utils/withLocalization";
import { IconButton } from "@material-ui/core";
import { Link } from "react-router-dom";
import ArrowBack from "../../coraWebMComponents/dataDisplay/icons/ArrowBack";
import routes from "../../routes";

const styles = (theme) => ({
  ...getSharedStyles(theme),
  paperCard: {
    padding: "20px",
    [theme.breakpoints.up("sm")]: {
      padding: "30px",
    },
    [theme.breakpoints.up("md")]: {
      padding: "45px",
    },
    [theme.breakpoints.up("lg")]: {
      padding: "60px",
    },
  },
  backButton: {
    backgroundColor: "transparent!important",
    paddingLeft: "0px",
  },
});

class PublicZone extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      text: "",
    };
  }
  componentDidMount = async () => {
    const txt = await this.props.translateWithLocaleAsync(
      this.props.locale,
      "DM_VEREJNA_ZONA"
    );
    this.setState({ text: txt });
  };

  render() {
    const { classes } = this.props;
    return (
      <div className="container" style={{ marginTop: "70px" }}>
        <Link to={routes.PRELOGIN}>
          <IconButton
            className={classNames(classes.backButton, classes.ml1, classes.mr6)}
            color="inherit"
            toolTip={this.props.translate("back")}
            toolTipPlace="right"
          >
            <ArrowBack
              color="secondary"
              variant="fab"
              style={{ fontSize: 30 }}
            />
          </IconButton>
        </Link>
        <div className={classNames(classes.mt3, classes.textCenter)}>
          <Typography
            variant="h6"
            className={classNames(classes.textCenter, classes.fontBold)}
          >
            Verejná zóna
          </Typography>
        </div>
        {this.state.text.length > 0 && (
          <div className={classNames(classes.mt4, classes.mb4)}>
            <Typography className={classNames(classes.textCenter)}>
              {this.state.text}
            </Typography>
          </div>
        )}
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    isAuth: state.user.data !== null,
    locale: state.locale.locale,
  };
};

export default withLocalizationConsumer(
  connect(mapStateToProps)(withStyles(styles)(withWidth()(PublicZone)))
);
