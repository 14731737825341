import * as requests from '../coraWebMComponents/utils/requests';

const buildGetListSubsidiesRatingFilter = (io, iStav = '') => {

  if (iStav === 0)
    iStav = '';

  return { filters: [{ field: "I_O", operator: "in", value: io }, { field: "I_STAV", operator: "in", value: iStav }] };
}

export const getListSubsidiesRating = async (io, iStav) => {
  try {
    let filter = buildGetListSubsidiesRatingFilter(io, iStav);
    let page = { skip: 1, take: 1000 };
    return await requests.getList('/api/Subsidies/DT_DT', filter, '', page);

  }
  catch (error) {
    throw error;
  }
}

export const getListSubsidyDetail = async (iDt) => {
  try {
    let filter = { filters: [{ field: "I_DT", operator: "in", value: iDt }] };
    let page = { skip: 1, take: 1000 };
    let sort = [{ field: "C_ZORAD_PKRT", dir: "asc"}, { field: "C_ZORAD_KRT", dir: "asc"}, { field: "I_PKRT", dir: "asc"}];
    return await requests.getList('/api/Subsidies/DT_TXT', filter, sort, page);

  }
  catch (error) {
    throw error;
  }
}

export const getListSubsidyRatingDetail = async (iDt) => {
  try {
    let filter = { filters: [{ field: "I_DT", operator: "in", value: iDt }] };
    let page = { skip: 1, take: 1000 };
    let sort = [{ field: "C_ZORAD_KRT", dir: "asc"}];
    return await requests.getList('/api/Subsidies/DT_HOD', filter, sort, page);

  }
  catch (error) {
    throw error;
  }
}

/*export const updSubsidyRating = async (iDt, hod) => {
  const url = `/api/Subsidies/DT_HOD?id=${iDt}&hod=${hod !== null ? hod : ''}`;
  try {
    return await requests.put(url);
  }
  catch (error) {
    throw error;
  }
}*/

export const updSubsidyRating = async (data) => {
  try {
    return await requests.put(`/api/Subsidies/DT_HOD`, data);
  }
  catch (error) {
    throw error;
  }
}