import { TextField, MenuItem, Button, Stack } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';

import "./treeViewControl.css";

import sk from './sk.json';

const LOCALE = sk.treeViewControl;

/**
 * Komponent sluzi na zobrazenie filtrov levelov a hromadne nastavovanie rozbalenych levelov.
 *
 * @param {*} { 
 *    currentExpanded - prave rozbalene uzly
 *    setCurrentExpanded - handler na zmenu zobrazenych uzlov
 *    allovedLevels  - povolene levely na zobrazenie
 *    handleClickExpanded - handler pre rozbalenie uzla
 *    handleClickUnexpanded - handler pre zbalenie uzla
 *    themeCustom  - referencia na custom temu, pre riadenie breakpoints
 * }
 * @return {*} 
 */
const TreeViewControl = ({ currentExpanded, setCurrentExpanded, allovedLevels = [], handleClickExpanded, handleClickUnexpanded, themeCustom }) => {
  const matchesThemeMD = useMediaQuery(themeCustom?.breakpoints?.down('xs'));

  let allowedCurrentExpandedInc = currentExpanded > Math.max(...allovedLevels) ? Math.max(...allovedLevels) : currentExpanded;

  return (
    <Stack className='controll-wrapper' direction={matchesThemeMD ? 'column' : 'row'} spacing={2}>
      <TextField
        onChange={(e) => { setCurrentExpanded(e.target.value) }}
        sx={{ width: matchesThemeMD ? "100%" : "14em" }}
        label={LOCALE.expandLabel}
        value={allowedCurrentExpandedInc}
        select
        fullWidth
        size="small"
      >
        {allovedLevels.map(item =>
          <MenuItem key={item} value={item}>{LOCALE.levelItem} {item}</MenuItem>
        )}
      </TextField>
      <Button
        onClick={handleClickExpanded}
        sx={{ width: matchesThemeMD ? "100%" : "16em" }}
        color='success'
        variant={'contained'}>
        {LOCALE.expandButtonLabel}
      </Button>
      <Button
        onClick={handleClickUnexpanded}
        sx={{ width: matchesThemeMD ? "100%" : "16em" }}
        color='warning'
        variant={'contained'}>
        {LOCALE.unexpandButtonLabel}
      </Button>
    </Stack>
  )
}

export default TreeViewControl