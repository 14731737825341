import React from 'react';
import PropTypes from 'prop-types';
import { useEffect, useState } from 'react';
import { connect } from 'react-redux';
import withWidth from '@material-ui/core/withWidth';
import getSharedStyles from '../../coraWebMComponents/sharedStyles';
import { withStyles } from '@material-ui/core/styles';
import IconButton from '../../coraWebMComponents/inputs/IconButton';
import classNames from 'classnames';
import ArrowBack from '../../coraWebMComponents/dataDisplay/icons/ArrowBack';
import Typography from '../../coraWebMComponents/dataDisplay/Typography';
import * as taxesActions from '../../actions/taxesActions';
import * as userActions from '../../actions/userActions';
import { setTitle } from './Taxes';
import ProceedingsInstallments from './ProceedingsInstallments';
import DialogIcon from '../../coraWebMComponents/feedBack/DialogIcon';
import EuroIcon from '../../coraWebMComponents/dataDisplay/icons/EuroIcon';
import CreditCard from '../../coraWebMComponents/dataDisplay/icons/CreditCardIcon';
import routes from '../../routes';
import * as taxesUtils from '../../utils/taxesUtils';
import { useNavigate } from 'react-router';

const _currencyCode = 'EUR';

const styles = theme => ({
  ...getSharedStyles(theme),

})

/** Splátky k rozhodnutiu */
const Installments = (props) => {
  const navigate = useNavigate()
  const { classes } = props;

  // vybrané konanie
  const [actualProceedingItem, setActualProceedingItem] = useState(null);

  let title = '';
  // doplnenie predpisov splátok k jednotlivým konaniam
  let listProceeds = props.listProceedings.map(item => ({
    ...item,
    N_PRJ_E: setTitle(item.N_PRJ_E, title),
    installments: props.listInstallments.filter(x => x.I_KON === item.I_KON)
  }));

  // inicializačné načítanie dát
  useEffect(() => {
    async function fetchData() {
      // zoznam konaní
      await props.getListProceedings(props.zozCisRozh, props.person.I_O);
      // zoznam splátok
      await props.getListInstallments(props.zozIPoh, props.person.I_O);
    }
    fetchData();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // otvorenie dialógu pre výber spôsobu platby (Platobná brána, resp. Platobné údaje)
  const handleOpenAlert = (name, item) => {
    setActualProceedingItem(item);
  };

  // zatvorenie dialógu pre výber spôsobu platby (Platobná brána, resp. Platobné údaje)
  const handleCloseAlert = (name) => {
    setActualProceedingItem(null);
  }

  // platba
  const handlePayment = async (type) => {

    // načítanie metadát prebiehajúcej platby
    const { itemProceeding, selectedInstallments } = actualProceedingItem;

    setActualProceedingItem(null);

    const { I_KON, CIS_ROZH } = itemProceeding;

    if (type === 1) { // platobna brana

      const { VS, SSY } = itemProceeding;

      // údaje pre platbu - vygenerovanie sprievodky
      const payData = { 'items': [], 'totalAmount': 0, 'totalAmountDec': 0, currencyCode: _currencyCode };
      const items = [];
      selectedInstallments.forEach(index => {

        const itemInstallment = itemProceeding.installments[index];

        items.push({ IPoh: itemInstallment.I_POH, SkSpl: itemInstallment.PREDPIS });

        const name = `${itemProceeding.N_PRJ_E}, č.kon.: ${itemInstallment.C_KON}, splátka č. ${itemInstallment.CISLO_SPLATKY}`;

        const amountDec = Number(itemInstallment.PREDPIS);
        payData.totalAmountDec = Number(payData.totalAmountDec) + amountDec;

        const amount = Math.round(amountDec * 100);
        payData.totalAmount = Number(payData.totalAmount) + amount;

        payData.items.push({ name, amount, amountDec });
      })

      let vsExt;
      try {
        // získanie externého variabilného symbolu platby (vygenerovanie záznamu v tabuľke NUM.E_B_VS_EXT)
        const res = await taxesUtils.getVsExt(VS, SSY, I_KON, null, items);
        vsExt = res.Data;
      } catch (error) {
        console.error(error);
      }
      if (!vsExt || !vsExt.IBVsExt || vsExt.IBVsExt <= 0 || !vsExt.VsExt || vsExt.VsExt.length <= 0) {
        props.dispatch(userActions.showErrorMsg('Platba sa nepodarila.'))
        return;
      }

      // pôvodné riesenie - TODO: odstrániť nepotrebné údaje (VS, CENA, ...)
      let paymentData = { VS: vsExt.VsExt, CENA: payData.totalAmountDec, LANG: 'sk', EMAIL: props.user.data.n_uz };

      // už iba hodnota 1 (0 pre pôvodné riešenie sa už nepoužíva)
      const payMethod = 1;
      // ID projektu
      const prjId = 4005;
      // typ: 3 - Dane a Poplatky
      const payType = 3;
      // ID tabuľky NUM.E_B_VS_EXT
      const iPk = vsExt.IBVsExt;
      // variabilný symbol platby - VS_EXT z tabuľky NUM.E_B_VS_EXT
      const vs = vsExt.VsExt;
      // požadovaná jazyková mutácia platby
      const language = 'SK';
      // mailová adresa pre zaslanie informácie o platbe
      const email = paymentData.EMAIL;

      // sprievodka
      const waybill = { payMethod, prjId, payType, iPk, vs, language, email, 'transactionId': null };

      paymentData = { ...paymentData, waybill, payData };

      navigate(routes.PAYMENT_GATEWAY, { state: paymentData });
    }
    else {
      await props.getProceedingDetail(I_KON, CIS_ROZH);
      let totalAmount = 0;
      selectedInstallments.forEach(index => {

        const itemInstallment = itemProceeding.installments[index];
        const amount = Number(itemInstallment.PREDPIS);
        totalAmount = Number(totalAmount) + amount;
      })

      navigate(routes.PAYMENT_PAY, { state: { payType: 2, totalAmount } }) // payType 1 = parkovanie, 2 = Dane a poplatky
    }
  }

  return (
    <div>
      <div className='container w-margin w-padding'>
        <div className={classNames(classes.dFlex, classes.justifyContentStart, classes.alignItemsCenter, classes.pt3, classes.mb2)}>
          <IconButton
            className={classNames(classes.backButton, classes.p0)}
            color="inherit"
            onClick={props.onClose}
            toolTip="Späť"
            toolTipPlace="right"
            disableRipple
            disableHover
          >
            <ArrowBack
              color='secondary'
              fontSize='default'
              variant='fab'
              style={{ fontSize: 30 }}
            />
          </IconButton>
          <Typography style={{ paddingLeft: '1em' }} className={classes.fontBold}>Splátky</Typography>
        </div>
        {listProceeds.map(item =>
          <ProceedingsInstallments
            itemProceeding={item}
            openAlert={handleOpenAlert}
            dispatch={props.dispatch}
          />
        )}
      </div>
      <DialogIcon
        open={actualProceedingItem}
        onClose={() => handleCloseAlert('openAlertPayment')}
        title='Spôsob platby'
        actions={[
          {
            label: 'Platobná brána',
            onClick: () => handlePayment(1),
            icon: <CreditCard />
          },
          {
            label: 'Platobné údaje',
            onClick: () => handlePayment(2),
            icon: <EuroIcon />
          }
        ]}
      />
    </div>
  )
}

Installments.propTypes = {
  onClose: PropTypes.func,
  zozCisRozh: PropTypes.string,
  zozIPoh: PropTypes.string,
}

const mapStateToProps = (state) => ({
  person: state.person.data,
  listInstallments: state.listInstallments.data,
  listProceedings: state.listProceedings.data,
  proceeding: state.proceeding.data,
  isLoading: state.listProceedings.isLoading,
  user: state.user,
  paymentGatewaySettings: state.paymentGatewaySettings.data.Data
})

const mapDispatchToProps = (dispatch) => ({
  getListInstallments: (zozIPoh, iO) => dispatch(taxesActions.getListInstallments(zozIPoh, iO)),
  getListProceedings: (zozCisRozh, iO) => dispatch(taxesActions.getListProceedings(zozCisRozh, iO)),
  getProceedingDetail: (iKon, cRozh) => dispatch(taxesActions.getProceedingDetail(iKon, cRozh)),
  dispatch
});

export default connect(mapStateToProps, mapDispatchToProps)(withStyles(styles)(withWidth()(Installments)));

